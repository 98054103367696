import React from 'react';
import { useMediaQuery, useTheme } from 'vendor/material';
import { CSVLink } from 'react-csv';
import { Button } from '@tackle-io/platform-ui';
import { Download } from 'mdi-material-ui';

import useStyles from './CSVDownloadButton.styles';
import { escapeObjectArray, escapeArray } from '../../utils/csvDownload';

interface CSVDownloadButtonProps {
  headers: string[];
  data: any[];
  filename: string;
  id: string;
}

const CSVDownloadButton: React.FC<CSVDownloadButtonProps> = ({
  headers = [],
  data = [],
  filename = '',
  id = '',
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down(768));

  const cleanHeaders = escapeArray(headers);
  const cleanData = escapeObjectArray(data);
  return (
    <CSVLink
      headers={cleanHeaders}
      data={cleanData}
      filename={filename}
      className={classes.csvLink}
    >
      <Button
        variant="outlined"
        appearance="secondary"
        className={classes.exportButton}
        startIcon={<Download />}
        id={id}
      >
        {screenMobile ? null : ' Export CSV'}
      </Button>
    </CSVLink>
  );
};

export default CSVDownloadButton;
