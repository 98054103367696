import { isNil, reduce } from 'lodash/fp';

import { Action, Status } from 'stores/typings';

import { withConvenienceBooleans } from 'stores/utils';

import { PrivateOffer, PrivateOffersReducerState } from './typings';

const determineStatus = (state: PrivateOffersReducerState): Status =>
  isNil(state.content) ? 'idle' : 'hasValue';

type Reducer = (
  s: PrivateOffersReducerState,
  a: Action,
) => PrivateOffersReducerState;

const syncReducer: Reducer = (s: PrivateOffersReducerState) => ({
  ...s,
  status: 'syncing' as Status,
});

const setContentReducer = (s: PrivateOffersReducerState, a: Action) => ({
  ...s,
  content: a.payload.content,
  status: 'hasValue' as Status,
});

const syncOneReducer = (s: PrivateOffersReducerState) => ({
  ...s,
  selectedOffer: null,
  selectedOfferStatus: 'syncing' as Status,
});

const setOneReducer = (s: PrivateOffersReducerState, a: Action) => ({
  ...s,
  selectedOffer: a.payload.content,
  selectedOfferStatus: 'hasValue' as Status,
});

const mergeUpdatedOfferWithExistingOffers =
  ({ payload: { id: offerId, content: updatedOffer } }: Action) =>
  (offers: PrivateOffer[], offer: PrivateOffer) =>
    offer.poId === offerId
      ? offers.concat([updatedOffer])
      : offers.concat([offer]);

const updateContentByIdReducer = (s: PrivateOffersReducerState, a: Action) => ({
  ...s,
  selectedOffer: a.payload.content,
  content: reduce(mergeUpdatedOfferWithExistingOffers(a), [])(s.content),
  status: 'hasValue' as Status,
  error: null,
});

const setErrorReducer = (s: PrivateOffersReducerState, a: Action) => ({
  ...s,
  error: a.payload.error,
  status: 'hasError' as Status,
  selectedOfferStatus: 'hasError' as Status,
});

const clearErrorReducer = (s: PrivateOffersReducerState) => ({
  ...s,
  error: null,
  status: determineStatus(s),
});

const updateReducer = (s: PrivateOffersReducerState) => ({
  ...s,
  error: null,
  status: 'hasValue' as Status,
});

const clearSelectedOfferReducer = (s: PrivateOffersReducerState) => ({
  ...s,
  error: null,
  selectedOffer: null,
  selectedOfferStatus: 'idle' as Status,
});

const updateListSearchParamsReducer = (
  s: PrivateOffersReducerState,
  a: Action,
) => ({
  ...s,
  listSearchParams: a.payload.content,
});

export enum PrivateOfferReducerAction {
  Sync = 'SYNC',
  SyncOne = 'SYNC_ONE',
  SetContent = 'SET_CONTENT',
  SetOne = 'SET_ONE',
  UpdateContentById = 'UPDATE_CONTENT_BY_ID',
  SetError = 'SET_ERROR',
  ClearError = 'CLEAR_ERROR',
  Update = 'UPDATE',
  ClearSelectedOffer = 'CLEAR_SELECTED_OFFER',
  UpdateListSearchParams = 'UPDATE_LIST_SEARCH_PARAMS',
}

const privateOfferStateReducers: { [at: string]: Reducer } = {
  [PrivateOfferReducerAction.Sync]: syncReducer,
  [PrivateOfferReducerAction.SetContent]: setContentReducer,
  [PrivateOfferReducerAction.SyncOne]: syncOneReducer,
  [PrivateOfferReducerAction.SetOne]: setOneReducer,
  [PrivateOfferReducerAction.UpdateContentById]: updateContentByIdReducer,
  [PrivateOfferReducerAction.SetError]: setErrorReducer,
  [PrivateOfferReducerAction.ClearError]: clearErrorReducer,
  [PrivateOfferReducerAction.Update]: updateReducer,
  [PrivateOfferReducerAction.ClearSelectedOffer]: clearSelectedOfferReducer,
  [PrivateOfferReducerAction.UpdateListSearchParams]:
    updateListSearchParamsReducer,
};

const privateOfferReducer = (
  state: PrivateOffersReducerState,
  action: Action,
): PrivateOffersReducerState => {
  const { type } = action;
  const reducerForActionType = privateOfferStateReducers[type];

  if (!reducerForActionType) {
    throw new Error(`Private offers store: Unhandled action type: ${type}`);
  }

  const updatedState = reducerForActionType(state, action);

  return withConvenienceBooleans(updatedState) as PrivateOffersReducerState;
};

export default privateOfferReducer;
