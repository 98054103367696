import { setIn, getIn } from 'final-form';

// Lifted from this lovely snippet
// https://gist.github.com/manzoorwanijk/5993a520f2ac7890c3b46f70f6818e0a
function makeValidateForm(schema) {
  return async (values) => {
    try {
      await schema.validate(values, { abortEarly: false });
      return {};
    } catch (err) {
      if (err.name !== 'ValidationError') {
        throw err;
      }

      const errors = err.inner.reduce((formError, innerError) => {
        const { path, message, params } = innerError;

        if (getIn(formError, path)) {
          return formError;
        }

        return setIn(formError, path, {
          message,
          params,
        });
      }, {});

      return errors;
    }
  };
}

export default makeValidateForm;
