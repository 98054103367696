import { Typography, Link } from 'vendor/material';

import { Page } from '../../components';
import Email from '../../utils/constants';

import useStyles from './Error.styles';

function Error(props) {
  const classes = useStyles();

  return (
    <Page size="md">
      <Typography component="h1" variant="h4" className={classes.header}>
        {props.children || "Whoops, we're sorry about that!"}
      </Typography>
      <Typography component="h2" variant="body2" className={classes.subheader}>
        If you continue to run into this issue, please{' '}
        <Link href={`mailto:${Email.Support}`}>contact support</Link>. Otherwise{' '}
        <Link href="/">click here</Link> to start over.
      </Typography>
    </Page>
  );
}

export default Error;
