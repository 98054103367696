import {
  AceInvitationCloudStatusEnum,
  TagColorEnum,
} from 'packages/cosell/src/types/enums';
import { Tag } from 'pages/CoSell/components/Tag';

const statusToColorMapping: {
  [key in AceInvitationCloudStatusEnum]: TagColorEnum;
} = {
  [AceInvitationCloudStatusEnum.INVITED]: TagColorEnum.STANDARD,
  [AceInvitationCloudStatusEnum.ACCEPTED]: TagColorEnum.GREEN,
  [AceInvitationCloudStatusEnum.REJECTED]: TagColorEnum.RED,
};

interface AwsInvitationStatusTagProps {
  status: AceInvitationCloudStatusEnum | string;
}

const AceInvitationLabelsOverrideMap = {
  [AceInvitationCloudStatusEnum.INVITED]: 'Pending',
};
export const AwsInvitationStatusTag = (props: AwsInvitationStatusTagProps) => {
  return (
    <Tag color={statusToColorMapping[props.status]}>
      {AceInvitationLabelsOverrideMap[props.status] || props.status}
    </Tag>
  );
};
