import { decorate, observable } from 'mobx';
import { assign, countBy, sumBy, findLast } from 'lodash-es';

import CASReport from './CASReport';

class CASReportGroup {
  name = '';

  loaded = false;

  frequency = null;

  lastFailingReport = null;

  lastSuccessfulReport = null;

  totalLoads = 0;

  totalFailures = 0;

  reports = [];

  constructor(casReports = []) {
    const [{ name, frequency }] = casReports;

    const reports = addMissingDates(casReports);
    const loaded = countBy(reports, 'loaded').true > 0 || false;
    const totalLoads = sumBy(reports, 'loadTotal');
    const totalFailures = countBy(reports, 'failure').true || 0;
    const lastFailingReport = findLast(reports, (r) => r.failure);
    const lastSuccessfulReport = findLast(reports, (r) => !r.failure);

    assign(this, {
      name,
      loaded,
      frequency,
      lastFailingReport,
      lastSuccessfulReport,
      totalLoads,
      totalFailures,
      reports,
    });
  }
}

function addMissingDates(reports = []) {
  return reports.reduce((newReports, report, index, reports) => {
    if (reports.length <= 1) return reports;
    newReports = newReports.concat([report]);

    if (index === reports.length - 1) return newReports;

    let currPubDate = report.pubDate;
    const nextPubDate = reports[index + 1].pubDate;

    currPubDate = currPubDate.plus({ day: 1 });
    if (currPubDate.hasSame(nextPubDate, 'day')) return newReports;

    let { days } = currPubDate.diff(nextPubDate, 'day');
    while (days < 0) {
      const missingReport = new CASReport({
        name: report.name,
        pub_date: currPubDate,
        failure: true,
      });

      currPubDate = currPubDate.plus(1, 'day');
      newReports = newReports.concat([missingReport]);
      days++;
    }

    return newReports;
  }, []);
}

decorate(CASReportGroup, {
  name: observable,
  loaded: observable,
  frequency: observable,
  lastFailingReport: observable,
  lastSuccessfulReport: observable,
  totalLoads: observable,
  totalFailures: observable,
  reports: observable,
});

export default CASReportGroup;
