import { useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { CompanyAvatar } from '@tackle-io/platform-ui';
import classnames from 'classnames';
import { Avatar, IconButton, Typography, Skeleton } from 'vendor/material';
import { truncate } from 'lodash-es';

import { gql } from '@apollo/client';
import { useGetVendorProfileInformationQuery } from 'generated/graphql';

import { pendoIdGenerator } from 'utils/idGenerator';
import { accountSwitcherElements } from 'utils/pendoEnums';

import NavTooltip from './NavTooltip';
import AccountSwitcher from './AccountSwitcher/AccountSwitcher';

// ASSETS
import useStyles from './VendorProfile.styles';

// HELPERS
import { Auth } from '../../stores';

export const GET_VENDOR_PROFILE_QUERY = gql`
  query getVendorProfileInformation {
    currentUser {
      id
      isSuperAdmin
      allowedVendors
      vendor {
        id
        name
        logo_url
      }
    }
  }
`;

const CTA_TEXT = 'Switch accounts';

const VendorProfile = ({
  drawerOpen,
  productionVendorName,
  productionVendorId,
}: {
  drawerOpen: boolean;
  productionVendorName?: string;
  productionVendorId?: string;
}): JSX.Element => {
  const { expired } = useContext(Auth);
  const { data, loading } = useGetVendorProfileInformationQuery();
  const classes = useStyles();
  const [isOpen, setMenuOpen] = useState(false);
  const canSwitch = !loading && data?.currentUser?.allowedVendors?.length > 1;
  // If logged into a sandbox vendor_type, display its production vendor name
  const accountName = productionVendorName || data?.currentUser?.vendor?.name;
  const activeVendorId = productionVendorId || data?.currentUser?.vendor?.id;
  // EVENTS
  function toggleMenu(): void {
    if (canSwitch) setMenuOpen(!isOpen);
  }

  const closeMenu = (): void => setMenuOpen(false);

  if (expired) {
    return null;
  }

  // STYLES
  const cssProfile = classnames(classes.vendorProfile, {
    [classes.vendorProfileCollapsed]: !drawerOpen,
    [classes.switchEnabled]: canSwitch,
  });

  const tooltipText = canSwitch ? CTA_TEXT : data?.currentUser?.vendor?.name;

  return (
    <div>
      <NavTooltip text={tooltipText || ''} disabled={loading || drawerOpen}>
        <div
          className={cssProfile}
          onClick={toggleMenu}
          role="presentation"
          id={pendoIdGenerator(
            accountSwitcherElements.ACCOUNT_SWITCH_OPEN_MODAL,
          )}
        >
          <IconButton aria-haspopup="true" color="inherit" disabled>
            {loading ? (
              <Skeleton variant="circle" className={classes.skeleton}>
                <Avatar className={classes.avatar} />
              </Skeleton>
            ) : (
              <CompanyAvatar
                logo={data?.currentUser?.vendor?.logo_url}
                alt={data?.currentUser?.vendor?.name}
                className={classes.avatar}
              />
            )}
          </IconButton>
          {drawerOpen && (
            <div style={{ flexGrow: 1 }}>
              {loading ? (
                <Skeleton
                  variant="text"
                  width="75%"
                  className={classes.skeleton}
                />
              ) : (
                <>
                  <Typography className={classes.vendorProfileName}>
                    {truncate(accountName, {
                      length: 19,
                    })}
                  </Typography>
                  {canSwitch && (
                    <Typography className={classes.vendorProfileID}>
                      {CTA_TEXT}
                    </Typography>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </NavTooltip>
      <AccountSwitcher
        open={isOpen}
        onClose={closeMenu}
        currentVendorId={activeVendorId}
        isSuperAdmin={data?.currentUser?.isSuperAdmin}
      />
    </div>
  );
};

export default observer(VendorProfile);
