import React from 'react';
import { DateTime } from 'luxon';
import { Button, Link } from '@tackle-io/platform-ui';

import {
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
} from 'vendor/material';
import {
  AlertCircleOutline,
  ClockOutline,
  Git,
  SourceBranch,
  Sync,
} from 'mdi-material-ui';

import useApiDetails from 'stores/api/hooks';
import buildEnvironment from 'utils/buildEnvironment';
import { formatDate } from 'utils/dates';

const DownstreamEnvironmentList: React.FC = () => {
  const branchURL = `https://github.com/tackle-io/downstream-app/tree/${buildEnvironment.branch}`;
  const commitURL = `https://github.com/tackle-io/downstream-app/commits/${buildEnvironment.sha1}`;

  return (
    <List
      subheader={<ListSubheader>Downstream</ListSubheader>}
      disablePadding
      dense
    >
      <ListItem
        component={Link}
        to={branchURL}
        disableStyles
        external
        dense
        title="Git Branch"
      >
        <ListItemIcon>
          <SourceBranch />
        </ListItemIcon>
        <ListItemText primary={buildEnvironment.branch} />
      </ListItem>
      <ListItem
        component={Link}
        to={commitURL}
        disableStyles
        external
        dense
        title="Git Commit SHA1"
      >
        <ListItemIcon>
          <Git />
        </ListItemIcon>
        <ListItemText primary={buildEnvironment.sha1.substr(0, 7)} />
      </ListItem>
    </List>
  );
};

const APIEnvironmentList: React.FC = () => {
  const {
    content: apiDetails,
    error: apiError,
    isSyncing,
    sync,
  } = useApiDetails();

  const branchURL = `https://github.com/tackle-io/tackle-upstream-api/tree/${apiDetails.gitBranch}`;
  const commitURL = `https://github.com/tackle-io/tackle-upstream-api/commits/${apiDetails?.gitCommit}`;

  return (
    <List
      subheader={
        <ListSubheader>
          <Box display="flex">
            <Box flexGrow={1}>Upstream</Box>
            <Box>
              <Button
                appearance="secondary"
                variant="text"
                startIcon={<Sync />}
                loading={isSyncing}
                disabled={isSyncing}
                onClick={sync}
              />
            </Box>
          </Box>
        </ListSubheader>
      }
      disablePadding
      dense
    >
      {apiError && (
        <ListItem dense>
          <ListItemIcon>
            <AlertCircleOutline />
          </ListItemIcon>
          <ListItemText
            primary="Unable to fetch latest API version"
            secondary={`Reason: ${apiError}`}
          />
        </ListItem>
      )}
      {apiDetails?.gitBranch && (
        <ListItem
          component={Link}
          to={branchURL}
          disableStyles
          external
          dense
          title="Git Branch"
        >
          <ListItemIcon>
            <SourceBranch />
          </ListItemIcon>
          <ListItemText primary={apiDetails.gitBranch} />
        </ListItem>
      )}
      {apiDetails?.gitCommit && (
        <ListItem
          component={Link}
          to={commitURL}
          disableStyles
          external
          dense
          title="Git Commit SHA1"
        >
          <ListItemIcon>
            <Git />
          </ListItemIcon>
          <ListItemText primary={apiDetails?.gitCommit.substr(0, 7)} />
        </ListItem>
      )}
      {apiDetails?.buildTimestamp && (
        <ListItem dense title="API Build Time">
          <ListItemIcon>
            <ClockOutline />
          </ListItemIcon>
          <ListItemText
            primary={formatDate(
              apiDetails?.buildTimestamp,
              DateTime.DATETIME_FULL,
            )}
          />
        </ListItem>
      )}
    </List>
  );
};

const EnvironmentList = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div {...props} ref={ref}>
    <DownstreamEnvironmentList />
    <APIEnvironmentList />
  </div>
));

export default EnvironmentList;
