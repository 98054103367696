import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  previewLabel: {
    backgroundColor: theme.palette.NEUTRAL030,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.NEUTRAL700,
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 400,
    height: theme.spacing(2.5),
    width: theme.spacing(8),
    justifyContent: 'center',
    padding: '1px, 4px',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default useStyles;
