import { decorate, observable } from 'mobx';
import { assign } from 'lodash-es';
import { toDateTime } from '../utils/dates';

class ActivityItem {
  action = '';

  description = '';

  createdAt = null;

  user = {};

  constructor(props) {
    let { created_at: createdAt = null, ...rest } = props;
    createdAt = toDateTime(createdAt);

    assign(this, { ...rest, createdAt });
  }
}

decorate(ActivityItem, {
  action: observable,
  description: observable,
  createdAt: observable,
  user: observable,
});

export default ActivityItem;
