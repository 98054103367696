import { decorate, observable } from 'mobx';
import { get, camelCase, assign, isPlainObject, omit } from 'lodash-es';
import { mapKeys } from 'lodash/fp';
import { toDateTime, infinity } from '../utils/dates';
import { checksum } from '../utils/hashing';

class Event {
  datetime = '';

  description = '';

  eventType = '';

  sourceMetadata = {};

  constructor(props) {
    let { datetime = null } = props;
    datetime = toDateTime(datetime, infinity);

    const description = get(props, 'description');
    const type = get(props, 'event_type');
    const eventId = checksum(`${datetime}-${type}-${description}`);

    let sourceMetadata = get(props, 'source_metadata', {});
    if (!isPlainObject(sourceMetadata)) {
      sourceMetadata = {};
    }

    let group_id = get(props, 'group_id');
    let event_id = get(props, 'event_id');

    assign(this, { sourceMetadata, datetime, eventId, group_id, event_id });

    const baseProps = omit(props, ['source_metadata', 'datetime']);
    assign(this, mapKeys((k) => camelCase(k))(baseProps));
  }
}

decorate(Event, {
  datetime: observable,
  source_metadata: observable,
});

export default Event;
