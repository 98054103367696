import { Cloud, CloudType, isCloudType } from 'utils/cloudTypes';

import {
  isListingType,
  ListingType,
  ListingTypeType,
} from 'utils/listingTypes';

export enum Pricing {
  SimplePricingV1Aws = 'simple-pricing-v1-aws',
  SimplePricingV1Azure = 'simple-pricing-v1-azure',
  SimplePricingV2Gcp = 'simple-pricing-v2-gcp',
  GcpMarketplacePricingV1 = 'gcp-marketplace-pricing-v1',
  MarketplacePricingV1Redhat = 'marketplace-pricing-v1-redhat',
  PricingAwsAmiHourlyAnnualV1 = 'pricing-aws-ami-hourly-annual-v1',
  PricingAwsAmiContractV1 = 'pricing-aws-ami-contract-v1',
  AzurePricingLongTermSaasV1 = 'azure-pricing-long-term-saas-v1',
  AzureMarketplacePricingV1 = 'azure-marketplace-pricing-v1',
}

type PricingType =
  | Pricing.SimplePricingV1Aws
  | Pricing.SimplePricingV1Azure
  | Pricing.SimplePricingV2Gcp
  | Pricing.GcpMarketplacePricingV1
  | Pricing.MarketplacePricingV1Redhat
  | Pricing.PricingAwsAmiHourlyAnnualV1
  | Pricing.PricingAwsAmiContractV1
  | Pricing.AzurePricingLongTermSaasV1
  | Pricing.AzureMarketplacePricingV1;

export function isPricingType(pricingType: PricingType) {
  return Object.values(Pricing).indexOf(pricingType) !== -1;
}

export const DEFAULT_CLOUD_PRICING = {
  [Cloud.Aws]: Pricing.SimplePricingV1Aws,
  [Cloud.Gcp]: Pricing.SimplePricingV2Gcp,
  [Cloud.Azure]: Pricing.AzurePricingLongTermSaasV1,
  [Cloud.Redhat]: Pricing.MarketplacePricingV1Redhat,
};

const CLOUD_AND_LISTING_TYPE_PRICING = {
  [Cloud.Aws]: {
    [ListingType.Saas]: Pricing.SimplePricingV1Aws,
    [ListingType.AmiHourlyAnnual]: Pricing.PricingAwsAmiHourlyAnnualV1,
    [ListingType.AmiContract]: Pricing.PricingAwsAmiContractV1,
  },
  [Cloud.Gcp]: {
    [ListingType.Standard]: Pricing.SimplePricingV2Gcp,
  },
  [Cloud.Azure]: {
    [ListingType.FlatRate]: Pricing.AzurePricingLongTermSaasV1,
  },
};

export function getPricingByCloudAndListingType(
  cloud: CloudType,
  listingType: ListingTypeType,
  isAzureLTSPricing?: boolean,
): PricingType {
  if (!cloud) {
    console.error('No cloud provided to determine pricing.');

    return null;
  }

  if (!isCloudType(cloud)) {
    console.error(`Invalid cloud: ${cloud} used to determine pricing.`);

    return null;
  }

  const pricingByCloudDefault = DEFAULT_CLOUD_PRICING[cloud];

  if (!pricingByCloudDefault) {
    console.error(`Cloud: ${cloud} has no default pricing.`);

    return null;
  }

  if (cloud === Cloud.Azure && !isAzureLTSPricing) {
    return Pricing.SimplePricingV1Azure;
  }

  if (!listingType) {
    return pricingByCloudDefault;
  }

  if (listingType && !isListingType(listingType)) {
    console.error(
      `Invalid listingType: ${listingType} used to determine pricing. Using cloud: ${cloud} default.`,
    );

    return pricingByCloudDefault;
  }

  const pricingByCloud = CLOUD_AND_LISTING_TYPE_PRICING[cloud];
  const pricingByCloudAndListingType = pricingByCloud[listingType];

  return pricingByCloudAndListingType ?? pricingByCloudDefault;
}

/** alias of Record<Cloud, Pricing> */
export interface CloudToPricingVersion extends Record<Cloud, Pricing> {}
