import { useQuery } from '@tanstack/react-query';
import { getTackleCoSellOpportunity } from 'packages/salesforce-canvas/api/requests/getTackleCoSellOpportunity';

export function useTackleCoSellOpportunity({
  id,
}: {
  /** tackle-cosell-id */
  id?: string;
}) {
  const {
    data: opportunity,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['tackle-cosell-opportunity', id],
    queryFn: () => getTackleCoSellOpportunity({ id }),
    select: (data) => {
      return { ...data, id };
    },
    enabled: !!id,
    staleTime: Infinity,
    retry: 3,
  });

  return {
    opportunity,
    error,
    isLoading,
    refetch,
  };
}
