import { decorate, action, computed } from 'mobx';
import { filter, find, replace } from 'lodash/fp';

import Store from './Store';
import api from 'utils/api';
import { Product } from '../models';
import { reportError } from 'utils/monitor';

class ProductsStore extends Store {
  get products() {
    return super.data;
  }

  set products(products) {
    super.data = products;
  }

  // Insert/update a product
  sync(product) {
    // find existing, replace with updated
    this.products = [
      ...this.products.filter(
        (p) => p.productid_internal !== product.productid_internal,
      ),
      product,
    ];
  }

  // Insert/update a product from a JSON API response
  syncFromJson = (productJson, provider) => {
    this.products = [
      ...this.products.filter(
        (product) =>
          product.productid_internal !== productJson.productid_internal,
      ),
      new Product(productJson, provider),
    ];
  };

  create = (json) => {
    super.loading = true;

    api
      .post('product', { json })
      .json()
      .then(({ product }) => {
        this.products.push(new Product(product));
        super.loading = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };

  load = () => {
    super.loading = true;

    this.products = ['aws', 'azure', 'gcp', 'redhat'].reduce(
      (products, provider) => {
        const localProducts =
          JSON.parse(localStorage.getItem(`products-${provider}`)) || [];
        return products.concat(
          localProducts.map((p) => new Product(p, provider)),
        );
      },
      [],
    );

    if (this.products.length) {
      super.loading = false;
      super.syncing = true;
    }

    api
      .get('product')
      .json()
      .then(({ aws = [], azure = [], gcp = [], redhat = [] }) => {
        try {
          ['aws', 'azure', 'gcp', 'redhat'].forEach((provider) => {
            localStorage.setItem(
              `products-${provider}`,
              JSON.stringify({ aws, azure, gcp, redhat }[provider]),
            );
          });
        } catch (e) {
          if (e.name !== 'QuotaExceededError') reportError(e);
        }

        aws = aws.map((p) => new Product(p, 'aws'));
        azure = azure.map((p) => new Product(p, 'azure'));
        gcp = gcp.map((p) => new Product(p, 'gcp'));
        redhat = redhat.map((p) => new Product(p, 'redhat'));
        this.products = [...aws, ...azure, ...gcp, ...redhat];

        super.loading = false;
        super.syncing = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };

  filterInvalid = filter(({ listing, cloud }) => this.find(listing, cloud));

  find = (id, provider) => {
    const predicate = { productid: replace('lst_', '', id) };
    if (provider) predicate.provider = provider;

    const products = this.products || [];
    const product = find(predicate, products);

    if (product) return product;
    return {};
  };

  findByInternalId = (internalId) =>
    (this.products || []).find(
      ({ productid_internal }) => productid_internal === internalId,
    );

  updateProduct = (provider, rawProduct) => {
    const localStorageKey = `products-${provider}`;
    const localProducts =
      JSON.parse(localStorage.getItem(localStorageKey)) || [];
    const localProductsIndex = localProducts.findIndex(
      (p) => p.productid === rawProduct.productid,
    );
    localProducts[localProductsIndex] = rawProduct;

    try {
      localStorage.setItem(localStorageKey, JSON.stringify(localProducts));
    } catch (e) {
      if (e.name !== 'QuotaExceededError') reportError(e);
    }

    const productsIndex = this.products.findIndex(
      (p) => p.productid === rawProduct.productid,
    );
    this.products[productsIndex] = new Product(rawProduct, provider);
  };
}

decorate(ProductsStore, {
  products: computed,
  create: action,
  load: action,
  updateProduct: action,
});

export default ProductsStore;

const productsStore = new ProductsStore();
export { productsStore };
