import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@tackle-io/platform-ui';

import { Cloud } from 'stores/typings';
import { OffersDataId } from '../../utils/intercomEnums';

interface MarketplaceFeeFieldProps {
  cloud: Cloud;
}

const MarketplaceFeeField: React.FC<MarketplaceFeeFieldProps> = ({ cloud }) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      name="pricing.marketplaceFee"
      control={control}
      render={({ ref, ...props }): React.ReactElement => (
        <TextField
          {...props}
          label="Marketplace fee"
          id="pricing.marketplaceFee"
          data-id={OffersDataId.PRIVATE_OFFERS_MARKETPLACE_FEE_FIELD}
          aria-label="Pricing marketplace fee"
          error={errors.pricing?.marketplaceFee?.message}
          inputProps={{ ref }}
          mode="numberformat"
          fixedDecimalScale
          suffix="%"
          numberPrefix=""
          type="number"
          decimalScale={cloud === 'gcp' ? 6 : 2}
        />
      )}
    />
  );
};

export default MarketplaceFeeField;
