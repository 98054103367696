import { Banner, Link, Select, TextField } from '@tackle-io/platform-ui';
import FieldsSelectDate from 'pages/PrivateOffers/components/FieldsSelectDate/FieldsSelectDate';
import {
  convertISODateStringToLocalJSDate,
  maxServiceStartDate,
  minServiceEndDate,
  minServiceStartDate,
} from 'pages/PrivateOffers/utils/formatFormData';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  BillingTermValue,
  PaymentModelValue,
  BillingTermValueType,
  PaymentModelValueType,
  PrivateOffer,
  OfferType,
} from 'stores/privateOffers/typings';
import { pendoIdGenerator } from 'utils/idGenerator';
import { privateOffersUIElements } from 'utils/pendoEnums';
import { Grid } from 'vendor/material';
import {
  billingTermOptionsByCloud,
  paymentModelOptionsByCloud,
} from './constants';
import { getBillingTermDefault, getDurationValueDefault } from './utils';
import { Cloud } from 'utils/cloudTypes';
import React, { useMemo } from 'react';
import {
  billingTermFormPath,
  durationValueFormPath,
  getDurationMinMax,
  paymentModelFormPath,
  scheduleFormPath,
  serviceStartAtFormPath,
  serviceEndAtFormPath,
} from 'components/FieldsPricing/utils';
import useStyles from './FieldsPricingConfig.styles';
import { GcpProduct } from 'stores/products/typings';
import { useSelectedProduct } from 'stores/products/hooks';

interface FieldsPricingConfigProps {
  cloud: Cloud;
  offer: PrivateOffer;
  selectedProductId?: string;
  showFDAPricing?: boolean;
  isAmendmentOffer: boolean;
  offerType?: OfferType;
}

const getPaymentModelGridSize = (
  offerType: OfferType,
  isAmendmentOffer: boolean,
  isFDAOffer: boolean,
) =>
  isAmendmentOffer && offerType === OfferType.Direct
    ? 12
    : isFDAOffer || offerType === OfferType.PartnerResale
    ? 3
    : 4;

const FieldsPricingConfig: React.FC<FieldsPricingConfigProps> = ({
  cloud,
  offer,
  isAmendmentOffer,
  selectedProductId = '',
  showFDAPricing = false,
  offerType = OfferType.Direct,
}) => {
  const { control, errors, setValue } = useFormContext();
  const classes = useStyles();
  const { content: selectedProduct } = useSelectedProduct(
    selectedProductId,
    cloud,
  );

  const paymentModelOptions =
    paymentModelOptionsByCloud(isAmendmentOffer)[cloud];

  let billingTermOptions = billingTermOptionsByCloud[cloud];
  if (!showFDAPricing) {
    billingTermOptions = billingTermOptions.filter(
      ({ value }) => value !== BillingTermValue.FutureDated,
    );
  }

  const durationValueDefaultValue = getDurationValueDefault(offer);

  const selectedBillingTerm = useWatch({
    control,
    name: billingTermFormPath,
  }) as BillingTermValueType;

  const defaultBillingTerm =
    selectedBillingTerm ?? billingTermOptions.at(0)?.value;
  const billingTermDefaultValue = getBillingTermDefault(
    offer,
    defaultBillingTerm,
  );

  const selectedPaymentModel = useWatch({
    control,
    name: paymentModelFormPath,
  }) as PaymentModelValueType;

  const paymentModelDefaultValue =
    selectedPaymentModel ??
    offer?.metadata?.pricing?.paymentModel ??
    paymentModelOptions[0]?.value;

  const shouldShowMonths =
    !(isAmendmentOffer && offerType === OfferType.Direct) &&
    selectedBillingTerm === BillingTermValue.Custom;
  const isFDAOffer = selectedBillingTerm === BillingTermValue.FutureDated;

  const offerServiceStartAtJSDate = convertISODateStringToLocalJSDate(
    offer?.metadata?.pricing?.serviceStartAt,
  );
  const offerServiceEndAtJSDate = convertISODateStringToLocalJSDate(
    offer?.metadata?.pricing?.serviceEndAt,
  );

  const handlePaymentModelChange = (onChange, value): void => {
    onChange(value);

    // if toggling to a per product payment model, clear any existing payments
    if (value === PaymentModelValue.PerProduct) {
      setValue(scheduleFormPath, []);
    }
  };

  const { min: durationMin, max: durationMax } = useMemo(() => {
    return getDurationMinMax(
      cloud,
      selectedPaymentModel as PaymentModelValueType,
      selectedBillingTerm as BillingTermValueType,
    );
  }, [cloud, selectedPaymentModel, selectedBillingTerm]);

  const paymentModelGridSize = getPaymentModelGridSize(
    offerType,
    isAmendmentOffer,
    isFDAOffer,
  );

  const shouldShowMaxStartDateField =
    cloud === Cloud.Aws && offerType === OfferType.PartnerResale;

  const showGCPFutureDatedNotEnabledWarning =
    cloud === Cloud.Gcp &&
    selectedBillingTerm === BillingTermValue.FutureDated &&
    selectedProduct &&
    !(selectedProduct as GcpProduct).isEnrolledInFutureDatedOffers;

  const shouldShowFDADateFields =
    isFDAOffer &&
    (cloud === Cloud.Gcp || offerType === OfferType.Direct) &&
    !isAmendmentOffer;

  return (
    <Grid container spacing={2}>
      <Grid item xs={paymentModelGridSize} className={classes.hideClearAll}>
        <Controller
          control={control}
          name={paymentModelFormPath}
          defaultValue={paymentModelDefaultValue}
          render={({ ref, onChange, value, ...props }): React.ReactElement => (
            <Select
              {...props}
              label="Payment model"
              data-id={pendoIdGenerator(
                privateOffersUIElements.PRIVATE_OFFERS_PAYMENT_MODEL_SELECT_DROPDOWN,
              )}
              placeholder="Payment model"
              defaultValue={paymentModelDefaultValue}
              onChange={({ target: { value } }): void =>
                handlePaymentModelChange(onChange, value)
              }
              disabled={isAmendmentOffer}
            >
              {paymentModelOptions.map(({ text, value }) => (
                <option key={value} value={value}>
                  {text}
                </option>
              ))}
            </Select>
          )}
        />
      </Grid>
      <Controller
        control={control}
        name={billingTermFormPath}
        defaultValue={billingTermDefaultValue}
        render={({ ref, onChange, value, ...props }): React.ReactElement => (
          <>
            {!(isAmendmentOffer && offerType === OfferType.Direct) && (
              <Grid
                item
                xs={isFDAOffer || offerType === OfferType.PartnerResale ? 3 : 4}
                className={classes.hideClearAll}
              >
                <Select
                  {...props}
                  label="Billing term"
                  data-id={pendoIdGenerator(
                    privateOffersUIElements.PRIVATE_OFFERS_BILLING_TERM_SELECT_DROPDOWN,
                  )}
                  placeholder="Billing term"
                  onChange={({ target: { value } }): void => onChange(value)}
                  defaultValue={billingTermDefaultValue}
                >
                  {billingTermOptions.map(({ text, value }) => (
                    <option key={value} value={value}>
                      {text}
                    </option>
                  ))}
                </Select>
              </Grid>
            )}
          </>
        )}
      />
      {shouldShowMonths && (
        <Controller
          control={control}
          name={durationValueFormPath}
          defaultValue={durationValueDefaultValue}
          render={({ ref, ...props }): React.ReactElement => (
            <Grid item xs={offerType === OfferType.PartnerResale ? 3 : 4}>
              <TextField
                {...props}
                label="Months"
                data-id={pendoIdGenerator(
                  privateOffersUIElements.PRIVATE_OFFERS_DURATION_VALUE_INPUT,
                )}
                type="number"
                error={errors.pricing?.durationValue?.message}
                helperText={`Number between ${durationMin} and ${durationMax} months`}
                inputRef={ref}
                inputProps={{ min: durationMin, max: durationMax }}
              />
            </Grid>
          )}
        />
      )}
      {shouldShowMaxStartDateField && (
        <Grid item xs={3}>
          <FieldsSelectDate
            id={
              privateOffersUIElements.PRIVATE_OFFERS_PARTNER_OFFER_MAX_START_DATE_INPUT
            }
            labelText="Max service start date"
            helperText={null}
            defaultValue={offerServiceStartAtJSDate}
            fieldName={serviceStartAtFormPath}
            control={control}
            error={errors?.pricing?.serviceStartAt}
            minDate={minServiceStartDate()}
            maxDate={maxServiceStartDate()}
            showClearDateFooter
          />
        </Grid>
      )}
      {shouldShowFDADateFields && (
        <>
          <Grid item xs={3}>
            <FieldsSelectDate
              fieldName={serviceStartAtFormPath}
              id={
                privateOffersUIElements.PRIVATE_OFFERS_FUTURE_START_DATE_INPUT
              }
              control={control}
              error={errors?.pricing?.serviceStartAt}
              defaultValue={offerServiceStartAtJSDate}
              labelText="Start date *"
              helperText={null}
              minDate={minServiceStartDate()}
              maxDate={maxServiceStartDate()}
            />
          </Grid>
          <Grid item xs={3}>
            <FieldsSelectDate
              fieldName={serviceEndAtFormPath}
              id={privateOffersUIElements.PRIVATE_OFFERS_FUTURE_END_DATE_INPUT}
              control={control}
              error={errors?.pricing?.serviceEndAt}
              defaultValue={offerServiceEndAtJSDate}
              labelText="End date *"
              helperText={null}
              minDate={minServiceEndDate()}
            />
          </Grid>
        </>
      )}
      {showGCPFutureDatedNotEnabledWarning && (
        <Grid item xs={12}>
          <Banner
            type="danger"
            title={
              <span>
                Your listing is not enabled for future-dated agreements. To
                create this offer, first{' '}
                <Link
                  to="https://docs.tackle.io/articles/tackle-docs/enable-google-listings-future-dated-agreements"
                  className={classes.infoLink}
                  external
                >
                  enable the listing in the Google Console
                </Link>
                .
              </span>
            }
            defaultOpen={false}
            open={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FieldsPricingConfig;
