export enum DisplayCloudType {
  AWS = 'aws',
  MICROSOFT = 'microsoft',
  GCP = 'gcp',
}

export enum DisplayLabelCloudType {
  AWS = 'AWS',
  MICROSOFT = 'Microsoft',
  GCP = 'Google cloud',
}

export enum CoSellCloudType {
  ACE = 'ace',
  MSPC = 'mspc',
  GCP = 'gcp',
}

export const CoSellToDisplayCloudType = {
  [CoSellCloudType.ACE]: DisplayCloudType.AWS,
  [CoSellCloudType.MSPC]: DisplayCloudType.MICROSOFT,
  [CoSellCloudType.GCP]: DisplayCloudType.GCP,
};
