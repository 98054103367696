import React from 'react';
import { RotateRight, Email } from 'mdi-material-ui';
import { ProviderIcon, Provider } from '@tackle-io/platform-ui';
import { useTheme } from 'vendor/material';

interface DestinationIconProps {
  destination: 'callback' | Provider;
}

const DestinationIcon: React.FC<DestinationIconProps> = ({ destination }) => {
  const theme = useTheme();

  if (destination === 'callback') {
    return <RotateRight htmlColor={theme.palette.NEUTRAL300} />;
  }

  if (destination === 'postmark') {
    return <Email htmlColor={theme.palette.NEUTRAL300} />;
  }

  return <ProviderIcon provider={destination} fontSize="inherit" />;
};

export default DestinationIcon;
