import { makeStyles } from 'vendor/material';
import { ThemeProvider, Typography, theme } from 'ui';
import { Mountains } from './Mountains';
import { Link } from 'components/Link';
import { CONTACT_SUPPORT_URL } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  mountains: {
    [theme.breakpoints.up('xl')]: {
      width: '1000px',
    },
    [theme.breakpoints.down('xl')]: {
      width: '600px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '500px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '400px',
    },
  },
  textContainer: {
    textAlign: 'center',
    margin: '0 auto',
  },
}));

export function ErrorPage({ title }) {
  const classes = useStyles();
  return (
    <ThemeProvider>
      <Typography variant="hero" mb={3}>
        {title}
      </Typography>
      <div className={classes.textContainer}>
        <Typography variant="h6" mb={1}>
          Oops, something went wrong
        </Typography>
        <Typography variant="body2">
          The page failed to load. Please refresh the browser.
        </Typography>
        <Typography
          variant="body2"
          style={{ margin: `${theme.spacing(1)}px 0` }}
        >
          If the issue persists, contact{' '}
          <Link to={CONTACT_SUPPORT_URL} external>
            Tackle Support.
          </Link>
        </Typography>
        <div style={{ marginTop: theme.spacing(5) }}>
          <Mountains className={classes.mountains} />
        </div>
      </div>
    </ThemeProvider>
  );
}
