// This file contains enums for Data Feed  ui elements to be used with pendoIdGenerator()
export const dataFeedUIElements = {
  DATA_FEEDS_META_SEARCH: 'data_feeds_search',
  DATA_FEEDS_PRODUCT_FILTER: 'data_feeds_product_filter',
  DATA_FEEDS_DATE_FILTER: 'data_feeds_date_filter',
  DATA_FEEDS_EXPORT_CSV: 'data_feeds_export_csv',
  DATA_FEED_ITEM_EXPAND_BUTTON: 'data_feeds_item_expand_button',
  DATA_FEED_ITEM_CLOSE_BUTTON: 'data_feeds_item_close_button',
  DATA_FEEDS_ITEM_METADATA: 'data_feeds_item_metadata',
  DATA_FEEDS_ITEM_GET_HELP: 'data_feeds_item_get_help',
  DATA_FEEDS_ITEM_GET_HELP_MODAL_CANCEL:
    'data_feeds_item_get_help_modal_cancel',
  DATA_FEEDS_EVENT_TILE_EXPAND: 'data_feeds_event_tile_expand',
  DATA_FEEDS_ITEM_ROW: 'data_feeds_item_row',
};

export const appUIElements = {
  HELP_CENTER: 'help_center',
};

export const dashboardUIElements = {
  MENU_TOGGLE: 'menu_toggle',
  LOGO_HOME: 'logo_home',
  SIDEBAR_DASHBOARD: 'sidebar_dashboard',
  SIDEBAR_INSIGHTS: 'sidebar_insights',
  SIDEBAR_DATA_FEEDS: 'sidebar_data_feeds',
  SIDEBAR_LISTINGS: 'sidebar_listings',
  SIDEBAR_DISBURSEMENTS: 'sidebar_disbursements',
  SIDEBAR_CONTRACTS: 'sidebar_contracts',
  SIDEBAR_METERING: 'sidebar_metering',
  SIDEBAR_OFFERS: 'sidebar_offers',
  SIDEBAR_CONNECTORS: 'sidebar_connectors',
  SIDEBAR_CO_SELL: 'sidebar_cosell',
  SIDEBAR_USER_MANAGEMENT: 'sidebar_user_management',
  SIDEBAR_STATUS_REPORTS: 'sidebar_status_reports',
  SIDEBAR_PRIVATE_OFFERS: 'sidebar_private_offers',
  SIDEBAR_PROSPECT: 'sidebar_prospect',
  SIDEBAR_ACCOUNT_SETTINGS: 'sidebar_account_settings',
  EVENT_STREAM_VIEW_ALL: 'event_stream_view_all',
  EVENT_STREAM_TILE_PREVIEW: 'event_stream_tile_preview',
  EVENT_STREAM_CARD: 'event_stream_card',
  SIDEBAR_INTEGRATIONS: 'sidebar_integrations',
  EVENT_TILE_EXPAND: 'event_tile_expand',
  EVENT_TILE_CLOSE: 'event_tile_close',
  WHATS_NEW: 'whats_new',
  SUPPORT: 'support',
  INSIGHTS_VIEW_ALL: 'insights_view_all',
  OFFER_TILE_PREVIEW: 'offer_tile_preview',
  OFFERS_VIEW_ALL: 'offers_view_all',
  OFFERS_SCROLL_LEFT: 'offers_scroll_left',
  OFFERS_SCROLL_RIGHT: 'offers_scroll_right',
  ONBOARDING_TILE_PREVIEW: 'onboarding_tile_preview',
  ONBOARDING_SCROLL_LEFT: 'onboarding_scroll_left',
  ONBOARDING_SCROLL_RIGHT: 'onboarding_scroll_right',
  BETA_DASHBOARD_FEATURE_TOGGLE: 'beta_dashboard_feature_toggle',
  DASHBOARD_BETA_DOCSTOOLTIP_BOOKEDREVENUE:
    'dashboard_beta_docstooltip_bookedrevenue',
  DASHBOARD_BETA_DOCSTOOLTIP_DISBURSEDREVENUE:
    'dashboard_beta_dosctooltip_disbursedrevenue',
  DASHBOARD_BETA_LINECHART_BOOKEDREVENUE:
    'dashboard_beta_linechart_bookedrevenue',
  DASHBOARD_BETA_LINECHART_DISBURSEDREVENUE:
    'dashboard_beta_linechart_disbursedrevenue',
  DASHBOARD_BETA_MARKETPLACE_FILTER: 'dashboard_beta_marketplace_filter',
  DASHBOARD_BETA_TIME_RANGE_FILTER: 'dashboard_beta_time_range_filter',
  LISTINGS_IMPORT_FROM_MARKETPLACE_BUTTON:
    'listings_import_from_marketplace_button',
  LISTINGS_MODAL_IMPORT_FROM_MARKETPLACE_BUTTON:
    'listings_modal_import_from_marketplace_button',
  LISTINGS_SPLASH_SCREEN_IMPORT_FROM_MARKETPLACE_BUTTON:
    'listings_splash_screen_import_from_marketplace_button',
  LISTINGS_SPLASH_SCREEN_CREATE_LISTING_BUTTON:
    'listings_splash_screen_create_listing_button',
  LISTINGS_SPLASH_SCREEN_CONNECT_MARKETPLACE_BUTTON:
    'listings_splash_screen_connect_marketplace_button',
  LISTINGS_VIEW_IN_MARKETPLACE_BUTTON: 'listings_view_in_marketplace_button',
  LISTINGS_SIDE_BAR_EDIT_BUTTON: 'listings_side_bar_edit_button',
  LISTINGS_SIDE_BAR_CANCEL_EDIT_BUTTON: 'listings_side_bar_cancel_edit_button',
  LISTINGS_CONFIRMATION_MODAL_CANCEL_EDIT_BUTTON:
    'listings_confirmation_modal_cancel_edit_button',
  LISTING_EDITS_SIDE_BAR_SUBMIT_FOR_REVIEW_BUTTON:
    'listing_edits_side_bar_submit_for_review_button',
  LISTING_EDITS_CONFIRMATION_MODAL_SUBMIT_FOR_REVIEW_BUTTON:
    'listing_edits_confirmation_modal_submit_for_review_button',
  DASHBOARD_BETA_FISCAL_YEAR_OPEN_MODAL:
    'dashboard_beta_fiscal_year_open_modal',
  DASHBOARD_BETA_FISCAL_YEAR_SAVE: 'dashboard_beta_fiscal_year_save',
  DASHBOARD_BETA_DOCSTOOLTIP_COSELL: 'dashboard_beta_docstooltip_cosell',
  DASHBOARD_BETA_BARCHART_COSELL: 'dashboard_beta_barchart_cosell',
  DASHBOARD_BETA_ZERO_CONNECT_COSELL_CTA:
    'dashboard_beta_zero_connect_cosell_cta',
  DASHBOARD_BETA_ZERO_SEND_COSELL_OPPORTUNITY_CTA:
    'dashboard_beta_zero_send_cosell_opportunity_cta',
  DASHBOARD_BETA_VIEW_ALL_DISBURSED_REVENUE:
    'dashboard_beta_view_all_disbursed_revenue',
  DASHBOARD_BETA_VIEW_ALL_BOOKED_REVENUE:
    'dashboard_beta_view_all_booked_revenue',
  DASHBOARD_BETA_VIEW_ALL_COSELL: 'dashboard_beta_view_all_cosell',
  DASHBOARD_COSELL_PIPELINE_TOOLTIP: 'dashboard_cosell_pipeline_tooltip',
  DASHBOARD_COSELL_REVENUE_TOOLTIP: 'dashboard_cosell_revenue_tooltip',
  DASHBOARD_COSELL_WIN_RATE_TOOLTIP: 'dashboard_cosell_win_rate_tooltip',
};

export const insightsUIElements = {
  INSIGHTS_SEARCH: 'insights_search',
  INSIGHTS_MARKET_FILTER: 'insights_market_filter',
  INSIGHTS_PRODUCT_FILTER: 'insights_product_filter',
  INSIGHTS_STATUS_FILTER: 'insights_status_filter',
  INSIGHTS_DATE_FILTER: 'insights_date_filter',
  INSIGHTS_EXPORT_CSV: 'insights_export_csv',
  INSIGHTS_REPORTED_ESTIMATED_DROPDOWN: 'insights_reported_estimated_dropdown',
  INSIGHTS_DATE_RANGE_TAB: 'insights_date_range_tab',
  INSIGHTS_VIEW_MORE: 'insights_view_more',
  INSIGHTS_CONTRACT_EXPAND: 'insights_contract_expand',
  INSIGHTS_CONTRACT_METADATA: 'insights_contract_metadata',
  INSIGHTS_SORT: 'insights_sort',
};

export const privateOffersUIElements = {
  PRIVATE_OFFERS_CSV: 'private_offers_csv',
  PRIVATE_OFFERS_SEARCH: 'private_offers_search',
  PRIVATE_OFFERS_MARKETPLACE_FILTER: 'private_offers_marketplace_filter',
  PRIVATE_OFFERS_STATUS_FILTER: 'private_offers_status_filter',
  PRIVATE_OFFERS_PRODUCT_FILTER: 'private_offers_product_filter',
  PRIVATE_OFFERS_DATE_FILTER: 'private_offers_date_filter',
  PRIVATE_OFFERS_SORT_DROPDOWN: 'private_offers_sort_dropdown',
  PRIVATE_OFFERS_SORT_DIRECTION: 'private_offers_sort_direction',
  PRIVATE_OFFERS_ASSOCIATE_NEW_OFFER: 'private_offers_associate_new_offer',
  PRIVATE_OFFERS_CREATE_NEW_OFFER: 'private_offers_create_new_offer',
  PRIVATE_OFFERS_TILE_MENU: 'private_offers_tile_menu',
  PRIVATE_OFFERS_VIEW_MODE_GRID: 'private_offers_view_mode_grid',
  PRIVATE_OFFERS_VIEW_MODE_LIST: 'private_offers_view_mode_list',
  PRIVATE_OFFERS_CREATE_OPPORTUNITY_SEARCH:
    'private_offers_create_opportunity_search',
  PRIVATE_OFFERS_CREATE_REMOVE_BUYER: 'private_offers_create_remove_buyer',
  PRIVATE_OFFERS_CREATE_LISTING_SEARCH: 'private_offers_create_listing_search',
  PRIVATE_OFFERS_USERS_TO_NOTIFY_FILTER:
    'private_offers_users_to_notify_filter',
  PRIVATE_OFFERS_CREATE_ADD_METADATA_ITEM:
    'private_offers_create_add_metadata_item',
  PRIVATE_OFFERS_CREATE_REMOVE_METADATA_ITEM:
    'private_offers_create_remove_metadata_item',
  PRIVATE_OFFERS_CREATE_QUICK_ADD_USER_EMAIL:
    'private_offers_create_quick_add_user_email',
  PRIVATE_OFFERS_CREATE_MANUAL_ADD_USER_EMAIL:
    'private_offers_create_manual_add_user_email',
  PRIVATE_OFFERS_REVIEW_EDIT_MODAL_COMPANY:
    'private_offers_review_edit_modal_company',
  PRIVATE_OFFERS_REVIEW_EDIT_MODAL_BUYER:
    'private_offers_review_edit_modal_buyer',
  PRIVATE_OFFERS_REVIEW_EDIT_MODAL_LISTING:
    'private_offers_review_edit_modal_listing',
  PRIVATE_OFFERS_REVIEW_EDIT_MODAL_OFFER:
    'private_offers_review_edit_modal_offer',
  PRIVATE_OFFERS_REVIEW_EDIT_MODAL_PARTNER_DETAILS:
    'private_offers_review_edit_modal_partner_details',
  PRIVATE_OFFERS_PARTNER_DETAILS_FIELDS_PARTNER_NAME:
    'private_offers_partner_details_fields_partner_name',
  PRIVATE_OFFERS_PARTNER_DETAILS_FIELDS_PARTNER_REF:
    'private_offers_partner_details_fields_partner_ref',
  PRIVATE_OFFERS_REVIEW_EDIT_MODAL_METADATA:
    'private_offers_review_edit_modal_metadata',
  PRIVATE_OFFERS_FUTURE_DATED_OFFER_CHECKBOX:
    'private_offers_future_dated_offer_checkbox',
  PRIVATE_OFFERS_FUTURE_START_DATE_INPUT:
    'private_offers_future_start_date_input',
  PRIVATE_OFFERS_PARTNER_OFFER_MAX_START_DATE_INPUT:
    'private_offers_partner_offer_max_start_date_input',
  PRIVATE_OFFERS_FUTURE_END_DATE_INPUT: 'private_offers_future_end_date_input',
  PRIVATE_OFFERS_AMENDMENT_SERVICE_START_DATE_INPUT:
    'private_offers_amendment_service_end_date_input',
  PRIVATE_OFFERS_AMENDMENT_SERVICE_END_DATE_INPUT:
    'private_offers_amendment_service_end_date_input',
  PRIVATE_OFFERS_DETAILS_VIEW_OFFER_LINK:
    'private_offers_details_view_offer_link',
  PRIVATE_OFFERS_BILLING_TERM_SELECT_DROPDOWN:
    'private_offers_billing_term_select_dropdown',
  PRIVATE_OFFERS_PAYMENT_MODEL_SELECT_DROPDOWN:
    'private_offers_payment_model_select_dropdown',
  PRIVATE_OFFERS_DURATION_VALUE_INPUT: 'private_offers_duration_value_input',
  PRIVATE_OFFERS_SHOW_PAYMENT_SCHEDULE_TOOL_BUTTON:
    'private_offers_show_payment_schedule_tool',
  PRIVATE_OFFERS_HIDE_PAYMENT_SCHEDULE_TOOL_BUTTON:
    'private_offers_hide_payment_schedule_tool',
  PRIVATE_OFFERS_SHOW_PREVIEW_PAYMENT_SCHEDULE_BUTTON:
    'private_offers_show_preview_payment_schedule',
  PRIVATE_OFFERS_HIDE_PREVIEW_PAYMENT_SCHEDULE_BUTTON:
    'private_offers_hide_preview_payment_schedule',
  PRIVATE_OFFERS_CREATE_PAYMENT_SCHEDULE_BUTTON:
    'private_offers_create_payment_schedule',
  PRIVATE_OFFERS_PAYMENT_SCHEDULE_DOCS_TOOLTIP:
    'private_offers_payment_schedule_docs_tooltip',
  PRIVATE_OFFERS_CREATE_IS_ABO_OFFER_TOGGLE:
    'private_offers_create_is_abo_offer_toggle',
  PRIVATE_OFFERS_DETAILS_IS_ABO_OFFER_TOGGLE:
    'private_offers_details_is_abo_offer_toggle',
  PRIVATE_OFFERS_ADD_PAYMENT_BUTTON: 'private_offers_add_payment_button',
  PRIVATE_OFFERS_ADD_DIMENSION_BUTTON: 'private_offers_add_dimension_button',
  PRIVATE_OFFERS_REVIEW_OFFER_BUTTON: 'private_offers_review_offer_button',
  PRIVATE_OFFERS_ASSOCIATE_OFFER_BUTTON:
    'private_offers_associate_offer_button',
  PRIVATE_OFFERS_IS_RENEWAL_CHECKBOX: 'private_offers_is_renewal_checkbox',
  PRIVATE_OFFERS_AWS_EULA_OPTION_CURRENT_TERMS:
    'private_offers_aws_eula_current_terms',
  PRIVATE_OFFERS_AWS_EULA_OPTION_PUBLIC: 'private_offers_aws_eula_public',
  PRIVATE_OFFERS_AWS_EULA_OPTION_STANDARD: 'private_offers_aws_eula_standard',
  PRIVATE_OFFERS_AWS_EULA_OPTION_CUSTOM: 'private_offers_aws_eula_custom',
  PRIVATE_OFFERS_AWS_UPLOAD_EULA: 'private_offers_aws_upload_eula',
  PRIVATE_OFFERS_GCP_EULA_OPTION_STANDARD: 'private_offers_gcp_eula_standard',
  PRIVATE_OFFERS_GCP_EULA_OPTION_CUSTOM: 'private_offers_gcp_eula_custom',
  PRIVATE_OFFERS_GCP_UPLOAD_EULA: 'private_offers_gcp_upload_eula',
  PRIVATE_OFFERS_AZURE_UPLOAD_TERMS_AND_CONDITIONS:
    'private_offers_azure_upload_terms_and_conditions',
  PRIVATE_OFFERS_AWS_RESELLER_AGREEMENT_OPTION_NONE_SELECTED:
    'private_offers_aws_reseller_agreement_none_selected',
  PRIVATE_OFFERS_AWS_RESELLER_AGREEMENT_OPTION_RCMP:
    'private_offers_aws_reseller_agreement_rcmp',
  PRIVATE_OFFERS_AWS_RESELLER_AGREEMENT_OPTION_CUSTOM:
    'private_offers_aws_reseller_agreement_custom',
  PRIVATE_OFFERS_AWS_UPLOAD_RESELLER_AGREEMENT:
    'private_offers_aws_upload_reseller_agreement',
  PRIVATE_OFFERS_ENABLE_ZERO_DOLLAR_PRICING_CHECKBOX:
    'private_offers_enable_zero_dollar_pricing_checkbox',
  PRIVATE_OFFERS_CANCEL_OFFER_MENU_ITEM:
    'private_offers_cancel_offer_menu_item',
  PRIVATE_OFFERS_CANCEL_OFFER_CONFIRM_BUTTON:
    'private_offers_cancel_offer_confirm_button',
  PRIVATE_OFFERS_SHOW_PRICING_ON_EMAIL_TOGGLE:
    'private_offers_show_pricing_on_email_toggle',
  PRIVATE_OFFERS_RESEND_OFFER_EMAIL_BUTTON:
    'private_offers_resend_offer_email_button',
  PRIVATE_OFFERS_SEND_OFFER_EMAIL_BUTTON:
    'private_offers_send_offer_email_button',
  PRIVATE_OFFERS_RETRY_OFFER_CREATION_BUTTON:
    'private_offers_retry_offer_creation_button',
  PRIVATE_OFFERS_SEND_PURCHASE_INSTRUCTIONS_BUTTON:
    'private_offers_send_purchase_instructions_button',
  PRIVATE_OFFERS_MODAL_CHANGE_EXPIRATION_DATE_BUTTON:
    'private_offers_modal_change_expiration_date_button',
  PRIVATE_OFFERS_CHANGE_EXPIRATION_DATE_FOR_EXPIRED_BUTTON:
    'private_offers_change_expiration_date_for_expired_button',
  PRIVATE_OFFERS_CREATE_DIRECT_OFFER_BUTTON:
    'private_offers_create_direct_offer_button',
  PRIVATE_OFFERS_CREATE_PARTNER_OFFER_BUTTON:
    'private_offers_create_partner_offer_button',
  PRIVATE_OFFERS_AUTO_CREATE_DIRECT_OFFER_BUTTON:
    'private_offers_auto_create_direct_offer_button',
  PRIVATE_OFFERS_AUTO_CREATE_PARTNER_OFFER_BUTTON:
    'private_offers_auto_create_partner_offer_button',
  PRIVATE_OFFERS_AWS_DIMENSIONS_COUNT: 'private_offers_aws_dimensions_count',
};

// these constants should be combined with a unique suffix when generating a data-id for pendo use
export const privateOffersUINonUniqueElements = {
  PRIVATE_OFFERS_LIST_ROW: 'private_offers_list_row',
  PRIVATE_OFFERS_GRID_CARD_COMPANY_NAME:
    'private_offers_grid_card_company_name',
  PRIVATE_OFFERS_GRID_CARD_STATUS_OFFER_ID_CREATED_AT_CONTAINER:
    'private_offers_grid_card_status_offer_id_created_at_container',
  PRIVATE_OFFERS_GRID_CARD_MORE_MENU_VIEW_OPTION:
    'private_offers_grid_card_more_menu_view_option',
  PRIVATE_OFFERS_GRID_CARD_MORE_MENU_CLONE_OPTION:
    'private_offers_grid_card_more_menu_clone_option',
  PRIVATE_OFFERS_GRID_CARD_MORE_MENU_CREATE_ABO_OPTION:
    'private_offers_grid_card_more_menu_create_abo_option',
  PRIVATE_OFFERS_GRID_CARD_MORE_MENU_RESEND_INSTRUCTIONS_OPTION:
    'private_offers_grid_card_more_menu_resend_instructions_option',
  PRIVATE_OFFERS_GRID_CARD_MORE_MENU_ARCHIVE_OPTION:
    'private_offers_grid_card_more_menu_archive_option',
  PRIVATE_OFFERS_CREATE_FORM_LISTING_SEARCH_ITEM:
    'private_offers_create_form_listing_search_item',
  PRIVATE_OFFERS_CONTRACT_METADATA_KEY_INPUT:
    'private_offers_contract_metadata_key_input',
  PRIVATE_OFFERS_CONTRACT_METADATA_VALUE_INPUT:
    'private_offers_contract_metadata_value_input',
};

export const meteringUIElements = {
  METERING_SEARCH: ' metering_search',
  METERING_MARKETPLACE_FILTER: 'metering_marketplace_filter',
  METERING_PRODUCT_FILTER: 'metering_product_filter',
  METERING_DATE_FILTER: 'metering_date_filter',
  METERING_EXPORT_CSV: 'metering_export_CSV',
  METERING_IMPORT_EXAMPLE_CSV: 'metering_import_example_CSV',
  METERING_SORT_DROPDOWN: 'metering_sort_dropdown',
  METERING_CREATE_RECORD: 'metering_create_record',
  METERING_CONFIRM_MODAL_CONFIRM: 'metering_confirm_modal_confirm',
  METERING_CONFIRM_MODAL_CANCEL: 'metering_confirm_modal_cancel',
};

export const disbursementsUIElements = {
  DISBURSEMENTS_DATE_RANGE_TAB: 'disbursements_date_range_tab',
  DISBURSEMENTS_EXPORT_CSV: 'disbursements_export_csv',
  DISBURSEMENTS_LINE_ITEM_EXPAND: 'disbursements_line_item_expand',
  DISBURSEMENTS_LINE_ITEM_CLOSE: 'disbursements_line_item_close',
  DISBURSEMENTS_MONTH_EXPAND: 'disbursements_month_expand',
  DISBURSEMENTS_MONTH_CLOSE: 'disbursements_month_close',
  DISBURSEMENTS_LOAD_MORE: 'disbursements_load_more',
};

export const cosellUIElements = {
  NEW_SALESFORCE_OPPORTUNITY_BUTTON: 'cosell_new_salesforce_opportunity_button',
  NEW_TACKLE_OPPORTUNITY_BUTTON: 'cosell_new_tackle_opportunity_button',
  OPPORTUNITIES_MODAL_CLOSE_BUTTON: 'cosell_opportunities_modal_close_button',
  CO_SELL_EXPORT_CSV: 'cosell_export_csv',
  SEARCH_OPPORTUNITIES_FIELD: 'cosell_search_opportunities',
  FILTER_OPPORTUNITIES_BY_STATUS: 'cosell_filter_opportunities_by_status',
  FILTER_OPPORTUNITIES_BY_ORIGINATION:
    'cosell_filter_opportunities_by_origination',
  FILTER_OPPORTUNITIES_BY_CLOUD: 'cosell_filter_opportunities_by_cloud',
  FILTER_OPPORTUNITIES_BY_DATE: 'cosell_filter_opportunities_by_date',
  RESET_FILTERS: 'cosell_reset_filters',
  TABLE_HEADER: 'cosell_table_header',
  TABLE_ROW: 'cosell_table_row',
  COPY_TEXT_ICON_BUTTON: 'cosell_copy_text_icon_button',
  CO_SELL_REQUEST_INBOUND_BUTTON: 'cosell_request_inbound_button',
  CO_SELL_MOCK_ACCEPT_BUTTON: 'cosell_mock_accept_button',
  CO_SELL_MOCK_DECLINE_BUTTON: 'cosell_mock_decline_button',
};

export const cosellDetailsOpportunityMenuElements = {
  SAVE_AND_REGISTER_BUTTON: 'cosell_details_save_and_register_button',
  SAVE_AS_A_TEMPLATE_BUTTON: 'cosell_details_save_as_a_template_button',
  CANCEL: 'cosell_details_cancel_button',
  SEND_INTRO_EMAIL_BUTTON: 'cosell_details_send_intro_email_button',
  CONNECT_SALES_REPS_CLICKABLE_LINKS:
    'cosell_details_connect_sales_reps_clickable_links',
};

export const cosellOpportunityDetailElements = {
  EDIT_BUTTON: 'cosell_opportunity_detail_edit_button',
  LOST_BUTTON: 'cosell_opportunity_detail_header_lost_button',
  WON_BUTTON: 'cosell_opportunity_detail_header_won_button',
  CLOSE_AS_ERROR_BUTTON: 'cosell_opportunity_detail_close_as_error_button',
  EXPAND_NEXT_STEPS_BUTTON:
    'cosell_opportunity_detail_expand_next_steps_button',
  DECLINE_BUTTON: 'cosell_opportunity_detail_header_decline_button',
  ACCEPT_BUTTON: 'cosell_opportunity_detail_header_accept_button',
};

export const cosellMsftOpportunityDetailElements = {
  EDIT_BUTTON: 'cosell_msft_opportunity_detail_edit_button',
  DECLINE_BUTTON: 'cosell_msft_opportunity_detail_header_decline_button',
  ACCEPT_BUTTON: 'cosell_msft_opportunity_detail_header_accept_button',
};

export const cosellFormErrorElements = {
  CLOSE_BUTTON: 'cosell_form_error_close_button',
};

export const cosellFormElements = {
  EXPAND_NEXT_STEPS_BUTTON: 'cosell_form_expand_next_steps_button',
};

export const cosellCancelModalElements = {
  CANCEL: 'cosell_cancel_modal_cancel_button',
  CONFIRM: 'cosell_cancel_modal_confirm_button',
};

export const cosellDraftFormButtonElements = {
  CANCEL: 'cosell_draft_form_cancel_button',
  SAVE_DRAFT_AND_EXIT: 'cosell_draft_form_save_draft_and_exit_button',
  SUBMIT_TO_CLOUD: 'cosell_draft_form_submit_to_cloud_button',
  DELETE: 'cosell_draft_form_delete_button',
};

export const cosellRegisterDealModalElements = {
  CANCEL: 'cosell_register_deal_cancel_button',
  REGISTER_DEAL: 'cosell_register_deal_register_button',
  SUPPORTED_BY_REP_YES: 'cosell_register_deal_supported_by_rep_yes',
  SUPPORTED_BY_REP_NO: ' cosell_register_deal_supported_by_rep_no',
  FOR_MARKETPLACE_YES: 'cosell_register_deal_for_marketplace_yes',
  FOR_MARKETPLACE_NO: ' cosell_register_deal_for_marketplace_no',
  NET_NEW_BUSINESS_YES: 'cosell_register_deal_net_new_business_yes',
  NET_NEW_BUSINESS_NO: 'cosell_register_deal_net_new_business_no',
};

export const cosellOpportunityDetailsFormSection = {
  COSELL_OPPORTUNITY_DETAILS_PARENT_COSELL_ID:
    'cosell_opportunity_details_parent_cosell_id',
  COSELL_OPPORTUNITY_DETAILS_ESTIMATED_AWS_MONTHLY_RECURRING_REVENUE:
    'cosell_opportunity_details_estimated_aws_monthly_recurring_revenue',
  COSELL_OPPORTUNITY_DETAILS_SOLUTIONS: 'cosell_opportunity_details_solutions',
};

export const cosellSettingsImportExistingOpportunities = {
  BACKFILL_CLOUD_ID: 'backfill_cloud_id',
  BACKFILL_SALESFORCE_ID: 'backfill_salesforce_id',
};

export const cosellBuyerCompanyInformationFormSection = {
  COSELL_BUYER_COMPANY_INFORMATION_DUNS_NUMBER:
    'cosell_buyer_company_information_duns_number',
};

export const cosellMarketingActivityFormSection = {
  IS_OPP_FROM_MARKETING_ACTIVITY:
    'cosell_is_opportunity_from_marketing_activity',
  AWS_MARKETING_SALESFORCE_CAMPAIGN_NAME:
    'cosell_aws_marketing_salesforce_campaign_name',
  WERE_MARKETING_DEVELOPMENT_FUNDS_USED:
    'cosell_were_marketing_development_funds_used',
};

export const cosellAdditionalDetailsFormSection = {
  COSELL_ADDITIONAL_DETAILS_BUYER_BUSINESS_PROBLEM:
    'cosell_additional_details_buyer_business_problem',
};

export const cosellAceWonDealModalElements = {
  COSELL_ACE_WON_DEAL_MODAL_SOLUTIONS: 'cosell_ace_won_deal_modal_solutions',
  COSELL_ACE_WON_DEAL_MODAL_OFFER: 'cosell_ace_won_deal_modal_offer',
  COSELL_ACE_WON_DEAL_MODAL_CLOSE_WON_BUTTON:
    'cosell_ace_won_deal_modal_close_won_button',
  COSELL_ACE_WON_DEAL_MODAL_CANCEL_BUTTON:
    'cosell_ace_won_deal_modal_cancel_button',
};

export const cosellAceWonDealWithLaunchDetails = {
  COSELL_ACE_WON_DEAL_WITH_LAUNCH_DETAILS_OFFER:
    'cosell_ace_won_deal_with_launch_details_offer',
  COSELL_ACE_WON_DEAL_WITH_LAUNCH_DETAILS_PROCUREMENT_TYPE:
    'cosell_ace_won_deal_with_launch_details_procurement_type',
  COSELL_ACE_WON_DEAL_WITH_LAUNCH_DETAILS_CUSTOMER_SOFTWARE_VALUE:
    'cosell_ace_won_deal_with_launch_details_customer_software_value',
  COSELL_ACE_WON_DEAL_WITH_LAUNCH_DETAILS_CLOSE_WON_BUTTON:
    'cosell_ace_won_deal_with_launch_details_close_won_button',
  COSELL_ACE_WON_DEAL_WITH_LAUNCH_DETAILS_CANCEL_BUTTON:
    'cosell_ace_won_deal_with_launch_details_cancel_button',
  COSELL_ACE_WON_DEAL_WITH_LAUNCH_DETAILS_CANCEL_SOLUTIONS:
    'cosell_ace_won_deal_with_launch_details_cancel_solutions',
};

export const cosellAcceptDeclineOpportunityModalElements = {
  CANCEL: 'cosell_accept_decline_opportunity_cancel_button',
  DECLINE: 'cosell_accept_decline_opportunity_decline_deal_button',
};

export const cosellLostOpportunityModalElements = {
  CANCEL: 'cosell_lost_opportunity_cancel_button',
  CLOSE_DEAL: 'cosell_lost_opportunity_close_deal_button',
};

export const cosellMsftWonOpportunityModalElements = {
  CANCEL: 'cosell_msft_won_opportunity_cancel_button',
  CLOSE_DEAL: 'cosell_msft_won_opportunity_close_deal_button',
};

export const cosellMsftLostOpportunityModalElements = {
  CANCEL: 'cosell_msft_lost_opportunity_cancel_button',
  CLOSE_DEAL: 'cosell_msft_lost_opportunity_close_deal_button',
  CLOSE_ERROR: 'cosell_msft_lost_opportunity_close_error_button',
};

export const accountSwitcherElements = {
  ACCOUNT_SWITCH_OPEN_MODAL: 'account_switch_open_modal',
  ACCOUNT_SWITCH_SELECT_ACCOUNT: 'account_switch_select_account',
};

export const cosellDeleteModalElements = {
  CANCEL: 'cosell_delete_modal_cancel_button',
  CONFIRM: 'cosell_delete_modal_confirm_button',
};

export const settingsCoSellAws = {
  ENROLL_LINK: 'settings_cosell_aws_enroll_link',
  GET_STARTED: 'settings_cosell_aws_get_started_section',
  CREATE_SANDBOX_USER: 'settings_cosell_aws_create_sandbox_user_section',
  CREATE_PRODUCTION_ROLE: 'settings_cosell_aws_create_production_role_section',
  SETUP_ACE_ENVIRONMENT: 'settings_cosell_aws_setup_ace_env_section',
  SOLUTIONS_OFFERED: 'settings_cosell_solutions_offered',
  GIVE_ACCESS: 'settings_cosell_aws_give_access_section',
  LINK_PARTNER_CENTRAL: 'settings_cosell_aws_link_partner_central',
  COMPLETE_BTN: 'settings_cosell_aws_complete_button',
  SAVE_DRAFT_AND_EXIT_BTN: 'settings_cosell_aws_save_draft_and_exit_button',
  COLLABORATORS: 'settings_cosell_aws_collaborators',
  EMAIL_SETUP_STEPS: 'settings_cosell_aws_email_setup_steps_button',
  PARTNER_CENTRAL_EVENTS: 'settings_cosell_aws_pc_real_time_events',
};

export const settingsCoSellAwsGetStarted = {
  IDENTIFY_CHECKBOX: 'settings_cosell_aws_get_started_identify_checkbox',
  AWS_ORGANIZATIONS_LINK:
    'settings_cosell_aws_get_started_aws_organizations_link',
  ENSURE_ACCESS_CHECKBOX:
    'settings_cosell_aws_get_started_ensure_access_checkbox',
  TERMS_CHECKBOX: 'settings_cosell_aws_get_started_terms_checkbox',
  TERMS_LINK: 'settings_cosell_aws_get_started_terms_link',
  TAX_CHECKBOX: 'settings_cosell_aws_get_started_tax_checkbox',
  TAX_LINK: 'settings_cosell_aws_get_started_tax_link',
  BANKING_CHECKBOX: 'settings_cosell_aws_get_started_banking_checkbox',
  BANKING_LINK: 'settings_cosell_aws_get_started_banking_link',
  PUBLIC_PROFILE_CHECKBOX:
    'settings_cosell_aws_get_started_public_profile_checkbox',
  PUBLIC_PROFILE_LINK:
    'settings_cosell_aws_get_started_public_profile_checkbox',
  CONTINUE_BTN: 'settings_cosell_aws_get_started_continue_button',
};

export const settingsCoSellAwsCreateSandboxUser = {
  CREATE_IAM_USER_LINK: 'settings_cosell_aws_sandbox_user_create_iam_user_link',
  AWS_MANAGEMENT_CONSOLE_LINK:
    'settings_cosell_aws_sandbox_user_aws_management_console_link',
  SANDBOX_ARN: 'settings_cosell_aws_sandbox_user_sandbox_arn_textfield',
  CONTINUE_BTN: 'settings_cosell_aws_sandbox_user_continue_button',
  SKIP_STEP_BTN: 'settings_cosell_aws_sandbox_user_skip_step',
};

export const settingsCoSellAwsCreateProductionRole = {
  CLICK_HERE_LINK: 'settings_cosell_aws_production_role_click_here_link',
  TACKLE_ROLE_ARN:
    'settings_cosell_aws_production_role_tackle_role_arn_textfield',
  CONTINUE_BTN: 'settings_cosell_aws_production_role_continue_button',
};

export const settingsCoSellAwsSetupACEEnvironment = {
  PARTNER_CENTRAL_LINK: 'settings_cosell_aws_setup_ace_partner_central_link',
  SUPPORT_EMAIL: 'settings_cosell_aws_setup_ace_support_email_link',
  IAM_POLICY: 'settings_cosell_aws_setup_ace_iam_policy_text_field',
  CONTINUE_BTN: 'settings_cosell_aws_setup_ace_continue_button',
  SOLUTION_NEXT_BUTTON: 'settings_cosell_aws_setup_solution_next_button',
  SOLUTION_SASS_TOGGLE: 'settings_cosell_aws_setup_solution_sass_toggle',
  SOLUTION_SAVE_BUTTON: 'settings_cosell_aws_setup_solution_save_button',
  SOLUTION_ADD_SOLUTIONS: 'settings_cosell_aws_setup_solutions_add_below',
  SOLUTION_NAME_LABEL: 'settings_cosell_aws_setup_solution_name_label',
  SOLUTION_ID_LABEL: 'settings_cosell_aws_setup_solution_id_label',
  SOLUTION_DEFAULT_LABEL: 'settings_cosell_aws_setup_solution_default_label',

  LINKED_PARTNER_CENTERAL_STEPS: 'settings_cosell_aws_linked_partner_steps',
  LINKED_PARTNER_CENTRAL_TOGGLE:
    'settings_cosell_aws_linked_partner_central_toggle',
  LINKED_NEXT_BUTTON:
    'settings_cosell_aws_setup_linked_partner_central_next_button',
};

export const settingsCoSellAwsGiveAccess = {
  GRANT_ACCESS_TOOL_TIP: 'settings_cosell_aws_give_access_tooltip',
  GRANT_ACCESS_BTN: 'settings_cosell_aws_give_access_grant_access_button',
};

export const settingsCoSellAwsPCEvents = {
  LAUNCH_STACK_BTN: 'settings_cosell_aws_pc_events_grant_access_button',
};

export const prospectUIElements = {
  DASHBOARD_UPLOAD_ACCOUNTS_ZERO_STATE: 'prospect_dash_zero_upload',
  DASHBOARD_EXPORT: 'prospect_dash_filled_export',
  DASHBOARD_BEST_PRACTICES: 'prospect_dash_filled_best_practices',
  DASHBOARD_UPLOAD_ACCOUNTS_FILLED: 'prospect_dash_filled_upload',
  DASHBOARD_LINK_VIEW_MARKETPLACE:
    'prospect_dash_filled_view-marketplace-scores',
  DASHBOARD_LINK_VIEW_ALL: 'prospect_dash_filled_view-all-scores',
  DASHBOARD_LINK_NEW_HIGHS: 'prospect_dash_new-highs',
  DASHBOARD_DOCS_TOOLTIP: 'prospect_dash_docsToolTip',
  DASHBOARD_ZERO_SCORE_BULK: 'prospect_dashboard_zero_score_bulk',
  DASHBOARD_ZERO_SCORE_SINGLE: 'prospect_dashboard_zero_score_single',
  DASHBOARD_FILLED_SCORE_BULK: 'prospect_dashboard_filled_zero_score_bulk',
  DASHBOARD_FILLED_SCORE_SINGLE: 'prospect_dashboard_filled_zero_score_single',
  SCORE_MODAL_SINGLE_CLOSE_BUTTON: 'prospect_score_modal_single_close_button',
  SCORE_MODAL_SINGLE_SCORE_ANOTHER: 'prospect_score_modal_single_score_another',
  SCORE_MODAL_SINGLE_CLOSE_X: 'prospect_score_modal_single_x-close',
  TAB_BEST_PRACTICES: 'prospect_tab_zero_best_practices',
  TAB_UPLOAD_ACCOUNTS_ZERO_STATE: 'prospect_tab_zero_upload',
  TAB_UPLOAD_ACCOUNTS_FILLED: 'prospect_tab_filled_upload',
  TAB_ZERO_SCORE_BULK: 'prospect_tab_zero_score_bulk',
  TAB_ZERO_PLAY_VIDEO: 'prospect_play_video',
  TAB_ZERO_SCORE_SINGLE: 'prospect_tab_zero_score_single',
  TAB_FILLED_SCORE_BULK: 'prospect_tab_filled_score_bulk',
  TAB_FILLED_SCORE_SINGLE: 'prospect_tab_filled_score_single',
  TAB_EXPORT: 'prospect_tab_export',
  TAB_SEARCH: 'prospect_tab_search',
  TAB_FILTERS: 'prospect_tab_filters',
  TAB_PAGINATION: 'prospect_tab_pagination',
  TAB_NEW_HIGHS_ALERT: 'prospect_tab_new-highs_alert',
  TAB_INDUSTRY: 'prospect_tab_filter_industry',
  TAB_REVENUE: 'prospect_tab_filter_revenue_band',
  TAB_REGION: 'prospect_tab_filter_region',
  SINGLE_DOMAIN_INPUT: 'prospect_single_domain_input',
};

export const signUpUIElements = {
  PERSONAL_FORM_STEP_NEXT_BUTTON: 'signup_personal_form_step_next_button',
  COMPANY_FORM_STEP_NEXT_BUTTON: 'signup_company_form_step_next_button',
  PASSWORD_FORM_STEP_CREATE_ACCOUNT_BUTTON:
    'signup_password_form_step_create_account_button',
};

export const accountSettingsMarketplaceElements = {
  AWS_SIDE_BAR_VENDOR_VALIDATOR_BUTTON: 'aws_side_bar_vendor_validator_button',
};

export const activationDashboardElements = {
  PROSPECT_SCORE_SINGLE: 'activation_dashboard_prospect_score_single',
  PROSPECT_SCORE_BULK: 'activation_dashboard_prospect_score_bulk',
  PROSPECT_VIEW_ALL: 'activation_dashboard_prospect_view_all',
  PROSPECT_READ_BEST_PRACTICES:
    'activation_dashboard_prospect_read_best_practices',
  PROSPECT_DOCS_TOOLTIP: 'activation_dashboard_prospect_docs_tooltip',
  PROSPECT_PLAY_VIDEO: 'activation_dashboard_prospect_play_video',
  CO_SELL_VIEW_ALL: 'activation_dashboard_co_sell_view_all',
  CO_SELL_DOCS_TOOLTIP: 'activation_dashboard_co_sell_docs_tooltip',
  CO_SELL_DOCS_TOOLTIP_LEARN_MORE_LINK:
    'activation_dashboard_co_sell_docs_tooltip_learn_more_link',
  CO_SELL_READ_DOCS: 'activation_dashboard_co_sell_read_docs',
  CO_SELL_READ_BEST_PRACTICES:
    'activation_dashboard_co_sell_read_best_practices',
  CO_SELL_PLAY_VIDEO_LINK_NOT_SCORED:
    'activation_dashboard_co_sell_play_video_link_not_scored',
  CO_SELL_PLAY_VIDEO_THUMBNAIL_SCORED:
    'activation_dashboard_co_sell_play_video_thumbnail_scored',
  CO_SELL_PLAY_VIDEO_THUMBNAIL_NOT_SCORED:
    'activation_dashboard_co_sell_play_video_thumbnail_not_scored',
  CO_SELL_GET_CONNECTED: 'activation_dashboard_co_sell_get_connected',
  CO_SELL_ENROLL_TODAY: 'activation_dashboard_co_sell_enroll_today',
  CO_SELL_COMPLETE_CONNECTIONS:
    'activation_dashboard_co_sell_complete_connections',
  CO_SELL_SCORE_ACCOUNTS: 'activation_dashboard_co_sell_score_accounts',
  CO_SELL_START_CO_SELLING: 'activation_dashboard_co_sell_start_co_selling',
  CO_SELL_VIEW_ACCOUNTS: 'activation_dashboard_co_sell_view_accounts',
  OFFERS_GET_CONNECTED: 'activation_dashboard_offers_get_connected',
  OFFERS_COMPLETE_CONNECTIONS:
    'activation_dashboard_offers_complete_connections',
  OFFERS_VIEW_LISTINGS: 'activation_dashboard_offers_view_listings',
  OFFERS_CREATE_OR_IMPORT_LISTING:
    'activation_dashboard_offers_create_or_import_a_listing',
  OFFERS_SCORE_ACCOUNTS: 'activation_dashboard_offers_score_accounts',
  OFFERS_VIEW_ACCOUNTS: 'activation_dashboard_offers_view_accounts',
  OFFERS_READ_PROSPECT_BEST_PRACTICES:
    'activation_dashboard_offers_read_prospect_best_practices',
  OFFERS_READ_OFFERS_BEST_PRACTICES:
    'activation_dashboard_offers_read_offers_best_practices',
  OFFERS_READ_LISTINGS_DOCS: 'activation_dashboard_offers_read_docs',
  OFFERS_CREATE_AN_OFFER: 'activation_dashboard_offers_create_an_offer',
  OFFERS_PLAY_VIDEO_LINK_NOT_SCORED:
    'activation_dashboard_offers_play_video_link_not_scored',
  OFFERS_PLAY_VIDEO_THUMBNAIL_NOT_SCORED:
    'activation_dashboard_offers_play_video_thumbnail_not_scored',
  OFFERS_PLAY_VIDEO_THUMBNAIL_SCORED:
    'activation_dashboard_offers_play_video_thumbnail_scored',
  OFFERS_READ_CONNECT_TO_THE_CLOUD_DOCS:
    'activation_dashboard_offers_read_connect_to_the_cloud_docs',
  OFFERS_DOCS_TOOLTIP: 'activation_dashboard_offers_docs_tooltip',
};
