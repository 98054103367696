import React, { useState } from 'react';
import { Modal, TextField } from '@tackle-io/platform-ui';
import { Box, Typography } from 'vendor/material';
import { Magnify } from 'mdi-material-ui';
import { debounce } from 'lodash-es';

import AccountList from './AccountList';

interface AccountSwitcherProps {
  open: boolean;
  onClose: () => void;
  currentVendorId: string;
  isSuperAdmin: boolean;
}

const AccountSwitcher = ({
  open,
  onClose,
  currentVendorId,
  isSuperAdmin,
}: AccountSwitcherProps): React.ReactElement => {
  const [filterValue, setFilterValue] = useState<string>('');

  const debouncedSearch = debounce((value) => {
    setFilterValue(value);
  }, 200);

  const handleSearchChange = (e): void => {
    debouncedSearch(e.target.value);
  };

  const handleClose = () => {
    setFilterValue('');
    onClose();
  };

  return (
    <Modal
      title="Switch accounts"
      open={open}
      width="small"
      showCloseButton
      onClose={handleClose}
    >
      <Box mb={2}>
        <Typography variant="body2">
          To switch accounts, select an account from the list below.
        </Typography>
      </Box>
      <Box mb={1}>
        <TextField
          labelHidden
          iconLeft={<Magnify />}
          placeholder="Search accounts"
          onChange={handleSearchChange}
          id="searchAccounts"
          name="searchAccounts"
          helperText="Case sensitive"
        />
      </Box>
      <AccountList
        filterValue={filterValue}
        activeAccountId={currentVendorId}
        isSuperAdmin={isSuperAdmin}
      />
    </Modal>
  );
};

export default AccountSwitcher;
