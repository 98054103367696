export enum AceOpportunityDealValueCurrencyEnum {
  USD = 'USD',
  // EUR = 'EUR',
  // GBP = 'GBP',
  // AUD = 'AUD',
  // CAD = 'CAD',
  // CNY = 'CNY',
  // NZD = 'NZD',
  // INR = 'INR',
  // JPY = 'JPY',
  // CHF = 'CHF',
  // SEK = 'SEK',
  // AED = 'AED',
  // AFN = 'AFN',
  // ALL = 'ALL',
  // AMD = 'AMD',
  // ANG = 'ANG',
  // AOA = 'AOA',
  // ARS = 'ARS',
  // AWG = 'AWG',
  // AZN = 'AZN',
  // BAM = 'BAM',
  // BBD = 'BBD',
  // BDT = 'BDT',
  // BGN = 'BGN',
  // BHD = 'BHD',
  // BIF = 'BIF',
  // BMD = 'BMD',
  // BND = 'BND',
  // BOB = 'BOB',
  // BOV = 'BOV',
  // BRL = 'BRL',
  // BSD = 'BSD',
  // BTN = 'BTN',
  // BWP = 'BWP',
  // BYN = 'BYN',
  // BZD = 'BZD',
  // CDF = 'CDF',
  // CHE = 'CHE',
  // CHW = 'CHW',
  // CLF = 'CLF',
  // CLP = 'CLP',
  // COP = 'COP',
  // COU = 'COU',
  // CRC = 'CRC',
  // CUC = 'CUC',
  // CUP = 'CUP',
  // CVE = 'CVE',
  // CZK = 'CZK',
  // DJF = 'DJF',
  // DKK = 'DKK',
  // DOP = 'DOP',
  // DZD = 'DZD',
  // EGP = 'EGP',
  // ERN = 'ERN',
  // ETB = 'ETB',
  // FJD = 'FJD',
  // FKP = 'FKP',
  // GEL = 'GEL',
  // GHS = 'GHS',
  // GIP = 'GIP',
  // GMD = 'GMD',
  // GNF = 'GNF',
  // GTQ = 'GTQ',
  // GYD = 'GYD',
  // HKD = 'HKD',
  // HNL = 'HNL',
  // HRK = 'HRK',
  // HTG = 'HTG',
  // HUF = 'HUF',
  // IDR = 'IDR',
  // ILS = 'ILS',
  // IQD = 'IQD',
  // IRR = 'IRR',
  // ISK = 'ISK',
  // JMD = 'JMD',
  // JOD = 'JOD',
  // KES = 'KES',
  // KGS = 'KGS',
  // KHR = 'KHR',
  // KMF = 'KMF',
  // KPW = 'KPW',
  // KRW = 'KRW',
  // KWD = 'KWD',
  // KYD = 'KYD',
  // KZT = 'KZT',
  // LAK = 'LAK',
  // LBP = 'LBP',
  // LKR = 'LKR',
  // LRD = 'LRD',
  // LSL = 'LSL',
  // LYD = 'LYD',
  // MAD = 'MAD',
  // MDL = 'MDL',
  // MGA = 'MGA',
  // MKD = 'MKD',
  // MMK = 'MMK',
  // MNT = 'MNT',
  // MOP = 'MOP',
  // MRU = 'MRU',
  // MUR = 'MUR',
  // MVR = 'MVR',
  // MWK = 'MWK',
  // MXN = 'MXN',
  // MXV = 'MXV',
  // MYR = 'MYR',
  // MZN = 'MZN',
  // NAD = 'NAD',
  // NGN = 'NGN',
  // NIO = 'NIO',
  // NOK = 'NOK',
  // NPR = 'NPR',
  // OMR = 'OMR',
  // PAB = 'PAB',
  // PEN = 'PEN',
  // PGK = 'PGK',
  // PHP = 'PHP',
  // PKR = 'PKR',
  // PLN = 'PLN',
  // PYG = 'PYG',
  // QAR = 'QAR',
  // RON = 'RON',
  // RSD = 'RSD',
  // RUB = 'RUB',
  // RWF = 'RWF',
  // SAR = 'SAR',
  // SBD = 'SBD',
  // SCR = 'SCR',
  // SDG = 'SDG',
  // SGD = 'SGD',
  // SHP = 'SHP',
  // SLL = 'SLL',
  // SOS = 'SOS',
  // SRD = 'SRD',
  // SSP = 'SSP',
  // STN = 'STN',
  // SVC = 'SVC',
  // SYP = 'SYP',
  // SZL = 'SZL',
  // THB = 'THB',
  // TJS = 'TJS',
  // TMT = 'TMT',
  // TND = 'TND',
  // TOP = 'TOP',
  // TRY = 'TRY',
  // TTD = 'TTD',
  // TWD = 'TWD',
  // TZS = 'TZS',
  // UAH = 'UAH',
  // UGX = 'UGX',
  // USN = 'USN',
  // UYI = 'UYI',
  // UYU = 'UYU',
  // UZS = 'UZS',
  // VEF = 'VEF',
  // VND = 'VND',
  // VUV = 'VUV',
  // WST = 'WST',
  // XAF = 'XAF',
  // XCD = 'XCD',
  // XDR = 'XDR',
  // XOF = 'XOF',
  // XPF = 'XPF',
  // XSU = 'XSU',
  // XUA = 'XUA',
  // YER = 'YER',
  // ZAR = 'ZAR',
  // ZMW = 'ZMW',
  // ZWL = 'ZWL',
}
