import React from 'react';
import { ReactComponent as LockPerson } from '@material-symbols/svg-700/rounded/lock_person.svg';
import { ReactComponent as Block } from '@material-symbols/svg-700/rounded/block.svg';
import { Typography, Box } from 'vendor/material';
// ASSETS
import useStyles from './NotAvailableState.styles';
import { Link } from 'components/Link';

export enum NotAvailableReasonEnum {
  SANDBOX = 'sandbox',
  PERMISSION = 'permission',
  PERMISSION_DASHBOARD = 'permission_dashboard',
}
type NotAvailableReason =
  | NotAvailableReasonEnum.SANDBOX
  | NotAvailableReasonEnum.PERMISSION
  | NotAvailableReasonEnum.PERMISSION_DASHBOARD;
interface NotAvailableStateProps {
  feature: string;
  reason: NotAvailableReason;
}

interface NotAvailableMessageStateProps {
  feature: string;
  reason: NotAvailableReason;
}

const NotAvailableMessage: React.FC<NotAvailableMessageStateProps> = ({
  reason,
  feature,
}) => {
  const classes = useStyles();
  const reasonContent = {
    [NotAvailableReasonEnum.SANDBOX]: {
      heading: `${feature} is not enabled`,
      message: `${feature} is not enabled in test environments. Switch to your production environment, or contact Tackle support at `,
    },
    [NotAvailableReasonEnum.PERMISSION]: {
      heading: `${feature} access not available`,
      message: `You are not authorized to view ${feature}. To update your role or permissions, contact your Tackle administrator`,
    },
    [NotAvailableReasonEnum.PERMISSION_DASHBOARD]: {
      heading: `${feature} access not available`,
      message:
        "Viewing your company's cloud GTM performance on Tackle's dashboard requires access to Tackle Prospect, Co-Sell, or Offers." +
        ' Contact your Tackle administrator to request access',
    },
  };
  const heading = reasonContent[reason]?.heading || '';
  const message = reasonContent[reason]?.message || '';

  return (
    <>
      <Box mb={2} mt={2}>
        <Typography component="h1" className={classes.title}>
          {heading}
        </Typography>
      </Box>
      <Typography component="p" className={classes.message}>
        {message}
        {reason === NotAvailableReasonEnum.SANDBOX && (
          <Link to="mailto:support@tackle.io" external>
            support@tackle.io
          </Link>
        )}
        .
      </Typography>
    </>
  );
};

const NotAvailableState: React.FC<NotAvailableStateProps> = ({
  feature,
  reason,
  children,
}) => {
  const classes = useStyles();
  const iconContent = {
    [NotAvailableReasonEnum.SANDBOX]: Block,
    [NotAvailableReasonEnum.PERMISSION]: LockPerson,
    [NotAvailableReasonEnum.PERMISSION_DASHBOARD]: LockPerson,
  };
  const Icon = iconContent[reason];
  return (
    <div className={classes.container}>
      <Box mt={6}>
        <Icon className={classes.icon} />
      </Box>
      <NotAvailableMessage feature={feature} reason={reason} />
      {children}
    </div>
  );
};

export default NotAvailableState;
