import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: theme.palette.NEUTRAL800,
    display: 'flex',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    paddingLeft: 0,
    paddingRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
  },
  radioGroup: {
    marginLeft: 0,
  },
  radioButton: {
    '& label': {
      marginRight: theme.spacing(1),
    },
    '& span': {
      fontSize: theme.spacing(1.75),
      color: theme.palette.NEUTRAL800,
      '& div[class*="checked"]': {
        color: theme.palette.BLUE400,
      },
    },
    '& span[aria-disabled="true"]': {
      '& div[class*="checked"]': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
      '& div': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
  },
  helperText: {
    color: theme.palette.NEUTRAL300,
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.5),
  },
  radioHelperText: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.NEUTRAL800,
    marginTop: -theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(4),
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
