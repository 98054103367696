import { useCallback } from 'react';
import { Box, Grid, IconButton, makeStyles } from 'vendor/material';
import { Slide } from 'react-toastify';
import { ContentCopy } from 'mdi-material-ui';
import { theme } from 'ui';
import classNames from 'classnames';
import { useToast } from 'hooks';
import { copyToClipboard } from 'utils';
import { OptimizedTooltip } from '../OptimizedTooltip/OptimizedTooltip';

export const toastOptions = {
  type: 'info',
  closeButton: false,
  position: 'top-center',
  autoClose: 2000,
  closeOnClick: false,
  transition: Slide,
  draggable: false,
};

const useStyles = makeStyles(() => ({
  container: {
    marginRight: theme.spacing(0.75),
    display: 'inline-flex',
  },
  iconButton: {
    '& svg': {
      fill: 'white',
      height: 16,
      width: 16,
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  itemsCenter: {
    alignItems: 'center',
  },
  truncateText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface ToolTipCopyProps {
  content: string;
  name: string;
  hideNameInTooltip?: boolean;
  hideName?: boolean;
  maxWidth?: number; // To truncate and show ellipsis
}

const ToolTipCopy = ({
  content,
  name,
  hideNameInTooltip,
  hideName,
  maxWidth,
}: ToolTipCopyProps) => {
  const classes = useStyles();
  const { toaster } = useToast();

  const onCopyClicked = useCallback(
    (e) => {
      e.stopPropagation();
      copyToClipboard(content);
      toaster({
        message: `${name} has been copied to your clipboard.`,
        options: toastOptions,
      });
    },
    [content, name, toaster],
  );

  const Content = (
    <Grid item className={classNames(classes.flex, classes.itemsCenter)}>
      {!hideNameInTooltip ? `${name}` : ''}
      {content}
      <IconButton className={classes.iconButton} onClick={onCopyClicked}>
        <ContentCopy />
      </IconButton>
    </Grid>
  );

  return (
    <OptimizedTooltip position="bottom" content={Content}>
      <Box className={classNames(classes.container)}>
        <span
          className={classes.truncateText}
          style={maxWidth ? { maxWidth: maxWidth } : {}}
        >
          {!hideName ? `${name} ` : ''} {content}
        </span>
      </Box>
    </OptimizedTooltip>
  );
};
export default ToolTipCopy;
