import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  pagingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  pagingDetails: {
    display: 'flex',
    alignItems: 'center',
  },
  pageInfo: {
    marginLeft: `${theme.spacing(2)}px`,
  },
}));

export default useStyles;
