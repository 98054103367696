import { FILTER_TYPES, Filter } from 'components/FilterPanel/FilterPanel';

import { Tag, Button } from '@tackle-io/platform-ui';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useStyles from './ActiveFilterChips.styles';
import { Close } from 'mdi-material-ui';

interface ActiveFilterChipsProps {
  filterSet: Array<Filter>;
  dataId?: string;
  onChange?: Function;
}

export const ActiveFilterTags: React.FC<ActiveFilterChipsProps> = ({
  filterSet,
  dataId = '',
  onChange = () => {},
}) => {
  const location = useLocation();
  const history = useHistory();

  const getOptionValueName = (filter: Filter, value: string) => {
    return filter.options.find((option) => option.value === value)?.title || '';
  };
  const getOptionNameValue = (filterKey: string, name: string) => {
    const filter = filterSet.find((filter) => filter.key === filterKey);
    return filter.options.find((option) => option.title === name)?.value || '';
  };

  const classes = useStyles();
  const activeFilters = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return filterSet.reduce((arr, item) => {
      if (!searchParams.has(item.key)) return arr;

      switch (item.type) {
        case FILTER_TYPES.BOOLEAN:
          arr = [
            ...arr,
            {
              type: item.type,
              key: item.key,
              label: item.label,
              value: !!searchParams.get(item.key),
            },
          ];
          break;
        case FILTER_TYPES.MULTI_SELECT:
          searchParams.getAll(item.key).forEach(
            (entry) =>
              (arr = [
                ...arr,
                {
                  type: item.type,
                  key: item.key,
                  label: item.label,
                  value: getOptionValueName(item, entry),
                },
              ]),
          );
          break;
        case FILTER_TYPES.SINGLE_SELECT:
          arr = [
            ...arr,
            {
              type: item.type,
              key: item.key,
              label: item.label,
              value: getOptionValueName(item, searchParams.get(item.key)),
            },
          ];
          break;
      }

      return arr;
    }, []);
  }, [filterSet, location.search]);

  const handleRemoveSelectedFilter = (filter) => {
    const searchParams = new URLSearchParams(location.search);

    if (filter.type === FILTER_TYPES.MULTI_SELECT) {
      const updatedValues = searchParams.getAll(filter.key).filter((value) => {
        return value !== getOptionNameValue(filter.key, filter.value);
      });
      searchParams.delete(filter.key);
      updatedValues.forEach((value) => searchParams.append(filter.key, value));
    } else {
      searchParams.delete(filter.key);
    }
    const correctedPath = `${location.pathname}?${searchParams.toString()}`;
    history.push(correctedPath);
    onChange();
  };
  const handleClearAllFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    activeFilters.forEach((filter) => {
      searchParams.delete(filter.key);
    });
    const correctedPath = `${location.pathname}?${searchParams.toString()}`;
    history.push(correctedPath);
    onChange();
  };

  const filterTags = activeFilters.map((filter) => {
    let chipText = undefined;
    switch (filter.type) {
      case FILTER_TYPES.BOOLEAN:
        chipText = `${filter.label}`;
        break;
      default:
        chipText = `${filter.label}: ${filter.value}`;
    }
    return (
      <div
        key={`${filter.key}-${filter.value}`}
        onClick={() => handleRemoveSelectedFilter(filter)}
      >
        <Tag color="blue">
          <div className={classes.filterTag}>
            <span>{chipText}</span>
            <Close fontSize="inherit" />
          </div>
        </Tag>
      </div>
    );
  });
  if (filterTags.length < 1) return <></>;

  return (
    <div className={classes.activeFiltersContainer} data-id={dataId}>
      {filterTags}
      <Button
        size="small"
        variant="text"
        appearance="primary"
        onClick={handleClearAllFilters}
      >
        Clear All
      </Button>
    </div>
  );
};
