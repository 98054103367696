import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextField } from '@tackle-io/platform-ui';
import { flow, get, replace } from 'lodash/fp';

import { RegistrationField as RegistrationPageField } from 'stores/products/typings/Registration';
import { determineHTMLInputType } from './helpers';

export type RegistrationFieldProps = {
  field: RegistrationPageField;
  namePrefix?: string;
};

const RegistrationField: React.FC<RegistrationFieldProps> = ({
  field,
  namePrefix,
}): React.ReactElement => {
  const { register, errors } = useFormContext();

  const rawLabel = field.displayTitle?.replace('*', '') ?? field.title;
  const label = field.required ? `${rawLabel} *` : rawLabel;
  const name = namePrefix ? `${namePrefix}_${field.title}` : field.title;
  const type = determineHTMLInputType(field);

  const error = flow([get(name), get('message'), replace(name, rawLabel)])(
    errors,
  );

  return (
    <TextField
      id={name}
      label={label}
      name={name}
      type={type}
      error={error}
      inputProps={{
        ref: register,
        name,
      }}
    />
  );
};

export default RegistrationField;
