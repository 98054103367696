export const AceOpportunityCampaignNameEnum = [
  { display: 'APN Immersion Days', value: 'APN Immersion Days' },
  { display: 'APN Solution Space', value: 'APN Solution Space' },
  {
    display: 'ATO (Authority to Operate)',
    value: 'ATO (Authority to Operate)',
  },
  { display: 'AWS Marketplace Campaign', value: 'AWS Marketplace Campaign' },
  {
    display: 'ISV Immersion Day SFID Program',
    value: 'ISV Immersion Day SFID Program',
  },
  { display: 'ISV Workload Migration', value: 'ISV Workload Migration' },
  {
    display: 'Migration Acceleration Program',
    value: 'Migration Acceleration Program',
  },
  { display: 'Partner Launch Initiative', value: 'Partner Launch Initiative' },
  {
    display: 'Partner Opportunity Acceleration Funded',
    value: 'Partner Opportunity Acceleration Funded',
  },
  { display: 'The Next Smart', value: 'The Next Smart' },
  { display: 'VMware Cloud on AWS', value: 'VMware Cloud on AWS' },
  { display: 'Well-Architected', value: 'Well-Architected' },
  { display: 'Windows', value: 'Windows' },
  {
    display: 'Workspaces/AppStream Accelerator Program',
    value: 'Workspaces/AppStream Accelerator Program',
  },
  { display: 'WWPS NDPP', value: 'WWPS NDPP' },
  { display: 'P3', value: 'P3' },
];
