import { Route, Redirect } from 'react-router-dom';
import { reportError } from 'utils/monitor';

const RedirectWithAlert = ({
  from,
  exact,
  to,
  message = '',
}: {
  // TODO use types from react router
  from: string;
  to: string;
  exact?: boolean;
  message?: string;
}) => (
  <Route
    path={from}
    exact={exact}
    render={() => {
      reportError(
        new Error(
          `Attempt to access invalid route: ${window.location.href}${
            message && `\n${message}`
          }`,
        ),
      );
      return <Redirect to={to} />;
    }}
  />
);

export default RedirectWithAlert;
