import React, { useReducer, useEffect } from 'react';

import api from 'utils/api';

import {
  getLocalStorageState,
  setLocalStorageState,
} from 'stores/cache/localStorage';
import { defaultState } from 'stores/utils';
import { ProviderProps } from 'stores/typings';
import Context, { storageKey } from './context';
import reducer, { APISuccessResponse } from './reducer';
import {
  serialize,
  deserialize,
  AwsProductJSON,
  GcpProductJSON,
  AzureProductJSON,
  RedhatProductJSON,
} from './typings';

const Provider: React.FC<ProviderProps> = ({
  content = [],
  disableSync = false,
  disableCache = false,
  localStorageKey = storageKey,
  children,
}) => {
  let providerContent = content;

  // get cached content
  if (!disableCache) {
    const { data: cachedContent } = getLocalStorageState(localStorageKey);
    providerContent = cachedContent?.map(deserialize);
  }

  // setup Context reducer
  const [state, dispatch] = useReducer(
    reducer,
    defaultState({ content: providerContent }),
  );

  // sync with server on first render
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    // abort any pending requests
    if (disableSync) return (): void => controller.abort();

    dispatch({ type: 'SYNC' });
    api
      .get('product', {
        signal,
      })
      .json()
      .then(({ aws, gcp, azure, redhat }: APISuccessResponse) => {
        // TODO update API to respond with { data: [] }
        // TODO add cloud to Product dump
        dispatch({
          type: 'SET_CONTENT',
          payload: {
            content: [
              ...aws.map((p) => ({ cloud: 'aws', ...p } as AwsProductJSON)),
              ...gcp.map((p) => ({ cloud: 'gcp', ...p } as GcpProductJSON)),
              ...azure.map(
                (p) => ({ cloud: 'azure', ...p } as AzureProductJSON),
              ),
              ...redhat.map(
                (p) => ({ cloud: 'redhat', ...p } as RedhatProductJSON),
              ),
            ].map(deserialize),
          },
        });
      })
      .catch((error) => {
        dispatch({ type: 'SET_ERROR', payload: { error } });
      });

    return (): void => controller.abort(); // on unmount, abort any pending requests
  }, [disableSync]);

  // save content updates to cache
  useEffect(() => {
    if (state.content && !disableCache) {
      setLocalStorageState(storageKey, state.content.map(serialize));
    }
  }, [state.content, disableCache]);

  // return Context.Provider
  return (
    <Context.Provider value={{ ...state, dispatch }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
