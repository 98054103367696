import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  createContext,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Auth0Provider,
  useAuth0,
  Auth0ContextInterface,
} from '@auth0/auth0-react';

import { Auth, User } from 'stores';
import { useRenewAuth } from 'hooks';
import { isEqual } from 'lodash-es';
export { withAuthenticationRequired } from '@auth0/auth0-react';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const AuthSyncContext = createContext({ isLoading: true });

const useSyncAuthStore = () => {
  const [syncing, setSyncing] = useState(true);

  const authStore = useContext(Auth);
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth();

  useEffect(() => {
    const sync = async () => {
      if (!isAuthenticated) {
        setSyncing(false);
        return;
      }

      setSyncing(true);

      if (authStore.getTokenCallback !== getAccessTokenSilently) {
        authStore.getTokenCallback = getAccessTokenSilently;
      }

      const token = await getAccessTokenSilently();
      const { nonce, ...profile } = await getIdTokenClaims();

      if (authStore.token !== token) {
        authStore.token = token;
      }

      if (!isEqual(authStore.profile, profile)) {
        authStore.profile = profile;
      }

      setSyncing(false);
    };
    sync();
  }, [
    authStore,
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
  ]);

  return { syncing };
};

const AuthSync = ({ children }) => {
  const { isLoading } = useAuth0();
  const { refreshing } = useRenewAuth();
  const { syncing } = useSyncAuthStore();

  const contextValue = useMemo(() => {
    return { isLoading: isLoading || refreshing || syncing };
  }, [isLoading, refreshing, syncing]);

  return (
    <AuthSyncContext.Provider value={contextValue}>
      {children}
    </AuthSyncContext.Provider>
  );
};

export const AuthProvider = ({
  children,
}: {
  children: React.ReactChildren;
}): React.ReactElement => {
  const query = useQuery();
  const connection = query.get('con');
  return (
    <Auth0Provider
      useRefreshTokens
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID || ''}
      redirectUri={`${window.location.origin}/auth/callback`}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
      responseType={'token id_token code'}
      scope={'openid profile email offline_access'}
      cacheLocation="localstorage"
      connection={connection || ''}
    >
      <AuthSync>{children}</AuthSync>
    </Auth0Provider>
  );
};

type AuthInterface = Pick<
  Auth0ContextInterface,
  | 'isAuthenticated'
  | 'isLoading'
  | 'getAccessTokenSilently'
  | 'loginWithRedirect'
  | 'logout'
  | 'getIdTokenClaims'
>;

const useAuth = (): AuthInterface => {
  const {
    // Auth state:
    isAuthenticated,
    // Auth methods:
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    getIdTokenClaims,
  } = useAuth0();
  const { isLoading } = useContext(AuthSyncContext);
  const authStore = useContext(Auth);
  const { removeUserContext } = useContext(User);
  return {
    isAuthenticated,
    isLoading,
    // @ts-ignore for some reason, TS doesn't register the overloads in the return value of Auth0's `getAccessTokenSilently`
    getAccessTokenSilently: useCallback(
      async (options) => {
        try {
          return getAccessTokenSilently(options);
        } catch (e) {
          if (['login_required', 'consent_required'].includes(e.error)) {
            await loginWithRedirect();
          } else {
            throw e;
          }
        }
      },
      [getAccessTokenSilently, loginWithRedirect],
    ),
    loginWithRedirect,
    getIdTokenClaims,
    logout: useCallback(
      (options = {}) => {
        removeUserContext();
        authStore.resetState();
        logout(options);
      },
      [removeUserContext, authStore, logout],
    ),
  };
};

export { useAuth };
