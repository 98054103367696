import mime from 'mime';

const forceBrowserDownload = async (
  url,
  name = '',
  contentType = '',
  revokeOnSuccess = true,
) => {
  if (!url) {
    throw new Error('Unable to force browser download due to missing url');
  }

  // Get download name if not set
  const downloadName = name || url.split('/').pop()?.split('?')[0];
  if (!downloadName) {
    throw new Error(
      'Unable to force browser download due to invalid file name',
    );
  }

  // Get content type if not set
  const downloadContentType =
    contentType || mime.getType(downloadName.split('.').pop());
  if (!downloadContentType) {
    throw new Error(
      'Unable to force browser download due to invalid content type',
    );
  }

  // Fetch asset
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  // Force download
  const data = await response.blob();
  const anchor = document.createElement('a');
  anchor.href = window.URL.createObjectURL(
    new Blob([data], { type: downloadContentType }),
  );
  anchor.download = downloadName;
  anchor.click();

  if (revokeOnSuccess) {
    // Clear from memory
    window.URL.revokeObjectURL(anchor.href);
  }
};

export default forceBrowserDownload;
