import { fontWeightMedium } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  infoBox: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  infoTitle: {
    color: theme.palette.NEUTRAL300,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeightMedium,
    lineHeight: theme.typography.pxToRem(20),
  },
  infoValue: {
    color: theme.palette.NEUTRAL500,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: fontWeightMedium,
    lineHeight: theme.typography.pxToRem(24),
  },
}));

export default useStyles;
