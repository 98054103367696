import { SelectFormFieldOption } from 'pages/CoSell/types';
import { MsftStatesEnum } from 'pages/CoSell/types/enums';

export const MsftUsStatesList: SelectFormFieldOption[] = [
  { title: 'Alabama', value: MsftStatesEnum.ALABAMA },
  { title: 'Alaska', value: MsftStatesEnum.ALASKA },
  { title: 'Arizona', value: MsftStatesEnum.ARIZONA },
  { title: 'Arkansas', value: MsftStatesEnum.ARKANSAS },
  { title: 'California', value: MsftStatesEnum.CALIFORNIA },
  { title: 'Colorado', value: MsftStatesEnum.COLORADO },
  { title: 'Connecticut', value: MsftStatesEnum.CONNECTICUT },
  { title: 'Delaware', value: MsftStatesEnum.DELAWARE },
  { title: 'Florida', value: MsftStatesEnum.FLORIDA },
  { title: 'Georgia', value: MsftStatesEnum.GEORGIA },
  { title: 'Hawaii', value: MsftStatesEnum.HAWAII },
  { title: 'Idaho', value: MsftStatesEnum.IDAHO },
  { title: 'Illinois', value: MsftStatesEnum.ILLINOIS },
  { title: 'Indiana', value: MsftStatesEnum.INDIANA },
  { title: 'Iowa', value: MsftStatesEnum.IOWA },
  { title: 'Kansas', value: MsftStatesEnum.KANSAS },
  { title: 'Kentucky', value: MsftStatesEnum.KENTUCKY },
  { title: 'Louisiana', value: MsftStatesEnum.LOUISIANA },
  { title: 'Maine', value: MsftStatesEnum.MAINE },
  { title: 'Maryland', value: MsftStatesEnum.MARYLAND },
  { title: 'Massachusetts', value: MsftStatesEnum.MASSACHUSETTS },
  { title: 'Michigan', value: MsftStatesEnum.MICHIGAN },
  { title: 'Minnesota', value: MsftStatesEnum.MINNESOTA },
  { title: 'Mississippi', value: MsftStatesEnum.MISSISSIPPI },
  { title: 'Missouri', value: MsftStatesEnum.MISSOURI },
  { title: 'Montana', value: MsftStatesEnum.MONTANA },
  { title: 'Nebraska', value: MsftStatesEnum.NEBRASKA },
  { title: 'Nevada', value: MsftStatesEnum.NEVADA },
  { title: 'New Hampshire', value: MsftStatesEnum.NEW_HAMPSHIRE },
  { title: 'New Jersey', value: MsftStatesEnum.NEW_JERSEY },
  { title: 'New Mexico', value: MsftStatesEnum.NEW_MEXICO },
  { title: 'New York', value: MsftStatesEnum.NEW_YORK },
  { title: 'North Carolina', value: MsftStatesEnum.NORTH_CAROLINA },
  { title: 'North Dakota', value: MsftStatesEnum.NORTH_DAKOTA },
  { title: 'Ohio', value: MsftStatesEnum.OHIO },
  { title: 'Oklahoma', value: MsftStatesEnum.OKLAHOMA },
  { title: 'Oregon', value: MsftStatesEnum.OREGON },
  { title: 'Pennsylvania', value: MsftStatesEnum.PENNSYLVANIA },
  { title: 'Rhode Island', value: MsftStatesEnum.RHODE_ISLAND },
  { title: 'South Carolina', value: MsftStatesEnum.SOUTH_CAROLINA },
  { title: 'South Dakota', value: MsftStatesEnum.SOUTH_DAKOTA },
  { title: 'Tennessee', value: MsftStatesEnum.TENNESSEE },
  { title: 'Texas', value: MsftStatesEnum.TEXAS },
  { title: 'Utah', value: MsftStatesEnum.UTAH },
  { title: 'Vermont', value: MsftStatesEnum.VERMONT },
  { title: 'Virginia', value: MsftStatesEnum.VIRGINIA },
  { title: 'Washington', value: MsftStatesEnum.WASHINGTON },
  { title: 'West Virginia', value: MsftStatesEnum.WEST_VIRGINIA },
  { title: 'Wisconsin', value: MsftStatesEnum.WISCONSIN },
  { title: 'Wyoming', value: MsftStatesEnum.WYOMING },
  { title: 'District of Columbia', value: MsftStatesEnum.DISTRICT_OF_COLUMBIA },
  { title: 'American Samoa', value: MsftStatesEnum.AMERICAN_SAMOA },
  { title: 'Guam', value: MsftStatesEnum.GUAM },
  {
    title: 'Northern Mariana Islands',
    value: MsftStatesEnum.NORTHERN_MARIANA_SLANDS,
  },
  { title: 'Puerto Rico', value: MsftStatesEnum.PUERTO_RICO },
  {
    title: 'United States Minor Outlying Islands',
    value: MsftStatesEnum.UNITED_STATES_MINOR_OUTLYING_ISLANDS,
  },
  { title: 'U.S. Virgin Islands', value: MsftStatesEnum.US_VIRGIN_ISLANDS },
];
