import { Cloud } from '../../utils/cloudTypes';
import { Product } from '../../stores/products/typings/index';

const PAYMENT_SCHEDULE_BANNER_TITLE_AWS = 'Payment schedule';
const PAYMENT_SCHEDULE_BANNER_BODY_AWS =
  'At least one payment is required with a payment schedule. To avoid adding payments, change the payment model to pricing per product.';

const PAYMENT_SCHEDULE_BANNER_TITLE_AWS_AMENDMENT_OFFER =
  'Amendment payment schedule';
const PAYMENT_SCHEDULE_BANNER_BODY_AWS_AMENDMENT_OFFER =
  'At least one payment is required with a payment schedule. If your buyer accepts this offer, any remaining payments from previous agreements will be cancelled and therefore should be included in this offer. Below are the remaining payments from the previous agreement.';

const PAYMENT_SCHEDULE_BANNER_TITLE_GCP =
  'Each payment can be up to 12 months long and must be equal to or increase in value from the previous payment.';
const PAYMENT_SCHEDULE_BANNER_BODY_GCP =
  'The first payment can be up to three months longer to give your customer time to accept the offer. Each subsequent payment amount must be equal to or larger in value than the previous payment.';

interface ProducerParams {
  isAmendmentOffer: boolean;
}

type BannerTextProducer = (pp: ProducerParams) => string | null;
type BannerTextProducerMap = { [c: string]: BannerTextProducer };

const noOpProducer: BannerTextProducer = () => null;

const bannerTitleProducersByCloud: BannerTextProducerMap = {
  [Cloud.Gcp]: () => PAYMENT_SCHEDULE_BANNER_TITLE_GCP,
  [Cloud.Aws]: ({ isAmendmentOffer }) =>
    isAmendmentOffer
      ? PAYMENT_SCHEDULE_BANNER_TITLE_AWS_AMENDMENT_OFFER
      : PAYMENT_SCHEDULE_BANNER_TITLE_AWS,
};

export const getBannerTitleText = (
  cloud: Product['cloud'],
  isAmendmentOffer: boolean,
): string | null => {
  const titleProducer = bannerTitleProducersByCloud[cloud] || noOpProducer;
  return titleProducer({ isAmendmentOffer });
};

const bannerBodyProducerByCloud: BannerTextProducerMap = {
  [Cloud.Gcp]: () => PAYMENT_SCHEDULE_BANNER_BODY_GCP,
  [Cloud.Aws]: ({ isAmendmentOffer }) =>
    isAmendmentOffer
      ? PAYMENT_SCHEDULE_BANNER_BODY_AWS_AMENDMENT_OFFER
      : PAYMENT_SCHEDULE_BANNER_BODY_AWS,
};

export const getBannerBodyText = (
  cloud: Product['cloud'],
  isAmendmentOffer: boolean,
): string | null => {
  const bodyProducer = bannerBodyProducerByCloud[cloud] || noOpProducer;
  return bodyProducer({ isAmendmentOffer });
};
