import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  hideClearAll: {
    '& [class*="clearAll"]': {
      display: 'none',
    },
  },
  infoLink: {
    color: theme.palette.BLUE400,
    textDecoration: 'none',
  },
}));

export default useStyles;
