import useStyles from './CoSellTabs.styles';
import { Tabs } from '@tackle-io/platform-ui';
import { CoSellSearch } from '../CoSellSearch/CoSellSearch';
import { CoSellOpportunitiesTable } from '../CoSellOpportunitiesTable/CoSellOpportunitiesTable';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getUrlParamsPath,
  Operation,
  CoSellLandingPageTabs,
  TAB_KEY,
} from '../../helpers/urlParamsHelper';
import { CoSellInvitationsTable } from '../CoSellInvitationsTable/CoSellInvitationsTable';
import { coSellLandingPageDataId } from 'packages/cosell/src/utilities/intercomEnums';

const OpportunitiesTab = () => {
  const classes = useStyles();

  return (
    <div className={classes.tabsContent}>
      <CoSellSearch />
      <CoSellOpportunitiesTable />
    </div>
  );
};
const InvitationsTab = () => {
  const classes = useStyles();

  return (
    <div className={classes.tabsContent}>
      <CoSellInvitationsTable />
    </div>
  );
};

const TabIndexes = {
  OPPORTUNITIES: 0,
  INVIATIONS: 1,
};
const tabs = [
  {
    label: 'Opportunities',
    content: OpportunitiesTab,
  },
  {
    label: 'Invitations',
    content: InvitationsTab,
  },
];

export const CoSellTabs = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isInvitationsTab =
    searchParams.get(TAB_KEY) === CoSellLandingPageTabs.INVITATIONS;
  const handleTabClick = (tabIndex: number) => {
    const filter = {
      operation: Operation.TAB,
      value: CoSellLandingPageTabs.OPPORTUNITIES,
    };

    if (tabIndex === TabIndexes.INVIATIONS) {
      filter.value = CoSellLandingPageTabs.INVITATIONS;
    }

    const correctedPath = getUrlParamsPath({ filter, location });

    history.push(correctedPath);
  };

  return (
    <div data-id={coSellLandingPageDataId.TABS}>
      <Tabs
        key={`${isInvitationsTab}`}
        tabs={tabs}
        onChange={(_e, tabIndex: number) => handleTabClick(tabIndex)}
        value={
          isInvitationsTab ? TabIndexes.INVIATIONS : TabIndexes.OPPORTUNITIES
        }
      />
    </div>
  );
};
