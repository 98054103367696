import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 'calc(500px - 2rem)', // temporary to figure out proper height for fixed header/footer
    oveflow: 'clip',
    color: '#fff',
    fontFamily: theme.typography.fontFamily,
    boxSizing: 'border-box',
    display: 'grid',
  },
  header: {
    display: 'grid',
    position: 'sticky',
    top: 0,
  },
  content: {
    flex: 1,
    // background: 'lightblue',
    color: 'black',
    padding: '1rem',
    overflowY: 'auto',
  },

  footer: {
    position: 'sticky',
    bottom: 0,
    borderTop: '1px solid rgba(0,0,0,0.1)',
    boxSizing: 'border-box',
  },
}));

/** WIP:
 *  This is for development and will likely be removed.
 *  container with fixed header and footer,
 *  and scrollable content area in the middle
 *
 * When development is done, this will component will be renamed and moved to the correct location
 */
export const FixedHeaderFooter = ({
  children,
  footerContent,
  headerContent,
}: {
  children: React.ReactNode;
  footerContent: React.ReactNode;
  headerContent: React.ReactNode;
}) => {
  const {
    content,
    footer,
    // footerDemoContent,
    header,
    wrapper,
  } = useStyles();
  return (
    <div className={wrapper}>
      <header className={header}>{headerContent}</header>
      <section className={content}>{children}</section>
      <div className={footer}>{footerContent}</div>
    </div>
  );
};
