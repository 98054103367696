export enum MsftStatesEnum {
  ALABAMA = 'US-AL',
  ALASKA = 'US-AK',
  ARIZONA = 'US-AZ',
  ARKANSAS = 'US-AR',
  CALIFORNIA = 'US-CA',
  COLORADO = 'US-CO',
  CONNECTICUT = 'US-CT',
  DELAWARE = 'US-DE',
  FLORIDA = 'US-FL',
  GEORGIA = 'US-GA',
  HAWAII = 'US-HI',
  IDAHO = 'US-ID',
  ILLINOIS = 'US-IL',
  INDIANA = 'US-IN',
  IOWA = 'US-IA',
  KANSAS = 'US-KS',
  KENTUCKY = 'US-KY',
  LOUISIANA = 'US-LA',
  MAINE = 'US-ME',
  MARYLAND = 'US-MD',
  MASSACHUSETTS = 'US-MA',
  MICHIGAN = 'US-MI',
  MINNESOTA = 'US-MN',
  MISSISSIPPI = 'US-MS',
  MISSOURI = 'US-MO',
  MONTANA = 'US-MT',
  NEBRASKA = 'US-NE',
  NEVADA = 'US-NV',
  NEW_HAMPSHIRE = 'US-NH',
  NEW_JERSEY = 'US-NJ',
  NEW_MEXICO = 'US-NM',
  NEW_YORK = 'US-NY',
  NORTH_CAROLINA = 'US-NC',
  NORTH_DAKOTA = 'US-ND',
  OHIO = 'US-OH',
  OKLAHOMA = 'US-OK',
  OREGON = 'US-OR',
  PENNSYLVANIA = 'US-PA',
  RHODE_ISLAND = 'US-RI',
  SOUTH_CAROLINA = 'US-SC',
  SOUTH_DAKOTA = 'US-SD',
  TENNESSEE = 'US-TN',
  TEXAS = 'US-TX',
  UTAH = 'US-UT',
  VERMONT = 'US-VT',
  VIRGINIA = 'US-VA',
  WASHINGTON = 'US-WA',
  WEST_VIRGINIA = 'US-WV',
  WISCONSIN = 'US-WI',
  WYOMING = 'US-WY',
  DISTRICT_OF_COLUMBIA = 'US-DC',
  AMERICAN_SAMOA = 'US-AS',
  GUAM = 'US-GU',
  NORTHERN_MARIANA_SLANDS = 'US-MP',
  PUERTO_RICO = 'US-PR',
  UNITED_STATES_MINOR_OUTLYING_ISLANDS = 'US-UM',
  US_VIRGIN_ISLANDS = 'US-VI',
}
