import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    marginTop: theme.spacing(4),
  },
  tabIndicator: {
    background: '#0052CC',
  },
  tabLabel: {
    textTransform: 'none',
    color: '#505F79',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: '37px',
    letterSpacing: '0.15px',
    textAlign: 'center',
    minWidth: '0',
    borderBottom: '1px solid #0000001F',
    '&.Mui-selected': {
      color: '#0052CC', // Not sure why this isn't working, but leaving it for now
    },
  },
}));

export default useStyles;
