import decodeToken from 'jwt-decode';
import nonceFn from 'nonce-fast';
import { decorate, observable, action, computed } from 'mobx';
import { invert } from 'lodash/fp';
import { reportError } from 'utils/monitor';
import { omit } from 'lodash-es';
import Auth0Client from '../utils/auth0';

const generateNonce = nonceFn();

const CONNECTIONS = {
  azure: process.env.REACT_APP_AUTH0_AZURE_CONNECTION,
  google: 'google-oauth2',
  email: 'email+password',
};

const HUMANIZED_CONNECTIONS = invert(CONNECTIONS);

class Auth {
  constructor() {
    this.auth0Client = new Auth0Client();

    this._token = localStorage.getItem('token') || null;
    this._refreshToken = localStorage.getItem('refreshToken') || null;
    this._profile = JSON.parse(localStorage.getItem('profile') || 'null');
    this._expiresAt = JSON.parse(localStorage.getItem('expiresAt') || 'null');

    window.addEventListener('storage', ({ key, newValue }) => {
      if (key === 'token') {
        if (newValue !== this._token) {
          this._token = newValue;
        }
      }
    });

    window.addEventListener('storage', ({ key, newValue }) => {
      if (key === 'refreshToken') {
        if (newValue !== this._refreshToken) {
          this._refreshToken = newValue;
        }
      }
    });

    window.addEventListener('profile', ({ key, newValue }) => {
      if (key === 'profile') {
        if (newValue !== this._profile) {
          this._profile = newValue;
        }
      }
    });
  }

  get token() {
    const token = localStorage.getItem('token') || null;

    if (token !== this._token) {
      this.token = token;
    }

    return token;
  }

  set token(token) {
    localStorage.setItem('token', token);
    this._token = token;
  }

  get refreshToken() {
    return localStorage.getItem('refreshToken');
  }

  set refreshToken(token) {
    localStorage.setItem('refreshToken', token);
    this._refreshToken = token;
  }

  get valid() {
    return Boolean(this.token && !this.expired);
  }

  get expired() {
    if (!this.token) return true;

    try {
      const { exp } = decodeToken(this.token);
      const now = Date.now();
      return now / 1000 > exp;
    } catch (err) {
      return true;
    }
  }

  get nonce() {
    return JSON.parse(localStorage.getItem('nonce'));
  }

  set nonce(nonceMap) {
    localStorage.setItem('nonce', JSON.stringify(nonceMap));
  }

  get profile() {
    const profile = JSON.parse(localStorage.getItem('profile') || 'null');

    this._profile = profile;

    return profile;
  }

  set profile(profile) {
    localStorage.setItem('profile', JSON.stringify(profile));
    this._profile = profile;
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;
  }

  getRefreshToken = async ({ code }) => {
    try {
      return await this.auth0Client.getRefreshToken(code);
    } catch (err) {
      reportError(new Error('Failed to fetch refresh token'), err);
    }
  };

  getNewAccessToken = (refreshToken = null) => {
    return this.auth0Client
      .getNewAccessToken(refreshToken || this.refreshToken)
      .then((result) => {
        this.decodeValidateStorePayload(result);
      })
      .catch((err) => {
        reportError(new Error('Failed to fetch new access token'), err);
      });
  };

  renewAuth = () => {
    const nonceKey = generateNonce();
    this.nonce = {
      [nonceKey]: {},
    };

    this.auth0Client
      .renewAuth({ nonce: nonceKey })
      .then((result) => {
        this.decodeValidateStorePayload(result);
      })
      .catch((err) => {
        if (!['access_denied', 'login_required'].includes(err?.error)) {
          reportError(new Error('Failed to renew auth'), err);
        }
        this.logout();
      });
  };

  socialLogin = (connection, state = {}) => {
    if (!connection) throw new Error('Invalid Social Connection', connection);

    const nonceKey = generateNonce();
    this.nonce = {
      [nonceKey]: state,
    };

    const url = this.auth0Client.getSocialLoginUrl(connection, nonceKey);

    window.location.href = url;
  };

  login = (
    { username, password },
    state = {
      redirectTo: '/',
      sessionId: '',
    },
  ) => {
    const nonceKey = generateNonce();
    this.nonce = {
      [nonceKey]: state,
    };
    return this.auth0Client.login({ username, password }, nonceKey, state);
  };

  authCallback = async (args, shouldRefreshToken = false) => {
    const { idToken, code } = args;
    const { nonce: nonceKey } = decodeToken(idToken);

    if (!!nonceKey) {
      const state = this.nonce[nonceKey];

      if (!state) {
        reportError('Failed to get state for nonce', {
          nonce: nonceKey,
          nonceMap: this.nonce,
        });
      } else {
        this.state = state;
      }
    }

    if (code) {
      args = await this.getRefreshToken({ code });

      if (shouldRefreshToken) {
        await this.getNewAccessToken(args.refreshToken);
        return;
      }
    }

    this.decodeValidateStorePayload(args);
  };

  decodeValidateStorePayload = async (args) => {
    const { accessToken, idToken, refreshToken } = args;
    const expiresIn = 6 * 60;
    const decodedIdToken = decodeToken(idToken);
    const profile = omit(decodedIdToken, 'nonce');

    this.profile = profile;
    this.token = accessToken;
    this.refreshToken = refreshToken;

    const now = Date.now(); // ms
    // expiresIn is the token lifetime in seconds
    const expiresAt = now + Number(expiresIn) * 1000;
    this._expiresAt = expiresAt;

    localStorage.setItem('expiresAt', expiresAt);
  };

  fetchProfile = () => {
    return this.auth0Client.fetchProfile({ accessToken: this.token });
  };

  resetPassword = (email) => {
    return this.auth0Client.resetPassword(email);
  };

  resetState = () => {
    this.token = null;
    this.refreshToken = null;
    this.profile = null;
    this.tokenPromises = [];
    this.getTokenCallback = null;

    let keys = [];
    for (let i = 0; i < localStorage.length; i++) {
      keys.push(localStorage.key(i));
    }

    keys.forEach((key) => {
      if (
        !key.includes('auth0') &&
        !key.includes('returnTo') &&
        !key.includes('dashboardBeta')
      ) {
        localStorage.removeItem(key);
      }
    });
  };

  logout = (returnTo = null) => {
    // only trigger logout if values exist
    if (!this.token && !this.profile) return;

    this.resetState();

    returnTo = returnTo || window.location.origin;
    this.auth0Client.logout({ returnTo });
  };

  async getToken() {
    if (!this._getToken) {
      throw new Error('Auth store not initialized with getTokenCallback');
    }

    const token = await this._getToken();
    this.token = token;
    return token;
  }

  get getTokenCallback() {
    return this._getToken;
  }

  set getTokenCallback(callback) {
    this._getToken = callback;
  }
}

decorate(Auth, {
  _getToken: observable,
  _profile: observable,
  _refreshToken: observable,
  _token: observable,
  _expiresAt: observable,
  now: observable,
  socialLogin: action,
  login: action,
  logout: action,
  resetPassword: action,
  expired: computed,
  valid: computed,
});

export default Auth;

/**
 * Singleton of a mobx store that manages authentication of the current user and interfaces with our auth provider, Auth0.
 *
 * Makes use of refresh tokens to maintain a seamless user session until the current user logs out.
 *
 * Our JWT access tokens are set to expire after a short period (~30 minutes as of writing). To
 * maintain authenticated access to our API and other services throughout the user session,
 * we employ refresh tokens. When a user authenticates, we are given a an authorization code
 * along with an initial access token. This code is immediately used to fetch a refresh token.
 *
 * Each time our api clients make a request, we check if the access token is expired. If not,
 * the request proceeds. If the token is expired, we use the refresh token to fetch a new
 * access token from Auth0.
 *
 * Refresh token rotation must be enabled in the appropriate Auth0 application with a non-zero Reuse Interval.
 */
const authStore = new Auth();
export { authStore, CONNECTIONS, HUMANIZED_CONNECTIONS };
