import React from 'react';
import { Box, Grid } from 'vendor/material';
import { Page } from 'components';
import AceOpportunityDetailsHeader from './detailSections/AceOpportunityDetailsHeader';
import NextStepsCard from './detailSections/NextStepsCard';
import AceCustomerDetails from './detailSections/AceCustomerDetails';
import AceProjectDetails from './detailSections/AceProjectDetails';
import AceMarketingDetails from './detailSections/AceMarketingDetails';
import AceAdditionalDetails from './detailSections/AceAdditionalDetails';
import AceContactDetails from './detailSections/AceContactDetails';
import AcePartnerDetails from './detailSections/AcePartnerDetails';
import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import RightRailCard from './rightRail/RightRailCard';
import useStyles from './AceOpportunityDetails.style';
import { AceOpportunityStageEnum } from 'packages/cosell/src/types/enums';
import { Divider } from '../../../../../../vendor/material';
import { AceOpportunityEventResponse } from '../../../types/responses/AceOpportunityEventResponse';
import { CoSellActivityFeed } from '../../../components/CoSellActivityFeed/CoSellActivityFeed';

interface AceSingleOpportunityProps {
  opportunity: AceOpportunityResponse;
  requiresSaasDocumentationToLaunch: boolean;
  opportunityEvents: AceOpportunityEventResponse[];
}

const AceOpportunityDetails: React.FC<AceSingleOpportunityProps> = ({
  opportunity,
  requiresSaasDocumentationToLaunch,
  opportunityEvents,
}) => {
  const classes = useStyles();

  return (
    <Page className={classes.detailsPage}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Box mt={2}>
            <AceOpportunityDetailsHeader
              title={opportunity?.project?.title}
              source={opportunity?.source}
              lifeCycle={opportunity?.lifeCycle}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <NextStepsCard
            nextSteps={opportunity?.lifeCycle?.nextSteps}
            nextStepsHistory={opportunity?.lifeCycle?.nextStepsHistory}
          />

          <Box mt={4}>
            <AceCustomerDetails account={opportunity?.customer?.account} />
          </Box>
          <Box mt={4}>
            <AceProjectDetails
              project={opportunity?.project}
              targetCloseDate={opportunity?.lifeCycle?.targetCloseDate}
              awsProducts={opportunity?.awsProducts}
              solutions={opportunity?.solutions}
            />
          </Box>
          <Box mt={4}>
            <AceMarketingDetails marketing={opportunity?.marketing} />
          </Box>

          <Box mt={4}>
            <AceAdditionalDetails
              isSRRPAndLaunched={
                requiresSaasDocumentationToLaunch &&
                opportunity?.lifeCycle?.stage ===
                  AceOpportunityStageEnum.LAUNCHED
              }
              awsAccountId={opportunity?.customer?.account.awsAccountId}
              additionalComments={opportunity?.project?.additionalComments}
              partnerOpportunityIdentifier={
                opportunity?.partnerOpportunityIdentifier
              }
              competitiveTracking={opportunity?.project?.competitorName}
              softwareRevenue={opportunity?.softwareRevenue}
            />
          </Box>

          <Box mt={4}>
            <AceContactDetails contact={opportunity?.customer?.contact} />
          </Box>

          {!!opportunity?.opportunityTeam?.length &&
            opportunity?.opportunityTeam.map((teamMember, i) => (
              <Box mt={4} key={i}>
                <AcePartnerDetails partnerContact={teamMember} />
              </Box>
            ))}
        </Grid>
        <Grid item xs={12} md={3}>
          <RightRailCard
            status={opportunity?.lifeCycle?.reviewStatus}
            stage={opportunity?.lifeCycle?.stage}
            reviewStatusReason={opportunity?.lifeCycle?.reviewStatusReason}
            origin={opportunity?.origin}
            awsOpportunityTeam={opportunity?.awsOpportunityTeam}
          />

          <Box mt={4} mb={4}>
            <Divider />
          </Box>

          <CoSellActivityFeed activities={opportunityEvents} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default AceOpportunityDetails;
