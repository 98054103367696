import { ContractsQuery } from 'generated/graphql';
import { DetailItem } from './components/Summary/components/SummaryCard/SummaryCard';
import { DateTime } from 'luxon';

type Contract = ContractsQuery['contracts']['hits']['hits'][number]['_source'];
const buildRenewByHeader = (contract: Contract): string => {
  switch (contract?.contractStatus) {
    case 'Cancelled':
      return 'Cancelled on';
    case 'Amended':
      return 'Amended on';
    default:
      return 'Renew by';
  }
};

const buildTimeRemainingFooter = (contract: Contract): string | undefined => {
  const now = DateTime.now();
  const schedule = contract?.paymentSchedule?.schedule || [];

  const futureDates = schedule
    .map((item) => DateTime.fromISO(item.chargeDate))
    .filter((date) => date > now);

  if (futureDates.length === 0) {
    return undefined;
  }

  const nextChargeDate = futureDates.reduce((closest, date) => {
    return date < closest ? date : closest;
  });

  const formattedDate = nextChargeDate.toFormat('MMM dd yyyy');
  return `Next invoice on ${formattedDate}`;
};

const formatDate = (date?: string): string => {
  return date
    ? new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    : '--';
};

const buildSummaryItems = (contract: Contract): DetailItem[] => {
  const dateAccepted = formatDate(contract?.acceptedDate);
  const term = convertISOTimeFrame(contract?.contractDuration);
  const renewBy = formatDate(contract?.endDate);
  const startDate = formatTermDate(contract?.startDate);
  const endDate = formatTermDate(contract.endDate);
  const timeRemaining =
    contract?.endDate &&
    ['Active', 'Pending'].includes(contract?.contractStatus)
      ? computeTimeRemaining(contract.endDate)
      : '--';

  return [
    {
      header: 'Date accepted',
      content: dateAccepted,
      footer: `Offer ID ${contract?.offerId || '--'}`,
    },
    {
      header: 'Term',
      content: term,
      footer: `${startDate} - ${endDate}`,
    },
    {
      header: 'Time remaining',
      content: timeRemaining,
      footer: buildTimeRemainingFooter(contract),
    },
    {
      header: buildRenewByHeader(contract),
      content: renewBy,
    },
  ];
};

const buildTotalContractValueMetric = (contract: Contract): DetailItem[] => {
  const totalContractValue = formatCurrency(
    contract?.totalContractValue?.amount,
  );
  const netAfterFees = '--';
  const feeAmount = '--';

  return [
    {
      header: 'Total contract value',
      content: totalContractValue,
      footer: `${netAfterFees} net after ${feeAmount} fee`,
    },
  ];
};

const buildTotalDisbursedMetric = (contract: Contract): DetailItem[] => {
  const totalDisbursed = formatCurrency(contract?.grossDisbursed?.amount);
  const netDisbursed = '--';

  return [
    {
      header: 'Total disbursed',
      content: totalDisbursed,
      footer: `${netDisbursed} net`,
    },
  ];
};

const buildTotalUninvoicedPaymentsMetric = (
  contract: Contract,
): DetailItem[] => {
  const totalUninvoicedPayments = formatCurrency(
    contract?.productAndPricing?.net?.amount,
  );
  const netUninvoicedPayments = '--';

  return [
    {
      header: 'Total uninvoiced payments',
      content: totalUninvoicedPayments,
      footer: `${netUninvoicedPayments} net`,
    },
  ];
};

const formatTermDate = (dateString?: string): string => {
  if (!dateString) return '--';
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options).replace(/,/g, '');
};

const formatCurrency = (amount?: number | null | undefined): string => {
  if (amount === null || amount === undefined) return '--';
  return `$${amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const convertISOTimeFrame = (isoString?: string): string => {
  if (!isoString) return '--';
  const match = isoString.match(/P(\d+)(M)?/);
  if (!match) return '--';

  const months = parseInt(match[1], 10);
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  const yearText = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
  const monthText =
    remainingMonths > 0
      ? `${remainingMonths} month${remainingMonths > 1 ? 's' : ''}`
      : '';

  return [yearText, monthText].filter(Boolean).join(' and ') || '--';
};

const computeTimeRemaining = (endDate?: string): string => {
  if (!endDate) return '--';
  const today: Date = new Date();
  const end: Date = new Date(endDate);

  const diff: number = end.getTime() - today.getTime();
  if (diff < 0) return '--';
  const daysRemaining: number = Math.floor(diff / (1000 * 60 * 60 * 24));
  const years: number = Math.floor(daysRemaining / 365);
  const months: number = Math.floor((daysRemaining % 365) / 30);

  const yearText: string =
    years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
  const monthText: string =
    months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';

  return [yearText, monthText].filter(Boolean).join(' and ') || '--';
};

export const buildSummaryDetails = (contract: Contract) => {
  return {
    summaryItems: buildSummaryItems(contract),
    totalContractValueMetric: buildTotalContractValueMetric(contract),
    totalDisbursedMetric: buildTotalDisbursedMetric(contract),
    totalUninvoicedPaymentsMetric: buildTotalUninvoicedPaymentsMetric(contract),
  };
};

export const buildContractDetails = (contract: Contract) => {
  const basicInformation = [
    {
      sectionHeader: 'Contract',
      rows: [
        { label: 'Marketplace', value: contract?.cloudMarketplace || '--' },
        { label: 'Agreement ID', value: contract?.id || '--' },
        { label: 'Offer ID', value: contract?.offerId || '--' },
        {
          label: 'Offer accepted date',
          value: formatDate(contract?.acceptedDate),
        },
        { label: 'Offer accepted by', value: contract?.buyerId || '--' },
        { label: 'Contract start', value: '--' },
        {
          label: 'Duration',
          value: convertISOTimeFrame(contract?.contractDuration) || '--',
        },
        {
          label: 'Start date',
          value: formatDate(contract?.startDate),
        },
        {
          label: 'End date',
          value: formatDate(contract?.endDate),
        },
        { label: 'Auto renew', value: contract?.autoRenew ? 'Yes' : 'No' },
      ],
    },
    {
      sectionHeader: 'Billing Account',
      rows: [{ label: 'Customer Id', value: contract?.buyerId || '--' }],
    },
  ];

  const resellerInformation = [
    {
      sectionHeader: 'Reseller details',
      rows: [
        {
          label: 'Reseller name',
          value: contract?.registration?.companyName || '--',
        },
        {
          label: 'Partner AWS Account Number',
          value: contract?.vendorId || '--',
        },
        {
          label: 'CPPO Opportunity ID',
          value: contract?.offerTargetAccount || '--',
        },
      ],
    },
    {
      sectionHeader: 'Reseller agreement',
      rows: [
        { label: 'Reseller agreement version', value: 'Custom contract' },
        { label: 'Document 1', value: 'file_name.pdf' },
        { label: 'Document 2', value: 'file_name.pdf' },
      ],
    },
  ];

  const productAndPricing = [
    {
      sectionHeader: 'Product',
      rows: [
        {
          label: 'Product name',
          value: contract?.productAndPricing?.productName || '--',
        },
        {
          label: 'Product ID',
          value: contract?.productAndPricing?.productId || '--',
        },
        { label: 'Product type', value: contract?.listingType || '--' },
        {
          label: 'Tackle managed',
          value: contract?.productAndPricing?.isListingManagedByTackle
            ? '✔️ Tackle managed'
            : 'Not tackle managed',
        },
      ],
    },
    {
      sectionHeader: 'Pricing',
      rows: [
        { label: 'Pricing model', value: contract?.pricingModel || '--' },
        { label: 'Payment model', value: 'Payment schedule' },
        {
          label: 'Gross',
          value: formatCurrency(contract?.totalContractValue?.amount) || '--',
        },
        {
          label: 'Net',
          value:
            formatCurrency(contract?.productAndPricing?.net?.amount) || '--',
        },
      ],
    },
  ];

  if (contract?.productAndPricing?.dimensions) {
    productAndPricing.push({
      sectionHeader: 'Dimensions',
      rows: [
        ...(contract?.productAndPricing?.dimensions || []).map((dimension) => ({
          label: dimension?.dimension || '--',
          value: dimension?.quantity?.toString() || '--',
        })),
      ],
    });
  }

  return { basicInformation, resellerInformation, productAndPricing };
};
