import { DashboardCard } from 'pages/Overview/components/DashboardCard/DashboardCard';
import { Box } from 'vendor/material';
import useStyles from './SummaryCard.style';

export interface DetailItem {
  header: string;
  content: string;
  footer?: string;
}

interface DetailsCardProps {
  items: DetailItem[];
}

const SummaryCard: React.FC<DetailsCardProps> = ({ items }) => {
  const classes = useStyles();

  return (
    <DashboardCard
      customCardHeight={6}
      children={
        <Box
          className={classes.itemList}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {items.map((item, index) => (
            <Box key={index} display="flex" flexDirection="column" flex="1">
              <span className={classes.itemHeader}>{item.header}</span>
              <span className={classes.itemContent}>{item.content}</span>
              {item.footer && (
                <span className={classes.itemFooter}>{item.footer}</span>
              )}
            </Box>
          ))}
        </Box>
      }
    />
  );
};

export default SummaryCard;
