import React from 'react';
import { Grid, Icon as MuiIcon, Typography, Toolbar } from 'vendor/material';
import { Button } from '@tackle-io/platform-ui';

// COMPONENTS
import SearchInput from '../../../SearchInput';

// ASSETS
import useStyles from './EnhancedTableToolbar.styles';

const EnhancedTableToolbar = ({
  Icon,
  IconProps = { fontSize: 'inherit' },
  title,
  searchable = false,
  SearchProps,
  button = false,
  ButtonProps,
}) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid container item className={classes.title}>
          {Icon ? (
            <Grid item>
              <MuiIcon color="inherit" className={classes.icon}>
                <Icon {...IconProps} />
              </MuiIcon>
            </Grid>
          ) : null}
          <Grid item>
            <Typography
              variant="h6"
              component="h2"
              className={classes.titleText}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item className={classes.actions}>
          {searchable ? (
            <Grid item>
              <SearchInput {...SearchProps} />
            </Grid>
          ) : null}
          {button ? (
            <Grid item>
              <Button {...ButtonProps} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
