import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
  helperText: {
    color: theme.palette.NEUTRAL300,
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
