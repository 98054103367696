import { useContext } from 'react';

import api from 'utils/api';
import { withConvenienceBooleans } from 'stores/utils';

import Context from './context';
import {
  deserialize,
  isAPISuccess,
  APIVersion,
  APIResponse,
  APIReducerState,
} from './typings';

type Hook = Omit<APIReducerState, 'dispatch'> & {
  sync: () => Promise<APIVersion>;
};

const useApiDetails = (): Hook => {
  const { content, status, dispatch } = useContext(Context) as APIReducerState;

  const sync = (): Promise<APIVersion> =>
    new Promise((resolve, reject) => {
      dispatch({ type: 'SYNC' });

      api
        .get(`version`)
        .json()
        .then((data: APIResponse) => {
          if (isAPISuccess(data)) {
            const apiVersion = deserialize(data);
            dispatch({
              type: 'SET_CONTENT',
              payload: {
                content: apiVersion,
              },
            });
            resolve(apiVersion);
          } else {
            dispatch({ type: 'SET_ERROR', payload: { error: data.error } });
            reject(data.error);
          }
        })
        .catch((error) => {
          dispatch({ type: 'SET_ERROR', payload: { error } });
        });
    });

  return {
    ...withConvenienceBooleans({ content, status }),
    sync,
  } as Hook;
};

export default useApiDetails;
