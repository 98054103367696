import { Box } from 'vendor/material';
import { Banner } from '@tackle-io/platform-ui';
import useStyles from './AceOpportunityFormBanner.styles';

interface BannerBody {
  sectionTitle: string;
  fields: string;
}

export const AceOpportunityFormBanner = ({
  title,
  content,
}: {
  title: string;
  content: BannerBody[];
}) => {
  const classes = useStyles();

  return (
    <Box
      display={'flex'}
      alignItems={'flex-start'}
      flexDirection={'column'}
      marginTop={4}
      marginBottom={4}
    >
      <Banner
        borderPosition="top"
        defaultOpen={true}
        isCollapsible={true}
        title={title}
        body={
          <ul className={classes.body}>
            {content.map(({ sectionTitle, fields }) => (
              <li key={sectionTitle}>
                <strong>{sectionTitle}</strong> {fields}
              </li>
            ))}
          </ul>
        }
      />
    </Box>
  );
};
