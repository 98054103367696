import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  rightRailCard: {
    borderColor: theme.palette.BLUE400,
    padding: theme.spacing(3),
    '& > *:first-child': {
      paddingTop: 0,
    },
  },
}));

export default useStyles;
