import { DateTime } from 'luxon';
import {
  flow,
  map,
  mapKeys,
  mapValues,
  pick,
  placeholder,
  find,
  get,
  isEmpty,
  join,
  cond,
  stubTrue,
  reject,
  partial,
  startCase,
} from 'lodash/fp';

import { User } from 'models';
import { PrivateOffer } from 'stores/privateOffers/typings';
import { toDateTime } from 'utils/dates';
import lastActivityAction from './lastActivityAction';
import { ACTIVITY_SLUG_TO_OFFER_STEP_INFO } from './constants';
import {
  dateTimeNoTimestampToString,
  dateTimeToString,
} from './poDateTimeFormatters';

const headersByOfferAttribute = {
  poId: 'Tackle Offer ID',
  cloud: 'Cloud',
  offerType: 'Offer Type',
  productRef: 'Tackle Product ID',
  customerRef: 'Customer ID',
  offerRef: 'Offer Ref',
  createdAt: 'Created at',
  sentAt: 'Sent at',
  viewedOfferAt: 'Viewed Offer at',
  acceptedAt: 'Accepted at',
  archivedAt: 'Archived at',
  lastModifiedAt: 'Last Modified at',
  usersToNotify: 'Users To Notify',
  buyers: 'Buyers',
  status: 'Status', // Does not live on PrivateOffer, defined below in toRow function
};

// Known Headers, not inclusive
export const exportHeaders = Object.values(headersByOfferAttribute);

const commaSeparatedStringFromArray = flow([
  reject(isEmpty),
  join(', '),
  cond([
    [isEmpty, (): null => null],
    [stubTrue, <T>(v: T): T => v],
  ]),
]);

const toRow = (
  {
    metadata: {
      preRegistrationDetails,
      offerMetadata,
      buyers,
      pricing: {
        version, // Ignored
        dimensions,
        schedule,
        ...pricing
      },
      eula,
      ...autoCreate
    },
    activities, // Converted to Status
    usersToNotify,
    ...offer
  }: PrivateOffer,
  users: User[],
): object => {
  const { slug } = lastActivityAction(activities, !!offer.offerRef);
  const { text = 'Unknown State' } = ACTIVITY_SLUG_TO_OFFER_STEP_INFO[slug];

  const formattedUsersToNotify = flow([
    map(({ contactType, value }) => {
      if (contactType === 'auth0') {
        return flow([find({ id: value }), get('email')])(users);
      }

      return value;
    }),
    commaSeparatedStringFromArray,
  ])(usersToNotify);

  const formattedBuyers = flow([
    map('emailAddress'),
    commaSeparatedStringFromArray,
  ])(buyers);

  const formattedPricingDimensions = flow([
    get('dimensions'),
    map(({ name, price, quantity }) => join(', ')([name, price, quantity])),
    commaSeparatedStringFromArray,
  ])(pricing);

  const formattedPricingSchedule = flow([
    map(
      ({ invoiceDate, invoiceAmount }) =>
        `${invoiceAmount} - ${dateTimeNoTimestampToString(
          toDateTime(invoiceDate),
        )}`,
    ),
    commaSeparatedStringFromArray,
  ])(schedule);

  const formattedEulaFiles = flow([
    get('files'),
    commaSeparatedStringFromArray,
  ])(eula);

  const predefinedColumns = flow(
    pick(Object.keys(headersByOfferAttribute)),
    mapKeys((key: string): string => headersByOfferAttribute[key]),
  )({
    ...offer,
    usersToNotify: formattedUsersToNotify,
    status: text,
    buyers: formattedBuyers,
    eulaType: eula?.type,
    eulaFiles: formattedEulaFiles,
  });

  const addPostfix = (data: object, postfix: string): object =>
    mapKeys((key: string): string => {
      if (key === 'marketplaceFee') key = 'marketplaceFeePercentage';
      return `${startCase(key)} - ${postfix}`;
    }, data);

  const formatDateTime = mapValues(
    cond([
      [DateTime.isDateTime, dateTimeToString],
      [stubTrue, <T>(v: T): T => v],
    ]),
  );

  return formatDateTime({
    ...predefinedColumns,
    ...addPostfix(preRegistrationDetails, 'Company'),
    ...addPostfix(offerMetadata, 'Contract'),
    ...addPostfix(pricing, 'Pricing'),
    ...addPostfix(autoCreate, 'Offer'),
    'Schedules - Pricing': formattedPricingSchedule,
    'Dimensions - Pricing': formattedPricingDimensions,
    'Tackle URL': `https://downstream.tackle.io/private-offers/${offer.poId}`,
  });
};

export const offersToCSV = (
  data: PrivateOffer[],
  users: User[] = [],
): object[] => map(partial(toRow, [placeholder, users]), data);
