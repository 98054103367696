import { decorate, observable, computed } from 'mobx';
import { assign } from 'lodash-es';

import { awsMoneyToDinero } from '../utils/money';
import { toDateTime } from '../utils/dates';

class LineItem {
  _amount = 0;

  currency = '';

  dimension = '';

  quantity = 0;

  fee_description = '';

  start_date = null;

  end_date = null;

  constructor(props) {
    let { start_date = null, end_date = null, amount = 0 } = props;
    delete props.amount;
    start_date = toDateTime(start_date, 'Missing start_date');
    end_date = toDateTime(end_date, 'Missing end_date');

    assign(this, props, { start_date, end_date, _amount: amount });
  }

  get amount() {
    return awsMoneyToDinero({
      amount: this._amount,
      currency: this.currency,
    });
  }
}

decorate(LineItem, {
  _amount: observable,
  currency: observable,
  dimension: observable,
  quantity: observable,
  fee_description: observable,
  start_date: observable,
  end_date: observable,
  amount: computed,
});

export default LineItem;
