import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  buttonPrimary: {
    color: theme.palette.BLUE400,
    marginBottom: 16,
  },
  buttonTextPrimary: {
    '&:hover': {
      backgroundColor: theme.palette.BLUE050,
    },
  },
  fieldKey: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  fieldValue: {
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  fieldAction: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttonIcon: {
    color: theme.palette.NEUTRAL500,
  },
  dimensionFields: {
    marginBottom: 16,
  },
  durationText: {
    color: theme.palette.NEUTRAL300,
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
