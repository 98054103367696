import { split, toNumber, isNumber } from 'lodash';
import { flow, reduce } from 'lodash/fp';
import Dinero from 'dinero.js';
import { CurrencyCode } from './currency';

const formatAwsMoney = (
  { amount = 0.0, currency = 'USD' } = { amount: 0.0, currency: 'USD' },
) => {
  // consume Number, convert to type Number with fractional length of two
  amount = Number(amount).toFixed(2);

  const [main, fractional = '00'] = split(amount, '.', 2);

  // prepare for Dinero consumption
  return { amount: toNumber(main + fractional), currency, precision: 2 };
};

const toDinero = (param = 0) => {
  if (!param) param = 0;
  if (typeof param === 'object') {
    if (param.getAmount !== undefined) {
      // param is an Instance of Dinero
      return param;
    }
    if (
      param.amount !== undefined &&
      !isNaN(param.amount) &&
      param.currency !== undefined &&
      param.precision !== undefined
    ) {
      // param is from formatAwsMoney
      return Dinero(param);
    }
    console.warn('toDinero: unhandled object', param);
    return Dinero();
  }

  // -Infinity is returned by rechart
  if (param === -Infinity || !param) param = 0;

  let amount = param;
  // amount must be a number
  if (!isNumber(amount)) amount = toNumber(amount);

  // Dinero only accepts type Integer
  amount = parseInt(amount, 10);

  return Dinero({ amount });
};

const formatDinero = (dinero = null) => {
  // dinero doesn't exist
  if (!dinero) return '-';
  // only accept Dinero
  if (typeof dinero !== 'object') dinero = toDinero(dinero);
  // value is 0
  // if (dinero.getAmount() === 0) return '$0.00'; // why do this and not let dinero format the zero with the correct currency symbol?

  // returns "$0,0.00"
  return dinero.toFormat();
};

const sumDinero = reduce((sum, d) => sum.add(d), Dinero());

const awsMoneyToDinero = flow(formatAwsMoney, toDinero);

const awsNumberToCurrency = (numberAmount) => {
  return formatDinero(awsMoneyToDinero({ amount: numberAmount }));
};

// credit - https://stackoverflow.com/a/10601315
const abbvMoney = (num = 0, fixed = 0) => {
  let tmp = toDinero(num);
  tmp = tmp.toFormat('$0');

  let isPositive = num >= 0;
  if (isPositive) {
    num = Number.parseInt(tmp.substr(1));
  } else {
    num = 0 - Number.parseInt(tmp.substr(2));
  }

  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  const suffixes = ['', 'k', 'm', 'b', 't'];
  let value = num.toPrecision(2).split('e'); // get power
  const suffixIndex =
    value.length === 1 ? 0 : Math.floor(Math.min(value[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions

  value =
    suffixIndex < 1
      ? num.toFixed(0 + fixed)
      : (num / Math.pow(10, suffixIndex * 3)).toFixed(1 + fixed); // divide by power

  const symbol = isPositive ? '$' : '-$';
  return symbol + Math.abs(value) + suffixes[suffixIndex];
};

const numberToDinero = (amount, precision = 2) => {
  if (!amount) amount = 0.0;

  return Dinero({
    amount: parseInt(amount, 10),
    precision: precision,
  });
};

const floatToDinero = (
  amount,
  precision,
  currencyCode = CurrencyCode.UnitedStatesDollar,
) => {
  const dineroAmount = amount ? amount : 0;
  const dineroPrecision = precision
    ? precision
    : dineroAmount.toString().split('.')[1]?.length ?? 0;
  const amountInMinimalUnit = Math.round(
    dineroAmount * Math.pow(10, dineroPrecision),
  );

  return Dinero({
    amount: amountInMinimalUnit,
    precision: dineroPrecision,
    currency: currencyCode,
  });
};

export {
  formatAwsMoney,
  toDinero,
  formatDinero,
  sumDinero,
  awsMoneyToDinero,
  awsNumberToCurrency,
  abbvMoney,
  numberToDinero,
  floatToDinero,
};
