import React from 'react';
import { Button, CircularProgress, makeStyles } from 'vendor/material';
import classNames from 'classnames';

const useStyles = ({ palette, brand, color }) =>
  makeStyles((theme) => {
    let finalPalette = palette;
    if (!finalPalette) finalPalette = brand ? theme[brand] : theme.palette;
    return {
      root: {
        color: color || finalPalette.primary.light,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      },
      circularProgress: {
        color: theme.palette.BLUE400,
      },
      sentenceCase: {
        textTransform: 'none',
      },
    };
  })();

const TextButton = ({
  children,
  className = '',
  palette = null,
  brand = null,
  color = null,
  isLoading = false,
  disabled = false,
  ...props
}) => {
  const classes = useStyles({
    palette,
    brand,
    color,
    ...props,
  });
  const { appearance, className: propClassName, classes: propClasses } = props;

  const hasRipple = () => appearance === 'subtle';

  const cssButton = classNames(
    {
      [classes.root]: !appearance,
      [classes.sentenceCase]: !!appearance,
    },
    propClassName,
  );

  return (
    <Button
      className={cssButton}
      classes={propClasses}
      disableRipple={!hasRipple}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <CircularProgress
          size={14}
          classes={{ root: classes.circularProgress }}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default TextButton;
