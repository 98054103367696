import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Popover, MenuItem } from 'vendor/material';
import classNames from 'classnames';
import { Calendar } from '@tackle-io/platform-ui';

// ASSETS
import useStyles from './DateListPicker.styles';

// HELPERs
import {
  formatDisplayDate,
  DatePopoverProps,
  DateListPickerProps,
} from './DateListPicker.helpers';

const DatePopover: React.FC<DatePopoverProps> = ({
  id,
  open,
  onChangeDate,
  datesSelected,
  anchorEl,
  onClose,
  pickerView,
  rangePosition,
  maxDate,
}) => {
  // HOOKS
  const classes = useStyles();

  // EVENTS
  const handleClose = (): void => {
    onClose();
  };

  // MISC
  const finalMaxDate = (): Date => {
    if (datesSelected.to) {
      return datesSelected.to.toJSDate();
    }
    return maxDate;
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      classes={{
        paper: classes.paper,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.calendarContent}>
        <Calendar
          onChange={onChangeDate}
          maxDetail={pickerView}
          maxDate={finalMaxDate()}
          minDate={
            datesSelected.from ? datesSelected.from.toJSDate() : undefined
          }
          value={
            rangePosition === 'from'
              ? datesSelected.from?.toJSDate()
              : datesSelected.to?.toJSDate()
          }
        />
      </div>
    </Popover>
  );
};

const DateListPicker: React.FC<DateListPickerProps> = ({
  pickerView = 'year',
  maxDate,
  selectedDate,
  options,
  anchorEl,
  id = 'DateListPicker',
  onClose,
  onChangeDate,
  onChangeList,
  selectedOptionKey,
  hasCustomRange = false,
  open,
}) => {
  // HOOKS
  const classes = useStyles();
  const [anchorElPicker, setAnchorElPicker] = useState(null);
  const [rangeContainerOpen, setRangeContainerOpen] = useState(
    selectedOptionKey === 'customRange',
  );
  const [rangePosition, setRangePosition] = useState('');
  const [datesSelected, setDatesSelected] = useState({
    from: undefined,
    to: undefined,
  });

  useEffect(() => {
    setRangeContainerOpen(selectedOptionKey === 'customRange');
  }, [selectedOptionKey]);

  useEffect(() => {
    if (selectedOptionKey === 'customRange') {
      if (datesSelected.from && datesSelected.to) {
        onChangeDate(datesSelected);
      }
    }
  }, [datesSelected, selectedOptionKey, onChangeDate, rangeContainerOpen]);

  // MISC
  const openPicker = Boolean(anchorElPicker);
  const idPicker = openPicker ? 'picker-date' : undefined;

  const finalOptions = [...options];

  if (hasCustomRange) {
    finalOptions.push({
      key: 'customRange',
      text: 'Custom range',
      isSelected: selectedOptionKey === 'customRange',
    });
  }

  // EVENTS
  const handleClickOpenPicker = (event: any): void => {
    setAnchorElPicker(event.currentTarget);
    setRangePosition(event.currentTarget.id);
  };

  const handleClosePicker = (): void => {
    setAnchorElPicker(null);
  };

  const handleChangeDate = (value, event): void => {
    // converting dates to DateTime object for having a better format method
    const newDate = {
      year: value.getFullYear(),
      month: value.getMonth() + 1,
      day: value.getDate(),
    };

    if (selectedOptionKey === 'customRange') {
      if (rangePosition === 'from') {
        setDatesSelected({
          from: DateTime.fromObject(newDate),
          to: datesSelected.to,
        });
      } else {
        setDatesSelected({
          from: datesSelected.from,
          to: DateTime.fromObject(newDate),
        });
      }
    }

    setAnchorElPicker(null);
  };

  const handleOpenRangeContainer = (): void => {
    setRangeContainerOpen(true);
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.containerList} data-testid="containerList">
          {finalOptions.map(({ key, text, isSelected }) => {
            const cssMenuItem = classNames(classes.menuItem, {
              selected: isSelected,
            });
            return (
              <MenuItem
                key={text}
                classes={{ root: cssMenuItem }}
                onClick={(event): void => {
                  if (key === 'customRange') {
                    handleOpenRangeContainer();
                  }

                  onChangeList(key);
                }}
              >
                {text}
              </MenuItem>
            );
          })}
        </div>
        {rangeContainerOpen && (
          <div className={classes.containerRange} data-testid="containerRange">
            <div role="presentation">
              <div className={classes.inputDateContainer}>
                <input
                  id="from"
                  type="text"
                  value={formatDisplayDate(datesSelected.from, pickerView)}
                  onClick={handleClickOpenPicker}
                />
              </div>
            </div>
            <div className={classes.dividerTo}>to</div>
            <div role="presentation">
              <div className={classes.inputDateContainer}>
                <input
                  id="to"
                  type="text"
                  value={formatDisplayDate(datesSelected.to, pickerView)}
                  onClick={handleClickOpenPicker}
                />
              </div>
            </div>
          </div>
        )}
      </Popover>
      <DatePopover
        open={openPicker}
        id={idPicker}
        datesSelected={datesSelected}
        anchorEl={anchorElPicker}
        onChangeDate={handleChangeDate}
        onClose={handleClosePicker}
        pickerView={pickerView}
        maxDate={maxDate}
        rangePosition={rangePosition}
      />
    </div>
  );
};

export default DateListPicker;
