import { makeStyles } from 'vendor/material';

export const useStatusTagV2Styles = makeStyles((theme) => ({
  statusTag: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.3),
    '& span': {
      textAlign: 'center',
    },
    padding: `7px 8px`,
    borderRadius: '4px',
  },
  standardTag: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.3),
    '& span': {
      textAlign: 'center',
    },
    padding: `7px 8px`,
    borderRadius: '4px',
    background: '#EBECF0',
    display: 'inline-block',
  },
}));
