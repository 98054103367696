// eslint-disable-next-line no-restricted-imports
import Typography from '@mui/material/Typography';
declare module '@mui/material/styles' {
  interface TypographyVariants {
    hero: React.CSSProperties;
    standardLabel: React.CSSProperties;
    smallLabel: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    hero?: React.CSSProperties;
    standardLabel?: React.CSSProperties;
    smallLabel?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    hero: true;
    standardLabel: true;
    smallLabel: true;
  }
}

export default Typography;
