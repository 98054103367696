import { useWindowSize } from 'react-use';
import { useTheme } from 'vendor/material';

const useMinWidth = (key) => {
  const { breakpoints } = useTheme();
  const { width } = useWindowSize();
  return width >= breakpoints.values[key];
};

export { useMinWidth };
