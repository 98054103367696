import React from 'react';
import { Menu, Typography } from 'vendor/material';

import buildEnvironment from 'utils/buildEnvironment';
import APIDetailsProvider from 'stores/api/provider';

// COMPONENTS
import EnvironmentList from './EnvironmentList';

// ASSETS
import useStyles from './EnvironmentButton.styles';

const Environment: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <APIDetailsProvider>
      <Typography onClick={handleClick} className={classes.environmentLabel}>
        {buildEnvironment.compilationEnvironment}
        {' - '}
        {buildEnvironment.apiEnvironment}
      </Typography>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        marginThreshold={12}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        disableAutoFocus
      >
        <EnvironmentList />
      </Menu>
    </APIDetailsProvider>
  );
};

export default Environment;
