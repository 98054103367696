import { UserManagement, hasLoaded } from '../stores';
import useStore from './useStore';

function useFilteredUsers(query, includeTackleUsers = false) {
  const userManagementStore = useStore(UserManagement);

  if (!hasLoaded(userManagementStore)) return [];

  const users = userManagementStore.users.filter((user) => {
    if (includeTackleUsers) {
      return user.metadata?.vendorid;
    }

    return user.metadata?.vendorid && !user?.email.includes('@tackle.io');
  });
  if (query === '') return users;

  return users.filter(({ email, name, nickname, role, pendingInvitation }) => {
    const finalQuery = query.toLowerCase();

    if (finalQuery === 'pending') return pendingInvitation;
    if (finalQuery === 'accepted') return !pendingInvitation;

    return (
      email.toLowerCase().includes(finalQuery) ||
      name.toLowerCase().includes(finalQuery) ||
      nickname.toLowerCase().includes(finalQuery) ||
      role?.toLowerCase().includes(finalQuery)
    );
  });
}

export default useFilteredUsers;
