import ky from 'ky';

/**
 * provides ky instances for tackle http apis with the bearer token prefilled along with default headers.
 *  - coSellApi: for co-sell api which is actively in development
 *  - dataMapperApi: for data mapper api which is actively in development
 *
 * NOTE: Temporary for development
 * this was created to work with the api without conflicts.
 * Once co-sell api is integrated, this will be removed or refactored.
 *
 */

let config = {
  headers: {
    'Content-Type': 'application/json',
  },
  hooks: {
    beforeRequest: [
      async (request: Request) => {
        const bearerToken = Sfdc?.canvas.oauth.token();
        if (bearerToken) {
          request.headers.set('Authorization', `Bearer ${bearerToken}`);
        }
      },
    ],
  },
};

// the co-sell v3 api
let coSellApi = ky.extend({
  prefixUrl: `${process.env.REACT_APP_TACKLE_CO_SELL_V3_API_URL}/api`,
  ...config,
});

// the data mapper api
let dataMapperApi = ky.extend({
  prefixUrl: process.env.REACT_APP_DATA_MAPPER_API_URL,
  ...config,
});

export { coSellApi, dataMapperApi };
