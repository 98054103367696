import useStore, { hasLoaded } from './hooks/useStore';
import { useLocation } from 'react-router-dom';
import { useToast } from './hooks';
import { observer } from 'mobx-react-lite';
import { Loader } from '@tackle-io/platform-ui';
import { User, Vendor } from './stores';
import PrivateOfferProvider from './stores/privateOffers/provider';
import ProductProvider from './stores/products/provider';
import { CoSellContextProvider } from 'stores/CoSell/CoSellContext';

import TemporarilyOffline from 'pages/TemporaryOutage/TemporaryOutage';
import LegacyLogout from './pages/Logout/LegacyLogout';

const checkLocalStorage = ({ location }) => {
  if (location.pathname.indexOf('datafeeds') === -1)
    localStorage.removeItem('search_datafeeds');

  if (location.pathname.indexOf('insights') === -1)
    localStorage.removeItem('search_insights');
};

const checkAppFatalErrors = ({ userStore, vendorStore }) => {
  for (const store of [userStore, vendorStore]) {
    if (
      store &&
      store.error &&
      (store.error instanceof TypeError || store.error.response?.status >= 500)
    ) {
      return <TemporarilyOffline />;
    }
    if (store && store.error?.response?.status >= 400) {
      store.error = null;
      return <LegacyLogout />;
    }
  }
};

// TODO: move these global context providers to wrap the corresponding features that require this data
export const AppData = observer(({ children }) => {
  const location = useLocation();
  const userStore = useStore(User);
  const vendorStore = useStore(Vendor);
  const fatalError = checkAppFatalErrors({
    userStore,
    vendorStore,
  });
  const { dismiss } = useToast();
  // Dismiss dataSyncing toaster when changing pages
  dismiss('dataSyncing');
  checkLocalStorage({ location });

  if (!hasLoaded(userStore) || !hasLoaded(vendorStore)) return <Loader />;
  if (fatalError) return fatalError;

  return (
    <ProductProvider>
      <PrivateOfferProvider>
        <CoSellContextProvider>{children}</CoSellContextProvider>
      </PrivateOfferProvider>
    </ProductProvider>
  );
});

export const withAppData = (Component) => {
  function Wrapped(props) {
    return (
      <AppData>
        <Component {...props} />
      </AppData>
    );
  }

  Wrapped.displayName = `withAppData(${Component.displayName}`;

  return Wrapped;
};
