import { decorate, observable, action, computed } from 'mobx';
import { assign, omit, has, isObject, isArray, truncate } from 'lodash-es';
import { filter, some } from 'lodash/fp';

import api from 'utils/api';
import hasFeatures from '../utils/features';

import ProductStyleProps from './ProductStyleProps';
import Offer from './Offer';

class Product {
  categories = [];

  contact_email = '';

  dimension_desc = '';

  dimensions = [];

  documentation = [];

  full_description = '';

  highlights = [];

  landing_page_header_bg_color = '';

  landing_page_html = '';

  legal = {};

  license = {};

  listing_type = '';

  logo = '';

  marketplace_url = '';

  metadata_keywords = '';

  media_image = [];

  media_video = [];

  metering_service_dimensions = []; // azure specific

  name = '';

  overview_urls = [];

  pricing = {};

  privacy_policy_link = '';

  product_video_url = '';

  productid = '';

  productid_internal = '';

  provider = '';

  registration_message = '';

  registration_page_fields = [];

  contract_standard_fields = [];

  registration_redirect = null;

  search_keywords = '';

  send_customer_email = false;

  short_description = '';

  sns_configuration = {};

  status = '';

  support = {};

  support_description = '';

  support_email = '';

  support_url = '';

  style_props = {};

  industries = [];

  vendorid = '';

  // Offer
  encrypted_productid = null;

  error = null;

  loading = false;

  offers = [];

  packages = [];

  constructor(props, provider) {
    assign(this, omit(props, ['style_props, offers']));
    this.provider = provider;

    if (has(props, 'style_props') && isObject(props.style_props)) {
      this.setProps(new ProductStyleProps(props.style_props));
    }

    if (has(props, 'offers') && isArray(props.offers)) {
      this.offers = props.offers.map((offer) => new Offer(offer));
    }
  }

  setLoading = (loading) => {
    this.loading = loading;
  };

  setError = (error) => {
    this.error = error;
  };

  get full_name() {
    return `${truncate(this.name, { length: 20 })} - ${this.listing_type}`;
  }

  get listing_type_humanized() {
    if (this.provider === 'gcp') return 'SaaS';

    const contract_type_map = {
      saas: 'SaaS Contract',
      ami: 'AMI',
      ccp: 'CCP',
      flat_rate: 'Flat Rate',
      per_user: 'Per User',
      'saas-subscription': 'Subscription',
    };
    return contract_type_map[
      this.listing_type
        ? this.listing_type.toLowerCase()
        : this.pricing.pricing_type?.toLowerCase()
    ];
  }

  get isAmi() {
    return this.listing_type_humanized === 'AMI';
  }

  get meteringDimensions() {
    return {
      aws: this.dimensions,
      azure: this.metering_service_dimensions,
      gcp: filter('meterable', this.dimensions),
      redhat: filter('meterable', this.dimensions),
    }[this.provider];
  }

  get meterable() {
    return {
      aws: this.awsMeterable(),
      azure: this.azureMeterable(),
      gcp: this.gcpMeterable(),
      redhat: this.redhatMeterable(),
    }[this.provider];
  }

  awsMeterable() {
    return (
      (this.listing_type === 'ccp' ||
        this.listing_type === 'saas-subscription') &&
      this.dimensions.length > 0
    );
  }

  gcpMeterable() {
    return some('meterable', this.dimensions);
  }

  azureMeterable() {
    return this.pricing ? this.pricing.pricing_type === 'flat_rate' : false;
  }

  redhatMeterable() {
    return some('meterable', this.dimensions);
  }

  get defaultOrderProps() {
    const orderProps = {};
    this.registration_page_fields.forEach((field) => {
      if (field.enabled) orderProps[field.title] = '';
    });
    return orderProps;
  }

  setProps = (props) => {
    this.style_props = props;
  };

  sync = (product) => {
    assign(
      this,
      omit(product, ['productid', 'vendorid', 'style_props', 'offers']),
    );

    if (has(product, 'style_props') && isObject(product.style_props)) {
      this.setProps(new ProductStyleProps(product.style_props));
    }

    if (has(product, 'offers') && isArray(product.offers)) {
      this.offers = product.offers.map((offer) => new Offer(offer));
    }
  };

  update = (json) => {
    this.setLoading(true);

    return api
      .put(`product/${this.productid}`, {
        json,
      })
      .json()
      .then(({ product }) => {
        this.sync(product);
        this.setLoading(false);

        return product;
      })
      .catch((error) => {
        this.setError(error);
        this.setLoading(false);
      });
  };

  hasFeatures = (features) => hasFeatures(this.packages, features);
}

decorate(Product, {
  categories: observable,
  contact_email: observable,
  dimension_desc: observable,
  dimensions: observable,
  documentation: observable,
  encrypted_productid: observable,
  error: observable,
  full_description: observable,
  highlights: observable,
  landing_page_header_bg_color: observable,
  landing_page_html: observable,
  legal: observable,
  license: observable,
  listing_type: observable,
  loading: observable,
  logo: observable,
  marketplace_url: observable,
  metadata_keywords: observable,
  media_image: observable,
  media_video: observable,
  name: observable,
  offers: observable,
  overview_urls: observable,
  packages: observable,
  privacy_policy_link: observable,
  product_video_url: observable,
  productid_internal: observable,
  productid: observable,
  pricing: observable,
  registration_message: observable,
  registration_page_fields: observable,
  contract_standard_fields: observable,
  registration_redirect: observable,
  search_keywords: observable,
  send_customer_email: observable,
  short_description: observable,
  sns_configuration: observable,
  status: observable,
  style_props: observable,
  support: observable,
  support_description: observable,
  support_email: observable,
  support_url: observable,
  industries: observable,
  vendorid: observable,

  setError: action,
  setLoading: action,
  setProps: action,
  sync: action,
  update: action,

  defaultOrderProps: computed,
  full_name: computed,
  isAmi: computed,
  listing_type_humanized: computed,
  meteringDimensions: computed,
  meterable: computed,
});

export default Product;
