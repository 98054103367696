import flow from 'lodash/fp/flow';
import toPairs from 'lodash/fp/toPairs';
import fromPairs from 'lodash/fp/fromPairs';
import map from 'lodash/fp/map';
import isArray from 'lodash/fp/isArray';

const sanitizeScalars = (json) => {
  const handleArray = (array) =>
    map((el) => {
      if (isArray(el)) {
        return handleArray(el);
      }
      if (el !== null && typeof el === 'object') {
        return handleObj(el);
      }
      if (el === '') {
        return null;
      }
      return el;
    }, array);

  const handleObj = flow(
    toPairs,
    map(([key, val]) => {
      if (isArray(val)) {
        return [key, handleArray(val)];
      }
      if (val !== null && typeof val === 'object') {
        return [key, handleObj(val)];
      }
      if (val === '') {
        return [key, null];
      }
      return [key, val];
    }),
    fromPairs,
  );

  return handleObj(json);
};

export { sanitizeScalars };
