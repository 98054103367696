import { makeStyles } from 'vendor/material';
import { fontWeightMedium } from 'utils/fontWeightConstants';

export const useNextStepsListStyles = makeStyles((theme) => ({
  nextStepsLabel: {
    color: theme.palette.NEUTRAL300,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeightMedium,
  },
  nextStepsList: {
    color: theme.palette.NEUTRAL500,
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  noNextStepsText: {
    color: theme.palette.NEUTRAL500,
  },
}));
