import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Loader } from '@tackle-io/platform-ui';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const InitiateAuth = () => {
  const query = useQuery();
  const issuer = query.get('iss');
  const { vendorid } = useParams<{ vendorid: string }>();
  if (issuer) {
    window.location.href = `${process.env.REACT_APP_TKL_AUTH_SERVICE_URL}/api/v1/auth/initiate?iss=${issuer}`;
  } else {
    window.location.href = `${process.env.REACT_APP_TKL_AUTH_SERVICE_URL}/api/v1/auth/initiate/${vendorid}`;
  }

  return <Loader />;
};
