import React, { useState } from 'react';
import { Paper, Table, TablePagination } from 'vendor/material';

// COMPONENTS
import {
  EnhancedTableBody,
  EnhancedTableHead,
  EnhancedTableToolbar,
} from './components';

// ASSETS
import useTableStyles from './CustomTable.styles';

function CustomTable({
  title,
  Icon,
  data,
  headers,
  toolbarProps,
  defaultOrderColumn = 'label',
  defaultRowsPerPage = 5,
  ...props
}) {
  const classes = useTableStyles();
  const [order, setOrder] = useState('asc');
  const [orderColumn, setOrderColumn] = useState(defaultOrderColumn);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  function handleRequestSort(event, property) {
    const isDesc = orderColumn === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc');
    setOrderColumn(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <div className={classes.root} {...props}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar title={title} Icon={Icon} {...toolbarProps} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              headers={headers}
              classes={classes}
              order={order}
              orderColumn={orderColumn}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <EnhancedTableBody
              data={data}
              order={order}
              orderColumn={orderColumn}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default CustomTable;
