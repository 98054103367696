import { decorate, observable, computed } from 'mobx';

import { DateTime } from 'luxon';
import { toDateTime } from '../utils/dates';

class AwsEntitlement {
  dimension = '';

  expiration = null;

  value = '';

  constructor(props) {
    let { expiration = null } = props;
    expiration = toDateTime(expiration, 'Missing expiration');
    Object.assign(this, { ...props, expiration });
  }

  get isExpired() {
    const { expiration } = this;
    return expiration < DateTime.utc();
  }
}

decorate(AwsEntitlement, {
  dimension: observable,
  expiration: observable,
  value: observable,
  isExpired: computed,
});

export default AwsEntitlement;
