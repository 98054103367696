import React from 'react';
import { useFormContext } from 'react-hook-form';
import FieldsSelectDate from 'pages/PrivateOffers/components/FieldsSelectDate/FieldsSelectDate';
import { privateOffersUIElements } from 'utils/pendoEnums';

interface AmendmentStartDateFieldProps {
  defaultValue: Date;
}

export const FIELD_NAME = 'pricing.serviceStartAt';

/**
 * FDA amendment offers must have the same start date as the existing agreement.
 */
const AmendmentStartDateField: React.FC<AmendmentStartDateFieldProps> = ({
  defaultValue,
}) => {
  const { control, errors } = useFormContext();

  return (
    <FieldsSelectDate
      fieldName={FIELD_NAME}
      id={
        privateOffersUIElements.PRIVATE_OFFERS_AMENDMENT_SERVICE_START_DATE_INPUT
      }
      control={control}
      error={errors?.pricing?.serviceStartAt}
      defaultValue={defaultValue ?? ''}
      labelText="Service start date"
      helperText={
        'Service start date must match the original offer service start date.'
      }
    />
  );
};

export default AmendmentStartDateField;
