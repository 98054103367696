import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {},
  searchGroupByContainer: {
    display: 'flex',
    padding: 0,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
