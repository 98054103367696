import { SelectFormFieldOption } from 'pages/CoSell/types';
import { MsftNeedFromCloudEnum } from 'pages/CoSell/types/enums';

export const MsftNeedFromCloudList: SelectFormFieldOption[] = [
  {
    title: 'General or other',
    value: MsftNeedFromCloudEnum.GENERAL_OR_OTHER,
  },
  {
    title: 'Workload - specific value proposition',
    value: MsftNeedFromCloudEnum.WORKLOAD,
  },
  {
    title: 'Customer technical architecture',
    value: MsftNeedFromCloudEnum.CUSTOMER_TECHNICAL_ARCHITECTURE,
  },
  {
    title: 'Proof of concept / Demo',
    value: MsftNeedFromCloudEnum.PROOF_OF_CONCEPT,
  },
  {
    title: 'Quotes / Licensing',
    value: MsftNeedFromCloudEnum.QUOTES,
  },
  {
    title: 'Post - sales customer success',
    value: MsftNeedFromCloudEnum.POST_SALES_CUSTOMER_SUCCESS,
  },
  {
    title: 'No help required at this time',
    value: MsftNeedFromCloudEnum.NO_HELP_REQUIRED,
  },
];
