import './bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';

import { init as initMonitoring } from './utils/monitor';

import App from './App';
import * as serviceWorker from './serviceWorker';

initMonitoring();

ReactDOM.render(<App />, document.getElementById('root'));

if (process.env.REACT_APP_ENABLE_SW === 'true') {
  serviceWorker.register();
  serviceWorker.beforeInstallPrompt();
} else {
  serviceWorker.unregister();
}
