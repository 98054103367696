import React from 'react';
import { useSalesforceCoSellWidgetHeaderStyles } from './SalesforceCoSellWidgetHeader.styles';
import TackleLogo from 'images/tackle-logo';

export const SalesforceCoSellWidgetHeader = () => {
  const classes = useSalesforceCoSellWidgetHeaderStyles();

  return (
    <div className={classes.container}>
      <TackleLogo theme={'light'} width="104px" />
    </div>
  );
};
