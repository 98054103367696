import { DateTime } from 'luxon';

/**
 * Luxon is missing an infinity constant
 * suggested work around is to use maximum supported Luxon timestamp.
 * source: https://github.com/moment/luxon/issues/291
 */
const infinity = DateTime.fromMillis(253402300799999).setZone('utc');

function toDateTime(date = null, invalidReason = 'Missing Date') {
  return date
    ? DateTime.fromISO(date).toUTC()
    : DateTime.invalid(invalidReason);
}

function formatDate(
  date = null,
  localeString = DateTime.DATE_MED,
  omitSetZone = false,
) {
  if (!date) return '-';

  let dateTime = date;
  if (typeof dateTime !== 'object' || !dateTime.isDateTime) {
    // Attempt to convert date into Luxon.DateTime
    dateTime = toDateTime(date);
  }

  if (omitSetZone === true) {
    return dateTime.toLocaleString(localeString);
  }
  if (dateTime.invalid) {
    if (dateTime.invalidReason === 'unparsable') return '-';

    return dateTime.invalidReason;
  }

  if (dateTime.equals(infinity)) return '-';

  return dateTime.setZone().toLocaleString(localeString);
}

// Taken from dashboard helper formatDate
function formatLocalDate(date) {
  try {
    return date.toLocal().toFormat('LLL dd, yyyy, t ZZZZ');
  } catch (e) {
    return '';
  }
}

function formatDateRange(start, end, localeString) {
  return `${formatDate(start, localeString)} - ${formatDate(
    end,
    localeString,
  )}`;
}

const filenameWithDate = (filename, extension) => {
  const date = new Date(Date.now());
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const year = `${date.getFullYear()}`;
  const clean_filename = filename.replace(/\s/g, '-');

  return `${clean_filename}-${month}-${day}-${year}.${extension}`;
};

// Timestamp
function getCurrentDateTime() {
  const date = new Date().toLocaleString();
  return date;
}

function getDateFormattedAsMMDDYY(date) {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = (1 + newDate.getMonth()).toString().padStart(2, '0');
  const day = newDate.getDate().toString().padStart(2, '0');

  return `${month}/${day}/${year}`;
}

// Returns whole-number representing the days between today and a given date
function calculateDaysBetweenDateAndToday(date) {
  const today = DateTime.fromISO(DateTime.now(), {
    zone: 'utc',
  });
  const comparisonDate = DateTime.fromISO(date, {
    zone: 'utc',
  });
  const difference = comparisonDate.diff(today, ['days']);
  return Math.floor(difference.days);
}

// Returns whole-number representing the days between date1 and date2
function calculateDaysBetweenTwoDates(date1, date2) {
  const formatDate1 = DateTime.fromISO(date1).startOf('day');
  const formatDate2 = DateTime.fromISO(date2).startOf('day');
  const difference = formatDate1.diff(formatDate2, ['days']);
  return Math.floor(Math.abs(difference.days));
}

export {
  toDateTime,
  formatDate,
  formatLocalDate,
  formatDateRange,
  filenameWithDate,
  getCurrentDateTime,
  getDateFormattedAsMMDDYY,
  calculateDaysBetweenDateAndToday,
  calculateDaysBetweenTwoDates,
  infinity,
};
