import { ApolloError, gql } from '@apollo/client';
import { useVendorMarketplaceStatusQuery } from 'generated/graphql';
import { ReportingStatus } from 'utils/constants';

gql`
  query VendorMarketplaceStatus {
    currentUser {
      id
      vendor {
        id
        vendor_type
        configuration {
          id
          status {
            aws {
              value
              last_connected_at
              updated_at
              error
            }
            cas {
              value
              last_connected_at
              updated_at
              error
            }
            sdds {
              value
              last_connected_at
              updated_at
              error
            }
            events_sqs {
              value
              last_connected_at
              updated_at
              error
            }
          }
        }
        reporting {
          id
          health {
            sdds_s3_status {
              status
              raw_error
            }
            sdds_sqs_status {
              status
              raw_error
            }
            tkl_iam_role_status {
              status
              raw_error
            }
            cas_iam_status {
              status
              raw_error
            }
            cas_s3_status {
              status
              raw_error
            }
            cas_sqs_status {
              status
              raw_error
            }
            cas_generation_status {
              status
              raw_error
            }
            gcp_status {
              status
              raw_error
            }
            azure_status {
              status
              raw_error
            }
          }
        }
      }
    }
  }
`;

export type VendorMarketplaceConnection = {
  status: 'disconnected' | 'connected' | 'pending' | 'unconfigured' | 'unknown';
  updatedAt: string | null | undefined;
  lastConnectedAt: string | null | undefined;
  errors: string[];
};

// vendor validator possible statues: "unconfigured" | "connected" | "disconnected" | "pending"
const coalesceStatuses = (
  ...statuses: (string | string[] | undefined | null)[]
): VendorMarketplaceConnection['status'] => {
  if (
    statuses.some(
      (s) => s === 'disconnected' || s === ReportingStatus.DISCONNECTED,
    )
  ) {
    return 'disconnected';
  }
  if (statuses.every((s) => s === 'connected' || s === ReportingStatus.GOOD)) {
    return 'connected';
  }
  if (
    statuses.some(
      (s) =>
        s === 'unconfigured' ||
        s === ReportingStatus.NOT_CONFIGURED ||
        s === ReportingStatus.N_A,
    )
  ) {
    return 'unconfigured';
  }
  if (statuses.some((s) => s === 'pending')) {
    return 'pending';
  }
  return 'unknown';
};

const coalesceErrors = (...errors: (string | string[] | undefined | null)[]) =>
  [...new Set(errors.flat().filter((str) => Boolean(str)))] as string[];

const demoVendorOverrideData: VendorMarketplaceConnection = {
  status: 'unknown',
  updatedAt: null,
  lastConnectedAt: null,
  errors: [],
};

const useVendorMarketplaceStatus = (): {
  loading: boolean;
  error: ApolloError;
  aws: {
    cas: VendorMarketplaceConnection;
    tackleIam: VendorMarketplaceConnection;
    sdds: VendorMarketplaceConnection;
    sqs: VendorMarketplaceConnection;
  };
  azure: VendorMarketplaceConnection;
  gcp: VendorMarketplaceConnection;
  isAwsDisconnected: boolean;
  isAzureDisconnected: boolean;
  isGcpDisconnected: boolean;
} => {
  const { data, loading, error } = useVendorMarketplaceStatusQuery({
    errorPolicy: 'all',
  });

  // Don't show disconnection states for demo vendors
  if (data?.currentUser?.vendor?.vendor_type === 'demo') {
    return {
      loading,
      error,
      gcp: demoVendorOverrideData,
      azure: demoVendorOverrideData,
      isAwsDisconnected: false,
      isAzureDisconnected: false,
      isGcpDisconnected: false,
      aws: {
        cas: demoVendorOverrideData,
        tackleIam: demoVendorOverrideData,
        sdds: demoVendorOverrideData,
        sqs: demoVendorOverrideData,
      },
    };
  }

  const vendorStatuses = data?.currentUser?.vendor?.configuration?.status;
  const reportingStatuses = data?.currentUser?.vendor?.reporting?.health;

  const gcp: VendorMarketplaceConnection = {
    status: coalesceStatuses(reportingStatuses?.gcp_status?.status),
    updatedAt: null,
    lastConnectedAt: null,
    errors: coalesceErrors(reportingStatuses?.gcp_status?.raw_error),
  };
  const azure: VendorMarketplaceConnection = {
    status: coalesceStatuses(reportingStatuses?.azure_status?.status),
    updatedAt: null,
    lastConnectedAt: null,
    errors: coalesceErrors(reportingStatuses?.azure_status?.raw_error),
  };

  const sdds: VendorMarketplaceConnection = {
    status: coalesceStatuses(
      vendorStatuses?.sdds?.value,
      reportingStatuses?.sdds_s3_status?.status,
      reportingStatuses?.sdds_sqs_status?.status,
    ),
    updatedAt: vendorStatuses?.sdds?.updated_at,
    lastConnectedAt: vendorStatuses?.sdds?.last_connected_at,
    errors: coalesceErrors(
      vendorStatuses?.sdds?.error?.split(';'),
      reportingStatuses?.sdds_s3_status?.raw_error,
      reportingStatuses?.sdds_sqs_status?.raw_error,
    ),
  };
  const cas: VendorMarketplaceConnection = {
    status: coalesceStatuses(
      vendorStatuses?.cas?.value,
      reportingStatuses?.cas_generation_status?.status,
      reportingStatuses?.cas_iam_status?.status,
      reportingStatuses?.cas_s3_status?.status,
      reportingStatuses?.cas_sqs_status?.status,
    ),
    updatedAt: vendorStatuses?.cas?.updated_at,
    lastConnectedAt: vendorStatuses?.cas?.last_connected_at,
    errors: coalesceErrors(
      vendorStatuses?.cas?.error?.split(';'),
      reportingStatuses?.cas_generation_status?.raw_error,
      reportingStatuses?.cas_iam_status?.raw_error,
      reportingStatuses?.cas_s3_status?.raw_error,
      reportingStatuses?.cas_sqs_status?.raw_error,
    ),
  };
  const tackleIam: VendorMarketplaceConnection = {
    status: coalesceStatuses(
      vendorStatuses?.aws?.value,
      reportingStatuses?.tkl_iam_role_status?.status,
    ),
    updatedAt: vendorStatuses?.aws?.updated_at,
    lastConnectedAt: vendorStatuses?.aws?.last_connected_at,
    errors: coalesceErrors(
      vendorStatuses?.aws?.error?.split(';'),
      reportingStatuses?.tkl_iam_role_status?.raw_error,
    ),
  };
  const sqs: VendorMarketplaceConnection = {
    status: coalesceStatuses(vendorStatuses?.events_sqs?.value),
    updatedAt: vendorStatuses?.events_sqs?.updated_at,
    lastConnectedAt: vendorStatuses?.events_sqs?.last_connected_at,
    errors: coalesceErrors(vendorStatuses?.events_sqs?.error?.split(';') || []),
  };

  return {
    loading,
    error,
    gcp,
    azure,
    isAwsDisconnected:
      coalesceStatuses(tackleIam.status, sdds.status, sqs.status) ===
      'disconnected',
    isAzureDisconnected: azure.status === 'disconnected',
    isGcpDisconnected: gcp.status === 'disconnected',
    aws: {
      cas,
      tackleIam,
      sdds,
      sqs,
    },
  };
};

export default useVendorMarketplaceStatus;
