import { makeStyles } from 'vendor/material';
import { fontWeightRegular } from 'utils/fontWeightConstants';
import { getToolbarHeight } from 'utils/styleHelpers';

const DRAWER_WIDTH = 224;
const DRAWER_BACKGROUND_COLOR = '#23263B';

export default makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: DRAWER_BACKGROUND_COLOR,
  },
  alertIcon: {
    color: theme.palette.RED200,
  },
  grow: {
    flexGrow: 1,
    color: theme.palette.NEUTRAL000,
    fontWeight: fontWeightRegular,
  },
  menuButton: {
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: 20,
    color: theme.palette.NEUTRAL000,
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    position: 'absolute',
    width: '0%',
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      width: DRAWER_WIDTH,
    },
  },
  drawerPaper: {
    backgroundColor: DRAWER_BACKGROUND_COLOR,
    borderRight: 0,
    justifyContent: 'space-between',
    scrollbarColor: `${theme.palette.NEUTRAL100} ${theme.palette.NEUTRAL020}`,
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar-track': {
      borderRadius: 0,
    },
    '&::-webkit-scrollbar': {
      width: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 0,
    },
  },
  drawerOpen: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
    },
  },
  drawerClose: {
    overflowX: 'hidden',
    width: '0%',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(12),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(1),
    boxSizing: 'content-box',
    borderBottom: '1px solid #36395A',
    height: getToolbarHeight(theme),
  },
  toolbarCollapsed: {
    justifyContent: 'center',
    '& $menuButton': {
      marginRight: theme.spacing(0),
    },
  },
  toolbarGutters: {
    padding: theme.spacing(0, 2),
  },
  tooltip: {
    marginTop: '-10px',
    marginLeft: '20px',
  },
  list: {
    paddingTop: 0,
  },
  tackleLogo: {
    '& > *': {
      fontSize: theme.typography.pxToRem(110),
      height: 25,
      textAlign: 'left',
      transform: 'translateX(-10px)',
    },
  },
  navListItem: {
    borderLeft: '4px solid transparent',
    borderRight: '4px solid transparent',
    color: theme.palette.NEUTRAL030,
    '& $icon': {
      color: theme.palette.NEUTRAL030,
    },
    '&.active': {
      backgroundColor: '#111432',
      borderLeft: `4px solid ${theme.palette.BLUE300}`,
      '& $icon': {
        color: theme.palette.NEUTRAL000,
      },
      '& $listItemIcon': {
        backgroundColor: '#111432',
      },
    },
    '&:hover': {
      backgroundColor: '#111432',
      '& $icon': {
        color: theme.palette.NEUTRAL000,
      },
    },
    '&:active': {
      backgroundColor: '#111432',
      '& $icon': {
        color: theme.palette.NEUTRAL000,
      },
    },
  },
  listVertical: {
    flexDirection: 'column',
    '&.active': {
      borderLeft: '4px solid transparent',
    },
    '& $listItemIcon': {
      marginRight: theme.spacing(0),
    },
    '& $text': {
      '& span': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  listItemIcon: {
    fontSize: theme.typography.pxToRem(16),
    minWidth: 24,
    width: 24,
    height: 24,
    maxWidth: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1.5),
    borderRadius: 3,
    fill: 'currentColor',
  },
  cosellListItem: {
    width: 20,
    height: 20,
  },
  prospectIcon: {
    fill: theme.palette.NEUTRAL000,
    width: 20,
    height: 20,
  },
  icon: {},
  text: {
    '& span': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  skeleton: {
    backgroundColor: theme.palette.NEUTRAL300,
  },
  shareWithSignal: {
    height: 56,
  },
}));
