import React from 'react';
import { Tag } from './../Tag';
import { AceOpportunityStageEnum, TagColorEnum } from '../../types/enums';

const stageToColorMapping: { [key in AceOpportunityStageEnum]: TagColorEnum } =
  {
    [AceOpportunityStageEnum.PROSPECT]: TagColorEnum.STANDARD,
    [AceOpportunityStageEnum.QUALIFIED]: TagColorEnum.STANDARD,
    [AceOpportunityStageEnum.TECHNICAL_VALIDATION]: TagColorEnum.STANDARD,
    [AceOpportunityStageEnum.BUSINESS_VALIDATION]: TagColorEnum.STANDARD,
    [AceOpportunityStageEnum.COMMITTED]: TagColorEnum.STANDARD,
    [AceOpportunityStageEnum.LAUNCHED]: TagColorEnum.GREEN,
    [AceOpportunityStageEnum.CLOSED_LOST]: TagColorEnum.RED,
  };

interface AwsCoSellStageTagProps {
  stage: AceOpportunityStageEnum;
}

export const AwsCoSellStageTag = (props: AwsCoSellStageTagProps) => {
  return <Tag color={stageToColorMapping[props.stage]}>{props.stage}</Tag>;
};
