import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router-dom';

import useStore, { hasLoaded } from 'hooks/useStore';
import { Products, Vendor } from 'stores';

import { Loader } from '@tackle-io/platform-ui';
import { useIsSandboxEnvironment } from 'hooks';
import NotAvailableState, {
  NotAvailableReasonEnum,
} from 'components/NotAvailableState/NotAvailableState';
import { PermissionProtectedPage } from 'boot/PermissionProtectedPage';
import { AREA_PERMISSION_COSELL } from 'utils/rbacPermissions';

const AllowedSandboxCoSellVendors = ['aws'];
enum CoSellCloudFeature {
  gcp = 'GCP Co-Sell',
  microsoft = 'Microsoft Co-Sell',
}

export const WithCoSellPackages = (Component) => {
  const Wrapped = (props) => {
    const productStore = useStore(Products);
    const vendorStore = useStore(Vendor);
    const isSandboxEnvironment = useIsSandboxEnvironment();
    const cloud = props.match.params.cloud;
    if (!hasLoaded(vendorStore, productStore)) return <Loader />;

    const coSellFeatures_V1 =
      vendorStore?.vendor?.feature_flags?.is_co_sell_enabled ||
      vendorStore?.vendor?.feature_flags?.is_co_sell_enabled_for_microsoft;
    if (!coSellFeatures_V1) return <Redirect to="/" />;

    if (
      isSandboxEnvironment &&
      cloud &&
      !AllowedSandboxCoSellVendors.includes(cloud)
    ) {
      return (
        <NotAvailableState
          feature={CoSellCloudFeature[cloud] || `${cloud} Co-Sell Opportunity`}
          reason={NotAvailableReasonEnum.SANDBOX}
        />
      );
    }
    return (
      <PermissionProtectedPage
        permission={AREA_PERMISSION_COSELL}
        pageFeatureName="Co-sell"
      >
        <Component {...props} vendorId={vendorStore.vendor.id} />
      </PermissionProtectedPage>
    );
  };

  return observer(Wrapped);
};
