import React from 'react';
import { Box, Typography } from 'vendor/material';

import useStyles from './DetailsSection.styles';

export interface DetailsSectionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const DetailsSection: React.FC<DetailsSectionProps> = ({
  title,
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <Box className={className} mb={2}>
      <Box mb={1.5}>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default DetailsSection;
