export enum AceOpportunityUseCaseEnum {
  AI_MACHINE_LEARNING_ANALYTICS = 'AI Machine Learning and Analytics',
  BIG_DATA_DATA_WAREHOUSE_DATA_LAKE = 'Big Data: Data Warehouse / Data Integration / ETL / Data Lake / BI',
  BUSINESS_APPLICATIONS = 'Business Applications & Contact Center',
  CLOUD_MANAGEMENT_TOOLS = 'Cloud Management Tools',
  CONTAINERS_SERVERLESS = 'Containers & Serverless',
  EDGE_END_USER_COMPUTING = 'Edge Computing / End User Computing',
  ENEGRY = 'Energy',
  FINANCIAL_SERVICES = 'Financial Services',
  HEALTHCARE_LIFE_SCIENCES = 'Healthcare and Life Sciences',
  HYBRID_APPLICATION_PLATFORM = 'Hybrid Application Platform',
  INDUSTRIAL_SOFTWARE = 'Industrial Software',
  IOT = 'IOT',
  MEDIA_HIGH_PERFORMANCE_COMPUTING = 'Media & High performance computing (HPC)',
  MIGRATION_DATABASE_MIGRATION = 'Migration / Database Migration',
  NETWORKING = 'Networking',
  SECURITY_AND_COMPLIANCE = 'Security & Compliance',
  STORAGE_AND_BACKUP = 'Storage & Backup',
  TRAINING = 'Training',
  ARCHIVING = 'Archiving',
  BLOCKCHAIN = 'Blockchain',
  BUSINESS_APPLICATIONS_AND_SAP_PROD = 'Business Applications & SAP Production',
  BUSINESS_APPLICATIONS_AND_MAINFRAME_MOD = 'Business Applications: Mainframe Modernization',
  CENTRALIZED_OPPS_MNGMT = 'Centralized Operations Management',
  CLOUD_MGMT_TOOLS_DEVOPS_CICD = 'Cloud Management Tools & DevOps with Continuous Integration & Continuous Delivery (CICD)',
  CONFIG_COMPLIANCE_AUDITING = 'Configuration, Compliance & Auditing',
  CONNECTED_SERVICES = 'Connected Services',
  CONTENT_DELIVERY_EDGE_SVCS = 'Content Delivery & Edge Services',
  DATABASE = 'Database',
  ENTERPRISE_GOVERNANCE_AND_CONTROLS = 'Enterprise Governance & Controls',
  ENTERPRISE_RESOURCE_PLANNING = 'Enterprise Resource Planning',
  HIGH_PERF_COMPUTING = 'High Performance Computing',
  MANUFACTURING_SUPPLY_CHAIN_OPS = 'Manufacturing, Supply Chain and Operations',
  MONITORING_AND_OBSERVABILITY = 'Monitoring & Observability',
  MONITORING_LOGGING_PERF = 'Monitoring, logging and performance',
  OUTPOST = 'Outpost',
  SAP = 'SAP',
  VMC = 'VMC',
  VMWARE = 'VMWare',
  WEB_DEV_AND_DEV_OPS = 'Web development & DevOps',
}
