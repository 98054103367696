import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select, TextField } from '@tackle-io/platform-ui';

import { Cloud } from 'stores/typings';

import { BillingTermValue } from 'stores/privateOffers/typings';

import { getMonthsDifferenceFromJSDates } from 'pages/PrivateOffers/utils/formatFormData';
import { getDurationMinMax, paymentModelFormPath } from './utils';

const azureDurations: DropdownProps['options'] = [
  { key: 'Choose duration', value: '' },
  { key: 'Monthly', value: 'Monthly' },
  { key: 'Annual', value: 'Annual' },
];

interface DropdownProps {
  options: { key: string; value: string }[];
}

const Dropdown: React.FC<DropdownProps> = ({ options }) => {
  const { register, errors } = useFormContext();

  return (
    <Select
      name="pricing.duration"
      label="Duration"
      id="pricing.duration"
      error={errors.pricing?.duration?.message}
      SelectProps={{ ref: register }}
    >
      {options.map((duration) => (
        <option key={duration.key} value={duration.value}>
          {duration.key}
        </option>
      ))}
    </Select>
  );
};

export interface DurationFieldProps {
  cloud: Cloud;
}

const DurationField: React.FC<DurationFieldProps> = ({ cloud }) => {
  const { control, errors, watch, setValue } = useFormContext();

  const paymentModelValue = watch(paymentModelFormPath);
  const billingTermValue = watch('pricing.billingTerm');
  const isFutureDatedOfferSelected =
    billingTermValue === BillingTermValue.FutureDated;

  const serviceStartAtValue = watch('pricing.serviceStartAt');
  const serviceEndAtValue = watch('pricing.serviceEndAt');

  const serviceDatesDiffInMonths = isFutureDatedOfferSelected
    ? getMonthsDifferenceFromJSDates(serviceStartAtValue, serviceEndAtValue)
    : 0;

  useEffect(() => {
    if (isFutureDatedOfferSelected) {
      setValue('pricing.duration', serviceDatesDiffInMonths);
    }
  }, [isFutureDatedOfferSelected, serviceDatesDiffInMonths, setValue]);

  const { min: durationMin, max: durationMax } = useMemo(() => {
    return getDurationMinMax(cloud, paymentModelValue, billingTermValue);
  }, [cloud, paymentModelValue, billingTermValue]);

  const textDurationField = isFutureDatedOfferSelected ? (
    <Controller
      control={control}
      name="pricing.duration"
      render={({ ref, ...props }): React.ReactElement => (
        <TextField
          {...props}
          value={serviceDatesDiffInMonths}
          label="Duration"
          type="number"
          helperText="Duration is calculated based on service start and end dates for future-dated offers"
          inputRef={ref}
          inputProps={{ min: durationMin, max: durationMax }}
          readOnly
        />
      )}
    />
  ) : (
    <Controller
      control={control}
      name="pricing.duration"
      render={({ ref, ...props }): React.ReactElement => (
        <TextField
          {...props}
          label="Duration"
          type="number"
          error={errors.pricing?.duration?.message}
          helperText={`Number between ${durationMin} and ${durationMax} months`}
          inputRef={ref}
          inputProps={{ min: durationMin, max: durationMax }}
        />
      )}
    />
  );

  let durationField = null;
  switch (cloud) {
    case 'aws':
      durationField = textDurationField;
      break;
    case 'azure':
      durationField = <Dropdown options={azureDurations} />;
      break;
    case 'gcp':
      durationField = textDurationField;
      break;
    default:
      durationField = null;
      break;
  }

  return durationField;
};

export default DurationField;
