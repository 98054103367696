import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    minHeight: '60vh',
    color: theme.palette.primary.contrastText,
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  headline: {
    ...theme.typography.headline,
    fontFamily: "'Open Sans', sans-serif",
    color: 'inherit',
    margin: `${theme.spacing(1.5)}px 0`,
  },
  logo: {
    display: 'inline-block',
    width: '180px',
  },
}));

export default useStyles;
