import { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router';
import { useAuth } from 'vendor/auth0';
import { Loader } from '@tackle-io/platform-ui';

import { AppData } from 'AppData';
import { useReturnTo } from '../hooks/useReturnTo';

interface PrivateRouteProps extends RouteProps {
  /** @deprecated New routes should not rely on global data being loaded up front. */
  loadData?: boolean;
}

function PrivateRouteInner({ loadData, children }) {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [, setReturnTo] = useReturnTo();
  const { isLoading, isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      setReturnTo(`${window.location.pathname}${window.location.search}`);
      setShouldRedirect(true);
    }
  }, [isAuthenticated, isLoading, setReturnTo]);

  if (isLoading) {
    return <Loader />;
  }

  if (shouldRedirect) {
    return <Redirect to="/login" />;
  }

  if (!isAuthenticated) {
    return null;
  }

  if (loadData) {
    return <AppData>{children}</AppData>;
  }

  return <>{children}</>;
}

export function PrivateRoute(props: PrivateRouteProps) {
  const {
    children,
    render,
    component: Component,
    loadData = false,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const toRender =
          children ||
          (render ? render(routeProps) : <Component {...routeProps} />);

        return (
          <PrivateRouteInner loadData={loadData}>{toRender}</PrivateRouteInner>
        );
      }}
    />
  );
}
