import {
  createTheme,
  Theme as MuiTheme,
  Palette,
  defaultShadows,
  Shadows,
} from 'vendor/material';

export interface PaletteExtra extends Palette {
  BLUE050: string;
  BLUE075: string;
  BLUE100: string;
  BLUE200: string;
  BLUE300: string;
  BLUE400: string;
  BLUE500: string;
  GREEN050: string;
  GREEN075: string;
  GREEN100: string;
  GREEN200: string;
  GREEN300: string;
  GREEN400: string;
  GREEN500: string;
  TEAL900: string;
  TEAL1000: string;
  NEUTRAL000: string;
  NEUTRAL010: string;
  NEUTRAL020: string;
  NEUTRAL030: string;
  NEUTRAL040: string;
  NEUTRAL050: string;
  NEUTRAL060: string;
  NEUTRAL070: string;
  NEUTRAL080: string;
  NEUTRAL090: string;
  NEUTRAL100: string;
  NEUTRAL200: string;
  NEUTRAL300: string;
  NEUTRAL400: string;
  NEUTRAL500: string;
  NEUTRAL600: string;
  NEUTRAL700: string;
  NEUTRAL800: string;
  NEUTRAL900: string;
  BLACK: string;
  PURPLE050: string;
  PURPLE075: string;
  PURPLE100: string;
  PURPLE200: string;
  PURPLE300: string;
  PURPLE400: string;
  PURPLE500: string;
  RED050: string;
  RED075: string;
  RED100: string;
  RED200: string;
  RED300: string;
  RED400: string;
  RED500: string;
  YELLOW050: string;
  YELLOW075: string;
  YELLOW100: string;
  YELLOW200: string;
  YELLOW300: string;
  YELLOW400: string;
  YELLOW500: string;
  TEAL050: string;
  TEAL075: string;
  TEAL100: string;
  TEAL200: string;
  TEAL300: string;
  TEAL400: string;
  TEAL500: string;
}

interface IDepthShadows {
  d100: string;
  d200: string;
  d300: string;
  d400: string;
  d500: string;
}

export interface ThemeTackle extends MuiTheme {
  palette: PaletteExtra;
  depth: IDepthShadows;
}

// SHADOWS
const shadows = [...defaultShadows] as Shadows;
shadows[1] =
  '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 1px 1px 0 rgba(9, 30, 66, 0.25)';
shadows[2] =
  '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 4px 8px -2px rgba(9, 30, 66, 0.25)';
shadows[3] =
  '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 8px 16px -4px rgba(9, 30, 66, 0.25)';
shadows[4] =
  '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 12px 24px -6px rgba(9, 30, 66, 0.25)';
shadows[5] =
  '0 0 1px 0 rgba(9,30,66,0.31), 0 20px 32px -8px rgba(9,30,66,0.25)';

// COLORS
const TackleLightBlue = '#639a9f';
const TackleBlue = '#002b41';
const TackleYellow = '#ffb600';

export const legacyColors = {
  BLUE050: '#DEEBFF',
  BLUE075: '#B3D4FF',
  BLUE100: '#4C9AFF',
  BLUE200: '#2684FF',
  BLUE300: '#0065FF',
  BLUE400: '#0052CC',
  BLUE500: '#0747A6',
  GREEN050: '#E2FFEE',
  GREEN075: '#ABF5D1',
  GREEN100: '#79F2C0',
  GREEN200: '#57D9A3',
  GREEN300: '#36B37E',
  GREEN400: '#00875A',
  GREEN500: '#006644',
  TEAL900: '#063649',
  TEAL1000: '#032432',
  NEUTRAL000: '#ffffff',
  NEUTRAL010: '#FAFBFC',
  NEUTRAL020: '#F4F5F7',
  NEUTRAL030: '#EBECF0',
  NEUTRAL040: '#DFE1E6',
  NEUTRAL050: '#C1C7D0',
  NEUTRAL060: '#B3BAC5',
  NEUTRAL070: '#A5ADBA',
  NEUTRAL080: '#97A0AF',
  NEUTRAL090: '#8993A4',
  NEUTRAL100: '#7A869A',
  NEUTRAL200: '#6B778C',
  NEUTRAL300: '#5E6C84',
  NEUTRAL400: '#505F79',
  NEUTRAL500: '#42526E',
  NEUTRAL600: '#344563',
  NEUTRAL700: '#253858',
  NEUTRAL800: '#172B4D',
  NEUTRAL900: '#091E42',
  BLACK: '#000000',
  PURPLE050: '#EAE6FF',
  PURPLE075: '#C0B6F2',
  PURPLE100: '#998DD9',
  PURPLE200: '#8777D9',
  PURPLE300: '#6554C0',
  PURPLE400: '#5243AA',
  PURPLE500: '#403294',
  RED050: '#FFEBE5',
  RED075: '#FFBDAD',
  RED100: '#FF8F73',
  RED200: '#FF7452',
  RED300: '#FF5630',
  RED400: '#DE350B',
  RED500: '#BF2600',
  YELLOW050: '#FFFAE6',
  YELLOW075: '#FFF0B3',
  YELLOW100: '#FFE380',
  YELLOW200: '#FFC400',
  YELLOW300: '#FFAB00',
  YELLOW400: '#FF991F',
  YELLOW500: '#FF8B00',
};

export const themeObject = {
  status: {
    success: '#00875A',
    warning: TackleYellow,
    info: TackleBlue,
    error: '#DE350B',
    onGoing: '#0052CC',
  },
  google: {
    light: '#679df6',
    main: '#4285F4',
    dark: '#2e5daa',
    contrastText: '#fff',
  },
  linkedin: {
    light: '#3392c3',
    main: '#0077B5',
    dark: '#00537e',
    contrastText: '#fff',
  },
  palette: {
    primary: {
      light: TackleLightBlue,
      main: TackleBlue,
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffc433',
      main: TackleYellow,
      dark: '#ff9900',
      contrastText: '#000',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      disabled: '#939393',
      hint: '#639A9F',
      success: '#328969',
      dark: '#636363',
    },
    divider: '#D8D8D8',
    background: {
      default: '#f5f5f5',
      paper: '#fff',
    },
    ...legacyColors,
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    useNextVariants: true,
  },
  depth: {
    d100: '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 1px 1px 0 rgba(9, 30, 66, 0.25)',
    d200: '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 4px 8px -2px rgba(9, 30, 66, 0.25)',
    d300: '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 8px 16px -4px rgba(9, 30, 66, 0.25)',
    d400: '0 0 1px 0 rgba(9, 30, 66, 0.31), 0 12px 24px -6px rgba(9, 30, 66, 0.25)',
    d500: '0 0 1px 0 rgba(9,30,66,0.31), 0 20px 32px -8px rgba(9,30,66,0.25)',
  },
  shadows,
};

const theme = createTheme(themeObject);

export default theme as ThemeTackle;
