import { WebAuth } from 'auth0-js';

const config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientID: process.env.REACT_APP_AUTH0_CLIENTID || '',
  redirectUri: `${window.location.origin}/auth/callback`,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  responseType: 'token id_token code',
  scope: 'openid profile email offline_access',
  realm: 'Username-Password-Authentication',
};

export interface OAuthTokenResponse {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  refreshToken: string;
  scope: string;

  tokenType: string;
}

export interface CallbackResponse {
  accessToken: string;
  code: string;
  expiresIn: number;
  idToken: string;
  scope: string;
  tokenType: string;
}

interface IDTokenPayload {
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  locale: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  at_hash: string;
  c_hash: string;
  nonce: string;
}

export interface RenewAuthResponse {
  accessToken: string;
  idToken: string;
  idTokenPayload: IDTokenPayload;
  state: string;
  expiresIn: number;
  tokenType: string;
  scope: string;
}

class Auth0Client {
  _webAuth: WebAuth | null = null;

  constructor() {
    const { domain, clientID, redirectUri, audience, responseType, scope } =
      config;

    this._webAuth = new WebAuth({
      domain,
      clientID,
      redirectUri,
      audience,
      responseType,
      scope,
    });
  }

  getRefreshToken = (code: string) => {
    return new Promise<OAuthTokenResponse>((resolve, reject) => {
      this._webAuth?.client.oauthToken(
        {
          code,
          grantType: 'authorization_code',
          redirectUri: config.redirectUri,
        },
        (err, result) => {
          if (err) {
            reject(err);
          }
          resolve(result);
        },
      );
    });
  };

  getNewAccessToken = (refreshToken: string) => {
    return new Promise<OAuthTokenResponse>((resolve, reject) => {
      this._webAuth?.client.oauthToken(
        {
          refreshToken,
          grantType: 'refresh_token',
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  };

  getSocialLoginUrl = (connection: string, nonce: string) => {
    const params = new URLSearchParams({
      client_id: config.clientID,
      response_type: config.responseType,
      redirect_uri: config.redirectUri,
      scope: config.scope,
      audience: config.audience,
      connection,
      nonce,
    });

    return `https://${config.domain}/authorize?${params}`;
  };

  login = ({ username, password }, nonce, state) => {
    return new Promise((resolve, reject) => {
      this._webAuth?.login(
        {
          username,
          password,
          realm: config.realm,
          nonce,
          state: JSON.stringify(state),
        },
        (err, payload) => {
          if (err) return reject(err);
          resolve(payload);
        },
      );
    });
  };

  logout = ({ returnTo }) => {
    this._webAuth?.logout({ clientID: config.clientID, returnTo });
  };

  resetPassword = (email) => {
    return new Promise<string>((resolve, reject) => {
      this._webAuth?.changePassword(
        {
          connection: config.realm,
          email,
        },
        (err, res) => {
          if (err) reject(err);
          resolve(res);
        },
      );
    });
  };

  renewAuth = ({ nonce }) => {
    return new Promise((resolve, reject) => {
      this._webAuth?.renewAuth(
        { nonce, responseType: config.responseType },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    });
  };

  fetchProfile = ({ accessToken }: { accessToken: string }) => {
    return new Promise((resolve, reject) => {
      this._webAuth?.client.userInfo(accessToken, (err, profile) => {
        if (err) reject(err);
        resolve(profile);
      });
    });
  };
}

export default Auth0Client;
