import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  icon: {
    height: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(24),
    margin: theme.spacing(0, 1, 0, 0),
    color: theme.palette.NEUTRAL600,
  },
  title: {
    flex: '1 1 auto',
    width: 'auto',
  },
  titleText: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.NEUTRAL600,
  },
  actions: {
    color: theme.palette.text.secondary,
    width: '100%',
    margin: theme.spacing(2, 0, 0),
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      margin: 0,
      justifyContent: 'flex-end',
    },
  },
}));

export default useStyles;
