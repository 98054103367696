import React from 'react';
import { Tooltip } from 'vendor/material';

function withTooltip(
  children,
  {
    disabled = false,
    fullWidth = false,
    wrapChildren = false,
    title = '',
    ...props
  } = {
    disabled: false,
    wrapChildren: false,
    fullWidth: false,
    title: '',
    props: {},
  },
) {
  if (disabled) {
    return children;
  }

  const divStyle = !fullWidth ? { display: 'inline-block' } : null;

  let finalChildren = children;
  if (wrapChildren) finalChildren = <div style={divStyle}>{children}</div>;
  return (
    <Tooltip title={title} {...props}>
      <div style={divStyle}>{finalChildren}</div>
    </Tooltip>
  );
}

export default withTooltip;
