import { RegistrationField as RegistrationPageField } from 'stores/products/typings/Registration';

export const determineHTMLInputType = (
  field: RegistrationPageField,
): HTMLInputElement['type'] => {
  // Attempt to determine HTML Input type by data_type
  // data_type is not a standard type, infer from the data_type or title
  const fieldType = field.dataType;
  if (fieldType === 'number') return 'number';
  if (fieldType === 'boolean' || fieldType === 'true/false') return 'checkbox';

  const title = field.title.toLowerCase();
  if (title.includes('name')) return 'name';
  if (title.includes('email')) return 'email';
  if (title.includes('company address')) return 'street-address';
  if (title.includes('website')) return 'url';
  if (title.includes('phone number')) return 'tel';
  if (title === 'company') return 'organization';

  return 'text';
};
