import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  tableCellRoot: {
    '&:first-child': {
      paddingLeft: theme.spacing(2.5),
    },
  },
}));

export default useStyles;
