import { decorate, observable, action } from 'mobx';
import { awsMoneyToDinero } from '../utils/money';

class Report {
  cloud = '';

  data = [];

  hasMore = false;

  object = '';

  currMonth = '';

  currMonth31To60DaysPending = 0;

  currMonth61To90DaysPending = 0;

  currMonth91To120DaysPending = 0;

  currMonthGreaterThan120DaysPending = 0;

  currMonthLessThan31DaysPending = 0;

  currMonth31To60DaysPastDue = 0;

  currMonth61To90DaysPastDue = 0;

  currMonth91To120DaysPastDue = 0;

  currMonthGreaterThan120DaysPastDue = 0;

  currMonthLessThan31DaysPastDue = 0;

  currMonthPastDueOverall = 0;

  currMonthOverall = 0;

  totalAmount = 0;

  totalAwsFee = 0;

  totalAwsFeeRefund = 0;

  totalCost = 0;

  totalCustomerBilledAmount = 0;

  totalDisbursedAmount = 0;

  totalNetAmount = 0;

  totalPartnerRevenueAmount = 0;

  totalRefundsAmount = 0;

  totalSellerRevenue = 0;

  totalUsSalesTaxCustomerBilledAmount = 0;

  totalUsSalesTaxRefundsAmount = 0;

  totalEstimatedRevenue = 0;

  totalTaxableAmount = 0;

  totalTaxAmount = 0;

  transactionStatus = '';

  usageType = '';

  error = null;

  loading = false;

  constructor(props) {
    const {
      cloud,
      data,
      has_more: hasMore,
      object,
      curr_month: currMonth,
      curr_month_31_to_60_days_pending: currMonth31To60DaysPending,
      curr_month_61_to_90_days_pending: currMonth61To90DaysPending,
      curr_month_91_to_120_days_pending: currMonth91To120DaysPending,
      curr_month_greater_than_120_days_pending:
        currMonthGreaterThan120DaysPending,
      curr_month_less_than_31_days_pending: currMonthLessThan31DaysPending,
      curr_month_past_due_31_to_60_days: currMonth31To60DaysPastDue,
      curr_month_past_due_61_to_90_days: currMonth61To90DaysPastDue,
      curr_month_past_due_91_to_120_days: currMonth91To120DaysPastDue,
      curr_month_past_due_greater_than_120_days:
        currMonthGreaterThan120DaysPastDue,
      curr_month_past_due_less_than_31_days: currMonthLessThan31DaysPastDue,
      curr_month_past_due_overall: currMonthPastDueOverall,
      curr_month_funds_overall: currMonthOverall,
      total_amount: totalAmount,
      total_aws_fee: totalAwsFee,
      total_aws_fee_refund: totalAwsFeeRefund,
      total_cost: totalCost,
      total_customer_billed_amount: totalCustomerBilledAmount,
      total_disbursed_amount: totalDisbursedAmount,
      total_net_amount: totalNetAmount,
      total_partner_revenue_amount: totalPartnerRevenueAmount,
      total_refunds_amount: totalRefundsAmount,
      total_seller_revenue: totalSellerRevenue,
      total_us_sales_tax_customer_billed_amount:
        totalUsSalesTaxCustomerBilledAmount,
      total_us_sales_tax_refunds_amount: totalUsSalesTaxRefundsAmount,
      total_estimated_revenue: totalEstimatedRevenue,
      total_taxable_amount: totalTaxableAmount,
      total_tax_amount: totalTaxAmount,
      transaction_status: transactionStatus,
      usage_type: usageType,
    } = props;

    this.cloud = cloud;
    this.data = data;
    this.hasMore = hasMore;
    this.object = object;
    this.currMonth = currMonth;
    this.currMonth31To60DaysPending = awsMoneyToDinero({
      amount: currMonth31To60DaysPending,
    });
    this.currMonth61To90DaysPending = awsMoneyToDinero({
      amount: currMonth61To90DaysPending,
    });
    this.currMonth91To120DaysPending = awsMoneyToDinero({
      amount: currMonth91To120DaysPending,
    });
    this.currMonthGreaterThan120DaysPending = awsMoneyToDinero({
      amount: currMonthGreaterThan120DaysPending,
    });
    this.currMonthLessThan31DaysPending = awsMoneyToDinero({
      amount: currMonthLessThan31DaysPending,
    });
    this.currMonth31To60DaysPastDue = awsMoneyToDinero({
      amount: currMonth31To60DaysPastDue,
    });
    this.currMonth61To90DaysPastDue = awsMoneyToDinero({
      amount: currMonth61To90DaysPastDue,
    });
    this.currMonth91To120DaysPastDue = awsMoneyToDinero({
      amount: currMonth91To120DaysPastDue,
    });
    this.currMonthGreaterThan120DaysPastDue = awsMoneyToDinero({
      amount: currMonthGreaterThan120DaysPastDue,
    });
    this.currMonthLessThan31DaysPastDue = awsMoneyToDinero({
      amount: currMonthLessThan31DaysPastDue,
    });
    this.currMonthPastDueOverall = awsMoneyToDinero({
      amount: currMonthPastDueOverall,
    });
    this.currMonthOverall = awsMoneyToDinero({ amount: currMonthOverall });
    this.totalAmount = awsMoneyToDinero({ amount: totalAmount });
    this.totalAwsFee = awsMoneyToDinero({ amount: totalAwsFee });
    this.totalAwsFeeRefund = awsMoneyToDinero({ amount: totalAwsFeeRefund });
    this.totalCost = awsMoneyToDinero({ amount: totalCost });
    this.totalCustomerBilledAmount = awsMoneyToDinero({
      amount: totalCustomerBilledAmount,
    });
    this.totalDisbursedAmount = awsMoneyToDinero({
      amount: totalDisbursedAmount,
    });
    this.totalNetAmount = awsMoneyToDinero({ amount: totalNetAmount });
    this.totalPartnerRevenueAmount = awsMoneyToDinero({
      amount: totalPartnerRevenueAmount,
    });
    this.totalRefundsAmount = awsMoneyToDinero({ amount: totalRefundsAmount });
    this.totalSellerRevenue = awsMoneyToDinero({ amount: totalSellerRevenue });
    this.totalUsSalesTaxCustomerBilledAmount = awsMoneyToDinero({
      amount: totalUsSalesTaxCustomerBilledAmount,
    });
    this.totalUsSalesTaxRefundsAmount = awsMoneyToDinero({
      amount: totalUsSalesTaxRefundsAmount,
    });
    this.totalEstimatedRevenue = awsMoneyToDinero({
      amount: totalEstimatedRevenue,
    });
    this.totalTaxAmount = awsMoneyToDinero({
      amount: totalTaxAmount,
    });
    this.totalTaxableAmount = awsMoneyToDinero({
      amount: totalTaxableAmount,
    });
    this.transactionStatus = transactionStatus;
    this.usageType = usageType;
  }

  setLoading = (loading) => {
    this.loading = loading;
  };

  setError = (error) => {
    this.error = error;
  };
}

decorate(Report, {
  cloud: observable,
  data: observable,
  object: observable,
  currMonth: observable,
  currMonth31To60DaysPending: observable,
  currMonth61To90DaysPending: observable,
  currMonth91To120DaysPending: observable,
  currMonthGreaterThan120DaysPending: observable,
  currMonthLessThan31DaysPending: observable,
  currMonth31To60DaysPastDue: observable,
  currMonth61To90DaysPastDue: observable,
  currMonth91To120DaysPastDue: observable,
  currMonthGreaterThan120DaysPastDue: observable,
  currMonthLessThan31DaysPastDue: observable,
  currMonthPastDueOverall: observable,
  currMonthOverall: observable,
  totalAmount: observable,
  totalAwsFee: observable,
  totalAwsFeeRefund: observable,
  totalCost: observable,
  totalCustomerBilledAmount: observable,
  totalDisbursedAmount: observable,
  totalNetAmount: observable,
  totalPartnerRevenueAmount: observable,
  totalRefundsAmount: observable,
  totalSellerRevenue: observable,
  totalUsSalesTaxCustomerBilledAmount: observable,
  totalUsSalesTaxRefundsAmount: observable,
  totalEstimatedRevenue: observable,
  totalTaxAmount: observable,
  totalTaxableAmount: observable,
  transactionStatus: observable,
  usageType: observable,

  setError: action,
  setLoading: action,
});

export default Report;
