import React from 'react';
import { Grid } from 'vendor/material';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';
import { PersonIcon } from 'packages/cosell/assets';
import { Contact } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
interface AceContactDetailsProps {
  contact: Contact;
}

const AceContactDetails: React.FC<AceContactDetailsProps> = ({ contact }) => {
  return (
    <DetailsSectionCard
      title="Contact details"
      icon={<PersonIcon />}
      subTitle="Provide the details of the end customer associated with the opportunity."
    >
      <Grid container spacing={2}>
        <InfoItem itemTitle="Customer first name" value={contact?.firstName} />

        <InfoItem itemTitle="Customer last name" value={contact?.lastName} />

        <InfoItem itemTitle="Customer title" value={contact?.businessTitle} />

        <InfoItem itemTitle="Customer email" value={contact?.email} />
        <InfoItem
          itemTitle="Customer phone"
          value={contact?.phone}
          gridSize={12}
        />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceContactDetails;
