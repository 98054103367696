import { makeStyles } from 'vendor/material';

export const useCanvasAppStyles = makeStyles((theme) => ({
  canvasAppContainer: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    color: theme.palette.NEUTRAL900,
    '& hr': {
      borderTop: `1px solid ${theme.palette.NEUTRAL030}`,
      borderBottom: 0,
    },
    '& strong': {
      fontWeight: 600,
    },
  },
}));
