import React, { useState } from 'react';
import { SvgIcon, IconButton, InputBase, withStyles } from 'vendor/material';
import { Eye, EyeOff } from 'mdi-material-ui';

// ASSETS
import useTokenStyles from './TokenInput.styles';

const TokenInputBase = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    color: '#253858',
    border: 'none',
    backgroundColor: 'transparent',
    fontFamily: theme.typography.fontFamily,
    '&:focus': {},
  },
}))(InputBase);

interface TokenInputProps {
  value: string;
  type: string;
}

const TokenInput: React.FC<TokenInputProps> = ({ value, type }) => {
  const isSecret = type === 'secret';

  const [hidePassword, setHidePassword] = useState(isSecret);

  const secretString = '*******************';

  const classes = useTokenStyles();

  function toggleVisibility(): void {
    setHidePassword(!hidePassword);
  }

  return (
    <div className={classes.inputGroup}>
      <TokenInputBase
        classes={{ root: classes.inputBase }}
        value={hidePassword ? secretString : value}
        id="bootstrap-input"
      />
      {isSecret && (
        <div className={classes.inputGroupAppend}>
          <IconButton
            classes={{ root: classes.iconButton }}
            edge="end"
            aria-label="toggle password visibility"
            onClick={toggleVisibility}
          >
            <SvgIcon htmlColor="#42526E">
              {hidePassword ? <Eye /> : <EyeOff />}
            </SvgIcon>
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default TokenInput;
