import { gql } from '@apollo/client';

import NotAvailableState, {
  NotAvailableReasonEnum,
} from 'components/NotAvailableState/NotAvailableState';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import {
  hasPermission,
  RoleAreaPermission,
  RoleAllPermissions,
} from 'utils/rbacPermissions';
import { Loader } from '@tackle-io/platform-ui';
import { usePermissionProtectedPageQuery } from 'generated/graphql';
interface RoleRestrictedPageProps {
  permission: RoleAreaPermission | RoleAllPermissions;
  pageFeatureName?: string;
  children: React.ReactElement;
}

gql`
  query permissionProtectedPage {
    currentUser {
      id
      role
      role_details {
        permissions
      }
    }
  }
`;

export const PermissionProtectedPage = ({
  permission,
  pageFeatureName,
  children,
}: RoleRestrictedPageProps) => {
  const { data, loading, error } = usePermissionProtectedPageQuery();

  if (error) {
    return <ErrorPage title="" />;
  }

  const userPermissions = data?.currentUser?.role_details?.permissions;

  if (loading) return <Loader />;
  if (!hasPermission(permission, userPermissions)) {
    return (
      <NotAvailableState
        feature={pageFeatureName}
        reason={NotAvailableReasonEnum.PERMISSION}
      />
    );
  }
  return <>{children}</>;
};
