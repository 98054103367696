import React from 'react';
import { Typography } from 'vendor/material';

import useStyles from './NameValuePair.styles';

interface NameValuePairProps {
  name: string;
  value: React.ReactNode;
}

const NameValuePair: React.FC<NameValuePairProps> = ({ name, value }) => {
  const classes = useStyles();
  return (
    <>
      <Typography component="h5" className={classes.name}>
        {name}
      </Typography>
      <Typography component="span" className={classes.value}>
        {value}
      </Typography>
    </>
  );
};

export default NameValuePair;
