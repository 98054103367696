import {
  BrowserConfig,
  EnrichmentPlugin,
  Event,
} from '@amplitude/analytics-types';
import { ampli } from 'utils/analytics/ampli';
import { EventType, PluginTypeEnum } from '../types';

const SOURCE_QUERY_PARAM_KEY = 'source';
const OFFER_TYPE_QUERY_PARAM_KEY = 'offerType';
const ABO_LINKED_OFFER_REF_QUERY_PARAM_KEY = 'aboLinkedOfferRef';
const SOURCE_OFFER_PO_ID_QUERY_PARAM_KEY = 'sourceOfferPoId';

const OFFERS_PAGE_NAME_NEW_OFFER = 'new_offer';
const OFFERS_PAGE_NAME_REVIEW_OFFER = 'review_offer';
const OFFERS_PAGE_NAME_LIST_OFFERS = 'list_offers';

const PageViewedEnrichmentPlugin = (): EnrichmentPlugin => {
  return {
    name: 'page-viewed-event-enrichment-plugin',
    type: PluginTypeEnum.ENRICHMENT,
    setup: async (config: BrowserConfig) => undefined,
    execute: async (event: Event) => {
      const eventType = event.event_type;
      if (eventType !== EventType.PAGE_VIEWED) return event;

      const isBrowser = typeof window !== 'undefined';
      const location = isBrowser ? window?.location : null;
      const urlQueryParams = isBrowser && new URLSearchParams(location?.search);
      const source = urlQueryParams?.get(SOURCE_QUERY_PARAM_KEY);

      if (source) event.event_properties.source = source;

      return event;
    },
  };
};

const OffersPageViewedEnrichmentPlugin = (): EnrichmentPlugin => {
  return {
    name: 'offers-page-viewed-event-enrichment-plugin',
    type: PluginTypeEnum.ENRICHMENT,
    setup: async (config: BrowserConfig) => undefined,
    execute: async (event: Event) => {
      const eventType = event.event_type;
      if (eventType !== EventType.PAGE_VIEWED) {
        return event;
      }
      const pageURL = event.event_properties['[Amplitude] Page URL'];
      if (!pageURL || !pageURL.includes('private-offers')) {
        return event;
      }

      const isBrowser = typeof window !== 'undefined';
      const location = isBrowser ? window?.location : null;
      const urlQueryParams = isBrowser && new URLSearchParams(location?.search);
      const offerType = urlQueryParams?.get(OFFER_TYPE_QUERY_PARAM_KEY) || '';
      const aboLinkedOfferRef =
        urlQueryParams?.get(ABO_LINKED_OFFER_REF_QUERY_PARAM_KEY) || '';
      const sourceOfferPoId =
        urlQueryParams?.get(SOURCE_OFFER_PO_ID_QUERY_PARAM_KEY) || '';

      let offersPageName = '';
      switch (true) {
        case /private-offers\/new/.test(pageURL):
          offersPageName = OFFERS_PAGE_NAME_NEW_OFFER;
          break;
        case /private-offers\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(
          pageURL,
        ):
          offersPageName = OFFERS_PAGE_NAME_REVIEW_OFFER;
          break;
        case /private-offers$/.test(pageURL):
          offersPageName = OFFERS_PAGE_NAME_LIST_OFFERS;
          break;
      }

      if (!offersPageName) {
        return event;
      }

      ampli.offersPageViewed({
        offers_page_name: offersPageName,
        offer_type: offerType,
        abo_linked_offer_ref: aboLinkedOfferRef,
        source_offer_po_id: sourceOfferPoId,
      });

      return event;
    },
  };
};

const GroupsEnrichmentPlugin = ({
  vendorId,
  organizationId,
}: {
  vendorId: string;
  organizationId: string;
}): EnrichmentPlugin => {
  return {
    name: 'groups-enrichment-plugin',
    type: PluginTypeEnum.ENRICHMENT,
    setup: async (config: BrowserConfig) => undefined,
    execute: async (event: Event) => {
      if (vendorId) event.groups = { vendor_id: vendorId };
      if (organizationId)
        event.groups = { ...event.groups, organization_id: organizationId };
      return event;
    },
  };
};

export {
  GroupsEnrichmentPlugin,
  OffersPageViewedEnrichmentPlugin,
  PageViewedEnrichmentPlugin,
};
