import { ThemeProvider as LegacyMuiThemeProvider } from 'vendor/material';
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import theme from './theme';

const ThemeProvider = ({ children }) => (
  <LegacyMuiThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </LegacyMuiThemeProvider>
);

export default ThemeProvider;
