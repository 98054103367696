import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(15deg) scale(1.1)',
    },
    '25%': {
      transform: 'rotate(-25deg) scale(0)',
    },
    '50%': {
      transform: 'rotate(90deg) scale(0.95)',
    },
    '75%': {
      transform: 'rotate(75deg) scale(1.75)',
    },
    '100%': {
      transform: 'rotate(360deg) scale(1)',
    },
  },
  '@keyframes moonrise': {
    '0%': {
      transform: 'translate(-50%, 100%)',
    },
    '50%': {
      transform: 'translate(0%, 0%)',
    },
    '100%': {
      transform: 'translate(100%, 100%)',
    },
  },
  star: {
    transformBox: 'fill-box',
    transformOrigin: 'center',
    transform: 'rotate(-15deg)',
    animation: '$spin 8000ms ease-in-out infinite',
    '&:nth-child(odd)': {
      animationDelay: '-1000ms',
      animationDirection: 'reverse',
    },
    '&:nth-child(4n)': {
      animationDelay: '-2000ms',
    },
    '&:nth-child(3n)': {
      animationDelay: '-3000ms',
    },
    '&:nth-child(5n)': {
      animationDelay: '-4000ms',
    },
  },
}));

export function Mountains({ className = '' }) {
  const classes = useStyles();
  return (
    <svg
      className={className}
      viewBox="0 0 600 466"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M250.958 464.909H0L32.5367 384.926L49.7283 375.502L76.7835 312.954L98.6605 300.102L119.456 262.476H139.994L185.626 339.515L208.843 351.51L250.958 464.909Z"
        fill="#EBEBEB"
      />
      <path
        d="M0 464.909H108.248V402.491L136.023 358.793L122.79 312.954L139.994 262.476H119.456L98.6605 300.102L76.7835 312.954L49.7283 375.502L32.5368 384.926L0 464.909Z"
        fill="#DBDBDB"
      />
      <path
        d="M139.994 464.909L150.479 432.051L167.446 415.342L175.016 395.636L180.298 399.92V418.77L167.446 433.764V465.033L139.994 464.909Z"
        fill="#DBDBDB"
      />
      <path
        d="M160.147 363.693L162.965 350.51L170.527 342.942L174.071 347.441L169.345 353.317L171.472 365.863L160.147 363.693Z"
        fill="#DBDBDB"
      />
      <path
        d="M232.903 464.85H600L530.73 320.192L497.412 305.907L462.049 236.801L438.723 211.716L418.167 165.958L394.097 169.859L352.945 265.729L329.967 282.872L292.647 381.619L255.881 417.333L232.903 464.85Z"
        fill="#EBEBEB"
      />
      <path
        d="M418.167 165.958L420.988 238.051L393.889 275.907L406.601 304.882L381.435 368.762L403.367 421.619L390.378 464.85H232.903L255.881 417.333L292.647 381.619L329.967 282.872L352.945 265.729L394.097 169.859L418.167 165.958Z"
        fill="#DBDBDB"
      />
      <path
        d="M411.398 350.192L430.299 317.335L428.816 303.406L435.857 293.406L444.382 321.8L418.81 350.192H411.398Z"
        fill="#DBDBDB"
      />
      <g>
        <path
          className={classes.star}
          d="M396.571 108.673L397.912 111.39L400.91 111.827L398.741 113.941L399.253 116.928L396.571 115.518L393.888 116.928L394.401 113.941L392.231 111.827L395.229 111.39L396.571 108.673Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M144.528 244.567L145.868 247.285L148.866 247.72L146.697 249.835L147.209 252.823L144.528 251.412L141.844 252.823L142.357 249.835L140.188 247.72L143.185 247.285L144.528 244.567Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M494.708 9.06193L496.049 11.7788L499.048 12.2155L496.879 14.33L497.391 17.3165L494.708 15.9068L492.026 17.3165L492.539 14.33L490.368 12.2155L493.367 11.7788L494.708 9.06193Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M193.544 13.1899L194.885 15.9068L197.884 16.3421L195.715 18.458L196.227 21.4445L193.544 20.0348L190.862 21.4445L191.375 18.458L189.204 16.3421L192.203 15.9068L193.544 13.1899Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M575.417 236.312L576.758 239.029L579.757 239.466L577.587 241.58L578.099 244.567L575.417 243.157L572.734 244.567L573.247 241.58L571.077 239.466L574.076 239.029L575.417 236.312Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M279.929 104L281.27 106.719L284.269 107.154L282.099 109.268L282.612 112.255L279.929 110.845L277.247 112.255L277.759 109.268L275.589 107.154L278.588 106.719L279.929 104Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M12.7743 48.0392L14.1152 50.7561L17.1129 51.1914L14.9436 53.3073L15.4561 56.2938L12.7743 54.8827L10.0911 56.2938L10.6036 53.3073L8.43431 51.1914L11.432 50.7561L12.7743 48.0392Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M70.6393 210.285L71.9802 213.003L74.9793 213.438L72.8086 215.553L73.3211 218.539L70.6393 217.13L67.9575 218.539L68.4686 215.553L66.2993 213.438L69.2984 213.003L70.6393 210.285Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M12.7743 256.54L13.6027 258.219L15.4561 258.487L14.1152 259.796L14.4311 261.641L12.7743 260.769L11.1161 261.641L11.432 259.796L10.0911 258.487L11.9445 258.219L12.7743 256.54Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M201.328 86.0701L202.157 87.7494L204.01 88.019L202.669 89.3262L202.986 91.1712L201.328 90.3006L199.67 91.1712L199.987 89.3262L198.646 88.019L200.5 87.7494L201.328 86.0701Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M340.637 235.338L341.467 237.017L343.319 237.287L341.978 238.594L342.295 240.44L340.637 239.568L338.98 240.44L339.296 238.594L337.955 237.287L339.808 237.017L340.637 235.338Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M27.3346 62.5981L28.1644 64.2774L30.0164 64.547L28.6755 65.8542L28.9928 67.7005L27.3346 66.8286L25.6764 67.7005L25.9937 65.8542L24.6528 64.547L26.5062 64.2774L27.3346 62.5981Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M499.048 211.862L499.876 213.541L501.73 213.811L500.389 215.118L500.706 216.964L499.048 216.092L497.391 216.964L497.707 215.118L496.366 213.811L498.22 213.541L499.048 211.862Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M81.8368 290.855L82.6666 292.536L84.52 292.804L83.1777 294.111L83.495 295.958L81.8368 295.086L80.18 295.958L80.4959 294.111L79.155 292.804L81.0084 292.536L81.8368 290.855Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M49.6539 201.229L50.4823 202.908L52.3357 203.177L50.9948 204.485L51.3121 206.33L49.6539 205.459L47.9957 206.33L48.313 204.485L46.9721 203.177L48.8255 202.908L49.6539 201.229Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M321.728 73.0613L322.558 74.7406L324.411 75.0088L323.069 76.316L323.386 78.1623L321.728 77.2904L320.071 78.1623L320.387 76.316L319.046 75.0088L320.9 74.7406L321.728 73.0613Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M505.614 290.855L506.442 292.536L508.295 292.804L506.954 294.111L507.27 295.958L505.614 295.086L503.955 295.958L504.273 294.111L502.932 292.804L504.785 292.536L505.614 290.855Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M588.884 130.581L589.712 132.26L591.566 132.53L590.225 133.837L590.542 135.683L588.884 134.811L587.227 135.683L587.543 133.837L586.202 132.53L588.056 132.26L588.884 130.581Z"
          fill="#DBDBDB"
        />
        <path
          className={classes.star}
          d="M515.689 128.03L516.518 129.709L518.371 129.979L517.03 131.286L517.347 133.132L515.689 132.26L514.031 133.132L514.348 131.286L513.007 129.979L514.861 129.709L515.689 128.03Z"
          fill="#DBDBDB"
        />
      </g>
      <path
        d="M461.956 54.4418C455.592 65.5635 443.604 73.0613 429.868 73.0613C409.43 73.0613 392.881 56.4988 392.881 36.0624C392.881 18.451 405.177 3.72081 421.669 0C409.779 5.46889 401.54 17.485 401.54 31.4177C401.54 50.5258 417.04 66.0114 436.147 66.0114C446.415 66.01 455.634 61.5479 461.956 54.4418Z"
        fill="#DBDBDB"
      />
      <g style={{ mixBlendMode: 'luminosity' }} opacity="0.5">
        <path
          d="M429.503 459.183H427.419V465.033H429.503V459.183Z"
          fill="#263238"
        />
        <path
          d="M328.959 459.183H326.876V465.033H328.959V459.183Z"
          fill="#263238"
        />
        <path
          d="M326.876 465.033L356.214 421.398L386.33 465.033H326.876Z"
          fill="#FFC727"
        />
        <path
          opacity="0.1"
          d="M326.876 465.033L356.214 421.398L386.33 465.033H326.876Z"
          fill="black"
        />
        <path
          d="M356.214 421.398H399.233L429.128 465.033H386.33L356.214 421.398Z"
          fill="#FFC727"
        />
        <path
          d="M374.55 429.338H394.212L400.194 438.067H380.575L374.55 429.338Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M346.573 465.033H365.076L356.214 421.398L346.573 465.033Z"
          fill="black"
        />
        <path
          d="M387.372 459.183H385.288V465.033H387.372V459.183Z"
          fill="#263238"
        />
        <path
          d="M356.214 421.398C349.108 435.572 340.873 451.156 333.181 465.033C340.3 450.85 348.518 435.265 356.214 421.398Z"
          fill="white"
        />
        <path
          d="M356.214 421.398C363.837 435.186 371.977 450.692 379.023 464.794C371.4 450.992 363.248 435.499 356.214 421.398Z"
          fill="white"
        />
        <path
          d="M346.573 465.033C346.573 465.033 345.24 458.849 338.85 453.404L356.214 421.398L346.573 465.033Z"
          fill="#FFC727"
        />
        <path
          d="M365.076 465.033L356.214 421.398L374.3 455.071C374.3 455.071 366.298 456.1 365.076 465.033Z"
          fill="#FFC727"
        />
      </g>
    </svg>
  );
}
