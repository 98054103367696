import { CssBaseline, ThemeProvider, StylesProvider } from 'vendor/material';
import { AuthProvider } from 'vendor/auth0';
import { BrowserRouter } from 'react-router-dom';
import { TackleAuthServiceProvider } from '@tackle-io/tackle-auth-tools';
import AuthorizedApolloProvider from 'components/AuthorizedApolloProvider/AuthorizedApolloProvider';
import defaultTheme from './Theme';
import CanvasApp from 'packages/salesforce-canvas/src/CanvasApp';

import { ThirdPartyServicesProvider, AmpliFeatureFlags } from 'boot';
import { Routes } from './boot/Routes';
import { AppLayout } from 'AppLayout';
import { createClassNameGenerator } from './utils/styles/createClassNameGenerator';

const environment = process.env.NODE_ENV;
const thirdPartyServicesEnabled =
  process.env.REACT_APP_THIRD_PARTY_SERVICES_ENABLED === 'true' || false;

const App = ({ initialEntries = [] }) => {
  const isSalesforceCanvasDomain =
    window.location.pathname.startsWith('/canvas');

  // Completely circumvent entire Tackle UI if the URL pathname starts with '/canvas'
  // <CanvasApp /> component is an isolated UI that renders components dynamically
  // based on context provided by the Salesforce app
  if (isSalesforceCanvasDomain) {
    return (
      <StylesProvider generateClassName={createClassNameGenerator(environment)}>
        <ThemeProvider theme={defaultTheme}>
          <CanvasApp />
        </ThemeProvider>
      </StylesProvider>
    );
  } else {
    return (
      <BrowserRouter initialEntries={initialEntries}>
        <StylesProvider
          generateClassName={createClassNameGenerator(environment)}
        >
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <TackleAuthServiceProvider
              authServiceUrl={process.env.REACT_APP_TKL_AUTH_SERVICE_URL}
            >
              <AuthProvider>
                <AmpliFeatureFlags>
                  <AuthorizedApolloProvider>
                    <ThirdPartyServicesProvider
                      enabled={thirdPartyServicesEnabled}
                    >
                      <AppLayout>
                        <Routes />
                      </AppLayout>
                    </ThirdPartyServicesProvider>
                  </AuthorizedApolloProvider>
                </AmpliFeatureFlags>
              </AuthProvider>
            </TackleAuthServiceProvider>
          </ThemeProvider>
        </StylesProvider>
      </BrowserRouter>
    );
  }
};

export default App;
