import { fontWeightBold } from 'utils/fontWeightConstants';
import { alpha, makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    padding: theme.spacing(1, 2),
    fontWeight: fontWeightBold,
    textTransform: 'none',
    transition: 'transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1)',
    '&:focus, &:active': {
      outline: 'none',
    },
    '&.noChildren': {
      minWidth: 0,
      padding: theme.spacing(1.25, 1.25),
    },
  },
  contained: {
    '&.primary': {
      color: '#fff',
      backgroundColor: theme.palette.BLUE400,
      boxShadow: '0 2px 2px -1px rgba(54, 97, 126, 0.3)',
      '&:hover': {
        backgroundColor: theme.palette.BLUE300,
        boxShadow:
          '0 4px 8px 0 rgba(54, 97, 126, 0.15), 0 2px 2px -1px rgba(54, 97, 126, 0.3)',
      },
      '&:active': {
        backgroundColor: theme.palette.BLUE500,
        boxShadow: '0 2px 2px -1px rgba(54, 97, 126, 0.3)',
      },
      '&:focus': {
        backgroundColor: theme.palette.BLUE400,
        boxShadow:
          '0 2px 2px -1px rgba(54, 97, 126, 0.3), 0px 0px 0px 3px rgba(1, 114, 203, 0.5)',
      },
    },
    '&.secondary': {
      color: theme.palette.NEUTRAL500,
      backgroundColor: '#EBECEE',
      boxShadow: '0 2px 2px -1px rgba(54, 97, 126, 0.3)',
      '&:hover': {
        backgroundColor: theme.palette.NEUTRAL030,
        boxShadow:
          '0 4px 8px 0 rgba(54, 97, 126, 0.15), 0 2px 2px -1px rgba(54, 97, 126, 0.3)',
      },
      '&:active': {
        backgroundColor: theme.palette.NEUTRAL040,
        boxShadow: '0 2px 2px -1px rgba(54, 97, 126, 0.3)',
      },
      '&:focus': {
        boxShadow:
          '0 2px 2px -1px rgba(54, 97, 126, 0.3), 0px 0px 0px 3px rgba(95, 115, 140, 0.3)',
      },
    },
    '&.destructive': {
      color: '#fff',
      backgroundColor: theme.palette.RED400,
      boxShadow: '0 2px 2px -1px rgba(123, 97, 96, 0.3)',
      '&:hover': {
        backgroundColor: theme.palette.RED300,
        boxShadow:
          '0 4px 8px 0 rgba(123, 97, 96, 0.15), 0 2px 2px -1px rgba(123, 97, 96, 0.3)',
      },
      '&:active': {
        backgroundColor: theme.palette.RED500,
        boxShadow: '0 2px 2px -1px rgba(123, 97, 96, 0.3)',
      },
      '&:focus': {
        backgroundColor: theme.palette.RED400,
        boxShadow:
          '0 2px 2px -1px rgba(123, 97, 96, 0.3), 0px 0px 0px 3px rgba(210, 28, 28, 0.5)',
      },
    },
    '&.success': {
      color: '#fff',
      backgroundColor: theme.palette.GREEN400,
      boxShadow: '0 2px 2px -1px rgba(39, 87, 83, 0.3)',
      '&:hover': {
        backgroundColor: theme.palette.GREEN300,
        boxShadow:
          '0 4px 8px 0 rgba(39, 87, 83, 0.15), 0 2px 2px -1px rgba(39, 87, 83, 0.3)',
      },
      '&:active': {
        backgroundColor: theme.palette.GREEN500,
        boxShadow: '0 2px 2px -1px rgba(39, 87, 83, 0.3)',
      },
      '&:focus': {
        backgroundColor: theme.palette.GREEN400,
        boxShadow:
          '0 2px 2px -1px rgba(39, 87, 83, 0.3), 0px 0px 0px 3px rgba(0, 169, 145, 0.5)',
      },
    },
    '&.white': {
      color: theme.palette.BLUE400,
      backgroundColor: theme.palette.NEUTRAL000,
      boxShadow: '0 2px 2px -1px rgba(54, 97, 126, 0.3)',
      '&:hover': {
        backgroundColor: theme.palette.NEUTRAL010,
        boxShadow:
          '0 4px 8px 0 rgba(54, 97, 126, 0.15), 0 2px 2px -1px rgba(54, 97, 126, 0.3)',
      },
      '&:active': {
        backgroundColor: theme.palette.NEUTRAL020,
        boxShadow: '0 2px 2px -1px rgba(54, 97, 126, 0.3)',
      },
    },
    '&.disabled': {
      color: theme.palette.NEUTRAL000,
      backgroundColor: theme.palette.NEUTRAL090,
      opacity: 0.75,
      boxShadow: 'none',
    },
    '&$disabled': {
      color: theme.palette.NEUTRAL000,
      backgroundColor: theme.palette.NEUTRAL090,
      opacity: 0.75,
      boxShadow: 'none',
    },
  },
  outlined: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: theme.spacing(0.875, 2),
    '&.noChildren': {
      minWidth: 0,
      padding: theme.spacing(1.125, 1.25),
    },
    '&.primary': {
      color: theme.palette.BLUE400,
      borderColor: theme.palette.NEUTRAL050,
      '&:hover': {
        backgroundColor: alpha(theme.palette.BLUE050, 0.5),
      },
      '&:focus': {
        boxShadow: '0px 0px 0px 3px rgba(1, 114, 203, 0.5)',
      },
    },
    '&.secondary': {
      color: theme.palette.NEUTRAL500,
      borderColor: theme.palette.NEUTRAL500,
      '&:hover': {
        backgroundColor: alpha(theme.palette.NEUTRAL030, 0.5),
      },
      '&:focus': {
        boxShadow: '0px 0px 0px 3px rgba(95, 115, 140, 0.3)',
      },
    },
    '&.destructive': {
      color: theme.palette.RED400,
      borderColor: theme.palette.RED400,
      '&:hover': {
        backgroundColor: alpha(theme.palette.RED050, 0.5),
      },
      '&:focus': {
        boxShadow: '0px 0px 0px 3px rgba(210, 28, 28, 0.5)',
      },
    },
    '&.success': {
      color: theme.palette.GREEN400,
      borderColor: theme.palette.GREEN400,
      '&:hover': {
        backgroundColor: alpha(theme.palette.GREEN050, 0.5),
      },
      '&:focus': {
        boxShadow: '0px 0px 0px 3px rgba(0, 169, 145, 0.5)',
        backgroundColor: 'transparent',
      },
    },
    '&.disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.NEUTRAL090,
      borderColor: theme.palette.NEUTRAL090,
    },
    '&$disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.NEUTRAL090,
      borderColor: theme.palette.NEUTRAL090,
      cursor: 'not-allowed',
    },
  },
  text: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: theme.spacing(1, 2),
    '&.primary': {
      color: theme.palette.BLUE400,
      '&:hover': {
        backgroundColor: alpha(theme.palette.BLUE050, 0.5),
      },
      '&:focus': {
        boxShadow: '0px 0px 0px 3px rgba(1, 114, 203, 0.5)',
        backgroundColor: 'transparent',
      },
    },
    '&.secondary': {
      color: theme.palette.NEUTRAL500,
      '&:hover': {
        backgroundColor: alpha(theme.palette.NEUTRAL030, 0.5),
      },
      '&:focus': {
        boxShadow: '0px 0px 0px 3px rgba(95, 115, 140, 0.3)',
      },
    },
    '&.destructive': {
      color: theme.palette.RED400,
      '&:hover': {
        backgroundColor: alpha(theme.palette.RED050, 0.5),
      },
      '&:focus': {
        boxShadow: '0px 0px 0px 3px rgba(210, 28, 28, 0.5)',
        backgroundColor: 'transparent',
      },
    },
    '&.success': {
      color: theme.palette.GREEN400,
      '&:hover': {
        backgroundColor: alpha(theme.palette.GREEN050, 0.5),
      },
      '&:focus': {
        boxShadow: '0px 0px 0px 3px rgba(0, 169, 145, 0.5)',
        backgroundColor: 'transparent',
      },
    },
    '&.disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.NEUTRAL090,
      borderColor: theme.palette.NEUTRAL090,
    },
    '&$disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.NEUTRAL090,
      borderColor: theme.palette.NEUTRAL090,
      cursor: 'not-allowed',
    },
  },
  disabled: {
    color: theme.palette.NEUTRAL000,
    backgroundColor: theme.palette.NEUTRAL060,
    opacity: 1,
    cursor: 'not-allowed',
  },
  buttonText: {
    '&.loading': {
      opacity: 0,
    },
    '&.withIcon': {
      opacity: 1,
    },
  },
  startIcon: {
    '&.noChildren': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  loadingSpinner: {
    position: 'absolute',
    display: 'inline-flex',
    '&.asIcon': {
      position: 'static',
    },
  },
  circularProgress: {
    color: theme.palette.BLUE400,
    '&.primary': {
      color: theme.palette.NEUTRAL000,
      '&.outlined, &.text': {
        color: theme.palette.BLUE400,
      },
    },
    '&.secondary': {
      color: theme.palette.NEUTRAL500,
    },
    '&.destructive': {
      color: theme.palette.NEUTRAL000,
      '&.outlined, &.text': {
        color: theme.palette.RED400,
      },
    },
    '&.success': {
      color: theme.palette.NEUTRAL000,
      '&.outlined, &.text': {
        color: theme.palette.GREEN400,
      },
    },
  },
  sizeSmall: {
    padding: theme.spacing(0.625, 1.5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(22),
  },
  sizeLarge: {
    padding: theme.spacing(1.625, 2.5),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(22),
  },
  outlinedSizeSmall: {
    padding: theme.spacing(0.5, 1.5),
  },
  outlinedSizeLarge: {
    padding: theme.spacing(1.5, 2.5),
  },
}));

export default useStyles;
