import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.NEUTRAL030,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    minWidth: '1024px',
  },
  headerColumnWrapper: {
    '&:not(:last-child)': {
      paddingRight: theme.spacing(1.5),
    },
    alignItems: 'center',
    display: 'flex',
  },
  headerText: {
    display: 'inline-flex',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 600,
    color: theme.palette.NEUTRAL900,
  },
  alignRight: {
    textAlign: 'right',
    display: 'block',
  },
  flexGrowSm: {
    flexGrow: 1,
    flexShrink: 1,
  },
  flexGrowMd: {
    flexGrow: 2,
    flexShrink: 2,
  },
  flexGrowLg: {
    flexGrow: 4,
    flexShrink: 4,
  },
  flexGrowCheckbox: {
    flexGrow: 0,
    flexShrink: 0,
  },
  fixedSyncStatus: {
    width: '78px',
  },
}));

export default useStyles;
