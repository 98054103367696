export enum OpportunityIndustryEnum {
  AEROSPACE = 'Aerospace',
  AGRICULTURE = 'Agriculture',
  AUTOMOTIVE = 'Automotive',
  COMPUTERS_ELECTRONICS = 'Computers & Electronics',
  CONSUMER_GOODS = 'Consumer Goods',
  EDUCATION = 'Education',
  FINANCIAL_SERVICES = 'Financial Services',
  GAMING = 'Gaming',
  GOVERNMENT = 'Government',
  HEALTHCARE = 'Healthcare',
  HOSPITALITY = 'Hospitality',
  LIFE_SCIENCES = 'Life Sciences',
  MANUFACTURING = 'Manufacturing',
  MARKETING_ADVERTISING = 'Marketing & Advertising',
  MEDIA_ENTERTAINMENT = 'Media & Entertainment',
  MINING = 'Mining',
  NON_PROFIT_ORGANIZATION = 'Non-Profit Organization',
  ENERGY_OIL_GAS = 'Energy - Oil & Gas',
  OTHER = 'Other',
  ENERGY_POWER_UTILITIES = 'Energy - Power & Utilities',
  PROFESSIONAL_SERVICES = 'Professional Services',
  REAL_ESTATE_CONSTRUCTION = 'Real Estate & Construction',
  RETAIL = 'Retail',
  SOFTWARE_INTERNET = 'Software & Internet',
  TELECOMMUNICATIONS = 'Telecommunications',
  TRANSPORTATION_LOGISTICS = 'Transportation & Logistics',
  TRAVEL = 'Travel',
  WHOLESALE_DISTRIBUTION = 'Wholesale & Distribution',
}
