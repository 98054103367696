import { Dimension, MetadataPricing } from '../../stores/privateOffers/typings';
import React from 'react';
import { Box } from 'vendor/material';
import { privateOffersUIElements } from '../../utils/pendoEnums';
import { pendoIdGenerator } from '../../utils/idGenerator';
import { useFormContext, useWatch } from 'react-hook-form';

interface AwsDimensionsUsedCountProps {
  awsMarketplacePricing?: MetadataPricing;
}

const AwsDimensionsUsedCount: React.FC<AwsDimensionsUsedCountProps> = ({
  awsMarketplacePricing,
}) => {
  const { control } = useFormContext();

  const formDimensions =
    (useWatch({
      control,
      name: 'pricing.dimensions',
    }) as Dimension[]) ?? [];

  const marketplaceDimensionApiNames = (
    (awsMarketplacePricing?.dimensions as Dimension[]) ?? []
  ).map(({ apiName }) => apiName);

  const newDimensionsCount = formDimensions.filter(
    ({ apiName: fieldApiName }) =>
      fieldApiName &&
      !marketplaceDimensionApiNames.some((apiName) => fieldApiName === apiName),
  ).length;

  return (
    <Box
      mb={2}
      data-intercom-target={pendoIdGenerator(
        privateOffersUIElements.PRIVATE_OFFERS_AWS_DIMENSIONS_COUNT,
      )}
    >
      Product dimensions used: {marketplaceDimensionApiNames.length}
      {newDimensionsCount > 0 && ` + ${newDimensionsCount} new`}
    </Box>
  );
};

export default AwsDimensionsUsedCount;
