// eslint-disable-next-line no-restricted-imports
import { createTheme } from '@mui/material/styles';
// eslint-disable-next-line no-restricted-imports
import { TypographyOptions } from '@mui/material/styles/createTypography';
// eslint-disable-next-line no-restricted-imports
import { Color as MuiColor } from '@mui/material';
import { themeObject } from 'Theme';

interface ExtendedTypographyOptions extends TypographyOptions {
  hero: React.CSSProperties;
  standardLabel: React.CSSProperties;
  smallLabel: React.CSSProperties;
}

interface Color extends MuiColor {
  1000: string;
}

declare module '@mui/material/styles' {
  // legacy colors
  interface Palette {
    BLUE050: string;
    BLUE075: string;
    BLUE100: string;
    BLUE200: string;
    BLUE300: string;
    BLUE400: string;
    BLUE500: string;
    GREEN050: string;
    GREEN075: string;
    GREEN100: string;
    GREEN200: string;
    GREEN300: string;
    GREEN400: string;
    GREEN500: string;
    NEUTRAL000: string;
    NEUTRAL010: string;
    NEUTRAL020: string;
    NEUTRAL030: string;
    NEUTRAL040: string;
    NEUTRAL050: string;
    NEUTRAL060: string;
    NEUTRAL070: string;
    NEUTRAL080: string;
    NEUTRAL090: string;
    NEUTRAL100: string;
    NEUTRAL200: string;
    NEUTRAL300: string;
    NEUTRAL400: string;
    NEUTRAL500: string;
    NEUTRAL600: string;
    NEUTRAL700: string;
    NEUTRAL800: string;
    NEUTRAL900: string;
    BLACK: string;
    PURPLE050: string;
    PURPLE075: string;
    PURPLE100: string;
    PURPLE200: string;
    PURPLE300: string;
    PURPLE400: string;
    PURPLE500: string;
    RED050: string;
    RED075: string;
    RED100: string;
    RED200: string;
    RED300: string;
    RED400: string;
    RED500: string;
    YELLOW050: string;
    YELLOW075: string;
    YELLOW100: string;
    YELLOW200: string;
    YELLOW300: string;
    YELLOW400: string;
    YELLOW500: string;
  }

  interface PaletteOptions {
    custom?: PaletteOptions['primary'];
  }

  export interface TypeText {
    hint: string;
  }
}

const red: Partial<Color> = {
  100: '#FFEBE5',
  200: '#FFBDAD',
  300: '#FF8F73',
  400: '#FF7452',
  500: '#FF5630',
  700: '#DE350B',
  900: '#BF2600',
};

const yellow: Partial<Color> = {
  100: '#FFFAE6',
  200: '#FFF0B3',
  300: '#FFE380',
  400: '#FFC400',
  500: '#FFAB00',
  700: '#FF991F',
  900: '#FF8B00',
};

const green: Partial<Color> = {
  100: '#E2FFEE',
  200: '#ABF5D1',
  300: '#79F2C0',
  400: '#57D9A3',
  500: '#36B37E',
  700: '#00875A',
  900: '#006644',
};

const blue: Partial<Color> = {
  100: '#DEEBFF',
  200: '#B3D4FF',
  300: '#4C9AFF',
  400: '#2684FF',
  500: '#0065FF',
  700: '#0052CC',
  900: '#0747A6',
};

const purple: Partial<Color> = {
  100: '#EAE6FF',
  200: '#C0B6F2',
  300: '#998DD9',
  400: '#8777D9',
  500: '#6554C0',
  700: '#5243AA',
  900: '#403294',
};

const teal: Partial<Color> = {
  900: '#063649',
  1000: '#032432',
};

const neutral: Partial<Color> = {
  100: '#F4F5F7',
  200: '#EBECF0',
  400: '#C1C7D0',
  500: '#97A0Af',
  600: '#7A869A',
  700: '#5E6C84',
  900: '#253858',
  1000: '#091E42',
};

const palette = {
  red,
  yellow,
  green,
  blue,
  purple,
  teal,
  neutral,
  white: '#FFFFFF',
  black: '000000',
};

const theme = createTheme({
  ...themeObject,
  palette: {
    ...themeObject.palette,
    ...palette,
    text: {
      primary: themeObject.palette.NEUTRAL900,
      secondary: themeObject.palette.NEUTRAL080,
      disabled: themeObject.palette.NEUTRAL050,
      hint: themeObject.palette.BLUE400,
    },
  },
  typography: {
    ...themeObject.typography,
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    hero: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '2.5rem',
      fontWeight: 300,
      lineHeight: '3.75rem',
      color: themeObject.palette.NEUTRAL900,
    },

    h1: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '2.25rem',
      fontWeight: 400,
      lineHeight: '3.375rem',
      color: themeObject.palette.NEUTRAL900,
    },
    h2: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: '3rem',
      color: themeObject.palette.NEUTRAL900,
    },
    h3: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '1.75rem',
      fontWeight: 500,
      lineHeight: '2.625rem',
      color: themeObject.palette.NEUTRAL900,
    },
    h4: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: '2.25rem',
      color: themeObject.palette.NEUTRAL900,
    },
    h5: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.875rem',
      color: themeObject.palette.NEUTRAL900,
    },
    body1: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
      color: themeObject.palette.NEUTRAL900,
    },
    body2: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
      color: themeObject.palette.NEUTRAL900,
    },
    button: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
      textTransform: 'none',
    },
    standardLabel: {
      color: themeObject.palette.NEUTRAL900,
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    smallLabel: {
      color: themeObject.palette.NEUTRAL900,
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: '1.25rem',
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          hero: 'h1',
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          button: 'span',
          standardLabel: 'h4',
          smallLabel: 'h5',
        },
      },
    },
  },
});

export default theme;
