import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '32px',
    width: '32px',
  },
  vendorProfile: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    borderTop: '1px solid #111432',
  },
  switchEnabled: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#111432',
    },
  },
  vendorProfileCollapsed: {
    justifyContent: 'center',
  },
  vendorProfileName: {
    color: theme.palette.NEUTRAL000,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
  },
  vendorProfileID: {
    color: theme.palette.NEUTRAL050,
    fontSize: theme.typography.pxToRem(12),
  },
  skeleton: {
    backgroundColor: theme.palette.NEUTRAL300,
  },
}));

export default useStyles;
