import React from 'react';
import { Box, Breakpoint, Grid, GridSize } from 'vendor/material';
import { toNumber } from 'lodash/fp';
import getCloudPricingFormat from 'utils/getCloudPricingFormat';
import { Cloud } from 'stores/typings';
import {
  Dimension,
  MetadataPricing,
  PaymentModelValue,
  PaymentModelValueType,
} from 'stores/privateOffers/typings';
import {
  allFormDimensionsEmpty,
  calcDimensionValue,
} from 'pages/PrivateOffers/utils/formatFormData';
import NameValuePair from './NameValuePair';
import DetailsSection from './DetailsSection';
import useStyles from './DimensionDetails.styles';
import { Cloud as CloudEnum } from 'utils/cloudTypes';
import { CurrencyCode, formatValueWithCurrencyPrefix } from '../utils/currency';

type GridVariantType = 'column' | 'row';

export interface DimensionDetailsProps {
  dimensions: Dimension[];
  cloud: Cloud;
  variant: GridVariantType;
  pricing?: MetadataPricing;
}

interface PricingVariables {
  paymentModel?: PaymentModelValueType;
  currencyCode?: CurrencyCode;
}

type FieldNameWithValue = { [fn: string]: string };

type DimensionMapper = (
  d: Dimension[],
  pv: PricingVariables,
) => FieldNameWithValue[];

enum Fields {
  DIMENSION = 'Dimension',
  API_NAME = 'Api name',
  QUANTITY = 'Quantity',
  PRICE_PER = 'Price per',
  TOTAL_PRICE = 'Total price',
  PLAN = 'Plan',
  SERVICE_LEVEL = 'Service level',
  TOTAL_CONTRACT_VALUE = 'Total contract value',
}

const columnBreakpoints: Partial<Record<Breakpoint, GridSize>> = {
  xs: 3,
  lg: 6,
};

const rowBreakpointsByField = {
  [Fields.DIMENSION]: 3,
  [Fields.API_NAME]: 3,
  [Fields.QUANTITY]: 2,
  [Fields.PRICE_PER]: 2,
  [Fields.TOTAL_PRICE]: 2,
  [Fields.PLAN]: 2,
  [Fields.SERVICE_LEVEL]: 2,
  [Fields.TOTAL_CONTRACT_VALUE]: 2,
};

const getRowBreakpointsForField = (
  fieldName: string,
): Partial<Record<Breakpoint, GridSize>> => {
  const breakpoint = rowBreakpointsByField[fieldName];
  return { xs: breakpoint, sm: breakpoint };
};

const getBreakpoints = (variant: GridVariantType, fieldName: string) =>
  variant === 'column'
    ? columnBreakpoints
    : getRowBreakpointsForField(fieldName);

const getPricePer = (
  d: Dimension,
  format: string,
  currencyCode: CurrencyCode = CurrencyCode.UnitedStatesDollar,
) => {
  const price = toNumber(d.price);
  return formatValueWithCurrencyPrefix(price, currencyCode, format);
};

const getTotalPrice = (
  d: Dimension,
  format: string,
  currencyCode: CurrencyCode = CurrencyCode.UnitedStatesDollar,
) => {
  const dimensionValue = calcDimensionValue(d);
  return formatValueWithCurrencyPrefix(dimensionValue, currencyCode, format);
};

const awsFormat = getCloudPricingFormat(CloudEnum.Aws);

const getAwsPriceFields = (d: Dimension, currencyCode: CurrencyCode) => ({
  [Fields.PRICE_PER]: getPricePer(d, awsFormat, currencyCode),
  [Fields.TOTAL_PRICE]: getTotalPrice(d, awsFormat, currencyCode),
});

const toAwsDimensionWithQuantity =
  (pv: PricingVariables) =>
  (d: Dimension): FieldNameWithValue => {
    const priceFields =
      pv.paymentModel === PaymentModelValue.PerProduct
        ? getAwsPriceFields(d, pv.currencyCode)
        : {};

    return {
      [Fields.DIMENSION]: d.name,
      [Fields.API_NAME]: d.apiName ?? '',
      [Fields.QUANTITY]: d.quantity,
      ...priceFields,
    };
  };

const toAwsDimensionWithoutQuantity =
  (pv: PricingVariables) =>
  (d: Dimension): FieldNameWithValue => {
    const priceFields =
      pv.paymentModel === PaymentModelValue.PerProduct
        ? { [Fields.PRICE_PER]: getPricePer(d, awsFormat, pv.currencyCode) }
        : {};

    return {
      [Fields.DIMENSION]: d.name,
      [Fields.API_NAME]: d.apiName ?? '',
      ...priceFields,
    };
  };

const formatAws = (
  dimensions: Dimension[],
  pv: PricingVariables,
): FieldNameWithValue[] => {
  // First dimension determines the shape
  const mapper = !!dimensions[0].quantity
    ? toAwsDimensionWithQuantity
    : toAwsDimensionWithoutQuantity;
  return dimensions.map(mapper(pv));
};

const azureFormat = getCloudPricingFormat(CloudEnum.Azure);

const toAzureDimensionWithQuantity = (d: Dimension) => ({
  [Fields.PLAN]: d.name,
  [Fields.QUANTITY]: d.quantity,
  '': '', // Placeholder
  [Fields.PRICE_PER]: getPricePer(d, azureFormat),
});

const toAzureDimensionWithoutQuantity = (d: Dimension) => ({
  [Fields.PLAN]: d.name,
  [Fields.PRICE_PER]: getPricePer(d, azureFormat),
});

const formatAzure = (dimensions: Dimension[]): FieldNameWithValue[] => {
  // First dimension determines the shape
  const mapper = !!dimensions[0].quantity
    ? toAzureDimensionWithQuantity
    : toAzureDimensionWithoutQuantity;
  return dimensions.map(mapper);
};

const gcpFormat = getCloudPricingFormat(CloudEnum.Gcp);

const toGcpDimension = (d: Dimension) => ({
  [Fields.SERVICE_LEVEL]: d.name,
  [Fields.TOTAL_CONTRACT_VALUE]: getTotalPrice(d, gcpFormat),
});

const formatGcp = (dimensions: Dimension[]): FieldNameWithValue[] =>
  dimensions.map(toGcpDimension);

const cloudMap: { [c: string]: DimensionMapper } = {
  aws: formatAws,
  azure: formatAzure,
  gcp: formatGcp,
};

const formatDimensions = (
  dimensions: Dimension[],
  cloud: Cloud,
  paymentVariables: PricingVariables,
): FieldNameWithValue[] => cloudMap[cloud](dimensions, paymentVariables);

const dimensionNameMap: Record<string, string> = {
  aws: Fields.DIMENSION,
  azure: Fields.PLAN,
  gcp: Fields.SERVICE_LEVEL,
};

const toDimensionRow =
  (variant: GridVariantType, classes: ReturnType<typeof useStyles>) =>
  ([fieldName, value]) => {
    const breakpoints = getBreakpoints(variant, fieldName);

    return (
      <Grid
        item
        key={fieldName}
        className={classes.item}
        zeroMinWidth
        {...breakpoints}
      >
        <NameValuePair name={fieldName} value={value} />
      </Grid>
    );
  };

const toDimensionGrid =
  (
    cloud: Cloud,
    variant: GridVariantType,
    classes: ReturnType<typeof useStyles>,
  ) =>
  (fieldNamesWithValues: FieldNameWithValue) =>
    (
      <Box
        key={fieldNamesWithValues[dimensionNameMap[cloud]]}
        className={classes.dimension}
      >
        <Grid container wrap="nowrap" spacing={1}>
          {Object.entries(fieldNamesWithValues).map(
            toDimensionRow(variant, classes),
          )}
        </Grid>
      </Box>
    );

// Displays dimensions for those order/offers that use the metadata pricing shape (aws, gcp, azure)
const DimensionDetails: React.FC<DimensionDetailsProps> = ({
  dimensions,
  cloud,
  variant,
  pricing,
}) => {
  const classes = useStyles({ variant });

  if (allFormDimensionsEmpty(dimensions) || !cloud) {
    return null;
  }

  const paymentVariables = {
    paymentModel: pricing?.paymentModel,
    currencyCode: pricing?.currencyCode as CurrencyCode,
  };
  const formattedDimensions = formatDimensions(
    dimensions,
    cloud,
    paymentVariables,
  );

  if (!formattedDimensions) {
    return null;
  }

  return (
    <DetailsSection title="Dimension details">
      {formattedDimensions.map(toDimensionGrid(cloud, variant, classes))}
    </DetailsSection>
  );
};

export default DimensionDetails;
