import { Dimension } from '../../../stores/privateOffers/typings';
import {
  AutocompleteRenderInputParams,
  InputLabel,
  TextField as MuiTextField,
  Typography,
} from 'vendor/material';
import { generateAwsDimensionApiName } from '../utils';
import { UseFormMethods } from 'react-hook-form/dist/types/form';
import React, { ChangeEvent, FocusEvent } from 'react';
import { useTextFieldStyles } from '@tackle-io/platform-ui';
import useStyles from './styles';
import FieldError from '../../../pages/PrivateOffers/components/FieldError/FieldError';

export interface DimensionNameOption {
  name: string;
  lowerCasedName: string;
  apiName: string;
}

const hasSameDimensionName =
  (dimensionNameInputValue: string) =>
  ({ name }: Dimension) =>
    name === dimensionNameInputValue;

const byLowerCasedDimensionName = (
  { lowerCasedName: n1 }: DimensionNameOption,
  { lowerCasedName: n2 }: DimensionNameOption,
) => n1.localeCompare(n2);

const toDimensionNameAndApiName = ({ name, apiName }: Dimension) => ({
  name,
  apiName,
});

export const getAvailableDimensionNameOptions = (
  marketplaceDimensionNameOptions: DimensionNameOption[],
  formDimensions: Dimension[],
  index: number,
) => {
  const formDimensionAtIndex = formDimensions.at(index);
  const usedDimensions = formDimensions.map(toDimensionNameAndApiName);

  const shouldIncludeDimensionNameOption = (v: DimensionNameOption) =>
    formDimensionAtIndex?.name === v.name ||
    !usedDimensions.find(
      ({ name, apiName }) => name === v.name && apiName === v.apiName,
    );

  return marketplaceDimensionNameOptions
    .filter(shouldIncludeDimensionNameOption)
    .sort(byLowerCasedDimensionName);
};

export const getOptionLabel = (option: DimensionNameOption | string) =>
  typeof option === 'string' ? option : option.name;

export const getFilteredOptions =
  (dimensionNameInputValue: string, formDimensions: Dimension[]) =>
  (dimensionNameOptions: DimensionNameOption[]) => {
    const lowerCasedDimensionNameInputValue =
      dimensionNameInputValue.toLowerCase();
    const dimensionNameInputValueMatchesFormDimension = formDimensions.some(
      hasSameDimensionName(dimensionNameInputValue),
    );

    const shouldIncludeDimensionNameOption = (o: DimensionNameOption) =>
      dimensionNameInputValueMatchesFormDimension ||
      o.lowerCasedName.includes(lowerCasedDimensionNameInputValue);

    const filteredDimensionNameOptions = dimensionNameOptions.filter(
      shouldIncludeDimensionNameOption,
    );

    const dimensionNameOptionMatchesDimensionNameInputValue = (
      o: DimensionNameOption,
    ) => o.lowerCasedName === lowerCasedDimensionNameInputValue;

    const showCreateNewDimensionOption =
      lowerCasedDimensionNameInputValue &&
      !filteredDimensionNameOptions.some(
        dimensionNameOptionMatchesDimensionNameInputValue,
      );

    return showCreateNewDimensionOption
      ? [dimensionNameInputValue, ...filteredDimensionNameOptions]
      : filteredDimensionNameOptions;
  };

export const getRenderOption =
  (
    marketplaceDimensionNameOptions: DimensionNameOption[],
    classes: ReturnType<typeof useStyles>,
  ) =>
  (option: DimensionNameOption | string) => {
    const marketplaceDimensionNameOption =
      typeof option === 'string'
        ? null
        : marketplaceDimensionNameOptions.find(
            ({ name, apiName }) =>
              name === option.name && apiName === option.apiName,
          );

    return marketplaceDimensionNameOption ? (
      <div>
        <Typography className={classes.dimensionNameTitle}>
          {marketplaceDimensionNameOption.name}
        </Typography>
        <div className={classes.dimensionNameApiNameSubtitle}>
          <Typography variant="caption">
            {marketplaceDimensionNameOption.apiName}
          </Typography>
        </div>
      </div>
    ) : (
      <div className={classes.createDimensionOption}>
        <span>{option}</span>
        <span> - Create dimension</span>
      </div>
    );
  };

type TextFieldChangeHandler = ({ target: { value } }) => void;

export const getOnTextFieldChanged =
  (setDimensionNameInput: (v: string) => void) =>
  ({ target: { value } }) => {
    setDimensionNameInput(value);
  };

export const getRenderInput =
  (
    textFieldStyles: ReturnType<typeof useTextFieldStyles>,
    onTextFieldChanged: TextFieldChangeHandler,
    isNewDimension: boolean,
    error: string,
  ) =>
  (params: AutocompleteRenderInputParams) =>
    (
      <>
        <InputLabel className={textFieldStyles.inputLabel} shrink>
          Dimension {isNewDimension && ' (new)'}
        </InputLabel>
        <MuiTextField
          {...params}
          variant="outlined"
          placeholder="Choose or create"
          onChange={onTextFieldChanged}
        />
        {error && <FieldError error={error} />}
      </>
    );

export const getOnChange =
  (
    setValue: UseFormMethods['setValue'],
    setSelectedDimensionNameOption: (o: DimensionNameOption) => void,
    setDimensionNameInputValue: (v: string) => void,
    index: number,
    formDimensionAtIndex?: Dimension,
  ) =>
  (
    _: ChangeEvent<DimensionNameOption | string>,
    option: DimensionNameOption | string,
  ) => {
    const selectedDimensionNameOption =
      typeof option === 'string'
        ? {
            name: option,
            lowerCasedName: option.toLowerCase(),
            apiName: generateAwsDimensionApiName(option),
          }
        : option;

    const mergedSelectedDimension: Dimension = {
      name: selectedDimensionNameOption.name,
      apiName: selectedDimensionNameOption.apiName,
      price: formDimensionAtIndex?.price ?? '0',
      quantity: formDimensionAtIndex?.quantity ?? '0',
    };

    setValue(`pricing.dimensions[${index}]`, mergedSelectedDimension);
    setSelectedDimensionNameOption(selectedDimensionNameOption);
    setDimensionNameInputValue(selectedDimensionNameOption.name);
  };

const createDimensionForName = (name: string): Dimension => ({
  name,
  apiName: generateAwsDimensionApiName(name),
  price: '0',
  quantity: '0',
});

export const getOnBlur =
  (
    selectedDimensionNameOption: DimensionNameOption,
    dimensionNameInputValue: string,
    setValue: UseFormMethods['setValue'],
    setSelectedDimensionNameOption: (v: DimensionNameOption) => void,
    setDimensionNameInputValue: (v: string) => void,
    index: number,
  ) =>
  (_: FocusEvent<HTMLDivElement>) => {
    if (
      selectedDimensionNameOption.name &&
      dimensionNameInputValue !== selectedDimensionNameOption.name
    ) {
      setDimensionNameInputValue(selectedDimensionNameOption.name);
      return;
    }

    if (!selectedDimensionNameOption.name && dimensionNameInputValue) {
      const newDimension = createDimensionForName(dimensionNameInputValue);
      const selectedDimensionNameOption = {
        name: newDimension.name,
        lowerCasedName: newDimension.name.toLowerCase(),
        apiName: newDimension.apiName,
      };

      setValue(`pricing.dimensions[${index}]`, newDimension);
      setSelectedDimensionNameOption(selectedDimensionNameOption);
    }
  };
