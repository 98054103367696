import { DateTime } from 'luxon';
import {
  flow,
  map,
  isObject,
  isArray,
  isNil,
  isUndefined,
  set,
  get,
  forEach,
  reject,
} from 'lodash/fp';

import {
  ConvenienceBooleans,
  ReducerState,
  BaseReducerState,
} from 'stores/typings';

import { toDateTime } from 'utils/dates';

export const convertValuesTo =
  (fn: (arg: string) => string, ignoreValuesByKey: string[] = []) =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: Record<string, any>[] | Record<string, any> | any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Record<string, any>[] | Record<string, any> | any => {
    if (isArray(obj)) {
      // Iterate over items in array
      return map(convertValuesTo(fn, ignoreValuesByKey), obj);
    }

    if (isObject(obj)) {
      // Rebuild object w/transformed key/value pairs
      // Ignore value if key is in 'ignoreValuesByKey'
      const tmp = {};
      Object.keys(obj).forEach((key: string) => {
        if (
          ignoreValuesByKey.includes(key) ||
          ignoreValuesByKey.includes(fn(key))
        ) {
          tmp[key] = obj[key];
        } else {
          tmp[key] = convertValuesTo(fn, ignoreValuesByKey)(obj[key]);
        }
      });

      return tmp;
    }

    // return converted item
    return fn(obj);
  };

export const convertKeysTo =
  (
    fn: (arg: string) => string,
    ignoreValuesByKey: string[] = [],
    ignoreKeys: string[] = [],
  ) =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: Record<string, any>[] | Record<string, any> | any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Record<string, any>[] | Record<string, any> | any => {
    if (isArray(obj)) {
      // Iterate over items in array
      return map(convertKeysTo(fn, ignoreValuesByKey, ignoreKeys), obj);
    }

    if (isObject(obj)) {
      // Rebuild object w/transformed key/value pairs
      // Ignore value if key is in 'ignoreValuesByKey'
      const shouldIgnoreValuesByKey = (key: string): boolean =>
        ignoreValuesByKey.includes(key) || ignoreValuesByKey.includes(fn(key));

      const shouldIgnoreKeys = (key: string): boolean =>
        ignoreKeys.includes(key) || ignoreKeys.includes(fn(key));

      const tmp = {};
      Object.keys(obj).forEach((key: string) => {
        if (shouldIgnoreValuesByKey(key) && shouldIgnoreKeys(key)) {
          tmp[key] = obj[key];
        } else if (shouldIgnoreValuesByKey(key)) {
          tmp[fn(key)] = obj[key];
        } else if (shouldIgnoreKeys(key)) {
          tmp[key] = convertKeysTo(fn, ignoreValuesByKey, ignoreKeys)(obj[key]);
        } else {
          tmp[fn(key)] = convertKeysTo(
            fn,
            ignoreValuesByKey,
            ignoreKeys,
          )(obj[key]);
        }
      });

      return tmp;
    }

    // return item
    return obj;
  };

export const stringToDateTime = (
  datetimeKeys: string[] = [],
  obj: {} = {},
): {} => {
  const kv = flow(
    map((path: string): [string, string | null | undefined] => [
      path,
      get(path, obj),
    ]),
    reject(([, value]): boolean => isUndefined(value)),
    map(([path, value]): [string, DateTime | null] => [
      path,
      isNil(value) ? null : toDateTime(value),
    ]),
  )(datetimeKeys);

  let newObj = obj;
  forEach(([path, value]) => {
    if (!isUndefined(get(path))) newObj = set(path, value, newObj);
  })(kv);

  return newObj;
};

export const dateTimeToString = (
  datetimeKeys: string[] = [],
  obj: {} = {},
): {} => {
  const kv = flow(
    map((path: string): [string, DateTime | null | undefined] => [
      path,
      get(path, obj),
    ]),
    reject(([, value]): boolean => isUndefined(value)),
    map(([path, value]): [string, string | null] => [
      path,
      isNil(value) ? null : toDateTime(value).toISO(),
    ]),
  )(datetimeKeys);

  let newObj = obj;
  forEach(([path, value]) => {
    newObj = set(path, value, newObj);
  })(kv);

  return newObj;
};

export const defaultState = (
  initialState: Partial<BaseReducerState> = {},
): ReducerState => {
  const state = {
    content: [],
    error: null,
    status: 'idle',
    dispatch: (): void => {},
    ...initialState,
  } as BaseReducerState;

  return withConvenienceBooleans(state) as ReducerState;
};

export const withConvenienceBooleans = (
  state: Partial<BaseReducerState>,
): Partial<BaseReducerState> & ConvenienceBooleans => ({
  ...state,
  isIdle: state.status === 'idle',
  isLoading: state.status === 'loading',
  isSyncing: state.status === 'syncing',
  hasValue: !isNil(state.content),
  hasError: !isNil(state.error),
});

const snakeCaseMatchRegex = /([_][a-z])/gi;
const stripUnderscore = (match: string): string =>
  match.toUpperCase().replace('_', '');

// lodash.camelCase removes special characters
export const camelCase = (str: string): string => {
  const firstCharLoweredStr = `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
  return firstCharLoweredStr.replace(snakeCaseMatchRegex, stripUnderscore);
};

// lodash.snakeCase removes special characters
export const snakeCase = (str: string): string =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
