import React from 'react';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';

export default function CanvasBulkCreateCoSell() {
  const { canvasSession } = useCanvasSession();

  return (
    <>
      <h1>Canvas Bulk Create Co Sell</h1>
      <p style={{ maxWidth: '100%' }}>
        <pre>{JSON.stringify(canvasSession, null, 2)}</pre>
      </p>
    </>
  );
}
