import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(6, 0, 3),
    textAlign: 'center',
  },
  subheader: {
    padding: theme.spacing(0, 0, 3),
    textAlign: 'center',
  },
}));

export default useStyles;
