const CalendarClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M9.45481 8.66699H10.5457V10.547L12.3203 11.487L11.7748 12.3537L9.45481 11.127V8.66699ZM12.3639 5.33366H2.18208V12.667H5.57845C5.26572 12.0603 5.09118 11.3803 5.09118 10.667C5.09118 9.42932 5.62754 8.24233 6.58227 7.36716C7.537 6.49199 8.83189 6.00033 10.1821 6.00033C10.9603 6.00033 11.7021 6.16033 12.3639 6.44699V5.33366ZM2.18208 14.0003C1.37481 14.0003 0.727539 13.4003 0.727539 12.667V3.33366C0.727539 2.59366 1.37481 2.00033 2.18208 2.00033H2.90936V0.666992H4.3639V2.00033H10.1821V0.666992H11.6366V2.00033H12.3639C12.7497 2.00033 13.1196 2.1408 13.3924 2.39085C13.6652 2.6409 13.8184 2.98004 13.8184 3.33366V7.40033C14.7203 8.24033 15.273 9.39366 15.273 10.667C15.273 11.9047 14.7366 13.0917 13.7819 13.9668C12.8272 14.842 11.5323 15.3337 10.1821 15.3337C8.79299 15.3337 7.53481 14.827 6.61845 14.0003H2.18208ZM10.1821 7.43366C9.24659 7.43366 8.34942 7.77431 7.68793 8.38068C7.02643 8.98705 6.65481 9.80946 6.65481 10.667C6.65481 12.4537 8.23299 13.9003 10.1821 13.9003C10.6453 13.9003 11.104 13.8167 11.5319 13.6542C11.9599 13.4917 12.3487 13.2535 12.6762 12.9533C13.0038 12.6531 13.2636 12.2966 13.4409 11.9043C13.6181 11.512 13.7094 11.0916 13.7094 10.667C13.7094 8.88033 12.1312 7.43366 10.1821 7.43366Z"
      fill="#253858"
    />
  </svg>
);
export default CalendarClockIcon;
