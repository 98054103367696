import React, { useState, useRef } from 'react';
import { Menu, Button, ButtonProps, MenuProps } from '@tackle-io/platform-ui';
import useStyles from './MenuButton.styles';
import classNames from 'classnames';

import MenuUp from 'mdi-material-ui/MenuUp';
import MenuDown from 'mdi-material-ui/MenuDown';

interface MenuButtonProps {
  items: MenuProps['items'];
  disabled?: ButtonProps['disabled'];
  onSelect: MenuProps['onSelect'];
  children: React.ReactNode;
  width?: string;
  buttonVariant?: ButtonProps['variant'];
  buttonClassName?: string;
}

function MenuButton({
  items,
  disabled,
  onSelect,
  children,
  width,
  buttonVariant = 'contained',
  buttonClassName,
}: MenuButtonProps) {
  const anchorRef = useRef();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (open) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const classes = useStyles({ width });
  const menuButtonClasses = classNames(classes.button, {
    [classes.customWidth]: !!width,
    [buttonClassName]: !!buttonClassName,
  });

  const handleSelect = (...args) => {
    onSelect.apply(null, args);
    handleClose();
  };

  return (
    <>
      <div ref={anchorRef}>
        <Button
          onClick={handleClick}
          endIcon={open ? <MenuUp /> : <MenuDown />}
          appearance="primary"
          variant={buttonVariant}
          disabled={disabled}
          className={menuButtonClasses}
        >
          {children}
        </Button>
      </div>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        onSelect={handleSelect}
        items={items}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </>
  );
}

export default MenuButton;
