import { makeStyles } from 'vendor/material';

// TODO remove in-favor of POA button styles
const useStyles = makeStyles((theme) => ({
  buttonPrimary: {
    color: theme.palette.BLUE400,
    marginBottom: 16,
  },
  buttonTextPrimary: {
    '&:hover': {
      backgroundColor: theme.palette.BLUE050,
    },
  },
  customDimensionsBanner: {
    marginBottom: theme.spacing(2),
  },
  totalPaymentsContainer: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
  },
  totalPayments: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1.5),
  },
  totalPaymentsLabel: {
    marginRight: '2rem',
    fontSize: theme.typography.pxToRem(13),
  },
  totalContractValue: {
    fontSize: theme.typography.pxToRem(13),
  },
  paymentScheduleBanner: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bottomMargin12px: {
    marginBottom: theme.spacing(1.5),
  },
}));

export default useStyles;
