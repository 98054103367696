import React from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';
import { useTheme } from 'vendor/material';

interface MobileRedirectorProps extends Omit<RedirectProps, 'to'> {
  desktopRoute: string;
  mobileRoute: string;
  from: string;
}

const MobileRedirector: React.FC<MobileRedirectorProps> = ({
  desktopRoute,
  mobileRoute,
  from,
  ...props
}) => {
  const theme: any = useTheme();
  const smallBreakpoint = theme.breakpoints.values.sm;
  const isDesktop = window.innerWidth > smallBreakpoint;
  const route = isDesktop ? desktopRoute : mobileRoute;
  return <Redirect {...props} from={from} to={route} />;
};

export default MobileRedirector;
