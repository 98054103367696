import { decorate, observable } from 'mobx';

class AzureEntitlement {
  plan = null;

  quantity = null;

  offer_id = '';

  subscription_name = '';

  subscription_id = '';

  constructor(props) {
    Object.assign(this, props);
  }
}

decorate(AzureEntitlement, {
  plan: observable,
  quantity: observable,
  offer_id: observable,
  subscription_name: observable,
  subscription_id: observable,
});

export default AzureEntitlement;
