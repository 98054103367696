import coSellClient from 'packages/cosell/api/coSellClient';
import type { AwsEnumListResponse } from 'packages/cosell/src/types/responses/AwsEnumResponse';
import { solutionsApiToFormFormatter } from 'packages/cosell/src/utilities/formatSolutions';
import { createContext, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSaasDocumentationRequiredToLaunchCheck } from 'packages/cosell/src/hooks/useSaasDocumentationRequiredToLaunchCheck';

export interface OpportunityContextProps {
  vendorId?: string;
  token?: string;
  solutionList?: Array<{ value: string; display: string }>;
  awsEnumListMap?: AwsEnumListResponse;
  authorizedCosellClient: ReturnType<typeof coSellClient>;
  opportunityId?: string;
  isSaasDocumentationRequiredToLaunch:
    | ReturnType<
        typeof useSaasDocumentationRequiredToLaunchCheck
      >['isSaasDocumentationRequiredToLaunch']
    | null;
}

export const OpportunityContext = createContext<
  Partial<OpportunityContextProps>
>({
  solutionList: undefined,
  opportunityId: undefined,
  awsEnumListMap: undefined,
  authorizedCosellClient: null,
  isSaasDocumentationRequiredToLaunch: null,
});

export interface OpportunityProviderProps {
  vendorId: string;
  token: string;
  opportunityId?: string;
  children?: React.ReactElement;
}

// TODO: move queryKeys to a queryKey dictionary
export const OpportunityProvider = ({
  vendorId,
  token,
  children,
  opportunityId,
}: OpportunityProviderProps) => {
  const authorizedCosellClient = coSellClient(token);

  const { data: solutionList } = useQuery({
    queryFn: () => authorizedCosellClient?.getVendorAceSolutions()!,
    enabled: !!authorizedCosellClient,
    select: (data) => solutionsApiToFormFormatter(data?.solutions),
    staleTime: Infinity,
  });

  const { data: awsEnumListMap } = useQuery({
    queryKey: ['aws-cosell-enum-options'],
    queryFn: () => authorizedCosellClient?.getAwsCoSellEnumOptions()!,
    enabled: !!authorizedCosellClient,
    // adjust this to a value that makes sense for this data
    staleTime: Infinity,
  });

  const {
    isSaasDocumentationRequiredToLaunch,
    // isCustomWorkflowLoading
  } = useSaasDocumentationRequiredToLaunchCheck({ token, vendorId });

  return (
    <OpportunityContext.Provider
      value={{
        vendorId,
        token,
        solutionList,
        awsEnumListMap,
        authorizedCosellClient,
        opportunityId,
        isSaasDocumentationRequiredToLaunch,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};

export function useOpportunity() {
  const context = useContext(OpportunityContext);
  if (context === undefined) {
    throw new Error('useOpportunity must be used within a OpportunityProvider');
  }
  return context;
}
