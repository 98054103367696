import React, { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TextField } from '@tackle-io/platform-ui';
import { OfferType } from '../../../../stores/privateOffers/typings';

interface FieldsCustomerBillingAccountNumberProps {
  offerType: OfferType;
}

const fieldName = 'customerBillingAccountNumber';
const label = 'Customer billing account number';
const partnerOfferLabel = 'Billing subaccount ID';
const helperText =
  '000000-000000-000000 with alphanumeric characters A-F and 0-9';
const partnerOfferHelperText =
  "ID of buyer's billing account provided by your reseller";

const FieldsCustomerBillingAccountNumber: React.FC<
  FieldsCustomerBillingAccountNumberProps
> = ({ offerType }) => {
  const { control, errors } = useFormContext();
  const customerBillingAccountNumber = useWatch({ name: fieldName, control });
  const defaultValue = customerBillingAccountNumber ?? '';

  const fieldLabel =
    offerType === OfferType.PartnerResale ? partnerOfferLabel : label;
  const fieldHelperText =
    offerType === OfferType.PartnerResale ? partnerOfferHelperText : helperText;

  const { message } = errors?.customerBillingAccountNumber ?? {};
  const formattedError = message?.replace(fieldName, fieldLabel);

  const textFieldRenderer = useCallback(
    ({ ref, ...props }) => (
      <TextField
        {...props}
        id={fieldName}
        label={`${fieldLabel} *`}
        helperText={fieldHelperText}
        error={formattedError}
        inputProps={{ ref }}
      />
    ),
    [formattedError, fieldLabel, fieldHelperText],
  );

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue}
      render={textFieldRenderer}
    />
  );
};

export default FieldsCustomerBillingAccountNumber;
