import { decorate, observable, computed } from 'mobx';
import { assign } from 'lodash-es';

import { toDateTime } from '../utils/dates';
import { awsMoneyToDinero } from '../utils/money';

class TransactionDisbursement {
  seller_rev_credit = 0;

  sellerrev = 0;

  aws_reffeerefund = 0;

  sellerussalestaxrefund = 0;

  sellerrevrefund = 0;

  sellerussalestax = 0;

  awsreffeecredit = 0;

  awsreffee = 0;

  awsreffeerefund = 0;

  net_amount = 0;

  _cost = 0;

  transaction_reference_id = '';

  customer_zip_code = '';

  customer_city = '';

  customer_state = '';

  customer_email_domain = '';

  payer_reference_id = '';

  tackle_subscription_change_id = '';

  solution_title = '';

  product = '';

  solution_id = '';

  report_date = null;

  constructor(props) {
    let { report_date = null, cost = 0 } = props;
    delete props.cost;
    report_date = toDateTime(report_date, 'Missing report_date');

    assign(this, props, { report_date, _cost: cost });
  }

  get sellerRevCredit() {
    return awsMoneyToDinero({ amount: this.seller_rev_credit });
  }

  get sellerRev() {
    return awsMoneyToDinero({ amount: this.sellerrev });
  }

  get sellerUSSalesTaxRefund() {
    return awsMoneyToDinero({ amount: this.sellerussalestaxrefund });
  }

  get sellerRevRefund() {
    return awsMoneyToDinero({ amount: this.sellerrevrefund });
  }

  get sellerUSSalesTax() {
    return awsMoneyToDinero({ amount: this.sellerussalestax });
  }

  get awsRefFeeCredit() {
    return awsMoneyToDinero({ amount: this.awsreffeecredit });
  }

  get awsRefFee() {
    return awsMoneyToDinero({ amount: this.awsreffee });
  }

  get awsRefFeeRefund() {
    if (this.aws_reffeerefund) {
      return awsMoneyToDinero({ amount: this.aws_reffeerefund });
    }

    return awsMoneyToDinero({ amount: this.awsreffeerefund });
  }

  get netAmount() {
    return awsMoneyToDinero({ amount: this.net_amount });
  }

  get cost() {
    return awsMoneyToDinero({ amount: this._cost });
  }
}

decorate(TransactionDisbursement, {
  seller_rev_credit: observable,
  sellerrev: observable,
  aws_reffeerefund: observable,
  sellerussalestaxrefund: observable,
  sellerrevrefund: observable,
  sellerussalestax: observable,
  awsreffeecredit: observable,
  awsreffee: observable,
  awsreffeerefund: observable,
  net_amount: observable,
  _cost: observable,
  transaction_reference_id: observable,
  customer_zip_code: observable,
  customer_city: observable,
  customer_state: observable,
  customer_email_domain: observable,
  payer_reference_id: observable,
  tackle_subscription_change_id: observable,
  solution_title: observable,
  product: observable,
  solution_id: observable,
  report_date: observable,
  sellerRevCredit: computed,
  sellerRev: computed,
  sellerUSSalesTaxRefund: computed,
  sellerRevRefund: computed,
  sellerUSSalesTax: computed,
  awsRefFeeCredit: computed,
  awsRefFee: computed,
  awsRefFeeRefund: computed,
  netAmount: computed,
  cost: computed,
});

export default TransactionDisbursement;
