import React from 'react';
import { makeStyles, Grid } from 'vendor/material';
import classNames from 'classnames';

const useStyles = makeStyles((theme: any) => ({
  padding: {
    padding: theme.spacing(1, 0.5, 0, 0),
  },
  title: {
    color: '#5e6c84',
    fontSize: theme.typography.pxToRem(11),
  },
  value: {
    fontSize: theme.typography.pxToRem(13),
  },
}));

interface PropsClasses {
  root: any;
  title: any;
  value: any;
}

interface DetailProps {
  title: string;
  value: string;
  classes: PropsClasses;
  titleProps: any;
  valueProps: any;
  disablePadding: boolean;
}

const Detail: React.FC<DetailProps> = ({
  title = '',
  value = '',
  classes: propsClasses = { root: null, title: null, value: null },
  titleProps,
  valueProps,
  disablePadding = false,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      direction="column"
      className={classNames(
        {
          [classes.padding]: !disablePadding,
        },
        propsClasses.root,
      )}
      key={title}
    >
      <Grid
        item
        className={classNames(classes.title, propsClasses.title)}
        {...titleProps}
      >
        {title}
      </Grid>
      <Grid
        item
        className={classNames(classes.value, propsClasses.value)}
        {...valueProps}
      >
        {value}
      </Grid>
    </Grid>
  );
};

export default Detail;
