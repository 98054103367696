import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@tackle-io/platform-ui';

const FieldsAwsAmiHourlyAnnualDuration: React.FC = () => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      control={control}
      name="pricing.duration"
      render={({ ref, ...props }): React.ReactElement => (
        <TextField
          {...props}
          label="Duration"
          id="pricing.duration"
          type="number"
          error={errors.pricing?.duration?.message}
          helperText="Number between 1 and 1095 days"
          inputRef={ref}
          inputProps={{ min: 1, max: 1095 }}
        />
      )}
    />
  );
};

export default FieldsAwsAmiHourlyAnnualDuration;
