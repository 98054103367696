import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import Route from './Route';
import { withAppData } from 'AppData';

import { useAuth } from 'vendor/auth0';

const withPrivateRedirect = (Component) => {
  function Wrapped(props) {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
      return <Component {...props} />;
    }

    return <RedirectToLogin />;
  }

  Wrapped.displayName = `withPrivateRedirect(${Component.displayName}`;

  return Wrapped;
};

const PrivateRoute = observer(({ component, ...args }) => {
  const ComponentToRender = withPrivateRedirect(withAppData(component));
  return <Route {...args} component={ComponentToRender} />;
});

PrivateRoute.displayName = 'PrivateRoute';

const RedirectToLogin = () => {
  const location = useLocation();
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {
          from: `${location.pathname}${location.search}${location.hash}`,
        },
      }}
    />
  );
};

export default PrivateRoute;
