import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  paginationDetails: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: `${theme.spacing(3)}px 0`,
  },
}));

export default useStyles;
