import { DateTime } from 'luxon';
import { toDateTime } from 'utils/dates';

import { ReducerState } from 'stores/typings';

// APIVersionJSON Example
// {
//   "gitCommit": "86825537886c3dad9af70bb1d08d8e410999ba5a",
//   "gitBranch": "development",
//   "buildNumber": "7039",
//   "buildVersion": "v7039-8682553",
//   "buildTimestamp": "2020-09-01T15:58:07Z"
// }

export interface APIVersionJSON {
  gitCommit: string;
  gitBranch: string;
  buildNumber: string;
  buildVersion: string;
  buildTimestamp: string;
}

export interface APIVersion extends Omit<APIVersionJSON, 'buildTimestamp'> {
  buildTimestamp: DateTime;
}

export const deserialize = (jsonVersion: APIVersionJSON): APIVersion => ({
  ...jsonVersion,
  buildTimestamp: jsonVersion?.buildTimestamp
    ? toDateTime(jsonVersion.buildTimestamp)
    : null,
});

export const serialize = (version: APIVersion): APIVersionJSON => ({
  ...version,
  buildTimestamp: version?.buildTimestamp
    ? version.buildTimestamp.toISO()
    : null,
});

export type APIReducerState = ReducerState & {
  content: APIVersion;
  status: ReducerState['status'];
  error: APIErrorResponse | ReducerState['error'];
};

export type APISuccessResponse = APIVersionJSON;
export interface APIErrorResponse {
  error: any;
}
export type APIResponse = APISuccessResponse | APIErrorResponse;
export function isAPISuccess(res: APIResponse): res is APISuccessResponse {
  return (res as APISuccessResponse).gitCommit !== undefined;
}
