import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  csvLink: {
    textDecoration: 'none',
  },
  exportButton: {
    textDecoration: 'none',
    color: theme.palette.NEUTRAL600,
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
