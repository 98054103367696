import { observer } from 'mobx-react-lite';
import { makeStyles } from 'vendor/material';

import NavTooltip from '../NavTooltip';

// HELPERS
import { AppEnvironment, getVendorEnvironmentLabel } from '../utils';
import { useEnvironmentColor } from '../hooks/useEnvironmentColor';

const useStyles = makeStyles(() => ({
  banner: {
    height: '8px',
  },
}));

const VendorEnvironmentSignal = ({
  activeEnvironment,
  loading,
}: {
  activeEnvironment?: AppEnvironment;
  loading: boolean;
}): JSX.Element => {
  const BANNER_TEXT = `${getVendorEnvironmentLabel({
    environment: activeEnvironment?.type,
    isShortLabel: false,
  })} environment`;
  const bgColor = useEnvironmentColor(activeEnvironment?.type);
  const classes = useStyles();

  return (
    <div>
      <NavTooltip text={BANNER_TEXT || ''} disabled={loading}>
        <div
          className={classes.banner}
          style={{ backgroundColor: bgColor }}
          role="presentation"
        >
          <span style={{ width: '100%' }} />
        </div>
      </NavTooltip>
    </div>
  );
};

export default observer(VendorEnvironmentSignal);
