import * as Yup from 'yup';
import {
  EMAIL_ERROR_MSG,
  PHONE_NUMBER_MSG,
  PHONE_NUMBER_REGEX,
  REQUIRED,
  WEBSITE_ERROR_MSG,
  WEBSITE_REGEX,
  UNITED_STATES_POSTAL_CODE_REGEX,
  UNITED_STATES_POSTAL_CODE_MSG,
} from '../FormValidationConstants';
import {
  AcePartnerNeedType,
  AceOpportunityTypeEnum,
  OpportunityIndustryEnum,
} from 'packages/cosell/src/types/enums';
import { OTHER } from '../FormFieldsConstants';
import { UNITED_STATES_COUNTRY_CODE } from './helpers';
import { MarketingSourceEnum } from 'packages/cosell/src/types/enums/MarketingSourceEnum';

export const aceOpportunityFormValidationSchema = Yup.object().shape({
  //  Section 1: Customer Details
  duns: Yup.string()
    .test('is 9 digits when provided', 'Must be 9 digits', (duns) => {
      return !duns || duns.length === 9;
    })
    .nullable(true),
  customerCompanyName: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .required(REQUIRED)
    .nullable(true),
  industry: Yup.string().required(REQUIRED).nullable(true),
  countryCode: Yup.string().required(REQUIRED).nullable(true),
  industryOther: Yup.string()
    .when('industry', {
      is: (industry) => industry === OpportunityIndustryEnum.OTHER,
      then: Yup.string(),
      otherwise: Yup.string(),
    })
    .nullable(true),
  nationalSecurity: Yup.string()
    .when(['industry', 'countryCode'], {
      is: (industry, countryCode) =>
        industry === OpportunityIndustryEnum.GOVERNMENT &&
        countryCode === UNITED_STATES_COUNTRY_CODE,
      then: Yup.string().required(REQUIRED),
    })
    .nullable(true),
  state: Yup.string()
    .max(255, 'Must be less than 255 characters.')
    .when('countryCode', {
      is: (countryCode) => countryCode === UNITED_STATES_COUNTRY_CODE,
      then: Yup.string().required(REQUIRED),
    })
    .nullable(true),
  postalCode: Yup.string()
    .when('countryCode', {
      is: (countryCode) => countryCode === UNITED_STATES_COUNTRY_CODE,
      then: Yup.string().matches(
        UNITED_STATES_POSTAL_CODE_REGEX,
        UNITED_STATES_POSTAL_CODE_MSG,
      ),
      otherwise: Yup.string()
        .min(1, 'Must be at least 1 character')
        .max(20, 'Must be less than 20 characters'),
    })
    .required(REQUIRED)
    .nullable(true),
  customerWebsite: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .matches(WEBSITE_REGEX, WEBSITE_ERROR_MSG)
    .required(REQUIRED)
    .nullable(true),
  // Section 2: Project details
  partnerNeedType: Yup.string().required(REQUIRED).nullable(true),
  primaryNeedsFromAws: Yup.array().when('partnerNeedType', {
    is: (partnerNeedType) =>
      partnerNeedType !==
      AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP,
    then: Yup.array().required(REQUIRED),
    otherwise: Yup.array().nullable(true),
  }),
  opportunityType: Yup.string().required(REQUIRED).nullable(true),
  parentOppId: Yup.string()
    .when('opportunityType', {
      is: (oppType) => oppType === AceOpportunityTypeEnum.FLAT_RENEWAL,
      then: Yup.string(),
      otherwise: Yup.string(),
    })
    .nullable(true),
  projectTitle: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .required(REQUIRED)
    .nullable(true),
  // Sales activities is required only when the partner needs support from AWS.
  salesActivities: Yup.array()
    .when('partnerNeedType', {
      is: (partnerNeedType) =>
        partnerNeedType !==
        AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP,
      then: Yup.array().required(REQUIRED),
      otherwise: Yup.array().nullable(true),
    })
    .nullable(true),
  customerBusinessProblem: Yup.string()
    .min(20, 'Must be at least 20 characters')
    .max(599, 'Must be less than 599 characters')
    .required(REQUIRED)
    .nullable(true),
  solutions: Yup.array().required(REQUIRED).nullable(true),
  otherSolutionDescription: Yup.string().when('solution', {
    is: (solution) => solution === OTHER,
    then: Yup.string().required(REQUIRED),
    otherwise: Yup.string().nullable(true),
  }),
  nextStep: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .nullable(true),
  customerUseCase: Yup.string().required(REQUIRED).nullable(true),
  deliveryModels: Yup.array().required(REQUIRED).nullable(true),
  expectedCustomerSpendAmount: Yup.number()
    .required(REQUIRED)
    .test('is larger than 0', 'Must be larger than $0.00', (monthlyRevenue) => {
      return monthlyRevenue > 0;
    })
    .nullable(true),
  expectedCustomerSpendCurrencyCode: Yup.string()
    .required(REQUIRED)
    .nullable(true),
  expectedCustomerSpendFrequency: Yup.string()
    .required(REQUIRED)
    .nullable(true),
  expectedCustomerSpendTargetCompany: Yup.string().required(REQUIRED),
  targetCloseDate: Yup.string()
    .test(
      'is future date',
      "Should be later than today's date",
      (selectedDate) => {
        const today = new Date();
        const selDate = new Date(selectedDate);
        return selDate > today;
      },
    )
    .required(REQUIRED)
    .nullable(true),
  campaignName: Yup.string().nullable(true),
  // Section 3: Marketing details
  marketingSource: Yup.string().required(REQUIRED).nullable(true),
  marketingActivityUseCases: Yup.string().nullable(true),
  marketingActivityChannel: Yup.array().nullable(true),
  isMarketingDevelopmentFunded: Yup.string()
    .when('marketingSource', {
      is: (marketingSource) =>
        marketingSource === MarketingSourceEnum.MARKETING_ACTIVITY,
      then: Yup.string().required(REQUIRED),
    })
    .nullable(true),
  // Section 4: Additional details
  competitiveTracking: Yup.array().nullable(true),
  awsAccountId: Yup.string()
    .test('is 12 digits when provided', 'Must be 12 digits', (awsAccountId) => {
      return !awsAccountId || awsAccountId.length === 12;
    })
    .nullable(true),
  additionalComments: Yup.string()
    .max(255, 'Must be less than 255 characters')
    .nullable(true),
  // Section 5: Customer contact
  customerFirstName: Yup.string()
    .max(80, 'Must be less than 80 characters')
    .nullable(true),
  customerLastName: Yup.string()
    .max(80, 'Must be less than 80 characters')
    .nullable(true),
  customerTitle: Yup.string()
    .max(80, 'Must be less than 80 characters')
    .nullable(true),
  customerEmail: Yup.string().email(EMAIL_ERROR_MSG).nullable(true),
  customerPhone: Yup.string()
    .matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_MSG)
    .nullable(true),
  // Section 6: Partner contact
  primaryContactFirstName: Yup.string()
    .max(80, 'Must be less than 80 characters')
    .nullable(true),
  primaryContactLastName: Yup.string()
    .max(80, 'Must be less than 80 characters')
    .nullable(true),
  primaryContactTitle: Yup.string()
    .max(80, 'Must be less than 80 characters')
    .nullable(true),
  primaryContactEmail: Yup.string().email(EMAIL_ERROR_MSG).nullable(true),
  primaryContactPhone: Yup.string()
    .matches(PHONE_NUMBER_REGEX, PHONE_NUMBER_MSG)
    .nullable(true),
});
