import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  toast: {
    borderRadius: 4,
    boxShadow: theme.depth.d300,
    padding: theme.spacing(2.5),
    '& > [role="alert"]': {
      margin: 0,
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
    },
  },
  toastSuccess: {
    backgroundColor: theme.palette.GREEN400,
    color: theme.palette.NEUTRAL000,
  },
  toastInfo: {
    backgroundColor: theme.palette.BLUE400,
    color: theme.palette.NEUTRAL000,
  },
  toastDanger: {
    backgroundColor: theme.palette.RED400,
    color: theme.palette.NEUTRAL000,
  },
  toastWarning: {
    color: theme.palette.NEUTRAL700,
    backgroundColor: theme.palette.YELLOW200,
  },
  toastNeutral: {
    backgroundColor: theme.palette.NEUTRAL070,
    color: theme.palette.NEUTRAL000,
  },
  toastContainer: {
    display: 'flex',
    lineHeight: theme.typography.pxToRem(24),
    alignItems: 'center',
  },
  circularLoading: {
    color: theme.palette.NEUTRAL000,
  },
  toastButtonSpan: {
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;
