import { DateTime } from 'luxon';

const getMidnightUTCDateTime = (
  offerExpirationAt: DateTime | string,
): DateTime => {
  const { year, month, day } = DateTime.isDateTime(offerExpirationAt)
    ? offerExpirationAt.toUTC()
    : DateTime.fromISO(offerExpirationAt).toUTC();

  return DateTime.utc(year, month, day);
};

export enum ExpiredState {
  NoExpiration = 'NoExpiration',
  NotExpired = 'NotExpired',
  Expired = 'Expired',
  Expiring = 'Expiring',
}

export enum ExpiredStateDisplay {
  NoExpiration = 'No Expiration',
  NotExpired = 'Not Expired',
  Expired = 'Expired',
  Expiring = 'Expiring',
}

type OfferExpiredResult = {
  isExpiring: boolean;
  isExpired: boolean;
  expirationState: ExpiredState;
  expirationText: ExpiredStateDisplay;
  expirationColorText: string;
  expirationColorName: string;
  expirationColorBorder: string;
  expirationColorBG: string;
};

const isOfferExpired = (offerExpirationAt): OfferExpiredResult => {
  let expirationText = ExpiredStateDisplay.NoExpiration;
  let expirationColorText = '';
  let expirationColorName = '';
  let expirationColorBorder = '';
  let expirationColorBG = '';
  let expirationState = ExpiredState.NoExpiration;

  if (offerExpirationAt) {
    const expirationDate = getMidnightUTCDateTime(offerExpirationAt);
    const daysExpired = DateTime.utc()
      .startOf('day')
      .diff(expirationDate, ['days'])
      .toObject().days;
    expirationText = ExpiredStateDisplay.NotExpired;
    expirationState = ExpiredState.NotExpired;

    if (daysExpired > 0) {
      expirationState = ExpiredState.Expired;
      expirationText = ExpiredStateDisplay.Expired;
      expirationColorText = '#DE350B';
      expirationColorName = 'red';
      expirationColorBorder = '#DE350B';
      expirationColorBG = '#FFEBE5';
    }

    if (daysExpired >= -5 && daysExpired <= 0) {
      expirationState = ExpiredState.Expiring;
      expirationText = ExpiredStateDisplay.Expiring;
      expirationColorText = '#FF5630';
      expirationColorName = 'orange';
      expirationColorBorder = '#FF8B00';
      expirationColorBG = '#FFF0B3';
    }
  }

  const isExpiring = [ExpiredState.Expiring, ExpiredState.Expired].includes(
    expirationState,
  );
  const isExpired = expirationState === ExpiredState.Expired;

  return {
    isExpiring,
    isExpired,
    expirationState,
    expirationText,
    expirationColorText,
    expirationColorName,
    expirationColorBorder,
    expirationColorBG,
  };
};

export default isOfferExpired;
