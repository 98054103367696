import { flatMap, uniq } from 'lodash-es';

// create and download a csv file given a byte array and report name
// as found in SO: https://stackoverflow.com/questions/35038884/download-file-from-bytes-in-javascript
export function arrayBufferToDownloadCSV(
  reportName: string,
  arrayBuffer: any,
): void {
  const blob = new Blob([arrayBuffer], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = reportName;
  link.download = fileName;
  link.click();
  link.remove();
}

const specialCharacters = ['=', '+', '@', '-'];

// escape special characters for csv download
export const prefixSpecialCharacters = (string: string): string => {
  const specialChar = specialCharacters.some((char) => string.startsWith(char));
  if (specialChar) {
    return `'${string}`;
  }
  return string;
};

// escape quotes & special characters for csv download
export const escapeString = (string: string): string => {
  const escaped = prefixSpecialCharacters(string);
  return escaped.replace(/"/g, '""');
};

export const escapeArray = (array: string[]): string[] => {
  return array.map((item) => {
    if (typeof item === 'undefined') {
      return '';
    }
    if (typeof item === 'string') {
      return escapeString(item);
    }
    return item;
  });
};

export const escapeObject = (object: any): object => {
  return Object.keys(object).reduce((acc, key) => {
    const value = object[key];
    const newKey = escapeString(key);
    acc[newKey] = typeof value === 'string' ? escapeString(value) : value;
    return acc;
  }, {});
};

export const escapeObjectArray = (array: object[]): object[] => {
  return array.map((item) => {
    if (typeof item === 'undefined') {
      return item;
    }
    if (typeof item === 'object') {
      return escapeObject(item);
    }
    return item;
  });
};

export const generateExportHeaders = (
  exportHeaders: string[] = [],
  data: object[],
): string[] => {
  const headers = flatMap(data, (item: object) => {
    const objHeaders = [];
    Object.keys(item).forEach((key) => objHeaders.push(key));
    return objHeaders;
  });
  return uniq(exportHeaders.concat(headers));
};
