import { AceSolutionResponse } from '../types/responses/AceSolutionResponse';

export const solutionsApiToFormFormatter = (
  solutions: AceSolutionResponse[],
) => {
  let solutionsToDisplay = [];
  if (!!solutions) {
    solutionsToDisplay = solutions?.map((s) => ({
      display: `${s.name}: ${s.cloudProviderId}`,
      value: s.cloudProviderId,
    }));
  }
  solutionsToDisplay.push({ display: 'Other', value: 'Other' });
  return solutionsToDisplay;
};
