export enum OpportunityStatusEnum {
  ACCEPTED = 'Accepted',
  ACTION_REQUIRED = 'Action required',
  CLOSED_ERROR = 'Closed - error',
  CLOSED_LOST = 'Closed - lost',
  CLOSED_WON = 'Closed - won',
  CLOUD_REVIEW = 'Cloud review',
  DECLINED = 'Declined',
  DRAFT = 'Draft',
  EXPIRED = 'Expired',
  INTERNAL_REVIEW = 'Internal review',
  SUBMITTING = 'Submitting',
}
