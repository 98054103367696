import { Cloud } from 'stores/typings';

export default function getCloudPricingFormat(cloud: Cloud): string {
  let format = '';
  switch (cloud) {
    case 'aws':
      format = '$0,0.000';
      break;
    case 'gcp':
      format = '$0,0.000000';
      break;
    case 'azure':
      format = '$0,0.00';
      break;
    case 'redhat':
      format = '$0,0.00';
  }
  return format;
}
