import { createContext, useContext } from 'react';
import {
  useCurrentUserVendorQuery,
  useContractsQuery,
  ContractsQuery,
} from 'generated/graphql';
import { useParams } from 'react-router-dom';

interface SingleContractContextProps {
  contract?: ContractsQuery['contracts']['hits']['hits'][number]['_source'];
  loading?: boolean;
  error?: any;
}

const SingleContractContext = createContext<SingleContractContextProps | null>(
  null,
);

export const SingleContractDataProvider: React.FC = ({ children }) => {
  const { data: currentUserData } = useCurrentUserVendorQuery({
    nextFetchPolicy: 'cache-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });
  const vendorId = currentUserData?.currentUser?.vendor?.id;
  const { contractId } = useParams<{ contractId: string }>();
  const id = `V#${vendorId}|Contract#${contractId}`;

  const { data, loading, error } = useContractsQuery({
    variables: {
      vendorId: vendorId,
      size: 1,
      query: {
        terms: {
          _id: [id],
        },
      },
    },
  });

  const contract = data?.contracts?.hits?.hits.length
    ? data?.contracts?.hits?.hits[0]._source
    : undefined;

  return (
    <SingleContractContext.Provider value={{ contract, loading, error }}>
      {children}
    </SingleContractContext.Provider>
  );
};

export const useSingleContractData = (): SingleContractContextProps => {
  const context = useContext(SingleContractContext);
  if (!context) {
    throw new Error(
      'useSingleContractData must be used within a SingleContractDataProvider',
    );
  }
  return context;
};
