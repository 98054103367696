import ky, { Options, AfterResponseHook } from 'ky';

import { authStore } from 'stores/Auth';

const NO_AUTH_HEADER = 'noAuth';

const omitAuthHeader = (): { headers: Options['headers'] } => ({
  headers: {
    [NO_AUTH_HEADER]: 'true',
  },
});

const legacyGetAuthToken = async () => authStore.getToken();

const makeApiClient = (getAccessToken) => {
  const handleUnauthorizedResponse: AfterResponseHook = (
    _,
    __,
    response: Response,
  ): Response => {
    if (response.status === 401) {
      authStore.logout();
      return response;
    }

    return response;
  };

  const kyApi = ky.extend({
    prefixUrl: process.env.REACT_APP_API_URL,
    timeout: 60000,
    hooks: {
      afterResponse: [handleUnauthorizedResponse],
      beforeRequest: [
        async (request) => {
          if (request.headers.get(NO_AUTH_HEADER)) {
            request.headers.delete(NO_AUTH_HEADER);
            return request;
          }
          const token = await getAccessToken();
          request.headers.set('Authorization', `Bearer ${token}`);
          return request;
        },
      ],
    },
  });

  const handler = {
    get(target, prop, receiver) {
      return function (input, options) {
        // encodedInput now encodes the path attached to the prefixUrl.
        // Firefox is unable to properly encode the pipe character which cause the
        // pre-flight OPTIONS request to fail due to missing CORS heads that are
        // required to interact with the API.
        // example: /vendor/users/auth0|12345 -> /vendor/users/auth0%7C12345
        const encodedInput = encodeURI(input);
        return target[prop].apply(this, [encodedInput, options]);
      };
    },
  };

  return new Proxy<typeof ky>(kyApi, handler);
};

const api = makeApiClient(legacyGetAuthToken);

export default api;
export { omitAuthHeader };
