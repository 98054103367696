import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.NEUTRAL800,
  },
  breadcrumbItem: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useStyles;
