import classNames from 'classnames';
import { makeStyles } from 'vendor/material';
import { useEnvironmentColor } from '../hooks/useEnvironmentColor';
import { getVendorEnvironmentLabel } from '../utils';
import { VendorEnvironmentEnum } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  environmentTag: {
    padding: '2px 5px',
    borderRadius: '3px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '17px',
    minWidth: '37px',
    textAlign: 'center',
  },
  lightText: {
    color: theme.palette.NEUTRAL000,
  },
  darkText: {
    color: theme.palette.TEAL900,
  },
}));

export const EnvironmentTag = ({
  environment,
  isShortLabel,
  className,
}: {
  environment: string;
  isShortLabel: boolean;
  className?: string;
}) => {
  const tagColor = useEnvironmentColor(environment);
  const classes = useStyles();

  const isLightText =
    environment === VendorEnvironmentEnum.PROD ||
    environment === VendorEnvironmentEnum.CUSTOMER_TEST ||
    environment === VendorEnvironmentEnum.DEV;

  const cssEnvironmentTag = classNames(classes.environmentTag, {
    [classes.lightText]: isLightText,
    [classes.darkText]: !isLightText,
    [className]: className,
  });

  return (
    <div
      className={cssEnvironmentTag}
      style={{
        backgroundColor: tagColor,
      }}
    >
      {getVendorEnvironmentLabel({ environment, isShortLabel })}
    </div>
  );
};
