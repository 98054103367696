import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: theme.typography.pxToRem(4),
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing(1.2)}px`,
  },
  cssOutlinedInput: {
    margin: theme.spacing(1, 0),
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
  },
  cssFocused: {},
  notchedOutline: {},
}));

export default useStyles;
