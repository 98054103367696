import { decorate, action, computed } from 'mobx';

import Store from './Store';
import { authStore } from './Auth';
import api from 'utils/api';
import * as monitor from 'utils/monitor';

import { User } from '../models';

import { storageKey as poStorageKey } from './privateOffers/context';

class UserStore extends Store {
  get user() {
    return super.data;
  }

  set user(user) {
    super.data = user;
  }

  load = () => {
    super.loading = true;

    // Skip loading cycle when authStore.token is not set
    if (!authStore.token) {
      super.skip = true;
      super.loading = false;
      return false;
    }

    return api
      .get('user/current')
      .json()
      .then((user) => {
        if (user) {
          this.user = new User({ ...user, profile: authStore.profile });

          monitor.setUserContext({
            id: this.user.id,
            name: this.user.name,
            email: this.user.email,
            metadata: this.user.metadata,
          });
        }

        const cacheKey = `${this.user.id}-${this.user.metadata.vendorid}`;
        if (localStorage.getItem('last-user') !== cacheKey) {
          localStorage.removeItem('contracts');
          localStorage.removeItem('disbursements');
          localStorage.removeItem('metering-records');
          localStorage.removeItem('products-aws');
          localStorage.removeItem('products-azure');
          localStorage.removeItem('products-gcp');
          localStorage.removeItem(poStorageKey);

          try {
            localStorage.setItem('last-user', cacheKey);
          } catch (e) {
            if (e.name !== 'QuotaExceededError') monitor.reportError(e);
          }
        }

        super.loading = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };

  get hasData() {
    return super.data || (!super.data && super.error);
  }

  removeUserContext = () => {
    monitor.removeUserContext();
  };
}

decorate(UserStore, {
  user: computed,
  load: action,
  removeUserContext: action,
});

export default UserStore;

const userStore = new UserStore();
export { userStore };
