interface LabelProps {
  label: string;
  required?: boolean;
}

export const Label = ({ label, required = false }: LabelProps): JSX.Element => {
  return (
    <div>
      {label}
      {required ? <span style={{ color: 'red' }}>&nbsp;*</span> : ''}
    </div>
  );
};
