import { observer } from 'mobx-react-lite';
import { AmpliFeatureFlagsProvider } from '@tackle-io/tackle-auth-tools';
import useAuthToken from 'hooks/useAuthToken';

interface FeatureFlagsProps {
  children: any;
}

const AmpliFeatureFlags = observer(({ children }: FeatureFlagsProps) => {
  const token = useAuthToken();

  return (
    <AmpliFeatureFlagsProvider
      accessToken={token}
      authServiceUrl={process.env.REACT_APP_TKL_AUTH_SERVICE_URL}
      ampliKey={process.env.REACT_APP_AMPLITUDE_DEPLOYMENT_KEY}
      serviceName="downstream-app"
      proxyServerUrl={process.env.REACT_APP_AMPLITUDE_SERVER_URL}
    >
      {children}
    </AmpliFeatureFlagsProvider>
  );
});

export { AmpliFeatureFlags };
