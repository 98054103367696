import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  item: (props: { variant: 'column' | 'row' }) =>
    props.variant === 'column'
      ? {
          flexGrow: 1,
          '&:nth-child(even)': {
            textAlign: 'right',
          },
          margin: theme.spacing(1, 0),
          overflowWrap: 'break-word',
        }
      : {
          overflowWrap: 'break-word',
        },

  dimension: {
    margin: theme.spacing(3, 0),
  },
}));

export default useStyles;
