import React from 'react';
import classNames from 'classnames';

import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  useTheme,
} from 'vendor/material';
import { isEqual } from 'lodash-es';
import { checksum } from '../utils/hashing';
import { formatDate } from '../utils/dates';

const DefaultStreamItem = ({
  Icon = null,
  date = null,
  client = null,
  secondary = null,
  onClick = null,
  status = 'success',
  color = null,
  theme,
  classes,
}) => (
  // If button=true, props intended for ButtonBase may be passed to ListItem
  // https://material-ui.com/api/list-item/#props
  <ListItem
    onClick={onClick}
    button={!!onClick}
    disableRipple
    classes={{
      root: classNames(classes.listItem, {
        [classes.listItemButton]: !!onClick,
      }),
    }}
  >
    {Icon ? (
      <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
        <Avatar
          classes={{ root: classes.avatarWrapper }}
          style={{ backgroundColor: color || theme.status[status] }}
        >
          <Icon className={classes.avatar} />
        </Avatar>
      </ListItemAvatar>
    ) : null}
    <ListItemText disableTypography>
      {date && client ? (
        <>
          <span
            className={classes.date}
            style={{ color: color || theme.status[status] }}
          >
            {formatDate(date)}
          </span>
          <span className={classes.separator}> - </span>
          <span className={classes.client}>{client}</span>
        </>
      ) : null}
      {secondary && (
        <>
          <br />
          <span className={classes.secondary}>{secondary}</span>
        </>
      )}
    </ListItemText>
  </ListItem>
);

const ActivityStream = ({
  header = null,
  headerComponent = 'h3',
  items = [],
  StreamItem = DefaultStreamItem,
  trailing = false,
  maxItems = -1,
  classes = {},
}) => {
  const theme = useTheme();

  const ActivityStreamListItem = (item, index) => {
    let key = checksum(`${new Date()}-trailing-${index}`);
    if (!!item.trailing && !!item.date) {
      key = checksum(`${item.date.toISODate()}-${item.client}-${item.type}`);
    }

    return <StreamItem theme={theme} classes={classes} key={key} {...item} />;
  };

  if (trailing && items.length > 0) items.push({ trailing });
  return (
    <section className={classes.root}>
      {header && (
        <Typography
          align="left"
          variant="h6"
          component={headerComponent}
          className={classes.header}
        >
          {header}
        </Typography>
      )}
      <List>{items.slice(0, maxItems).map(ActivityStreamListItem)}</List>
    </section>
  );
};

export default React.memo(ActivityStream, (prev, next) => isEqual(prev, next));
export { DefaultStreamItem as StreamItem };
