import React from 'react';

import AWSLogo from 'images/aws-logo';
import useStyles from './CoSellActivityFeedItem.styles';
import { toDateTime } from 'utils/dates';
import TackleLogoSymbol from 'images/tackle-logo-symbol';
import { Avatar } from 'vendor/material';

interface CoSellActivityFeedItemProps {
  source: string;
  displayName?: any;
  message: string;
  timestamp: string;
  picture_url: string;
}

enum SOURCE_ENUM {
  TACKLE = 'tackle',
  CLOUD_PARTNER = 'cloud_partner',
  USER = 'user',
}

const getLogo = (source: string, displayName: string, picture_url: string) => {
  const letter = displayName ? displayName[0] : '';

  switch (source) {
    case SOURCE_ENUM.TACKLE:
    case 'internalUpdateEvent':
      return <TackleLogoSymbol width="2.5em" height="2.5em" />;
    case SOURCE_ENUM.CLOUD_PARTNER:
    case 'cloudUpdateEvent':
      return <AWSLogo width={40} />;
    case SOURCE_ENUM.USER:
    case 'createOpportunity':
    case 'updateOpportunity':
    case 'updateSubmitOpportunity':
    case 'closeLostOpportunity':
    case 'launchOpportunity':
      // Should return Auth0 user profile picture here if relevant, placeholder for now
      return picture_url ? (
        <img src={picture_url} alt="Person Icon" />
      ) : (
        <Avatar>{letter}</Avatar>
      );
  }
};

const getTitle = (source, displayName) => {
  switch (source) {
    case 'tackle':
    case 'internalUpdateEvent':
      return 'Tackle';
    case 'cloud_partner':
    case 'cloudUpdateEvent':
      return 'Cloud Partner';
    case 'user':
    case 'createOpportunity':
    case 'updateOpportunity':
    case 'updateSubmitOpportunity':
    case 'closeLostOpportunity':
    case 'launchOpportunity':
      return displayName;
  }
};

export const CoSellActivityFeedItem = (props: CoSellActivityFeedItemProps) => {
  const classes = useStyles();

  return (
    <div className={classes.activityContainer}>
      <div className={classes.logoContainer}>
        {getLogo(props.source, props.displayName, props.picture_url)}
      </div>
      <div>
        <span className={classes.title}>
          {getTitle(props.source, props.displayName)}
        </span>
        <span className={classes.subTitle}>{props.message}</span>
        <span className={classes.dateTimestamp}>
          {toDateTime(props.timestamp)
            .toLocal()
            .toFormat('HH:mm | LLL dd, yyyy')}
        </span>
      </div>
    </div>
  );
};
