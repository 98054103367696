import { useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from 'vendor/auth0';

function useRenewAuth() {
  const { search, pathname } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const shouldRefresh = searchParams.get('renewAuth') === 'true';

  const [refreshing, setRefreshing] = useState(shouldRefresh);
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth();

  useEffect(() => {
    const updateAccessToken = async () => {
      searchParams.delete('renewAuth');
      const searchString = searchParams.toString();
      const url = searchString ? `${pathname}?${searchString}` : pathname;
      history.replace(url);

      await getAccessTokenSilently({ ignoreCache: true });
      setRefreshing(false);
    };

    if (shouldRefresh) {
      updateAccessToken();
    }
  }, [
    search,
    pathname,
    history,
    getAccessTokenSilently,
    shouldRefresh,
    searchParams,
  ]);

  return { refreshing };
}

export default useRenewAuth;
