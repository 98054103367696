import { useQuery } from '@tanstack/react-query';
import { getTackleOpportunityListByCrmId } from 'packages/salesforce-canvas/api/requests/getTackleOpportunityListByCrmId';

/** WIP: currently returns a summary of a single Tackle opportunity, which can be used to fetch the full opportunity */
export const useTackleOpportunityForCrmId = ({ crmId }: { crmId?: string }) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getTackleOpportunityListByCrmId({ crmId }),
    queryKey: [crmId],
    enabled: !!crmId,
    select: (data) => {
      /**
       *  As a stop-gap solution to get this working, we're returning the first opp in the list.
       *  There is a ticket created to handle multiple opportunities
       *  once a design decision is made.
       *
       */
      return data.opportunities?.[0] ?? null;
    },
  });

  return {
    data,
    isLoading,
  };
};
