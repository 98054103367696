import React from 'react';
import classNames from 'classnames';
import { TableSortLabel, TableCell } from 'vendor/material';

const TableHeadCell = ({
  id,
  label,
  order,
  orderColumn,
  hidden = false,
  sortable = false,
  numeric = false,
  disablePadding = false,
  classes,
  onSort,
}) => {
  if (!sortable) {
    return (
      <TableCell
        key={id}
        classes={{ root: classes.tableCellRoot }}
        padding={disablePadding ? 'none' : 'default'}
        align={numeric ? 'right' : 'left'}
        aria-hidden={hidden}
        style={{ visibility: hidden ? 'hidden' : 'visibile' }}
      >
        {label}
      </TableCell>
    );
  }

  const activeSort = orderColumn === id;
  const direction = order;
  return (
    <TableCell
      classes={{ root: classes.tableCellRoot }}
      key={id}
      align={numeric ? 'right' : 'left'}
      padding={disablePadding ? 'none' : 'default'}
      sortDirection={activeSort ? direction : false}
      aria-hidden={hidden}
      style={{ visibility: hidden ? 'hidden' : 'visibile' }}
    >
      <TableSortLabel
        active={activeSort}
        direction={direction}
        onClick={onSort(id)}
      >
        {label}
        {activeSort ? (
          <span
            className={classNames(classes.sortedLabel, classes.visuallyHidden)}
          >
            {direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};

export default TableHeadCell;
