import { decorate, observable } from 'mobx';
import { assign } from 'lodash-es';
import { toDateTime, infinity } from '../utils/dates';

class Entitlement {
  dimension = '';

  expiration = '';

  value = 0;

  status = null;

  constructor(props) {
    let { expiration = null } = props;
    expiration = toDateTime(expiration, infinity);

    assign(this, props, { expiration });
  }
}

decorate(Entitlement, {
  dimension: observable,
  expiration: observable,
  value: observable,
  status: observable,
});

export default Entitlement;
