import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
  },
  calendarContent: {
    marginBottom: theme.spacing(1.5),
  },
  calendarFooter: {
    padding: theme.spacing(1.5, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
  },
}));

export default useStyles;
