import { Tag } from '@tackle-io/platform-ui';
import React from 'react';
import { useStyles } from './OfferTag.styles';

interface OfferTagProps {
  offerType?: string;
}

const buildLabel = (offerType): string | undefined => {
  switch (offerType) {
    case 'public':
      return 'Public offer';
    case 'private':
      return 'Private offer';
    default:
      return '--';
  }
};

export const OfferTag: React.FC<OfferTagProps> = ({ offerType }) => {
  const classes = useStyles();

  if (!offerType) return <></>;

  return <Tag className={classes.offerTag}>{buildLabel(offerType)}</Tag>;
};
