import React from 'react';
import { Box, Typography, useTheme } from 'vendor/material';
import { Button } from '@tackle-io/platform-ui';
import { Form, Field } from 'react-final-form';
import { CheckCircle, CloseCircle } from 'mdi-material-ui';
import { useParams, Redirect, useHistory } from 'react-router-dom';

import usePasswordSession from './usePasswordSession';
import usePasswordCreate from './usePasswordCreate';

import EntryTemplate from '../components/EntryTemplate/EntryTemplate';
import TextField from '../components/TextField/TextField';

import useStyles from './CreatePassword.styles';
import { FormValues } from './CreatePassword.types';
import {
  validatePassword,
  PASSWORD_TEST_NAMES,
  PASSWORD_TESTS,
} from './CreatePassword.helpers';

type PathParams = {
  sessionId: string;
};

const CreatePassword: React.FC = () => {
  const history = useHistory();
  const { sessionId } = useParams<PathParams>();
  const {
    loading,
    error: sessionError,
    data,
  } = usePasswordSession({
    sessionId,
  });

  const handlePasswordCreated = (): void => {
    history.push('/login', {
      notificationMsg: 'Password successfully created.',
      notificationType: 'info',
    });
  };

  const {
    loading: createLoading,
    error: createError,
    onSubmit,
  } = usePasswordCreate({ onSuccess: handlePasswordCreated });

  const classes = useStyles();
  const theme = useTheme();
  const submitPassword = (values: FormValues): void => {
    onSubmit({
      password: values.password,
      sessionId,
    });
  };

  if (loading) {
    return null;
  }

  if (sessionError) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            notificationMsg: 'Invalid or expired session',
            notificationType: 'error',
            showContactSupport: true,
          },
        }}
      />
    );
  }

  if (data.passwordAlreadyCreated) {
    return <Redirect to="/login" />;
  }

  return (
    <Form<FormValues>
      onSubmit={submitPassword}
      validate={validatePassword}
      render={({ handleSubmit, invalid, errors }): React.ReactElement => (
        <EntryTemplate
          notificationMessage={
            createError ? 'Something went wrong. Try again' : null
          }
          notificationType="error"
        >
          <Box my={2.5} textAlign="center">
            <Typography component="p">
              Create a password for {data.email}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Field
              name="password"
              render={({ input, meta }): React.ReactElement => {
                return (
                  <TextField
                    placeholder="Password"
                    name="password"
                    type="password"
                    autoFocus
                    error={meta.touched ? meta.error : null}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                  />
                );
              }}
            />
            <Field
              name="confirmPassword"
              render={({ input, meta }): React.ReactElement => (
                <TextField
                  placeholder="Confirm password"
                  name="confirmPassword"
                  type="password"
                  error={meta.touched ? meta.error : null}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  onFocus={input.onFocus}
                />
              )}
            />
            <Box my={2.5}>
              <Button
                type="submit"
                appearance="primary"
                disabled={invalid}
                fullWidth
                loading={createLoading}
              >
                Create Password
              </Button>
            </Box>
          </form>
          <Box mb={2.5}>
            {PASSWORD_TEST_NAMES.map((testName) => {
              const Icon = errors.form.includes(testName) ? (
                <CloseCircle
                  fontSize="small"
                  htmlColor={theme.palette.RED300}
                />
              ) : (
                <CheckCircle
                  fontSize="small"
                  htmlColor={theme.palette.GREEN300}
                />
              );
              return (
                <Box
                  my={0.5}
                  key={testName}
                  display="flex"
                  alignContent="center"
                >
                  {Icon}
                  <Typography className={classes.validationRule}>
                    {PASSWORD_TESTS[testName].description}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </EntryTemplate>
      )}
    />
  );
};

export default CreatePassword;
