export enum AceOpportunityMarketingActivityUsecaseEnum {
  AI_ML = 'AI/ML',
  ANALYTICS = 'Analytics',
  APPLICATION_INTEGRATION = 'Application Integration',
  BLOCKCHAIN = 'Blockchain',
  BUSINESS_APPLICATIONS = 'Business Applications',
  CLOUD_FINANCIAL_MANAGEMENT = 'Cloud Financial Management',
  COMPUTE = 'Compute',
  CONTAINERS = 'Containers',
  CUSTOMER_ENGAGEMENT = 'Customer Engagement',
  DATABASES = 'Databases',
  DEVELOPER_TOOLS = 'Developer Tools',
  END_USER_COMPUTING = 'End User Computing',
  FRONT_END_WEB_MOBILE = 'Front End Web & Mobile',
  GAME_TECH = 'Game Tech',
  IOT = 'IoT',
  MANAGEMENT_GOVERNANCE = 'Management Governance',
  MEDIA_SERVICES = 'Media Services',
  MIGRATION_TRANSFER = 'Migration Transfer',
  NETWORKING_CONTENT_DELIVERY = 'Networking Content Delivery',
  QUANTUM_TECHNOLOGIES = 'Quantum Technologies',
  ROBOTICS = 'Robotics',
  SATELLITE = 'Satellite',
  SECURITY = 'Security',
  SERVERLESS = 'Serverless',
  STORAGE = 'Storage',
  VR_AR = 'VR & AR',
}
