import { UnifiedOpportunityFormShell } from '../UnifiedOpportunityFormShell';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import { convertAceFormValuesToCreateRequest } from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { aceOpportunityFormValidationSchema } from './aceOpportunityFormValidationSchema';
import { CreateAceOpportunityFormValues } from './AceOpportunityFormValues';
import { convertOpportunityResponseToAceFormValues } from 'packages/cosell/src/utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import { isEmpty } from 'lodash-es';
import {
  convertAceFormValuesToUpdateRequest,
  getSoftwareRevenue,
} from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import {
  getEditPageSubmitTackleOperationId,
  TackleOperationId,
} from 'packages/cosell/api/utils';
// import { getMockInitialValues } from 'packages/cosell/api/mockHelpers';
import { Loader } from '@tackle-io/platform-ui';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { COSELL_CREATE_DEFAULT_VALUES } from 'packages/cosell/src/utilities/constants/CoSellConstants';

interface AceOpportunityFormProps {
  opportunityId?: string;
}
const AceOpportunityForm = ({ opportunityId }: AceOpportunityFormProps) => {
  const { token, isSaasDocumentationRequiredToLaunch } = useOpportunity();
  const { aceOpportunityQuery, updateAceOpportunity, createAceOpportunity } =
    useAceOpportunity({ token, opportunityId });
  const opportunity = aceOpportunityQuery?.data;
  const isEdit = !!opportunityId;

  // const mockInitialValues = getMockInitialValues();

  const handleCreateOrUpdateOpportunity = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    if (isEdit) {
      const requiredSaasDocumentationToLaunchParams =
        isSaasDocumentationRequiredToLaunch({
          deliveryModels: opportunity.project?.deliveryModels,
        })
          ? { softwareRevenue: getSoftwareRevenue(values) }
          : null;

      const body = {
        ...convertAceFormValuesToUpdateRequest(values),
        ...requiredSaasDocumentationToLaunchParams,
      };
      const tackleOperationId = getEditPageSubmitTackleOperationId(
        opportunity?.lifeCycle?.reviewStatus,
      );
      return updateAceOpportunity.mutateAsync({
        requestBody: body,
        tackleOperationId,
      });
    } else {
      return createAceOpportunity.mutateAsync({
        requestBody: convertAceFormValuesToCreateRequest(values),
        tackleOperationId: TackleOperationId.CREATE_OPPORTUNITY,
      });
    }
  };

  const handleSaveAceOpportunityAsDraft = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    const requestBody = convertAceFormValuesToCreateRequest(values);
    return createAceOpportunity.mutateAsync({
      requestBody,
      tackleOperationId: TackleOperationId.CREATE_DRAFT_OPPORTUNITY,
    });
  };
  const flattenedAceFormValues =
    !isEmpty(opportunity) &&
    convertOpportunityResponseToAceFormValues(opportunity);

  if (createAceOpportunity.isLoading || updateAceOpportunity.isLoading) {
    return <Loader />;
  }

  const emptyStateInitialValues = {
    /** mockInitialValues should only be used in development.
     * enable if you want to pre-fill a form without having to fill all the fields. */
    // ...mockInitialValues,
    ...COSELL_CREATE_DEFAULT_VALUES,
  };

  return (
    <UnifiedOpportunityFormShell
      opportunityId={opportunityId}
      opportunity={opportunity}
      onSubmit={handleCreateOrUpdateOpportunity}
      onSaveAsDraft={handleSaveAceOpportunityAsDraft}
      initialValues={
        flattenedAceFormValues
          ? flattenedAceFormValues
          : emptyStateInitialValues
      }
      provider={DisplayCloudType.AWS}
      validationSchema={aceOpportunityFormValidationSchema}
    >
      <AceOpportunityFormFields />
    </UnifiedOpportunityFormShell>
  );
};

export default AceOpportunityForm;
