import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    color: theme.palette.NEUTRAL500,
  },
  usageDimensionFields: {
    marginBottom: 16,
  },
}));

export default useStyles;
