import { ErrorBoundary, Page } from 'components';
import useStyles from './CoSellLandingPage.styles';
import { useDocumentTitle } from 'hooks';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { CoSellHeader, CoSellCloudMenu, CoSellTabs } from './components';
import { DisplayCloudType } from '../../types/enums';

const CoSellLandingPage = () => {
  const classes = useStyles();

  useDocumentTitle('Co-Sell opportunities');

  return (
    <CoSellLandingPageContainer>
      <Page className={classes.container}>
        <CoSellHeader />
        <CoSellCloudMenu />
        <CoSellTabs />
      </Page>
    </CoSellLandingPageContainer>
  );
};

interface CoSellLandingPageContainerProps {
  vendorIdFromCanvas?: string;
  tokenFromCanvas?: string;
  opportunityId?: string;
  cloud?: DisplayCloudType;
  children?: React.ReactElement;
}

export const CoSellLandingPageContainer = ({
  children,
}: CoSellLandingPageContainerProps) => {
  return (
    <ErrorBoundary
      renderError={() => <ErrorPage title="Co-sell opportunities" />}
    >
      {children}
    </ErrorBoundary>
  );
};

export default CoSellLandingPage;
