import { decorate, observable } from 'mobx';
import { assign } from 'lodash-es';

import Order from './Order';
import { toDateTime } from '../utils/dates';

class GCPOrder extends Order {
  provider = 'GCP';

  gcp_state = '';

  tkl_state = 'ACCOUNT_INVALID';

  // TODO GCPEntitlements
  entitlements = [];

  registration_dt = '';

  constructor(props) {
    let { registration_dt = null } = props;
    registration_dt = toDateTime(registration_dt, 'Missing registration date');
    super(assign(props, { registration_dt }));
  }
}

decorate(GCPOrder, {
  provider: observable,
  gcp_state: observable,
  tkl_state: observable,
  entitlements: observable,
  registration_dt: observable,
});

export default GCPOrder;
