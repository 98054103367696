import { decorate, action, computed } from 'mobx';
import { flow, map, sortBy, filter, includes, find } from 'lodash/fp';

import Store from './Store';
import api from 'utils/api';

import { User } from '../models';

import { ampli } from 'utils/analytics/ampli';

const buildUsers = flow(
  sortBy('email'),
  map((user) => new User(user)),
);

class UserManagementStore extends Store {
  inviteUsers = (invites = []) => {
    return new Promise((resolve, reject) => {
      api
        .post('v1/vendor/users', {
          json: { invites },
        })
        .json()
        .then(({ users = [], errors = [] }) => {
          if (errors?.length > 0) {
            super.loading = false;

            super.error = errors;
            const comparisonErrorMessage =
              'Email is already assigned to user of another vendor.';
            const organizationalErrorMessage =
              'Cannot add user to vendor - User exists in another organization';
            const userExistsOnDifferentVendorError = errors.find(
              (error) =>
                error.message === comparisonErrorMessage ||
                error.message === organizationalErrorMessage,
            );
            const errorEmail = userExistsOnDifferentVendorError.email;

            resolve(
              `${errorEmail} is already assigned to user of another vendor.`,
            );
          }
          this.users = buildUsers(users);
          (this.users || [])
            .filter((user) =>
              (invites || [])
                .map((invite) => invite.email)
                .includes(user.email),
            )
            .map((user) =>
              ampli.userInvited({
                user_id: user.id,
                user_role: user.metadata.role,
                vendor_id: user.metadata.vendorid,
              }),
            );
          resolve();
        })
        .catch((error) => {
          super.error = error;
          reject(error);
        });
    });
  };

  get users() {
    return super.data;
  }

  set users(users) {
    super.data = users;
  }

  load = () => {
    super.loading = true;

    api
      .get('v1/vendor/users')
      .json()
      .then(({ users = [] }) => {
        this.users = buildUsers(users);
        super.loading = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };

  getAllByUserIds(userIds = []) {
    const filterByUserIds = filter(({ id }) => includes(id, userIds));
    return filterByUserIds(this.users);
  }

  getByUserId(id) {
    return find({ id }, this.users);
  }
}

decorate(UserManagementStore, {
  users: computed,
  load: action,
  inviteUsers: action,
  getAllByUserIds: action,
  getByUserId: action,
});

export default UserManagementStore;

const userManagementStore = new UserManagementStore();

export { userManagementStore };
