import { any, curry, reject } from 'lodash/fp';

import {
  Activity,
  OfferType,
  PrivateOffer,
} from 'stores/privateOffers/typings';
import { OfferStatus } from 'pages/PrivateOffers/components/WidgetMarketplaceAction/WidgetMarketplaceAction.helpers';
import { ActivitySlug } from './constants';
import { Cloud } from 'utils/cloudTypes';
import { Product } from 'stores/products/typings';
import { ListingType } from 'utils/listingTypes';
import { Pricing } from 'utils/pricingTypes';

export enum MarketplaceAction {
  CreatePrivateOfferSaas = 'create_private_offer_saas',
  CreatePrivateOfferSaasReplacementOffer = 'create_private_offer_saas_replacement_offer',
  UpdatePrivateOfferChangeExpiration = 'update_private_offer_change_expiration',
  UpdatePrivateOfferCancelOffer = 'update_private_offer_cancel_offer',
  CreatePartnerOfferSaas = 'create_partner_offer_saas',
}

export enum TackleAction {
  SendPurchaseInstructions = 'send_purchase_instructions',
  CloneOffer = 'clone_offer',
}

export type OfferAction = MarketplaceAction | TackleAction;

interface CloudToOfferTypeToSupportedMarketplaceActions
  extends Record<Cloud, Record<OfferType, OfferAction[]>> {}

export const cloudOfferTypeToSupportedActions = {
  [Cloud.Aws]: {
    [OfferType.Direct]: [
      // assumes saas product
      // ami listings are not currently supported through auto-create
      MarketplaceAction.CreatePrivateOfferSaas,
      MarketplaceAction.CreatePrivateOfferSaasReplacementOffer,
      MarketplaceAction.UpdatePrivateOfferChangeExpiration,
      MarketplaceAction.UpdatePrivateOfferCancelOffer,
      TackleAction.SendPurchaseInstructions,
      TackleAction.CloneOffer,
    ],
    [OfferType.PartnerResale]: [
      MarketplaceAction.CreatePartnerOfferSaas,
      TackleAction.CloneOffer,
    ],
  },
  [Cloud.Azure]: {
    [OfferType.Direct]: [
      // allowed for following when pricing version Pricing.AzurePricingLongTermSaasV1
      MarketplaceAction.CreatePrivateOfferSaas,
      TackleAction.SendPurchaseInstructions,
      TackleAction.CloneOffer,
    ],
    [OfferType.PartnerResale]: [
      // allowed for following when pricing version Pricing.AzurePricingLongTermSaasV1
      MarketplaceAction.CreatePartnerOfferSaas,
      TackleAction.CloneOffer,
    ],
  },
  [Cloud.Gcp]: {
    [OfferType.Direct]: [
      // allowed for following when pricing version Pricing.GcpMarketplacePricingV1
      MarketplaceAction.CreatePrivateOfferSaas,
      TackleAction.SendPurchaseInstructions,
      TackleAction.CloneOffer,
    ],
    [OfferType.PartnerResale]: [],
  },
} as CloudToOfferTypeToSupportedMarketplaceActions;

const slugToStatusMap: Record<Activity['slug'], OfferStatus> = {
  [ActivitySlug.VendorCreatedMarketplaceOfferSuccess]: 'completed',
  [ActivitySlug.VendorCreatedMarketplaceOfferFailed]: 'failed',
  [ActivitySlug.VendorCreatedMarketplaceOfferPending]: 'waiting',
  [ActivitySlug.VendorSentPurchaseInstructions]: 'sent',
  [ActivitySlug.VendorCreatedPrivateOffer]: 'create',
  [ActivitySlug.BuyerAcceptedPrivateOffer]: 'accepted',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationPending]: 'updating',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess]: 'completed',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed]: 'completed',
  [ActivitySlug.VendorCancelledMarketplaceOfferPending]: 'cancelling',
  [ActivitySlug.VendorCancelledMarketplaceOfferSuccess]: 'cancelled',
  [ActivitySlug.VendorCancelledMarketplaceOfferFailed]: 'completed',
};

function isAutoCreateActivity(activity: Activity): boolean {
  return !!slugToStatusMap[activity.slug];
}

const isAcceptedOffer = (activities: Activity[]): boolean => {
  if (activities.length === 0) return false;

  return any({ slug: ActivitySlug.BuyerAcceptedPrivateOffer }, activities);
};

const isCancelledOffer = (activities: Activity[]): boolean => {
  if (activities.length === 0) return false;

  return any(
    { slug: ActivitySlug.VendorCancelledMarketplaceOfferSuccess },
    activities,
  );
};

export function getLatestActivity(activities: Activity[]): Activity | null {
  if (activities.length === 0) return null;

  const sortedActivies = activities.sort((a, b) =>
    a.createdAt > b.createdAt ? -1 : 1,
  );

  return sortedActivies[0];
}
/**
 * Gets status for <WidgetAutoCreate />
 * 1. If there has been no activity and no offer id exists we display the 'create' state
 * 2. If a private offer has an offer id, we display the 'completed' state because the
 * private offer must have been created in order to get an offer id.  This is true whether
 * it was created by the widget or entered manually.
 * 3. Otherwise we display the latest marketplace status, such as 'pending', 'updating',
 * 'failed' or 'completed'
 * @param activities private offer activities
 * @param offerRef private offer id
 * @returns OfferCreationStatus
 *
 */
function getAutoCreateStatus(
  activities: Activity[],
  offerRef?: string,
): OfferStatus {
  const autoCreateActivities = activities.filter(isAutoCreateActivity);
  if (isAcceptedOffer(autoCreateActivities)) {
    return 'accepted';
  }

  if (isCancelledOffer(autoCreateActivities)) {
    return 'cancelled';
  }

  const latestAutoCreateActivity = getLatestActivity(autoCreateActivities);

  if (
    latestAutoCreateActivity?.slug ===
    ActivitySlug.VendorChangedMarketplaceOfferExpirationPending
  ) {
    return 'updating';
  }

  if (
    latestAutoCreateActivity?.slug ===
    ActivitySlug.VendorCancelledMarketplaceOfferPending
  ) {
    return 'cancelling';
  }

  const activitiesWithoutUpdatingActivities = reject(({ slug }: Activity) =>
    [
      ActivitySlug.VendorChangedMarketplaceOfferExpirationPending,
      ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed,
      ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess,
    ].includes(slug as ActivitySlug),
  )(autoCreateActivities);

  const latestNonUpdatingActivity = getLatestActivity(
    activitiesWithoutUpdatingActivities,
  );

  // create is default status
  let status: OfferStatus = 'create';

  // if a valid status exists, set it to that
  if (latestNonUpdatingActivity) {
    status = slugToStatusMap[latestNonUpdatingActivity.slug];
  }

  const isMarketplaceOfferCreatedStatus =
    status === 'completed' || status === 'sent';

  // Sometimes we know an offer must have been created in the marketplace (because we have an offer id)
  // but we are missing an activity that would indicate it's been created.  In these cases we will default
  // to a 'completed' status.
  if (offerRef && !isMarketplaceOfferCreatedStatus) {
    status = 'completed';
  }

  return status;
}

/**
 * Helper function to determine if an auto-creatable private offer
 * is created in the marketplace or pending creation.
 * @param activities private offer activities
 * @returns boolean
 */
const isPendingOrSuccessfulAutoCreate = (
  activities: PrivateOffer['activities'] = [],
): boolean =>
  any(
    { slug: ActivitySlug.VendorCreatedMarketplaceOfferSuccess },
    activities,
  ) ||
  getLatestActivity(activities)?.slug ===
    ActivitySlug.VendorCreatedMarketplaceOfferPending;

export {
  getAutoCreateStatus,
  isPendingOrSuccessfulAutoCreate,
  isAcceptedOffer,
  isCancelledOffer,
};

const autoCreateEnabledPricingVersions = [
  Pricing.SimplePricingV1Aws,
  Pricing.AzurePricingLongTermSaasV1,
  Pricing.AzureMarketplacePricingV1,
  Pricing.GcpMarketplacePricingV1,
];

/**
 * Checks if auto-create is enabled for a private offer based on pricing version
 * @param offer
 * @returns boolean
 */
export const isAutoCreateEnabled = (offer: PrivateOffer) => {
  const isGcpPartnerOffer =
    offer?.cloud === Cloud.Gcp && offer?.offerType === OfferType.PartnerResale;

  // aws ami listings are not currently auto-createable; they have a different pricing version
  return (
    !isGcpPartnerOffer &&
    autoCreateEnabledPricingVersions.includes(
      offer?.metadata?.pricing?.version as Pricing,
    )
  );
};

const awsAutoCreateListingTypes = [ListingType.Saas, ListingType.Ccp];

const productIsNotAutoCreateReadyPredicate = () => false;
const productIsAutoCreateReadyPredicate = () => true;

const isAutoCreateReadyProductPredicateByCloud = {
  [Cloud.Aws]: ({ product }) => isAutoCreateReadyAWSListing(product),
  [Cloud.Azure]: productIsAutoCreateReadyPredicate,
  [Cloud.Gcp]: productIsAutoCreateReadyPredicate,
};

const isValidAutoCreateReadyCloudListing = (
  cloud: Cloud,
  product: Product,
): boolean => {
  const isAutoCreateReadyProductPredicate =
    isAutoCreateReadyProductPredicateByCloud[cloud] ||
    productIsNotAutoCreateReadyPredicate;

  return isAutoCreateReadyProductPredicate({ product });
};

export const isAutoCreateReadyListing = curry(
  (includedClouds: Cloud[], product: Product): boolean => {
    const cloud = product.cloud as Cloud;
    const isAutoCreateReadyCloud = includedClouds.includes(cloud);

    return (
      isAutoCreateReadyCloud &&
      isValidAutoCreateReadyCloudListing(cloud, product)
    );
  },
);

export const isAutoCreateReadyAWSListing = (product: Product) =>
  product.cloud === Cloud.Aws &&
  !!product.encryptedProductid &&
  awsAutoCreateListingTypes.includes(product.listingType as ListingType);
