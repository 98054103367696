import type { UpdateAceOpportunityFormValues } from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import { AcePartnerNeedType } from '../../types/enums';
import { MarketingSourceEnum } from '../../types/enums/MarketingSourceEnum';
import {
  AceOpportunityResponse,
  Address,
  Contact,
  Customer,
  CustomerAccount,
  LifeCycle,
  Marketing,
  Project,
  SoftwareRevenue,
} from '../../types/responses/AceOpportunityResponse';

const getPartnerNeedType = (response): AcePartnerNeedType =>
  response?.primaryNeedsFromAws
    ? AcePartnerNeedType.CO_SELL_WITH_AWS
    : AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP;

export const convertOpportunityResponseToAceFormValues = (
  response: AceOpportunityResponse,
): UpdateAceOpportunityFormValues => {
  const getAddressValues = (address: Address) => ({
    city: address?.city,
    countryCode: address?.countryCode,
    postalCode: address?.postalCode,
    state: address?.stateOrRegion,
    address: address?.streetAddress,
  });

  const getAccountValues = (account: CustomerAccount) => ({
    duns: account?.duns,
    awsAccountId: account?.awsAccountId,
    customerCompanyName: account?.companyName,
    industry: account?.industry,
    industryOther: account?.otherIndustry,
    customerWebsite: account?.websiteUrl,
    ...getAddressValues(account?.address),
  });

  const getContactValues = (contact: Contact) => ({
    customerFirstName: contact?.firstName,
    customerLastName: contact?.lastName,
    customerEmail: contact?.email,
    customerPhone: contact?.phone ? contact?.phone.replace('+', '') : undefined,
    customerTitle: contact?.businessTitle,
  });

  const getCustomerDetails = (customer: Customer) => ({
    ...getAccountValues(customer?.account),
    ...getContactValues(customer?.contact),
  });

  const getLifeCycleDetails = (lifeCycle: LifeCycle) => ({
    nextStep: lifeCycle?.nextSteps,
    targetCloseDate: lifeCycle?.targetCloseDate,
  });

  const getMarketingDetails = (marketing: Marketing) => ({
    isMarketingDevelopmentFunded: marketing?.awsFundingUsed,
    campaignName: marketing?.campaignName,
    marketingActivityChannel: marketing?.channels,
    marketingSource:
      (marketing?.source as MarketingSourceEnum) || MarketingSourceEnum.NONE,
    marketingActivityUseCases: marketing?.useCases,
  });

  const getProjectDetails = (
    project: Project,
  ): Pick<
    UpdateAceOpportunityFormValues,
    | 'additionalComments'
    | 'apnPrograms'
    | 'competitiveTracking'
    | 'customerBusinessProblem'
    | 'customerUseCase'
    | 'deliveryModels'
    | 'expectedCustomerSpendAmount'
    | 'expectedCustomerSpendCurrencyCode'
    | 'expectedCustomerSpendFrequency'
    | 'expectedCustomerSpendTargetCompany'
    | 'otherCompetitorNames'
    | 'salesActivities'
    | 'projectTitle'
    | 'otherSolutionDescription'
  > => ({
    additionalComments: project?.additionalComments,
    apnPrograms: project?.apnPrograms,
    competitiveTracking: project?.competitorName,
    customerBusinessProblem: project?.customerBusinessProblem,
    customerUseCase: project?.customerUseCase,
    deliveryModels: project?.deliveryModels,
    expectedCustomerSpendAmount:
      project?.expectedCustomerSpend?.[0]?.amount ?? null,
    expectedCustomerSpendCurrencyCode:
      project?.expectedCustomerSpend?.[0]?.currencyCode ?? null,
    expectedCustomerSpendFrequency:
      project?.expectedCustomerSpend?.[0]?.frequency ?? null,
    expectedCustomerSpendTargetCompany:
      project?.expectedCustomerSpend?.[0]?.targetCompany ?? null,
    otherCompetitorNames: project?.otherCompetitorNames,
    salesActivities: project?.salesActivities,
    projectTitle: project?.title,
    otherSolutionDescription: project?.otherSolutionDescription,
  });

  const getSoftwareRevenueDetails = (softwareRevenue: SoftwareRevenue) => ({
    deliveryModel: softwareRevenue?.deliveryModel,
    customerSoftwareValue: softwareRevenue?.value?.amount,
    currencyCode: softwareRevenue?.value?.currencyCode,
    effectiveDate: softwareRevenue?.effectiveDate,
    expirationDate: softwareRevenue?.expirationDate,
  });

  const getPartnerOpportunityTeamDetails = (team: Contact[]) => {
    if (!team || team.length === 0) {
      return {};
    }
    const primaryContact = team[0];
    if (!primaryContact) {
      return {};
    }
    return {
      primaryContactEmail: primaryContact?.email,
      primaryContactFirstName: primaryContact?.firstName,
      primaryContactLastName: primaryContact?.lastName,
      primaryContactPhone: primaryContact?.phone
        ? primaryContact?.phone.replace('+', '')
        : null,
      primaryContactTitle: primaryContact?.businessTitle,
    };
  };

  return {
    ...getCustomerDetails(response?.customer),
    ...getProjectDetails(response?.project),
    ...getLifeCycleDetails(response?.lifeCycle),
    ...getMarketingDetails(response?.marketing),
    ...getPartnerOpportunityTeamDetails(response?.opportunityTeam),
    ...getSoftwareRevenueDetails(response?.softwareRevenue),
    nationalSecurity: response?.nationalSecurity,
    partnerNeedType: getPartnerNeedType(response),
    opportunityType: response?.opportunityType,
    primaryNeedsFromAws: response?.primaryNeedsFromAws,
    awsProducts: response?.awsProducts,
    solutions: response?.solutions,
    stage: response?.lifeCycle?.stage,
  };
};
