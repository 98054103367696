const uppercaseRegex = /^[A-Z]+$/;
const marketplaceNames = [
  'AWS Marketplace',
  'Microsoft commercial marketplace',
  'Google Cloud Marketplace',
];
const isAcronym = (text: string) =>
  text.split('').every((char) => uppercaseRegex.test(char));
const isMarketplaceName = (text: string) =>
  marketplaceNames.includes(text.trim());

export const formatToSentenceCase = (text?: string) => {
  // See https://tackle.atlassian.net/wiki/spaces/GROWTH/pages/1060372540/GROWTH-31+Brand+consistency+among+all+text+within+the+product#Exceptions-for-Book-Case-or-Uppercase
  // Format text from "Book Case" to "Sentence case"
  // E.g. AWS Ref Fee Credit -> AWS ref fee credit
  if (!text) {
    return '';
  }
  // Exceptions
  if (isMarketplaceName(text)) {
    return text;
  }
  const textArray = text.split(' ');
  const formattedTextArray = textArray.map((word, index) => {
    if (isAcronym(word)) {
      return word;
    } else if (index === 0) {
      // Capitalize only first word in array
      const firstLetter = word[0].toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return `${firstLetter}${restOfWord}`;
    } else {
      // Lowercase all other words
      return word.toLowerCase();
    }
  });
  return formattedTextArray.join(' ');
};
