import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  fieldKey: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  fieldValue: {
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  fieldAction: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttonIcon: {
    color: theme.palette.NEUTRAL500,
  },
  dimensionFields: {
    marginBottom: 16,
  },
  durationText: {
    color: theme.palette.NEUTRAL300,
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  deleteButton: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(1.75),
  },
  formLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: theme.spacing(0.75),
  },
  dimensionNameTitle: {
    fontWeight: 500,
  },
  dimensionNameApiNameSubtitle: {
    color: theme.palette.NEUTRAL300,
  },
  createDimensionOption: {
    margin: '-6px -16px',
    padding: '6px 16px 6px 13px',
    borderLeft: '0.20rem solid',
    borderLeftColor: theme.palette.BLUE400,
  },
}));

export default useStyles;
