import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
  },
}));

export default useStyles;
