import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme, ClickAwayListener, useMediaQuery } from 'vendor/material';
import { find, truncate } from 'lodash/fp';
import { Dropdown, ActionList, ActionListOption } from '@tackle-io/platform-ui';

const FilterContractStatus: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const screen1280 = useMediaQuery(theme.breakpoints.between(901, 1280));
  const truncateLabel = truncate({ length: screen1280 ? 15 : 30 });

  const searchParams = new URLSearchParams(history.location.search);
  const contractStatus = searchParams.get('contractStatus');

  const options: ActionListOption[] = [
    {
      key: 'active',
      text: 'Active',
      isSelected: contractStatus === 'active',
    },
    {
      key: 'expiring',
      text: 'Expiring',
      isSelected: contractStatus === 'expiring',
    },
    {
      key: 'expired',
      text: 'Expired',
      isSelected: contractStatus === 'expired',
    },
  ];

  options.unshift({
    key: '',
    text: 'All',
    isSelected: false,
  });

  const selectedOption: ActionListOption | undefined = find(
    'isSelected',
    options,
  );

  // EVENTS
  const [anchorEl, setAnchorEl] = React.useState<Element>(null);
  const open = !!anchorEl;
  const handleClose = (): void => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent): void =>
    setAnchorEl(event.currentTarget);

  const handleChange = (item: ActionListOption): void => {
    if (contractStatus !== item.key) {
      searchParams.set('contractStatus', item.key);
      history.push({ search: searchParams.toString() });
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Dropdown
            value={selectedOption && truncateLabel(selectedOption.text)}
            onClick={handleClick}
            placeholder="Status"
            fullWidth
          />
        </div>
      </ClickAwayListener>
      <ActionList
        id="dropdown-list"
        anchorEl={anchorEl}
        open={open}
        onSelect={handleChange}
        onClose={handleClose}
        items={options}
      />
    </>
  );
};

export default FilterContractStatus;
