import Dinero from 'dinero.js';
import Contract from '../models/v4ContractType';

export const subtotalRevenueTooltip =
  'The sum of revenue from all active and expired contracts. Does not include revenue from unaccepted contracts. This revenue may not be received/disbursed yet.';

export const useInsightSummary = (
  contracts: Array<Contract>,
): Array<string> => {
  let subtotalRevenue = Dinero({ amount: 0 });
  let estimatedRevenue = Dinero({ amount: 0 });

  contracts?.forEach((contract) => {
    const subtotal = contract?.subtotal?.getAmount() || 0;
    const estimated = contract?.estimatedSubtotal?.getAmount() || 0;
    subtotalRevenue = subtotalRevenue.add(Dinero({ amount: subtotal }));
    estimatedRevenue = estimatedRevenue.add(Dinero({ amount: estimated }));
  });

  return [
    subtotalRevenue.toFormat('$0,0.00'),
    estimatedRevenue.toFormat('$0,0.00'),
  ];
};
