import { DateTime } from 'luxon';

export const getMidnightUTCLocalDateOrNull = (
  date?: string | Date | DateTime,
): DateTime | null => {
  if (!date) return null;

  const invoiceDateTime = DateTime.isDateTime(date)
    ? date
    : typeof date === 'string'
    ? DateTime.fromISO(date)
    : DateTime.fromJSDate(date);

  const { year, month, day } = invoiceDateTime.toUTC();

  return DateTime.local(year, month, day);
};
