import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { useEffect, useState } from 'react';
import { authStore } from 'stores/Auth';

const useOpportunityEventsQuery = (opportunityId: string) => {
  const [token, setToken] = useState<string>(null);

  useEffect(() => {
    async function getDownstreamToken() {
      const downstreamToken = await authStore.getToken();
      setToken(downstreamToken);
    }

    if (!token) {
      getDownstreamToken();
    }
  }, [token]);

  const authorizedCosellClient = coSellClient(token);

  const {
    data: opportunityEvents,
    error: opportunityEventsError,
    isLoading: isOpportunityEventsLoading,
  } = useQuery({
    queryKey: [opportunityId, 'events'],
    queryFn: () => authorizedCosellClient.getOpportunityEvents(opportunityId),
    enabled: !!opportunityId && !!authorizedCosellClient,
    // adjust this to a value that makes sense for this data
    staleTime: 30000,
  });

  return {
    opportunityEvents,
    opportunityEventsError,
    isOpportunityEventsLoading,
  };
};

export default useOpportunityEventsQuery;
