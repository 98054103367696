import { GenericProduct, GenericProductJSON } from './Product';

interface MeteringServiceDimension {
  identifier: string;
}

export interface AzureProductJSON extends GenericProductJSON {
  cloud: 'azure';
  metering_service_dimensions: MeteringServiceDimension[];
}

export interface AzureProduct extends GenericProduct {
  cloud: 'azure';
  meteringServiceDimensions: MeteringServiceDimension[];
  pricing?: AzurePricing;
}

export enum AzurePricingEnum {
  FlatRate = 'flat_rate',
  PerUser = 'per_user',
}

interface AzurePricing {
  pricingType: AzurePricingType;
  plans: AzurePricingPlan[];
  metrics: AzurePricingMetric[];
}

export type AzurePricingType =
  | AzurePricingEnum.PerUser
  | AzurePricingEnum.FlatRate;

export interface AzurePricingPlan {
  markets: string[];
  planUuid: string;
  userLimits: AzurePricingPlanUserLimits;
  visibility: AzurePricingPlanVisibility;
  name: string;
  description: string;
  planId: string;
  freeTrial: boolean;
  billingTerms?: AzurePricingPlanProductBillingTerm[];
}

interface AzurePricingPlanUserLimits {
  max: number;
  min: number;
}

interface AzurePricingPlanVisibility {
  public: boolean;
  allowedTenants: AzurePricingPlanAllowedTenants[];
}

interface AzurePricingPlanAllowedTenants {
  tenantId: string;
  description: string;
}

interface AzurePricingMetric {
  displayName: string;
  sku: string;
  productUnit: string;
  planPricing: AzurePricingMetricPlanPricing[];
}

interface AzurePricingMetricPlanPricing {
  planUuid: string;
  pricePerUnit: number;
  baseQuantityMonthly: AzurePricingMetricPlanPricingBaseQuantity;
  baseQuantityAnnual: AzurePricingMetricPlanPricingBaseQuantity;
}

interface AzurePricingMetricPlanPricingBaseQuantity {
  unlimited: boolean;
  quantity: number;
}

export interface AzurePricingPlanProductBillingTerm {
  paymentOption: AzureProductPaymentOption;
  pricePerPaymentInUsd: string;
  type: string;
  value: number;
}

export interface AzureProductPaymentOption {
  type: string;
  value: number;
}
