import React from 'react';
import { Grid } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { convertArrayToString } from '../helper';
import { Marketing } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';

interface AceMarketingDetailsProps {
  marketing: Marketing;
}

const AceMarketingDetails: React.FC<AceMarketingDetailsProps> = ({
  marketing,
}) => {
  return (
    <DetailsSectionCard title="Marketing details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem itemTitle="Opportunity source" value={marketing?.source} />

        <InfoItem
          itemTitle="Marketing campaign"
          value={marketing?.campaignName}
        />

        <InfoItem
          itemTitle="Marketing activity use case"
          value={convertArrayToString(marketing?.useCases)}
        />

        <InfoItem
          itemTitle="Marketing activity channel"
          value={convertArrayToString(marketing?.channels)}
        />
        <InfoItem
          itemTitle="Marketing development funds"
          value={marketing?.awsFundingUsed}
          gridSize={12}
        />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceMarketingDetails;
