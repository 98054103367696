import { useState } from 'react';
import api, { omitAuthHeader } from 'utils/api';

type CreatePasswordArgs = {
  password: string;
  sessionId: string;
};

type UsePasswordCreateHook = {
  loading: boolean;
  error?: Error;
  onSubmit: (args: CreatePasswordArgs) => Promise<void>;
};

const usePasswordCreate = ({
  onSuccess,
}: {
  onSuccess: (response) => void;
}): UsePasswordCreateHook => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>(null);

  const onSubmit = async ({
    password,
    sessionId,
  }: CreatePasswordArgs): Promise<void> => {
    try {
      setLoading(true);
      setError(null);

      const response = await api
        .post(`user/invite/${sessionId}/create-password`, {
          json: {
            password,
          },
          ...omitAuthHeader(),
        })
        .json();

      onSuccess(response);
    } catch (exception) {
      setError(exception);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, onSubmit };
};

export default usePasswordCreate;
