import React from 'react';
import { Breakpoint, Grid, GridSize } from 'vendor/material';
import {
  BillingTermValue,
  DURATION_TYPE_VALUE_TYPES,
  MetadataPricing,
  OfferType,
} from 'stores/privateOffers/typings';
import { contractDetailsIsEmpty } from 'pages/PrivateOffers/utils/formatFormData';
import getCloudPricingFormat from 'utils/getCloudPricingFormat';
import { Cloud } from 'stores/typings';
import DetailsSection from './DetailsSection';
import NameValuePair from './NameValuePair';
import useStyles from './ContractDetails.styles';
import { formatDate } from 'utils/dates';
import { DateTime } from 'luxon';
import { Cloud as CloudEnum } from 'utils/cloudTypes';
import { formatToSentenceCase } from 'utils/formatToSentenceCase';
import { CurrencyCode, formatValueWithCurrencyPrefix } from '../utils/currency';

const formatCurrency = (
  value: number,
  cloud: Cloud,
  currencyCode: CurrencyCode,
): string => {
  const format = getCloudPricingFormat(cloud);
  return formatValueWithCurrencyPrefix(value, currencyCode, format);
};

const formatValueAsCurrencyIfPresent = (
  value: string | number | null,
  cloud: Cloud,
  currencyCode: CurrencyCode = CurrencyCode.UnitedStatesDollar,
): string | undefined => value && formatCurrency(+value, cloud, currencyCode);

const formatContractDetails = (
  pricing: MetadataPricing,
  cloud: Cloud,
): Map<string, string> => {
  const duration = formatToSentenceCase(pricing?.duration?.toString()) ?? '';
  const marketplaceFee = `${pricing?.marketplaceFee?.toString() ?? '0'}%`;

  const grossAmount = formatValueAsCurrencyIfPresent(
    pricing?.totalContractValue,
    cloud,
    pricing?.currencyCode as CurrencyCode,
  );

  const netAmount = formatValueAsCurrencyIfPresent(
    pricing?.netContractValue,
    cloud,
    pricing?.currencyCode as CurrencyCode,
  );

  return new Map([
    ['Duration', duration],
    ['Gross amount', grossAmount],
    ['Marketplace fee', marketplaceFee],
    ['Net amount', netAmount],
  ]);
};

const formatMultiCloudContractDetails = (
  pricing: MetadataPricing,
  cloud: Cloud,
): Map<string, string> => {
  const term = `${pricing?.durationValue} ${pricing?.durationType}` ?? '';
  const marketplaceFee = `${pricing?.marketplaceFee?.toString() ?? '0'}%`;

  const grossAmount = formatValueAsCurrencyIfPresent(
    pricing?.totalContractValue,
    cloud,
    pricing?.currencyCode as CurrencyCode,
  );

  const netAmount = formatValueAsCurrencyIfPresent(
    pricing?.netContractValue,
    cloud,
    pricing?.currencyCode as CurrencyCode,
  );

  return new Map([
    ['Billing term / duration', term],
    ['Gross amount', grossAmount],
    ['Marketplace fee', marketplaceFee],
    ['Net amount', netAmount],
  ]);
};

function formatFDADetails(pricing: MetadataPricing): Map<string, string> {
  return new Map([
    [
      'Service start date',
      formatDate(pricing?.serviceStartAt, DateTime.DATE_SHORT, true) ?? '',
    ],
    [
      'Service end date',
      formatDate(pricing?.serviceEndAt, DateTime.DATE_SHORT, true) ?? '',
    ],
  ]);
}

function formatAWSPartnerOfferDateDetails(
  pricing: MetadataPricing,
): Map<string, string> {
  return new Map([
    [
      'Max service start date',
      formatDate(pricing?.serviceStartAt, DateTime.DATE_SHORT, true) ?? '',
    ],
  ]);
}

function formatAmendmentDateDetails(
  pricing: MetadataPricing,
  previousServiceEndAt?: DateTime,
): Map<string, string> {
  return new Map([
    [
      'Previous service end date',
      formatDate(previousServiceEndAt, DateTime.DATE_SHORT, true) ?? '',
    ],
    [
      'New service end date',
      formatDate(pricing?.serviceEndAt, DateTime.DATE_SHORT, true) ?? '',
    ],
  ]);
}

function formatFDAAmendmentDateDetails(
  pricing: MetadataPricing,
  previousServiceEndAt?: DateTime,
): Map<string, string> {
  return new Map([
    [
      'Previous service end date',
      formatDate(previousServiceEndAt, DateTime.DATE_SHORT, true) ?? '',
    ],
    [
      'Service start date',
      formatDate(pricing?.serviceStartAt, DateTime.DATE_SHORT, true) ?? '',
    ],
    [
      'New service end date',
      formatDate(pricing?.serviceEndAt, DateTime.DATE_SHORT, true) ?? '',
    ],
  ]);
}

export interface ContractDetailsProps {
  cloud: Cloud;
  pricing: MetadataPricing | null;
  buyerBillingAccountNumber?: string;
  variant: 'column' | 'row';
  offerType?: OfferType;
  isAmendmentOffer?: boolean;
  previousServiceEndAt?: DateTime;
}

const ContractDetails: React.FC<ContractDetailsProps> = ({
  cloud,
  pricing,
  buyerBillingAccountNumber,
  variant,
  isAmendmentOffer,
  previousServiceEndAt,
  offerType = OfferType.Direct,
}) => {
  const classes = useStyles({ variant });

  if (contractDetailsIsEmpty(pricing)) return null;

  const showNewMultiCloudContractDetails =
    [CloudEnum.Aws, CloudEnum.Gcp].includes(cloud as CloudEnum) &&
    pricing?.durationValue > 0 &&
    DURATION_TYPE_VALUE_TYPES.includes(pricing?.durationType);

  const details = showNewMultiCloudContractDetails
    ? formatMultiCloudContractDetails(pricing, cloud)
    : formatContractDetails(pricing, cloud);
  const breakpoints: Partial<Record<Breakpoint, GridSize>> =
    variant === 'column' ? { xs: 3, lg: 6 } : { xs: 3, sm: 3 };

  const contractDetailTitleValuePairs = [...details]
    .filter(([_, value]) => !!value)
    .map(([key, value]) => (
      <Grid item className={classes.item} key={key} {...breakpoints}>
        <NameValuePair name={key} value={value} />
      </Grid>
    ));

  const isFDAOffer = pricing?.billingTerm === BillingTermValue.FutureDated;
  const dateDetails =
    isFDAOffer && isAmendmentOffer && offerType === OfferType.Direct
      ? formatFDAAmendmentDateDetails(pricing, previousServiceEndAt)
      : isAmendmentOffer && offerType === OfferType.Direct
      ? formatAmendmentDateDetails(pricing, previousServiceEndAt)
      : isFDAOffer
      ? formatFDADetails(pricing)
      : offerType === OfferType.PartnerResale && cloud === CloudEnum.Aws
      ? formatAWSPartnerOfferDateDetails(pricing)
      : [];

  const buyerBillingAccountNumberLabel =
    cloud === CloudEnum.Gcp && offerType === OfferType.PartnerResale
      ? 'Buyer subaccount ID'
      : 'Customer billing account number';

  return (
    <DetailsSection title="Contract details">
      <Grid container className={classes.contract}>
        {contractDetailTitleValuePairs}
      </Grid>
      {dateDetails && (
        <Grid container className={classes.contract}>
          {[...dateDetails].map(([key, value]) => (
            <Grid item className={classes.item} key={key} {...breakpoints}>
              <NameValuePair name={key} value={value} />
            </Grid>
          ))}
        </Grid>
      )}
      {cloud !== CloudEnum.Aws && buyerBillingAccountNumber && (
        <Grid container className={classes.contract}>
          <Grid item className={classes.item} {...breakpoints}>
            <NameValuePair
              name={buyerBillingAccountNumberLabel}
              value={buyerBillingAccountNumber}
            />
          </Grid>
        </Grid>
      )}
    </DetailsSection>
  );
};

export default ContractDetails;
