import React, { useCallback, useMemo, useState } from 'react';
import { OffersDataId } from '../../utils/intercomEnums';
import { Box, Grid, Typography } from '../../vendor/material/index';
import { TextButton } from '../index';
import { pendoIdGenerator } from '../../utils/idGenerator';
import { privateOffersUIElements } from '../../utils/pendoEnums';
import { Banner, FormSection } from '@tackle-io/platform-ui';
import useStyles from './FieldsPricing.styles';
import { Product } from '../../stores/products/typings/index';
import { getTotalContractValueFromSchedule } from '../../pages/PrivateOffers/components/PaymentScheduler/utils';
import { ampli } from '../../utils/analytics/ampli/index';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { currencyCodeFormPath, scheduleFormPath } from './utils';
import PaymentScheduleDrawer from '../../pages/PrivateOffers/components/PaymentScheduler/PaymentSchedulerDrawer';
import { Schedule } from '../../stores/privateOffers/typings';
import { ScheduleFieldProps } from './ScheduleFields/typings';
import { DateTime } from 'luxon';
import FieldError from '../../pages/PrivateOffers/components/FieldError/FieldError';
import {
  CurrencyCode,
  formatValueWithCurrencyPrefix,
} from '../../utils/currency';
import { getBannerBodyText, getBannerTitleText } from './pricingScheduleUtils';

interface PricingScheduleProps {
  ScheduleField: React.FC<ScheduleFieldProps>;
  cloud?: Product['cloud'];
  isAmendmentOffer: boolean;
  showPaymentScheduleTool: boolean;
  disablePaymentScheduleToolDurationField?: boolean;
}

interface WatchedInvoiceAmountTarget {
  value: number;
}

interface WatchedInvoiceAmount {
  target: WatchedInvoiceAmountTarget;
}

interface WatchedScheduleField {
  invoiceDate: DateTime;
  invoiceAmount: WatchedInvoiceAmount | number;
}

const isWatchedInvoiceAmount = (
  object: unknown,
): object is WatchedInvoiceAmount => {
  return !object ? false : object.hasOwnProperty('target');
};

const PricingSchedule: React.FC<PricingScheduleProps> = ({
  ScheduleField,
  cloud,
  isAmendmentOffer,
  showPaymentScheduleTool,
  disablePaymentScheduleToolDurationField = false,
}) => {
  const classes = useStyles();
  const [isPaymentScheduleDrawerOpen, setIsPaymentScheduleDrawerOpen] =
    useState(false);

  const { control, errors } = useFormContext();

  const watchedScheduleFields: WatchedScheduleField[] =
    useWatch({
      name: scheduleFormPath,
      control,
    }) || [];

  const formCurrencyCode = useWatch({
    name: currencyCodeFormPath,
    control,
  }) as CurrencyCode;

  const {
    fields: scheduleFields,
    append: scheduleAppend,
    remove: scheduleRemove,
  } = useFieldArray({
    name: scheduleFormPath,
    control,
  });

  const schedule = watchedScheduleFields.map((field) => {
    return isWatchedInvoiceAmount(field.invoiceAmount)
      ? {
          invoiceAmount: (field.invoiceAmount as WatchedInvoiceAmount).target
            .value,
          invoiceDate: field.invoiceDate,
        }
      : {
          invoiceAmount: field.invoiceAmount as number,
          invoiceDate: field.invoiceDate,
        };
  });

  const totalContractValue = useMemo(
    () => getTotalContractValueFromSchedule(schedule),
    [schedule],
  );

  const handleScheduleAddition = useCallback(
    () => scheduleAppend({ invoiceAmount: 0, invoiceDate: undefined }),
    [scheduleAppend],
  );

  const handleScheduleRemoval = useCallback(
    (index): void => scheduleRemove(index),
    [scheduleRemove],
  );

  const openPaymentScheduleDrawer = () => {
    ampli.buttonClicked({
      button_location: 'create-offer-form',
      button_name: 'create-payment-schedule',
      button_product_area: 'offers',
    });
    setIsPaymentScheduleDrawerOpen(true);
  };

  const closePaymentScheduleDrawer = useCallback(() => {
    setIsPaymentScheduleDrawerOpen(false);
  }, []);

  const showTotalPayments = schedule.length > 0;

  const bannerTitleText = getBannerTitleText(cloud, isAmendmentOffer);
  const bannerBodyText = getBannerBodyText(cloud, isAmendmentOffer);
  const showPaymentScheduleBanner = bannerTitleText || bannerBodyText;

  const totalPayments = formatValueWithCurrencyPrefix(
    totalContractValue,
    formCurrencyCode,
  );

  return (
    <>
      <div data-id={OffersDataId.PRIVATE_OFFERS_PAYMENT_SCHEDULE_FORM_SECTION}>
        <FormSection title="Payment schedule" mb={1.5}>
          {showPaymentScheduleBanner && (
            <Box className={classes.paymentScheduleBanner}>
              <Banner
                title={bannerTitleText}
                body={bannerBodyText}
                type="info"
                isCollapsible={true}
                defaultOpen={!!bannerBodyText}
              />
            </Box>
          )}
          {ScheduleField &&
            scheduleFields.map((field, index) => (
              <Box mb={2} key={field.id}>
                <ScheduleField
                  field={field as Schedule}
                  index={index}
                  onRemove={handleScheduleRemoval}
                  numberOfPaymentScheduleRows={watchedScheduleFields.length}
                />
              </Box>
            ))}
          {showTotalPayments && (
            <Grid
              container
              spacing={2}
              className={classes.totalPaymentsContainer}
            >
              <Grid item xs={11} className={classes.totalPayments}>
                <Typography
                  component="span"
                  className={classes.totalPaymentsLabel}
                >
                  Total payments
                </Typography>
                <Typography
                  component="span"
                  className={classes.totalContractValue}
                >
                  {totalPayments}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                {''}
              </Grid>
            </Grid>
          )}
        </FormSection>
        <TextButton
          appearance="subtle"
          color="primary"
          onClick={handleScheduleAddition}
          classes={{
            root: classes.buttonPrimary,
            textPrimary: classes.buttonTextPrimary,
          }}
          aria-label="Add payment"
          data-id={pendoIdGenerator(
            privateOffersUIElements.PRIVATE_OFFERS_ADD_PAYMENT_BUTTON,
          )}
        >
          Add payment
        </TextButton>
        {showPaymentScheduleTool && (
          <>
            <TextButton
              appearance="subtle"
              color="primary"
              onClick={openPaymentScheduleDrawer}
              classes={{
                root: classes.buttonPrimary,
                textPrimary: classes.buttonTextPrimary,
              }}
              aria-label="Create payment schedule"
              data-id={pendoIdGenerator(
                privateOffersUIElements.PRIVATE_OFFERS_SHOW_PAYMENT_SCHEDULE_TOOL_BUTTON,
              )}
            >
              Create payment schedule
            </TextButton>
            <PaymentScheduleDrawer
              isPaymentScheduleDrawerOpen={isPaymentScheduleDrawerOpen}
              closePaymentScheduleDrawer={closePaymentScheduleDrawer}
              disablePaymentScheduleToolDurationField={
                disablePaymentScheduleToolDurationField
              }
            />
          </>
        )}
        {errors?.pricing?.schedule?.message && (
          <FieldError error={errors.pricing.schedule.message} />
        )}
      </div>
    </>
  );
};

export default PricingSchedule;
