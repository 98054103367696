import { decorate, observable } from 'mobx';

import Order from './Order';
import { toDateTime } from '../utils/dates';
import { DateTime } from 'luxon';

class RedhatOrder extends Order {
  provider = 'REDHAT';

  entitlements = [];

  registration_dt: string | DateTime = '';

  constructor(props) {
    super(props);
    this.registration_dt = toDateTime(
      props.registration_dt,
      'Missing registration date',
    );
  }
}

decorate(RedhatOrder, {
  provider: observable,
  entitlements: observable,
  registration_dt: observable,
});

export default RedhatOrder;
