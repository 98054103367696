import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { Loader } from '@tackle-io/platform-ui';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'vendor/material';

import { ActivityStream } from '../../components';

import { Products, Vendor } from '../../stores';

import { FEATURE_DOWNSTREAM_INSIGHTS_V1 } from 'utils/features';

import {
  expiringContractsItems,
  latestActivityItems,
  useContracts,
  useStore,
  useFeatureAccessValidation,
} from '../../hooks';

const useStyles = makeStyles((theme) => ({
  activityPage: {
    marginTop: theme.spacing(2),
  },
}));

const useActivityStreamStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 400,
    paddingLeft: theme.spacing(2),
  },
  date: {
    color: theme.palette.text.hint,
  },
  separator: {
    color: theme.palette.text.primary,
  },
  client: {
    color: theme.palette.text.primary,
    fontFamily: "'Open Sans', sans-serif",
  },
  secondary: {
    fontSize: '0.85em',
    color: theme.palette.text.dark,
  },
  listItem: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      top: 0,
      left: theme.spacing(2) + 14,
      display: 'block',
      height: '100%',
      width: 1,
      content: "''",
      background: theme.palette.divider,
      transform: 'translateX(-49%)',
    },
  },
  listItemButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      '& span:last-of-type': {
        textDecoration: 'underline',
      },
    },
  },
  avatarWrapper: {
    height: 32,
    width: 32,
  },
  avatar: {
    height: '0.75em',
    width: '0.75em',
  },
  defaultIcon: {
    display: 'none',
  },
}));

const ActivityStreamPage = observer(({ history }) => {
  const activityStreamClasses = useActivityStreamStyles();
  const classes = useStyles();
  const { active: activeContracts, all: allContracts, loaded } = useContracts();
  const { products } = useStore(Products);
  const vendorStore = useStore(Vendor);

  const { hasAccess: hasInsightsAccess, loading: featuresLoading } =
    useFeatureAccessValidation([FEATURE_DOWNSTREAM_INSIGHTS_V1]);

  const hasAccess = Boolean(
    hasInsightsAccess ||
      vendorStore?.vendor?.feature_flags?.is_marketplace_enabled,
  );

  const expiring = useMemo(
    () => expiringContractsItems(activeContracts, history.push),
    [activeContracts, history.push],
  );

  const latest = useMemo(
    () => latestActivityItems(allContracts, history.push, products),
    [allContracts, history.push, products],
  );

  if (!loaded || featuresLoading) return <Loader />;
  if (!hasAccess) return <Redirect to="/" />;

  return (
    <div className={classes.activityPage}>
      <ActivityStream
        classes={activityStreamClasses}
        header="Latest activity"
        items={latest}
        maxItems={5}
      />
      <ActivityStream
        classes={activityStreamClasses}
        header="Contracts expiring soon"
        items={expiring}
        maxItems={5}
      />
    </div>
  );
});

export default ActivityStreamPage;
