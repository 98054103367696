import { reduce } from 'lodash/fp';

import {
  Cloud,
  Content,
  ReducerState,
  APIErrorResponseBody,
} from 'stores/typings';

import { withConvenienceBooleans } from 'stores/utils';

import {
  Product,
  ProductJSON,
  AwsProductJSON,
  GcpProductJSON,
  AzureProductJSON,
  RedhatProductJSON,
} from './typings';

export type APISuccessResponse = {
  product?: ProductJSON;
} & {
  aws?: AwsProductJSON[];
  gcp?: GcpProductJSON[];
  azure?: AzureProductJSON[];
  redhat?: RedhatProductJSON[];
};

export type APIResponse = APISuccessResponse | APIErrorResponseBody;
export function isAPISuccess(res: APIResponse): res is APISuccessResponse {
  return (
    (res as APISuccessResponse)?.aws !== undefined ||
    (res as APISuccessResponse)?.product !== undefined
  );
}

export type ProductsReducerState = ReducerState & {
  dispatch: (action: ProductAction) => void;
  content: Product[];
  status: ReducerState['status'];
  error: APIErrorResponseBody | ReducerState['error'];
};

export type ProductAction = {
  type: string;
  payload?: {
    id?: string;
    cloud?: Cloud;
    content?: Content;
    error?: Error | null;
  };
};

const ProductReducer = (
  state: ProductsReducerState,
  action: ProductAction,
): ProductsReducerState => {
  let updatedState = state;

  switch (action.type) {
    case 'SYNC':
      updatedState = {
        ...updatedState,
        status: 'syncing',
      };
      break;
    case 'SET_CONTENT':
      updatedState = {
        ...updatedState,
        content: action.payload.content,
        status: 'hasValue',
      };
      break;
    case 'ADD_CONTENT':
      updatedState = {
        ...updatedState,
        content: updatedState.content.concat([action.payload.content]),
        status: 'hasValue',
      };
      break;
    case 'UPDATE_CONTENT_BY_ID':
      updatedState = {
        ...updatedState,
        content: reduce((all: Product[], product: Product) => {
          if (
            product.productid === action.payload.id &&
            product.cloud === action.payload.cloud
          ) {
            return all.concat([action.payload.content]);
          }

          return all.concat([product]);
        }, [])(updatedState.content),
        status: 'hasValue',
      } as ProductsReducerState;
      break;
    case 'SET_ERROR':
      updatedState = {
        ...updatedState,
        error: action.payload.error,
        status: 'hasError',
      };
      break;
    case 'UPDATE':
      updatedState = {
        ...updatedState,
        status: 'hasValue',
      };
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }

  return withConvenienceBooleans(updatedState) as ProductsReducerState;
};

export default ProductReducer;
