import buildEnvironment, { PRODUCTION_ENV } from '../buildEnvironment';

import { User } from './types';

import {
  setUserDetails as sentrySetUserDetails,
  initSentry,
  addError as sentryAddError,
} from './sentry';
import {
  initDatadog,
  setUser as datadogSetUser,
  addError as datadogAddError,
  removeUser as datadogRemoveUser,
} from './datadog';

/**
 * Initializes monitoring sdks (datadog & sentry)
 */
const init = (): void => {
  initSentry();
  initDatadog();
};

/**
 * Sets user context on monitoring tools
 */
const setUserContext = (user: User): void => {
  sentrySetUserDetails(user);
  datadogSetUser(user);
};

/**
 * Resets user context on monitoring tools
 */
const removeUserContext = (): void => {
  datadogRemoveUser();
};

/**
 * Sends a caught exception as well as optional contextual info to monitoring tools
 */
const reportError = (
  exception: unknown,
  context?: object,
  fingerprint?: string[],
) => {
  if (buildEnvironment.environment !== PRODUCTION_ENV) {
    console.error('[reportError]', { exception, context, fingerprint });
  }

  if (fingerprint) {
    // This is annoying but datadog and sentry have different places they want fingerprints to live for custom grouping
    exception['dd_fingerprint'] = fingerprint.join(' ');
  }
  datadogAddError(exception, context);
  sentryAddError(exception, context, fingerprint);
};

export { init, setUserContext, removeUserContext, reportError };
