const SyncingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M2 20V18H4.725C3.875 17.2667 3.20833 16.3833 2.725 15.35C2.24167 14.3167 2 13.2 2 12C2 10.1333 2.56667 8.4875 3.7 7.0625C4.83333 5.6375 6.26667 4.7 8 4.25V6.35C6.83333 6.76667 5.875 7.4875 5.125 8.5125C4.375 9.5375 4 10.7 4 12C4 12.9 4.17917 13.7292 4.5375 14.4875C4.89583 15.2458 5.38333 15.9 6 16.45V14H8V20H2ZM13 20C12.1667 20 11.4583 19.7083 10.875 19.125C10.2917 18.5417 10 17.8333 10 17C10 16.2 10.275 15.5125 10.825 14.9375C11.375 14.3625 12.05 14.0583 12.85 14.025C13.1333 13.425 13.5542 12.9375 14.1125 12.5625C14.6708 12.1875 15.3 12 16 12C16.8833 12 17.6458 12.2875 18.2875 12.8625C18.9292 13.4375 19.3167 14.15 19.45 15C20.15 15 20.75 15.2417 21.25 15.725C21.75 16.2083 22 16.7917 22 17.475C22 18.175 21.7583 18.7708 21.275 19.2625C20.7917 19.7542 20.2 20 19.5 20H13ZM15.9 11C15.7833 10.3167 15.5583 9.68333 15.225 9.1C14.8917 8.51667 14.4833 8 14 7.55V10H12V4H18V6H15.275C15.9917 6.63333 16.5792 7.375 17.0375 8.225C17.4958 9.075 17.7917 10 17.925 11H15.9ZM13 18H19.5C19.6333 18 19.75 17.95 19.85 17.85C19.95 17.75 20 17.6333 20 17.5C20 17.3667 19.95 17.25 19.85 17.15C19.75 17.05 19.6333 17 19.5 17H17.75V15.75C17.75 15.2667 17.5792 14.8542 17.2375 14.5125C16.8958 14.1708 16.4833 14 16 14C15.5167 14 15.1042 14.1708 14.7625 14.5125C14.4208 14.8542 14.25 15.2667 14.25 15.75V16H13C12.7167 16 12.4792 16.0958 12.2875 16.2875C12.0958 16.4792 12 16.7167 12 17C12 17.2833 12.0958 17.5208 12.2875 17.7125C12.4792 17.9042 12.7167 18 13 18Z"
      fill="#253858"
    />
  </svg>
);
export default SyncingIcon;
