import { curry, flow, find, get, map } from 'lodash/fp';
import { DateTime } from 'luxon';

import { Product } from 'stores/products/typings';
import { Activity, PrivateOffer } from 'stores/privateOffers/typings';

import lastActivityAction from './lastActivityAction';
import {
  ActivitySlug,
  ACTIVITY_SLUG_TO_OFFER_STEP_INFO,
  OfferStepInfo,
} from './constants';

export type PrivateOfferListItem = Pick<
  PrivateOffer,
  'poId' | 'offerRef' | 'createdAt' | 'lastModifiedAt' | 'offerType' | 'cloud'
> & {
  company?: string;
  product?: Product['name'];
  archivedAt?: DateTime;
  status: OfferStepInfo;
  activities?: Activity[];
  metadata?: Record<string, any>;
  recentActivityDate: DateTime;
};

const getProductName = flow(curry(find), get('name'));

const getStatus = flow(
  curry(lastActivityAction),
  get('slug'),
  (slug: ActivitySlug) => ACTIVITY_SLUG_TO_OFFER_STEP_INFO[slug],
);

const curriedBuildComposedPO = curry(
  (products: Product[], po: PrivateOffer) => ({
    poId: po.poId,
    cloud: po.cloud,
    offerRef: po.offerRef,
    createdAt: po.createdAt,
    lastModifiedAt: po.lastModifiedAt,
    offerType: po.offerType,
    company: get('metadata.preRegistrationDetails.Company', po),
    archivedAt: po.archivedAt,
    status: getStatus(po.activities),
    product: getProductName({ productid: po.productRef }, products),
    activities: po.activities,
    recentActivityDate: po.activities[po.activities.length - 1]?.createdAt,
    metadata: po.metadata,
  }),
);

const buildPrivateOfferList = (
  products: Product[],
  offers: PrivateOffer[],
): PrivateOfferListItem[] => {
  return map(curriedBuildComposedPO(products), offers);
};

export default buildPrivateOfferList;
