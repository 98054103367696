import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  environmentLabel: {
    color: theme.palette.NEUTRAL080,
    textAlign: 'center',
    padding: theme.spacing(1.25, 1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#111432',
    },
  },
}));

export default useStyles;
