import { DateTime } from 'luxon';
import { action, computed, decorate } from 'mobx';
import { map, filter, find } from 'lodash-es';

import { reportError } from 'utils/monitor';

import Store from './Store';
import api from '../utils/api';
import Contract from '../models/v4Contract';
import { toDateTime } from 'utils/dates';

class ContractsStore extends Store {
  load = () => {
    super.loading = true;
    const localContracts = JSON.parse(localStorage.getItem('contracts')) || [];
    this.contracts = localContracts.map((d) => new Contract(d));

    // this causes the private offers list page flash
    if (this.contracts.length) {
      super.loading = false;
      super.syncing = true;
    }

    api
      .get('v4/analytics/contracts')
      .json()
      .then(({ data = [] }) => {
        try {
          localStorage.setItem('contracts', JSON.stringify(data));
        } catch (e) {
          if (e.name !== 'QuotaExceededError') reportError(e);
        }
        this.contracts = map(data, (d) => new Contract(d));
        super.loading = false;
        super.syncing = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
        super.syncing = false;
      });
  };

  get contracts(): Contract[] {
    return super.data;
  }

  set contracts(contracts) {
    super.data = contracts;
  }

  addContract(jsonContract) {
    let localContracts = JSON.parse(localStorage.getItem('contracts')) || [];
    localContracts = [...localContracts, jsonContract];

    try {
      localStorage.setItem('contracts', JSON.stringify(localContracts));
    } catch (e) {
      if (e.name !== 'QuotaExceededError') reportError(e);
    }

    this.contracts = localContracts.map((c) => new Contract(c));
  }

  updateContract(jsonContract) {
    let localContracts = JSON.parse(localStorage.getItem('contracts')) || [];

    const { id: contractId } = jsonContract;
    localContracts = filter(localContracts, (con) => con.id !== contractId);
    localContracts = [...localContracts, jsonContract];

    try {
      localStorage.setItem('contracts', JSON.stringify(localContracts));
    } catch (e) {
      if (e.name !== 'QuotaExceededError') reportError(e);
    }

    this.contracts = localContracts.map((c) => new Contract(c));
  }

  removeContract(contractId) {
    let localContracts = JSON.parse(localStorage.getItem('contracts')) || [];
    localContracts = filter(localContracts, (con) => con.id !== contractId);

    try {
      localStorage.setItem('contracts', JSON.stringify(localContracts));
    } catch (e) {
      if (e.name !== 'QuotaExceededError') reportError(e);
    }

    this.contracts = localContracts.map((c) => new Contract(c));
  }

  syncContract(contractId) {
    super.syncing = true;

    const contractUrl = `v4/analytics/contracts/${contractId}`;
    return api
      .get(contractUrl)
      .json()
      .then((rawContract) => {
        this.updateContract(rawContract);
        super.syncing = false;
      })
      .catch((error) => {
        super.error = error;
        super.syncing = false;
      });
  }

  byProductId(id) {
    return filter(this.contracts, { listing: `lst_${id}` });
  }

  findByContractId(contractid: string) {
    /**
     * contractid could be in form con_12345 or productid__customerid__offerid
     */
    let contract = this.contracts.find((c) => c.id === contractid);
    if (!contract && contractid && contractid.includes('__')) {
      // fallback lookup by productid/customerid/offerid or productid/customerid
      const [productid, customerid, offerid] = contractid.split('__');
      if (offerid !== 'None') {
        contract = this.findByProductIdCustomerIdOfferId({
          productid,
          customerid,
          offerid,
        });
      } else {
        contract = this.findByProductIdCustomerId({
          productid,
          customerid,
        });
      }
    }
    return contract;
  }

  findByProductIdCustomerId({ customerid, productid }) {
    return find(this.contracts, {
      cloudMetadata: {
        customer_reference_id: customerid,
        product_code: productid,
      },
    });
  }

  findByProductIdCustomerIdOfferId({ customerid, productid, offerid }) {
    return find(this.contracts, {
      cloudMetadata: {
        customer_reference_id: customerid,
        product_code: productid,
        offer_id: offerid,
      },
    });
  }

  getActive = () =>
    this.contracts.filter((c) => {
      const now = DateTime.utc();
      return toDateTime(c.expirationDate) > now;
    });
}

decorate(ContractsStore, {
  contracts: computed,
  load: action,
});

export default ContractsStore;

const contractsStore = new ContractsStore();
export { contractsStore };
