import React from 'react';
import ProgressiveImage from 'react-progressive-image';

import bkg from '../../../../images/login-bg.jpg';
import bkg2x from '../../../../images/login-bg@2x.jpg';

import useStyles from './Background.styles';

const Background = () => {
  const classes = useStyles();

  const srcSet = `${bkg}, ${bkg2x} 2x`;
  return (
    <ProgressiveImage src={bkg} srcSetData={{ srcSet }}>
      {(src, _, srcSetData) => (
        <img
          src={src}
          srcSet={srcSetData.srcSet}
          className={classes.root}
          alt="Login Background"
        />
      )}
    </ProgressiveImage>
  );
};

export default Background;
