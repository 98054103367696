import React from 'react';
import { Grid } from 'vendor/material';
import { TextField } from '@tackle-io/platform-ui';
import { Controller, useFormContext } from 'react-hook-form';

import useStyles from './styles';
import { DimensionFieldProps } from './typings';

const GcpDimensionField: React.FC<DimensionFieldProps> = ({ field, index }) => {
  const classes = useStyles();
  const { control, errors } = useFormContext();

  return (
    <Grid container spacing={2} className={classes.dimensionFields}>
      <Grid item md={6} sm={11} xs={10} className={classes.fieldKey}>
        <Controller
          name={`pricing.dimensions[${index}].name`}
          control={control}
          defaultValue={field.name ?? ''}
          render={({ ref, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Service level"
              id={`pricing.dimensions.${index}.name`}
              error={errors.pricing?.dimensions?.[index]?.name?.message}
              inputRef={ref}
              inputProps={{
                'aria-label': `Dimension ${index} name`,
              }}
            />
          )}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12} className={classes.priceContainer}>
        <Controller
          name={`pricing.dimensions[${index}].price`}
          control={control}
          defaultValue={field.price ?? 0}
          render={({ ref, onChange, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Total contract value"
              id={`pricing.dimensions.${index}.price`}
              error={errors.pricing?.dimensions?.[index]?.price?.message}
              mode="numberformat"
              type="number"
              decimalScale={6}
              fixedDecimalScale
              aria-label={`Dimension ${index} price per`}
              inputProps={{ ref }}
              onChange={({ target: { value } }) => onChange(value)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default GcpDimensionField;
