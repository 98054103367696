import { useRef, useEffect } from 'react';

// returns the previous value of value argument
// useful for tracking changes in props
function usePreviousValue(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default usePreviousValue;
