import { makeStyles } from 'vendor/material';
import { fontWeightMedium } from '../../utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    alignItems: 'start',
  },
  logos: {
    display: 'block',
    position: 'relative',
    marginRight: theme.spacing(0.9),
  },
  hidden: {
    display: 'none',
  },
  provider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.NEUTRAL000,
    borderRadius: '50%',
    height: 32,
    width: 32,
    position: 'absolute',
    right: 3,
    bottom: -12,
    fontSize: theme.typography.pxToRem(19),
    paddingBottom: 1,
  },
  logo: {
    width: 48,
    height: 48,
    borderRadius: '50%',
    marginRight: theme.spacing(2),
  },
  companyName: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: fontWeightMedium,
    color: theme.palette.NEUTRAL700,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.6),
  },
  verticallyCenter: {
    lineHeight: '48px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  productName: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.NEUTRAL400,
    marginBottom: theme.spacing(1.5),
  },
  secondaryText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL400,
    display: 'flex',
  },
}));

export default useStyles;
