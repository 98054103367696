import { useQuery, useQueryClient } from '@tanstack/react-query';
import coSellClient from '../coSellClient';
import { useMutation } from '@tanstack/react-query';
import { TackleOperationId } from '../utils';
import {
  CreateAceOpportunityRequest,
  UpdateAceOpportunityRequest,
} from 'packages/cosell/src/types/requests/AceOpportunityRequest';
import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';

export const useAceOpportunity = ({
  token,
  opportunityId,
}: {
  token: string;
  opportunityId?: string;
}) => {
  const authorizedCosellClient = coSellClient(token);
  const queryClient = useQueryClient();

  const aceOpportunityQuery = useQuery<AceOpportunityResponse>({
    queryKey: ['co-sell-aws', opportunityId, authorizedCosellClient],
    queryFn: () => authorizedCosellClient!.getOpportunityByIdV3(opportunityId!),
    enabled: !!opportunityId && !!authorizedCosellClient,
    initialData: {} as AceOpportunityResponse,

    refetchInterval: (data: AceOpportunityResponse) => {
      const shouldRefetch =
        !data?.partnerOpportunityIdentifier && !data?.metadata?.hasErrors;

      return shouldRefetch ? 5000 : false;
    },
  });

  const createAceOpportunity = useMutation({
    mutationFn: async ({
      requestBody,
      tackleOperationId,
    }: {
      requestBody: CreateAceOpportunityRequest;
      tackleOperationId: TackleOperationId;
    }) =>
      await authorizedCosellClient.createOpportunity(
        requestBody,
        tackleOperationId,
      ),
  });

  /**TODO: refactor implementation to pass in opportunityId */
  const updateAceOpportunity = useMutation({
    mutationFn: async ({
      requestBody,
      tackleOperationId,
    }: {
      requestBody: UpdateAceOpportunityRequest;
      tackleOperationId: TackleOperationId;
    }) =>
      await authorizedCosellClient.updateOpportunity(
        requestBody,
        opportunityId,
        tackleOperationId,
      ),

    onSuccess: () => {
      queryClient.invalidateQueries(['co-sell-aws', opportunityId]);
    },
  });

  return {
    aceOpportunityQuery,
    createAceOpportunity,
    updateAceOpportunity,
  };
};
