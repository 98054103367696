import { TextField } from '@tackle-io/platform-ui';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Dimension } from 'stores/privateOffers/typings';

interface PlanFieldControllerProps {
  field: Dimension;
  control: any;
  errors: any;
  productId: string;
  privateOfferId: string;
  index: number;
}

const PlanFieldController: React.FC<PlanFieldControllerProps> = ({
  field,
  control,
  errors,
  productId,
  privateOfferId,
  index,
}) => {
  return (
    <Controller
      name={`pricing.dimensions.${index}.name`}
      control={control}
      defaultValue={field.name ?? ''}
      render={({ ref, ...props }): React.ReactElement => (
        <TextField
          {...props}
          label="Plan"
          id={`pricing.dimensions[${index}].name`}
          error={errors.pricing?.dimensions?.[index]?.name?.message}
          inputRef={ref}
          inputProps={{
            'aria-label': `Dimension ${index} Name`,
          }}
        />
      )}
    />
  );
};

export { PlanFieldController };
