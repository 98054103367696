import {
  Button,
  Calendar,
  Dropdown,
  useTextFieldStyles,
} from '@tackle-io/platform-ui';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { Calendar as CalendarIcon } from 'mdi-material-ui';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { formatDate } from 'utils/dates';
import { pendoIdGenerator } from 'utils/idGenerator';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Popover,
} from 'vendor/material';
import useStyles from './FieldsSelectDate.styles';

interface Error {
  [key: string]: string;
}

interface FieldsSelectDateProps {
  fieldName: string;
  control: Control;
  error: Error;
  labelText: string | JSX.Element;
  helperText: string;
  defaultValue?: any;
  minDate?: Date;
  maxDate?: Date;
  id?: string;
  disabled?: boolean;
  showClearDateFooter?: boolean;
}

export default function FieldsSelectDate({
  fieldName,
  control,
  defaultValue,
  labelText,
  helperText,
  error,
  minDate,
  maxDate,
  id: fieldId = `fields-select-date-${fieldName}`,
  disabled = false,
  showClearDateFooter = false,
}: FieldsSelectDateProps) {
  const classes = useStyles();
  const inputClasses = useTextFieldStyles();
  const hasError = !isEmpty(error);
  const errorMessage = hasError ? error?.message : '';
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: React.MouseEvent): void =>
    setAnchorEl(event.currentTarget);

  const handleClose = (): void => setAnchorEl(null);

  const open = !!anchorEl;
  const id = open ? 'date-popover' : undefined;

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue || null}
      shouldUnregister
      render={({ ref, onChange, value }): React.ReactElement => (
        <FormControl
          error={hasError}
          ref={ref}
          fullWidth
          className={classes.dateSelectFormControl}
        >
          <InputLabel className={inputClasses.inputLabel}>
            {labelText}
          </InputLabel>
          <div data-id={pendoIdGenerator(fieldId)}>
            <Dropdown
              value={
                value
                  ? formatDate(DateTime.fromJSDate(value), DateTime.DATE_SHORT)
                  : ''
              }
              onClick={handleClick}
              startIcon={<CalendarIcon />}
              fullWidth
              hideEndIcon
              disabled={disabled}
            />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            classes={{
              paper: classes.paper,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.calendarContent}>
              <Calendar
                onChange={(date): void => {
                  onChange(date);
                  setAnchorEl(null);
                }}
                value={value}
                minDate={minDate || null}
                maxDate={maxDate || null}
              />
            </div>
            {showClearDateFooter && value && (
              <div className={classes.calendarFooter}>
                <Button
                  variant="outlined"
                  appearance="secondary"
                  onClick={(e): void => onChange(null)}
                >
                  Clear Date
                </Button>
              </div>
            )}
          </Popover>
          <FormHelperText error={hasError}>
            {hasError ? errorMessage : helperText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
