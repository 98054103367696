const formatValueAxis = (n): string => {
  let output = `$${n}`;
  const divisor = [
    { value: 1000, unit: 'k' },
    {
      value: 1000000,
      unit: 'M',
    },
  ];
  if (n >= 0) {
    divisor.forEach((d): void => {
      if (n / d.value >= 1) {
        if (d.unit === 'M') {
          output = `$${(n / d.value).toFixed(1)}${d.unit}`;
        } else {
          output = `$${Math.floor(n / d.value)}${d.unit}`;
        }
      }
    });
  } else {
    divisor.forEach((d): void => {
      if (n / d.value <= -1) {
        output = `$${Math.floor(n / d.value)}${d.unit}`;
      }
    });
  }

  return output;
};

export default formatValueAxis;
