import { makeStyles } from 'vendor/material';
import { fontWeightMedium } from '../utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.NEUTRAL700,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: fontWeightMedium,
  },
}));

export default useStyles;
