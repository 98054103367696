import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  drawerShell: {},
  drawerContainer: {
    width: theme.spacing(60),
    padding: theme.spacing(4),
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  drawerHeaderText: {
    fontSize: theme.spacing(2.5),
    fontWeight: 600,
    lineHeight: `${theme.spacing(3.75)}px`,
    flex: 1,
  },
  headerText: {
    fontSize: theme.spacing(2.5),
    fontWeight: 600,
    lineHeight: `${theme.spacing(3.75)}px`,
    color: theme.palette.NEUTRAL100,
  },
  drawerTopActions: {
    color: theme.palette.NEUTRAL100,
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(0.5),
  },
  drawerTopAction: {
    padding: 0,
  },
  drawerBody: {
    position: 'relative',
    flex: '1 0',
    overflowY: 'auto',
    marginBottom: theme.spacing(0.5),
    // hack to put the scrollbar where it belongs.
    marginRight: -theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  bodyFadeElement: {
    height: '36px',
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
  },
  bodyFadeReverseElement: {
    height: '36px',
    width: '100%',
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  drawerFooter: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  filter: {
    paddingLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(4),
    '& ul': {
      zIndex: 10,
    },
  },
}));

export default useStyles;
