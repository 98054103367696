const GeneralErrorStateIcon = ({ className = '' }: { className?: string }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'luminosity' }}>
      <path
        d="M50.3736 23.3484L50.5587 24.2876L51.5137 24.3537C57.8045 24.7892 62.75 29.9629 62.75 36.3333C62.75 43.003 57.3363 48.4167 50.6667 48.4167H16C7.86369 48.4167 1.25 41.803 1.25 33.6667C1.25 26.0712 7.00245 19.8027 14.3988 19.0163L15.0656 18.9454L15.3753 18.3508C18.5022 12.3471 24.7782 8.25 32 8.25C41.0977 8.25 48.6734 14.7226 50.3736 23.3484Z"
        stroke="#063649"
        stroke-width="2.5"
      />
      <path
        d="M32.0789 57.4913C38.7499 57.4913 44.1578 52.0834 44.1578 45.4124C44.1578 38.7414 38.7499 33.3335 32.0789 33.3335C25.4079 33.3335 20 38.7414 20 45.4124C20 52.0834 25.4079 57.4913 32.0789 57.4913Z"
        fill="#CFEAF3"
        stroke="#063649"
        stroke-width="2"
      />
      <path
        d="M32.0791 53.9126C36.7735 53.9126 40.5791 50.107 40.5791 45.4126C40.5791 40.7182 36.7735 36.9126 32.0791 36.9126C27.3847 36.9126 23.5791 40.7182 23.5791 45.4126C23.5791 50.107 27.3847 53.9126 32.0791 53.9126Z"
        fill="white"
        stroke="#063649"
        stroke-width="2"
      />
      <path
        d="M28.9473 42.2808L35.2104 48.544"
        stroke="#063649"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M35.2104 42.2808L28.9473 48.5439"
        stroke="#063649"
        stroke-width="2"
        stroke-linecap="round"
      />
    </g>
  </svg>
);

export { GeneralErrorStateIcon };
