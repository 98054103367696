import theme from 'Theme';

type environmentColors = {
  [key: string]: string;
};
// Using legacy theme.palette
export const EnvironmentColorMap: environmentColors = {
  prod: 'GREEN400',
  sandbox: 'YELLOW300',
  'internal test': 'BLUE075',
  demo: 'GREEN075',
  dev: 'BLUE300',
  'customer test': 'NEUTRAL080',
};

function useEnvironmentColor(environment?: string): string {
  if (!environment) return 'NEUTRAL080';

  if (typeof environment === 'string') {
    const colorString = EnvironmentColorMap[environment] || 'NEUTRAL080';
    const returnColor = theme.palette[colorString];
    return returnColor;
  } else {
    throw new Error('Invalid input: Please provide a valid string.');
  }
}

export { useEnvironmentColor };
