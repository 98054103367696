import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  errorTable: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
  },
  errorHeader: {
    fontSize: theme.spacing(3.5),
    fontWeight: 300,
    lineHeight: 1.25,
    marginBottom: theme.spacing(2),
  },
  errorDescription: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.NEUTRAL700,
  },
}));

export default useStyles;
