import { decorate, observable } from 'mobx';

import Order from './Order';

class AWSOrder extends Order {
  provider = 'AWS';

  headless_email_sent = false;
}

decorate(AWSOrder, {
  provider: observable,
  headless_email_sent: observable,
});

export default AWSOrder;
