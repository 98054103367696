import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { isEmpty, truncate } from 'lodash/fp';
import { Typography } from 'vendor/material';
import { CompanyAvatar, ProviderIcon } from '@tackle-io/platform-ui';

import { Products } from 'stores';
import { useStore, hasLoaded } from 'hooks';

import useStyles from './CompanyHeader.styles';

interface CompanyHeaderProps {
  productId?: string;
  cloud?: string;
  companyName: string;
  companyDomain: string;
  CompanyAvatarProps?: object;
  secondaryText?: React.ReactChild;
}

const CompanyHeader: React.FC<CompanyHeaderProps> = ({
  cloud,
  productId,
  companyDomain,
  companyName,
  CompanyAvatarProps,
  secondaryText,
}) => {
  const classes = useStyles();

  let product = null;
  const productsStore = useStore(Products);
  if (hasLoaded(productsStore)) {
    product = productsStore.find(productId, cloud);
  }

  const providerClass = classNames(classes.provider, {
    [classes.hidden]: isEmpty(product),
  });

  const companyNameClass = classNames(classes.companyName, {
    [classes.verticallyCenter]: isEmpty(product),
  });

  return (
    <div className={classes.root}>
      <div className={classes.logos}>
        <CompanyAvatar
          domain={companyDomain}
          className={classes.logo}
          {...CompanyAvatarProps}
        />
        {!isEmpty(product) && (
          <div className={providerClass}>
            <ProviderIcon provider={product?.provider} fontSize="inherit" />
          </div>
        )}
      </div>
      <div>
        <Typography component="h1" className={companyNameClass}>
          {truncate({ length: 54 }, companyName)}
        </Typography>
        {!isEmpty(product) && (
          <Typography component="p" className={classes.productName}>
            {product?.name}
          </Typography>
        )}
        {secondaryText && (
          <div className={classes.secondaryText}>{secondaryText}</div>
        )}
      </div>
    </div>
  );
};

export default observer(CompanyHeader);
