import { decorate, action, computed, observable } from 'mobx';
import { Provider } from '@tackle-io/platform-ui';
import Store from './Store';
import api from 'utils/api';

const DEFAULT_ITEMS_PER_PAGE = 25;

export interface Event {
  event_id: string;
  group_id: string;
  created_at: string;
  productid?: string;
  customerid?: string;
  source: string;
  success: boolean;
  outgoing_payload?: object;
  outgoing_response?: object;
  actor: any | null;
  title: string;
  original_event: boolean;
  event_type: string;
  cloud_provider?: string;
  destination: Exclude<Provider, 'stripe'>;
  extra_data: any | null;
  vendorid: string;
}

class EventsStore extends Store {
  initiated = false;

  get events(): Event[] {
    return super.data || [];
  }

  set events(events) {
    super.data = events;
  }

  getPage = (pageIdx, itemsPerPage): Promise<any> => {
    super.loading = true;

    return api
      .get(`v1/events?page_idx=${pageIdx}&items_per_page=${itemsPerPage}`)
      .json<{ events: Event[] }>()
      .then((data) => {
        this.events = data.events;
        super.loading = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };

  load = (): Promise<any> => {
    return this.getPage(0, DEFAULT_ITEMS_PER_PAGE).finally(() => {
      this.initiated = true;
    });
  };

  replay = (event): Promise<any> => {
    return api
      .post('v1/events/retry', {
        json: { event_id: event.event_id, group_id: event.group_id },
      })
      .json()
      .then((data) => {
        super.loading = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };
}

decorate(EventsStore, {
  initiated: observable,
  events: computed,
  load: action,
  replay: action,
});

export default EventsStore;

const eventsStore = new EventsStore();
export { eventsStore };
