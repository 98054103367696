import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.NEUTRAL600,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.typography.pxToRem(8),
    fontWeight: 500,
  },
  nameContainer: {
    padding: theme.spacing(1),
  },
  name: {
    color: theme.palette.NEUTRAL300,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
  },
  value: {
    color: theme.palette.NEUTRAL500,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  rowsContainer: {
    maxHeight: '55vh',
    overflow: 'auto',
    borderTop: `1px solid ${theme.palette.NEUTRAL100}`,
  },
  scrollShadow: {
    maskImage: 'linear-gradient(to bottom, black 93%, transparent 100%)',
  },
  rows: {
    padding: theme.spacing(1),
    '&:nth-child(odd)': {
      background: theme.palette.NEUTRAL020,
    },
  },
  totalPaymentsContainer: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.NEUTRAL100}`,
  },
  totalPayments: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
