//ace related enum needFromCloud
export enum v13AceNeedFromCloudEnum {
  ARCHITECTURAL_VALIDATION = 'Architectural validation',
  BUSINESS_PRESENTATION = 'Business presentation',
  COMPETITIVE_INFORMATION = 'Competitive Information',
  PRICING_ASSISTANCE = 'Pricing Assistance',
  TECHNICAL_CONSULTATION = 'Technical consultation',
  TOTAL_COST_OF_OWNERSHIP_EVALUATION = 'Total Cost of Ownership Evaluation',
  FOR_VISIBILITY_NO_ASSISTANCE_NEEDED = 'For Visibility - No Assistance Needed',
  DEAL_SUPPORT = 'Deal support',
  OTHER = 'Other',
}
