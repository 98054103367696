import { DateTime } from 'luxon';
import { Link } from 'components/Link';
import { useTheme, Box } from 'vendor/material';

const PRIVACY_POLICY_URI = 'https://tackle.io/privacy-policy/';
const CURRENT_YEAR = DateTime.now().year;

export const AppFooter = () => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="center"
      px={3}
      py={1}
      color={theme.palette.NEUTRAL400}
      fontSize="0.775rem"
    >
      © {CURRENT_YEAR} Tackle.io
      <Box ml={2}>
        <Link to={PRIVACY_POLICY_URI} external>
          Privacy policy
        </Link>
      </Box>
    </Box>
  );
};
