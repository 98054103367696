import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../../images/tackle-logo';

import useStyles from './Modal.styles';

const Modal = ({ children }) => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <header className={classes.header}>
        <Link to="/" replace>
          <Logo theme="light" className={classes.logo} />
        </Link>
      </header>
      {children}
    </main>
  );
};

export default Modal;
