import { find, isEmpty, isNil } from 'lodash/fp';
import { MarketplaceAction } from 'pages/PrivateOffers/utils/autoCreate';
import { useCallback, useContext } from 'react';
import {
  camelCase,
  convertKeysTo,
  withConvenienceBooleans,
} from 'stores/utils';
import api from 'utils/api';
import { Cloud } from 'utils/cloudTypes';
import offersApi from 'utils/offersApi';
import Context from './context';
import {
  APIResponse,
  APISuccessResponse,
  MarketplaceOffer,
  deserialize,
  isAPISuccess,
  MetadataPricing,
  OfferType,
  PrivateOffer,
  PrivateOffersReducerState,
  serialize,
} from './typings';
import {
  fetchMarketplaceOffer,
  getPrivateOffers,
  getPrivateOffersResponse,
  ResponseType,
} from './offersApi';
import { ampli } from 'utils/analytics/ampli';
import { PrivateOfferReducerAction } from './reducer';

export interface CsvResponse {
  fileName: string;
  data: Blob;
}

type Hook = Omit<PrivateOffersReducerState, 'dispatch'> & {
  create: (privateOffer: Partial<PrivateOffer>) => Promise<string>;
  replaceAll: (searchParams: URLSearchParams) => Promise<void>;
  getCsv: (searchParams: URLSearchParams) => Promise<CsvResponse>;
  update: (
    poId: PrivateOffer['poId'],
    updates: Partial<PrivateOffer>,
  ) => Promise<PrivateOffer>;
  archive: (poId: PrivateOffer['poId']) => Promise<void>;
  getSinglePrivateOffer: (poId: PrivateOffer['poId']) => Promise<PrivateOffer>;
  sync: (poId: PrivateOffer['poId']) => Promise<void>;
  getMarketplaceOffer: (
    cloud: Cloud,
    offerRef: PrivateOffer['offerRef'],
    searchParams?: URLSearchParams,
  ) => Promise<MarketplaceOffer>;
  getAmendmentSourceOffer: (
    aboLinkedOfferRef: PrivateOffer['aboLinkedOfferRef'],
  ) => Promise<PrivateOffer | null>;
  sendPurchaseInstructions: (poId: PrivateOffer['poId']) => Promise<void>;
  createMarketplaceOffer: (offer: PrivateOffer) => Promise<void>;
  getAWSMarketplacePricing: (
    encryptedProductId: string,
  ) => Promise<MetadataPricing | void>;
  changeMarketplaceOfferExpiration: (
    poId: PrivateOffer['poId'],
    cloud: Cloud,
    newExpirationDate: string,
  ) => Promise<void>;
  cancelMarketplaceOffer: (
    poId: PrivateOffer['poId'],
    cloud: Cloud,
  ) => Promise<void>;
  contentById: (poId: string) => PrivateOffer;
};

const usePrivateOffers = (): Hook => {
  const {
    content,
    status,
    selectedOffer,
    selectedOfferStatus,
    error,
    dispatch,
  } = useContext(Context) as PrivateOffersReducerState;
  /**
   * Uses Offers API
   *
   * This action will return a synchronous response from Offers API. On success, it will kick off an
   * asynchronous process to create the offer in the marketplace. On failure, an error message will be displayed
   * to the user in the alert banner.
   */
  const createMarketplaceOffer = (offer: PrivateOffer): Promise<void> => {
    const action =
      offer.offerType === OfferType.Direct
        ? !isEmpty(offer.aboLinkedOfferRef)
          ? MarketplaceAction.CreatePrivateOfferSaasReplacementOffer
          : MarketplaceAction.CreatePrivateOfferSaas
        : MarketplaceAction.CreatePartnerOfferSaas;

    return performOffersApiMarketplaceActionRequest(
      offer.poId,
      offer.cloud as Cloud,
      action,
    );
  };

  const changeMarketplaceOfferExpiration = (
    poId: PrivateOffer['poId'],
    cloud: Cloud,
    newExpirationDate: string,
  ): Promise<void> => {
    const actionData = { expiration_date: newExpirationDate };

    return performOffersApiMarketplaceActionRequest(
      poId,
      cloud,
      MarketplaceAction.UpdatePrivateOfferChangeExpiration,
      actionData,
    );
  };

  const cancelMarketplaceOffer = (
    poId: PrivateOffer['poId'],
    cloud: Cloud,
  ): Promise<void> => {
    return performOffersApiMarketplaceActionRequest(
      poId,
      cloud,
      MarketplaceAction.UpdatePrivateOfferCancelOffer,
    );
  };

  const performOffersApiMarketplaceActionRequest = (
    poId: PrivateOffer['poId'],
    cloud: Cloud,
    action: MarketplaceAction,
    actionData?: any,
  ): Promise<void> =>
    new Promise((resolve, reject) => {
      dispatch({ type: PrivateOfferReducerAction.Update });

      offersApi
        .post(`public/v1/private-offers/${poId}/marketplace-action-request`, {
          json: {
            action,
            action_data: actionData,
            dry_run: false,
            use_api: [Cloud.Aws, Cloud.Azure].includes(cloud),
          },
          throwHttpErrors: false,
        })
        .json()
        .then(
          (resp: APISuccessResponse) =>
            new Promise<APIResponse>((res, rej) =>
              isAPISuccess(resp) ? res(resp) : rej(resp),
            ),
        )
        .then(({ data }: APISuccessResponse) => {
          dispatch({
            type: PrivateOfferReducerAction.UpdateContentById,
            payload: {
              id: data.po_id,
              content: deserialize(data),
            },
          });
          resolve();
        })
        .catch((requestError) => {
          dispatch({
            type: PrivateOfferReducerAction.SetError,
            payload: { error: requestError },
          });
          reject(requestError);
        });
    });

  /**
   * Uses Offers API. Retrieves an AWS product's pricing data from AWS using CAPI.
   * @param {string} encryptedProductId - the ID of the product in AMMP
   *
   * On success, all pricing data for the product is returned, and the AWS dimension data is supplied as
   * the default dimension data on the offers form and offers review page.
   * On failure, dimension data will be prepopulated by data from Tackle listing.
   *
   * See https://tackle.atlassian.net/browse/OFFERS-1304.
   */
  const getAWSMarketplacePricing = async (
    encryptedProductId: string,
  ): Promise<MetadataPricing | void> => {
    return await offersApi
      .get(`public/v1/marketplace/aws/product/${encryptedProductId}/pricing`)
      .json()
      .then((resp: MetadataPricing) => {
        return convertKeysTo(camelCase)(resp);
      })
      .catch((requestError) => {
        console.error(requestError);
      });
  };

  const create = (
    privateOffer: Partial<PrivateOffer>,
  ): Promise<PrivateOffer['poId']> =>
    new Promise((resolve, reject) => {
      dispatch({ type: PrivateOfferReducerAction.Update });

      api
        .post('v2/private-offers', {
          json: serialize(privateOffer),
        })
        .json()
        .then(({ data }: APISuccessResponse) => {
          resolve(data.po_id);
        })
        .catch((requestError) => {
          dispatch({
            type: PrivateOfferReducerAction.SetError,
            payload: { error: requestError },
          });
          reject(requestError);
        });
    });

  const replaceAll = async (searchParams: URLSearchParams): Promise<void> => {
    dispatch({ type: 'SYNC' });

    try {
      const privateOffers = await getPrivateOffers({ searchParams });

      dispatch({
        type: PrivateOfferReducerAction.SetContent,
        payload: { content: privateOffers },
      });
    } catch (error) {
      dispatch({
        type: PrivateOfferReducerAction.SetError,
        payload: { error },
      });
    }
  };

  const getFileNameAndBlobDataFromResponse = async (response: Response) => {
    const dispositionHeader = response.headers.get('content-disposition');
    const fileName = dispositionHeader.split('filename=')[1] as string;
    const data = await response.blob();

    return { fileName, data };
  };

  const getCsv = async (
    searchParams: URLSearchParams,
  ): Promise<CsvResponse> => {
    return getPrivateOffersResponse({ searchParams }, ResponseType.CSV).then(
      getFileNameAndBlobDataFromResponse,
    );
  };

  const update = (
    poId: PrivateOffer['poId'],
    updates: Partial<PrivateOffer>,
  ): Promise<PrivateOffer> =>
    new Promise((resolve, reject) => {
      dispatch({ type: PrivateOfferReducerAction.Update });

      api
        .patch(`v2/private-offers/${poId}`, {
          json: serialize(updates),
          throwHttpErrors: false,
        })
        .json()
        .then(
          (resp: APISuccessResponse) =>
            new Promise<APIResponse>((res, rej) =>
              isAPISuccess(resp) ? res(resp) : rej(resp),
            ),
        )
        .then(({ data }: APISuccessResponse) => {
          const updatedContent = deserialize(data);
          dispatch({
            type: PrivateOfferReducerAction.UpdateContentById,
            payload: {
              id: poId,
              content: updatedContent,
            },
          });
          resolve(updatedContent);
        })
        .catch((requestError) => {
          dispatch({
            type: PrivateOfferReducerAction.SetError,
            payload: { error: requestError },
          });
          reject(requestError);
        });
    });

  const archive = (poId: PrivateOffer['poId']): Promise<void> =>
    new Promise((resolve, reject) => {
      dispatch({ type: PrivateOfferReducerAction.Update });

      api
        .delete(`v2/private-offers/${poId}`, {})
        .json()
        .then(({ data }: APISuccessResponse) => {
          dispatch({
            type: PrivateOfferReducerAction.UpdateContentById,
            payload: {
              id: poId,
              content: deserialize(data),
            },
          });
          resolve();
        })
        .catch((requestError) => {
          dispatch({
            type: PrivateOfferReducerAction.SetError,
            payload: { error: requestError },
          });
          reject(requestError);
        });
    });

  const sendPurchaseInstructions = (
    poId: PrivateOffer['poId'],
  ): Promise<void> =>
    new Promise((resolve, reject) => {
      dispatch({ type: PrivateOfferReducerAction.Update });

      api
        .post(`v2/private-offers/${poId}/purchase-instructions`, {})
        .json()
        .then(({ data }: APISuccessResponse) => {
          ampli.offerExtended({
            cloud: data.cloud,
            offer_id: data.po_id,
            offer_type: data.offer_type,
          });
          dispatch({
            type: PrivateOfferReducerAction.UpdateContentById,
            payload: {
              id: poId,
              content: deserialize(data),
            },
          });
          resolve();
        })
        .catch((requestError) => {
          dispatch({
            type: PrivateOfferReducerAction.SetError,
            payload: { error: requestError },
          });
          reject(requestError);
        });
    });

  const getSinglePrivateOffer = async (
    poId: PrivateOffer['poId'],
  ): Promise<void | PrivateOffer> => {
    const controller = new AbortController();
    const { signal } = controller;

    return api
      .get(`v2/private-offers/${poId}`, { signal })
      .json()
      .then(({ data }: APISuccessResponse) => {
        return deserialize(data);
      })
      .catch((requestError) => {
        dispatch({
          type: PrivateOfferReducerAction.SetError,
          payload: { error: requestError },
        });
      });
  };

  const sync = async (poId: PrivateOffer['poId']): Promise<void> => {
    if (selectedOfferStatus === 'syncing') {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;
    dispatch({ type: PrivateOfferReducerAction.SyncOne });

    api
      .get(`v2/private-offers/${poId}`, { signal })
      .json()
      .then(({ data }: APISuccessResponse) => {
        dispatch({
          type: 'SET_ONE',
          payload: {
            id: poId,
            content: deserialize(data),
          },
        });
      })
      .catch((requestError) => {
        dispatch({
          type: PrivateOfferReducerAction.SetError,
          payload: { error: requestError },
        });
      });
  };

  const getMarketplaceOffer = async (
    cloud: Cloud,
    offerRef: string,
    searchParams?: URLSearchParams,
  ): Promise<MarketplaceOffer | null> => {
    try {
      return fetchMarketplaceOffer(cloud, offerRef, searchParams);
    } catch (error) {
      dispatch({
        type: PrivateOfferReducerAction.SetError,
        payload: { error },
      });
    }

    return null;
  };

  const getAmendmentSourceOffer = async (
    aboLinkedOfferRef: PrivateOffer['aboLinkedOfferRef'],
  ): Promise<PrivateOffer | null> => {
    try {
      const searchParams = new URLSearchParams({
        offerRef: aboLinkedOfferRef,
        status: 'not-archived',
        limit: '1',
      });
      const privateOffers = (await getPrivateOffers({ searchParams })) ?? [];

      return privateOffers?.at(0);
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: { error } });
    }

    return null;
  };

  return {
    ...withConvenienceBooleans({
      content,
      status,
      error,
    }),
    create,
    replaceAll,
    getCsv,
    update,
    archive,
    getSinglePrivateOffer,
    sync,
    getAmendmentSourceOffer,
    getMarketplaceOffer,
    selectedOffer,
    selectedOfferStatus,
    sendPurchaseInstructions,
    createMarketplaceOffer,
    getAWSMarketplacePricing,
    changeMarketplaceOfferExpiration,
    cancelMarketplaceOffer,
    contentById: useCallback(
      (poId: string) => find<PrivateOffer | undefined>({ poId }, content),
      [content],
    ),
  } as Hook;
};

export default usePrivateOffers;

export const useSelectedPrivateOffer = (poId: string) => {
  // Load all private offers
  const {
    update,
    archive,
    sendPurchaseInstructions,
    createMarketplaceOffer,
    changeMarketplaceOfferExpiration,
    cancelMarketplaceOffer,
    getMarketplaceOffer,
    sync,
    selectedOfferStatus,
    selectedOffer,
    error,
    hasError,
  }: Hook = usePrivateOffers();

  return {
    content: selectedOffer,
    status: selectedOfferStatus,
    error,
    hasError,
    isIdle: selectedOfferStatus === 'idle',
    isLoading: selectedOfferStatus === 'loading',
    isSyncing: selectedOfferStatus === 'syncing',
    hasValue: !isNil(selectedOffer),
    update: useCallback(
      (updates: Partial<PrivateOffer>) => update(poId, updates),
      [poId, update],
    ),
    archive: useCallback(() => archive(poId), [archive, poId]),
    sync: useCallback(() => sync(poId), [sync, poId]),
    syncAndSelectOffer: useCallback((newPoId: string) => sync(newPoId), [sync]),
    sendPurchaseInstructions: useCallback(
      () => sendPurchaseInstructions(poId),
      [sendPurchaseInstructions, poId],
    ),
    createMarketplaceOffer: useCallback(
      () => createMarketplaceOffer(selectedOffer),
      [createMarketplaceOffer, selectedOffer],
    ),
    changeMarketplaceOfferExpiration: useCallback(
      (newExpirationDate: string) =>
        changeMarketplaceOfferExpiration(
          selectedOffer.poId,
          selectedOffer.cloud as Cloud,
          newExpirationDate,
        ),
      [selectedOffer, changeMarketplaceOfferExpiration],
    ),
    cancelMarketplaceOffer: useCallback(
      () =>
        cancelMarketplaceOffer(
          selectedOffer.poId,
          selectedOffer.cloud as Cloud,
        ),
      [selectedOffer, cancelMarketplaceOffer],
    ),
    getMarketplaceOffer: useCallback(
      (cloud: Cloud, offerRef: string) => getMarketplaceOffer(cloud, offerRef),
      [getMarketplaceOffer],
    ),
  };
};
