import React from 'react';
import { AzurePricingPlanProductBillingTerm } from '../../../../../stores/products/typings/AzureProduct';
import {
  AzurePricingBillingTypeText,
  AzurePricingType,
  AzurePricingTypeAndValue,
  BillingTermValue,
} from '../../../../../stores/privateOffers/typings';

export const billingTermLabels: { [bt: string]: string } = {
  [BillingTermValue.OneMonth]: AzurePricingBillingTypeText.OneMonth,
  [BillingTermValue.OneYear]: AzurePricingBillingTypeText.OneYear,
  [BillingTermValue.TwoYears]: AzurePricingBillingTypeText.TwoYears,
  [BillingTermValue.ThreeYears]: AzurePricingBillingTypeText.ThreeYears,
};

const billingTermShapeToBillingTermValue: {
  [v: number]: { [t: string]: string };
} = {
  1: {
    month: BillingTermValue.OneMonth,
    year: BillingTermValue.OneYear,
  },
  2: {
    year: BillingTermValue.TwoYears,
  },
  3: {
    year: BillingTermValue.ThreeYears,
  },
};

export const mapBillingTermShapeToBillingTermValue = (
  bt: AzurePricingPlanProductBillingTerm,
): BillingTermValue => {
  const { value, type } = bt;
  const billingTermTypesToBillingTermValue =
    billingTermShapeToBillingTermValue[value] ?? {};

  return (billingTermTypesToBillingTermValue[type] ??
    BillingTermValue.OneMonth) as BillingTermValue;
};

const billingTermTypeToBillingTermShapeType: { [t: string]: string } = {
  month: AzurePricingType.Month,
  year: AzurePricingType.Year,
  years: AzurePricingType.Year,
};

export const mapBillingTermValueToBillingTermShapeValueAndType = (
  bt: BillingTermValue | null,
): Pick<AzurePricingPlanProductBillingTerm, 'value' | 'type'> => {
  const [btValueStr, btType] = (bt ?? '').split('-');
  const value = btValueStr ? parseInt(btValueStr) : 0;
  const type = billingTermTypeToBillingTermShapeType[btType] ?? '';

  return { value, type };
};

export const getBillingTermOptions = (
  billingTerms: AzurePricingPlanProductBillingTerm[],
): React.ReactElement[] => {
  return (billingTerms ?? [])?.map((bt: AzurePricingPlanProductBillingTerm) => {
    const billingTerm = mapBillingTermShapeToBillingTermValue(bt);
    const isOneMonthBillingTerm = billingTerm === BillingTermValue.OneMonth;
    const billingTermLabel = billingTermLabels[billingTerm];

    return (
      <option key={billingTerm} value={billingTerm}>
        {billingTermLabel}{' '}
        {isOneMonthBillingTerm ? '(Payment schedule not available)' : ''}
      </option>
    );
  });
};

export const getAbsolutePriceBillingTerm = (
  billingTerm: BillingTermValue,
): AzurePricingTypeAndValue => {
  const { type, value } =
    mapBillingTermValueToBillingTermShapeValueAndType(billingTerm);

  return { type: type as AzurePricingType, value };
};
