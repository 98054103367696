const SyncErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 20V18H5.75L5.35 17.65C4.48333 16.8833 3.875 16.0083 3.525 15.025C3.175 14.0417 3 13.05 3 12.05C3 10.2 3.55417 8.55417 4.6625 7.1125C5.77083 5.67083 7.21667 4.71667 9 4.25V6.35C7.8 6.78333 6.83333 7.52083 6.1 8.5625C5.36667 9.60417 5 10.7667 5 12.05C5 12.8 5.14167 13.5292 5.425 14.2375C5.70833 14.9458 6.15 15.6 6.75 16.2L7 16.45V14H9V20H3ZM12 17C11.7167 17 11.4792 16.9042 11.2875 16.7125C11.0958 16.5208 11 16.2833 11 16C11 15.7167 11.0958 15.4792 11.2875 15.2875C11.4792 15.0958 11.7167 15 12 15C12.2833 15 12.5208 15.0958 12.7125 15.2875C12.9042 15.4792 13 15.7167 13 16C13 16.2833 12.9042 16.5208 12.7125 16.7125C12.5208 16.9042 12.2833 17 12 17ZM11 13V7H13V13H11ZM15 19.75V17.65C16.2 17.2167 17.1667 16.4792 17.9 15.4375C18.6333 14.3958 19 13.2333 19 11.95C19 11.2 18.8583 10.4708 18.575 9.7625C18.2917 9.05417 17.85 8.4 17.25 7.8L17 7.55V10H15V4H21V6H18.25L18.65 6.35C19.4667 7.16667 20.0625 8.05417 20.4375 9.0125C20.8125 9.97083 21 10.95 21 11.95C21 13.8 20.4458 15.4458 19.3375 16.8875C18.2292 18.3292 16.7833 19.2833 15 19.75Z"
      fill="#DE350B"
    />
  </svg>
);
export default SyncErrorIcon;
