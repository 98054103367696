import { Grid } from 'vendor/material';
import { TextField } from '@tackle-io/platform-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useStyles from '../styles';
import { DimensionFieldProps } from '../typings';
import { PlanFieldController } from './PlanFieldController';

const AzurePerUnitDimensionField: React.FC<DimensionFieldProps> = ({
  field,
  index,
  productId,
  privateOfferId,
}) => {
  const classes = useStyles();
  const { control, errors } = useFormContext();

  return (
    <Grid container spacing={2} className={classes.dimensionFields}>
      <Grid item md={5} sm={11} xs={10} className={classes.fieldKey}>
        <PlanFieldController
          field={field}
          control={control}
          errors={errors}
          productId={productId}
          privateOfferId={privateOfferId}
          index={index}
        />
      </Grid>
      <Grid item md={5} sm={12} xs={12} className={classes.priceContainer}>
        <Controller
          name={`pricing.dimensions[${index}].price`}
          control={control}
          defaultValue={field.price ?? 0}
          render={({ ref, onChange, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Price per"
              error={errors.pricing?.dimensions?.[index]?.price?.message}
              mode="numberformat"
              type="number"
              fixedDecimalScale
              aria-label={`Dimension ${index} price per`}
              inputProps={{ ref }}
              onChange={({ target: { value } }) => onChange(value)}
            />
          )}
        />
      </Grid>
      <Grid item md={2} sm={12} xs={12} className={classes.fieldValue}>
        <Controller
          name={`pricing.dimensions[${index}].quantity`}
          control={control}
          defaultValue={field.quantity ?? 0}
          render={({ ref, onChange, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Quantity"
              type="number"
              error={errors.pricing?.dimensions?.[index]?.quantity?.message}
              inputRef={ref}
              inputProps={{
                'aria-label': `Dimension ${index} quantity`,
              }}
              onChange={({ target: { value } }) => onChange(value)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AzurePerUnitDimensionField;
