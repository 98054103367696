import { isAmiHourlyAnnual, ListingTypeType } from './listingTypes';
import { Cloud, CloudType } from './cloudTypes';
import { toFinite } from 'lodash';
import { BillingTermValue } from 'stores/privateOffers/typings';
import { getMonthsDifferenceFromJSDates } from 'pages/PrivateOffers/utils/formatFormData';

export enum PricingDurationUnitOfMeasure {
  Months = 'Months',
  Monthly = 'Monthly',
  Annual = 'Annual',
  Days = 'Days',
  Quarters = 'Quarters',
  Years = 'Years',
}

function toDays(duration: string | number): string {
  return `${toFinite(duration)} ${PricingDurationUnitOfMeasure.Days}`;
}

function toMonths(duration: string | number): string {
  return `${toFinite(duration)} ${PricingDurationUnitOfMeasure.Months}`;
}

export function createDurationValue(
  duration: string | number,
  cloud: CloudType,
  listingType: ListingTypeType,
  billingTerm?: BillingTermValue,
  serviceStartAt?: string,
  serviceEndAt?: string,
): string | number {
  if (
    billingTerm === BillingTermValue.FutureDated &&
    serviceStartAt &&
    serviceEndAt
  ) {
    const start = new Date(serviceStartAt);
    const end = new Date(serviceEndAt);
    duration = getMonthsDifferenceFromJSDates(start, end);
  }

  switch (cloud) {
    case Cloud.Aws:
      if (isAmiHourlyAnnual(listingType)) return toDays(duration);

      return toMonths(duration);
    case Cloud.Gcp:
      return toMonths(duration);
    default:
      return duration;
  }
}
