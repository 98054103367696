import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    position: 'relative',
    zIndex: 200,
  },
  icon: {
    fontSize: theme.typography.pxToRem(144),
    fill: theme.palette.NEUTRAL050,
    // These two lines only affect co-sell icon, which is imported via @material-symbols
    width: theme.typography.pxToRem(144),
    height: theme.typography.pxToRem(144),
  },
  title: {
    color: theme.palette.NEUTRAL900,
    fontSize: theme.typography.pxToRem(40),
  },
  message: {
    color: theme.palette.NEUTRAL500,
    fontSize: theme.typography.pxToRem(18),
    maxWidth: 600,
    margin: '0 auto',
  },
}));

export default useStyles;
