import { find } from 'lodash/fp';

import { Activity } from 'stores/privateOffers/typings';
import { ActivitySlug, PrivateOfferStep } from './constants';

interface ActivitySlugStepPair {
  slug: ActivitySlug;
  step: PrivateOfferStep;
}

const ACTIVITY_SLUG_TO_SLUG_STEP_PAIR = {
  [ActivitySlug.BuyerAcceptedPrivateOffer]: {
    slug: ActivitySlug.BuyerAcceptedPrivateOffer,
    step: PrivateOfferStep.Accepted,
  },
  [ActivitySlug.BuyerViewedPrivateOffer]: {
    slug: ActivitySlug.BuyerViewedPrivateOffer,
    step: PrivateOfferStep.Viewed,
  },
  [ActivitySlug.BuyerOpenedPurchaseInstructions]: {
    slug: ActivitySlug.BuyerOpenedPurchaseInstructions,
    step: PrivateOfferStep.Opened,
  },
  [ActivitySlug.VendorSentPurchaseInstructions]: {
    slug: ActivitySlug.VendorSentPurchaseInstructions,
    step: PrivateOfferStep.Sent,
  },
  [ActivitySlug.VendorCreatedPrivateOffer]: {
    slug: ActivitySlug.VendorCreatedPrivateOffer,
    step: PrivateOfferStep.Created,
  },
  [ActivitySlug.VendorCreatedPrivateOfferInSalesforce]: {
    slug: ActivitySlug.VendorCreatedPrivateOfferInSalesforce,
    step: PrivateOfferStep.Drafted,
  },
  [ActivitySlug.VendorDraftedPrivateOffer]: {
    slug: ActivitySlug.VendorDraftedPrivateOffer,
    step: PrivateOfferStep.Drafted,
  },
  // step: 1
  //   - does not exist in activities
  //   - only exists on 'Select a Listing' in /private-offers/new
} as Record<ActivitySlug, ActivitySlugStepPair>;

interface ActivityAction {
  step: PrivateOfferStep;
  slug: ActivitySlug;
}

const lastActivityAction = (
  activities: Activity[] = [],
  hasOfferRef: boolean = false,
): ActivityAction => {
  if (find({ slug: ActivitySlug.BuyerAcceptedPrivateOffer }, activities)) {
    return ACTIVITY_SLUG_TO_SLUG_STEP_PAIR[
      ActivitySlug.BuyerAcceptedPrivateOffer
    ];
  }

  let activity: ActivityAction;

  if (find({ slug: ActivitySlug.BuyerViewedPrivateOffer }, activities)) {
    activity =
      ACTIVITY_SLUG_TO_SLUG_STEP_PAIR[ActivitySlug.BuyerViewedPrivateOffer];
  } else if (
    find({ slug: ActivitySlug.BuyerOpenedPurchaseInstructions }, activities)
  ) {
    activity =
      ACTIVITY_SLUG_TO_SLUG_STEP_PAIR[
        ActivitySlug.BuyerOpenedPurchaseInstructions
      ];
  } else if (
    find({ slug: ActivitySlug.VendorSentPurchaseInstructions }, activities)
  ) {
    activity =
      ACTIVITY_SLUG_TO_SLUG_STEP_PAIR[
        ActivitySlug.VendorSentPurchaseInstructions
      ];
  } else if (
    !hasOfferRef &&
    find(
      { slug: ActivitySlug.VendorCreatedPrivateOfferInSalesforce },
      activities,
    )
  ) {
    activity =
      ACTIVITY_SLUG_TO_SLUG_STEP_PAIR[
        ActivitySlug.VendorCreatedPrivateOfferInSalesforce
      ];
  } else if (!hasOfferRef) {
    activity =
      ACTIVITY_SLUG_TO_SLUG_STEP_PAIR[ActivitySlug.VendorDraftedPrivateOffer];
  } else {
    activity =
      ACTIVITY_SLUG_TO_SLUG_STEP_PAIR[ActivitySlug.VendorCreatedPrivateOffer];
  }

  // If an offer was cancelled, we replace the next/active step with cancelled
  // to preserve the progress that was made prior to cancellation
  if (
    find(
      { slug: ActivitySlug.VendorCancelledMarketplaceOfferSuccess },
      activities,
    )
  ) {
    return {
      step: activity.step,
      slug: ActivitySlug.VendorCancelledMarketplaceOfferSuccess,
    };
  }

  return activity;
};

export default lastActivityAction;
