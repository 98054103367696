import { decorate, observable } from 'mobx';

class GcpEntitlement {
  account = '';

  createTime = '';

  name = '';

  plan = '';

  product = '';

  state = '';

  quoteExternalName = null;

  constructor(props) {
    Object.assign(this, props);
  }
}

decorate(GcpEntitlement, {
  account: observable,
  createTime: observable,
  name: observable,
  plan: observable,
  product: observable,
  state: observable,
  quoteExternalName: observable,
});

export default GcpEntitlement;
