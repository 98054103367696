import { decorate, observable, action, computed } from 'mobx';

import api from '../utils/api';
import Store from './Store';
import { toDateTime } from '../utils/dates';
import { reportError } from 'utils/monitor';

function recordsByGroupId(records) {
  return records.reduce((obj, record) => {
    const key = record.groupid ? record.groupid : record.uuid;
    if (!obj[key]) obj[key] = [];
    obj[key] = obj[key].concat(record);
    return obj;
  }, {});
}

class MeteringStore extends Store {
  get records() {
    return super.data;
  }

  set records(records) {
    super.data = records;
  }

  get recordsByGroupId() {
    return this._recordsByGroupId;
  }

  set recordsByGroupId(recordsByGroupId = {}) {
    this._recordsByGroupId = recordsByGroupId;
  }

  load = () => {
    super.loading = true;

    const localMeteringRecords =
      JSON.parse(localStorage.getItem('metering-records')) || [];

    if (localMeteringRecords.length) {
      this.records = localMeteringRecords.map((mr) => ({
        ...mr,
        created_at: toDateTime(mr.created_at, 'Missing created_at date'),
        sent_at: toDateTime(mr.sent_at, 'Missing sent_at date'),
      }));

      this.recordsByGroupId = recordsByGroupId(this.records);
      super.loading = false;
      super.syncing = true;
    }

    api
      .get('vendor/metering-records')
      .json()
      .then(({ records = [] }) => {
        try {
          localStorage.setItem('metering-records', JSON.stringify(records));
        } catch (e) {
          if (e.name !== 'QuotaExceededError') reportError(e);
        }

        this.records = records.map((mr) => ({
          ...mr,
          created_at: toDateTime(mr.created_at, 'Missing created_at date'),
          sent_at: toDateTime(mr.sent_at, 'Missing sent_at date'),
        }));

        this.recordsByGroupId = recordsByGroupId(this.records);
        super.loading = false;
        super.syncing = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };

  createAws = (payload) => {
    return api.post('vendor/manual-meter-multi', {
      json: payload,
      throwHttpErrors: false,
    });
  };

  createAzure = (payload) => {
    return api.post('v1/vendor/easy-meter/azure', {
      json: payload,
      throwHttpErrors: false,
    });
  };

  createGcp = (payload) => {
    return api.post('v2/metering/meter', {
      json: payload,
      throwHttpErrors: false,
    });
  };

  find = (id) => this.records.find(({ uuid }) => uuid === id);
}

decorate(MeteringStore, {
  records: computed,
  _recordsByGroupId: observable,
  recordsByGroupId: computed,
  load: action,
});

export default MeteringStore;

const meteringStore = new MeteringStore();
export { meteringStore };
