import React from 'react';
import { Grid } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { CustomerAccount } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';

interface AceCustomerDetailsProps {
  account: CustomerAccount;
}
const AceCustomerDetails: React.FC<AceCustomerDetailsProps> = ({ account }) => {
  return (
    <DetailsSectionCard title="Customer details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem itemTitle="Customer DUNS" value={account?.duns} />

        <InfoItem
          itemTitle="Customer company name"
          value={account?.companyName}
        />

        <InfoItem itemTitle="Industry vertical" value={account?.industry} />

        <InfoItem itemTitle="Industry other" value={account?.otherIndustry} />
        <InfoItem itemTitle="Customer website" value={account?.websiteUrl} />
        <InfoItem itemTitle="Country" value={account?.address.countryCode} />
        <InfoItem itemTitle="Address" value={account?.address.streetAddress} />
        <InfoItem itemTitle="City" value={account?.address.city} />
        <InfoItem itemTitle="State" value={account?.address.stateOrRegion} />
        <InfoItem itemTitle="Postal code" value={account?.address.postalCode} />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceCustomerDetails;
