import { Card } from '@tackle-io/platform-ui';
import { useTheme } from 'ui';
import { useWindowSize } from 'react-use';

interface DashboardCardProps {
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  dataId?: string;
  customCardHeight?: number;
}

export const CARD_HEIGHT_BREAKPOINT = 425;
export const CARD_HEIGHT_MOBILE = 54;
export const CARD_HEIGHT_DEFAULT = 47;

const DashboardCard: React.FC<DashboardCardProps> = ({
  header,
  subheader,
  children,
  footer,
  dataId,
  customCardHeight,
}) => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const cardHeightDefault =
    width <= CARD_HEIGHT_BREAKPOINT ? CARD_HEIGHT_MOBILE : CARD_HEIGHT_DEFAULT;
  const cardHeight = customCardHeight ? customCardHeight : cardHeightDefault;
  const bodyHeight = theme.spacing(cardHeight);

  const styleObj = {
    padding: theme.spacing(3),
    display: 'flex',
    overflow: 'visible',
  };

  return (
    <Card
      data-id={dataId}
      paperStyle={styleObj}
      p={0}
      bodyStyle={{ width: '100%', height: bodyHeight }}
    >
      {header}
      {subheader}
      {children}
      {footer}
    </Card>
  );
};

export { DashboardCard };
