import { Event } from './ampli';

export type AnalyticsUser = {
  id: string;
  email: string;
  name: string;
  metadata: { vendorid: string; role?: string };
};

export type CurrentUser = {
  id: string;
  name?: string;
  email?: string;
  role?: string;
  vendor?: {
    id: string;
    name?: string;
    vendor_type?: string;
  };
};

export type InitOptions =
  | {
      apiKey: string;
    }
  | Record<string, any>;

export interface IAnalyticsService {
  init: (initOptions: InitOptions) => void;
  identify: (user: CurrentUser) => void;
  track: (
    eventName: Event['event_type'],
    eventProperties?: Record<string, any>,
    eventOptions?: Record<string, any>,
  ) => void;
  optIn: (user: AnalyticsUser) => void;
  optOut: (user: AnalyticsUser) => void;
  flush: () => void;
}

export enum PluginTypeEnum {
  ENRICHMENT = 'enrichment',
  BEFORE = 'before',
  DESTINATION = 'destination',
}

export enum EventType {
  PAGE_VIEWED = '[Amplitude] Page Viewed',
}

export interface IAnalyticsServices {
  [key: string]: IAnalyticsService;
}
