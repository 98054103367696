import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: theme.spacing(4),
  },
  pageTitle: {
    fontWeight: 300,
    fontSize: '40px',
  },
  pageSubtitle: {
    fontSize: theme.spacing(2),
  },
  syncTime: {
    fontSize: theme.spacing(1.5),
  },
}));

export default useStyles;
