import React, { useCallback } from 'react';
import { Grid, Box } from 'vendor/material';
import { FormSection } from '@tackle-io/platform-ui';
import { AwsProduct } from 'stores/products/typings';
import FieldsAwsAmiHourlyAnnualDuration from './FieldsAwsAmiHourlyAnnualDuration';
import UnitField from './UnitField';
import MarketplaceFeeField from '../MarketplaceFeeField';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { TextButton } from 'components';
import useStyles from './FieldsAwsAmiHourlyAnnualPricing.styles';

export type FieldsAwsAmiHourlyAnnualPricingProps = {
  cloud: AwsProduct['cloud'];
};

const FieldsAwsAmiHourlyAnnualPricing: React.FC<
  FieldsAwsAmiHourlyAnnualPricingProps
> = ({ cloud }) => {
  const classes = useStyles();

  const { control } = useFormContext();

  const {
    fields: unitFields,
    append: unitAppend,
    remove: unitRemove,
  } = useFieldArray({
    control,
    name: 'pricing.units',
  });

  const handleAddition = useCallback(
    (): void =>
      unitAppend({
        unit: undefined,
        hourlyPrice: undefined,
        durationPrice: undefined,
      }),
    [unitAppend],
  );

  const handleRemoval = useCallback(
    (index): void => unitRemove(index),
    [unitRemove],
  );

  return (
    <>
      {unitFields.map((unitField, index) => (
        <Box mb={2} key={unitField.id}>
          <UnitField field={unitField} index={index} onRemove={handleRemoval} />
        </Box>
      ))}

      <TextButton
        appearance="subtle"
        color="primary"
        onClick={handleAddition}
        classes={{
          root: classes.buttonPrimary,
          textPrimary: classes.buttonTextPrimary,
        }}
        aria-label="Add unit"
      >
        Add unit
      </TextButton>

      <FormSection title="Contract details" mb={1.5}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={11} xs={10}>
            <MarketplaceFeeField cloud={cloud} />
          </Grid>
          <Grid item md={6} sm={11} xs={10}>
            <FieldsAwsAmiHourlyAnnualDuration />
          </Grid>
        </Grid>
      </FormSection>
    </>
  );
};

export default FieldsAwsAmiHourlyAnnualPricing;
