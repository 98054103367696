export enum AceOpportunityMarketingActivityChannelEnum {
  AWS_MARKETING_CENTRAL = 'AWS Marketing Central',
  CONTENT_SYNDICATION = 'Content Syndication',
  DISPLAY = 'Display',
  EMAIL = 'Email',
  LIVE_EVENT = 'Live Event',
  OUT_OF_HOME = 'Out Of Home (OOH)',
  PRINT = 'Print',
  SEARCH = 'Search',
  SOCIAL = 'Social',
  TELEMARKETING = 'Telemarketing',
  TV = 'TV',
  VIDEO = 'Video',
  VIRTUAL_EVENT = 'Virtual Event',
}
