import React from 'react';
import { Sync } from 'mdi-material-ui';
import { Button } from 'vendor/material';
// import { useAwsOpportunityActions } from '../../pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';
import { useAwsOpportunitySync } from '../../pages/UnifiedOpportunityForm/providers/useAwsOpportunitySync';
import { toDateTime } from 'utils/dates';

interface AwsCoSellSyncStatusProps {
  lastSyncDate: string;
  opportunityId: string;
}

/** WIP: this is simulated right now. */
export const AwsCoSellSyncStatus = ({
  lastSyncDate,
  opportunityId,
}: AwsCoSellSyncStatusProps) => {
  // const { triggerSyncOpportunityById } = useAwsOpportunityActions();
  const { syncAwsOpportunity, isLoading } = useAwsOpportunitySync({
    opportunityId,
    token: 'session token', // figure out better way to initialize this on load
  });

  const handleSync = () => {
    syncAwsOpportunity();
  };

  /** TODO:
   *  create a utility function for this date format.
   */
  const lastSyncDateFormatted = `${toDateTime(lastSyncDate).toFormat(
    'MM/dd/yyyy',
  )} at ${toDateTime(lastSyncDate).toFormat('HH:MM:ss')}`;
  return (
    <p>
      Opportunity last synced with AWS Partner Central {lastSyncDateFormatted}.
      Updates every 1 hour, next sync in 0h 28m. <br />
      <Button onClick={handleSync} size="small" startIcon={<Sync />}>
        {isLoading ? 'Syncing' : 'Sync again'}
      </Button>
    </p>
  );
};

export const formatDate = (date: string) =>
  toDateTime(date).toFormat('MM/dd/yyyy');
