import { Products, Contracts } from '../stores';
import useStore, { hasLoaded } from './useStore';

function useContracts() {
  const productsStore = useStore(Products);
  const contractsStore = useStore(Contracts);

  let all = [];
  let historical = [];
  let active = [];

  const loaded = hasLoaded(productsStore, contractsStore);
  if (!loaded) return { all, active, loaded };

  all = productsStore
    .filterInvalid(contractsStore.contracts)
    .sort((a, b) => b.startDate.diff(a.startDate));

  // includes expired contracts, filters out archived contracts
  historical = productsStore
    .filterInvalid(contractsStore.contracts)
    .filter((contract) => !contract.archivedAt)
    .sort((a, b) => b.startDate.diff(a.startDate));

  // filters out expired & archived contracts
  active = productsStore
    .filterInvalid(contractsStore.getActive())
    .filter((contract) => !contract.archivedAt)
    .sort((a, b) => b.startDate.diff(a.startDate));

  return { all, historical, active, loaded };
}

export default useContracts;
