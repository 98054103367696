import React from 'react';
import { useFormContext } from 'react-hook-form';
import FieldsSelectDate from 'pages/PrivateOffers/components/FieldsSelectDate/FieldsSelectDate';

interface ExistingAgreementEndDateFieldProps {
  defaultValue?: Date;
}

const FIELD_NAME = 'pricing.previousServiceEndAt';

const ExistingAgreementEndDateField: React.FC<
  ExistingAgreementEndDateFieldProps
> = ({ defaultValue }) => {
  const { control, errors } = useFormContext();

  return (
    <FieldsSelectDate
      disabled={true}
      defaultValue={defaultValue ?? ''}
      labelText="Previous service end date"
      helperText={null}
      fieldName={FIELD_NAME}
      control={control}
      error={errors?.pricing?.previousServiceEndAt}
    />
  );
};

export default ExistingAgreementEndDateField;
