import React from 'react';
import { useFormContext } from 'react-hook-form';
import FieldsSelectDate from 'pages/PrivateOffers/components/FieldsSelectDate/FieldsSelectDate';
import { privateOffersUIElements } from 'utils/pendoEnums';
import { minServiceEndDate } from 'pages/PrivateOffers/utils/formatFormData';

interface AmendmentEndDateFieldProps {
  defaultValue: Date;
}

export const FIELD_NAME = 'pricing.serviceEndAt';

const AmendmentEndDateField: React.FC<AmendmentEndDateFieldProps> = ({
  defaultValue,
}) => {
  const { control, errors } = useFormContext();

  return (
    <FieldsSelectDate
      fieldName={FIELD_NAME}
      id={
        privateOffersUIElements.PRIVATE_OFFERS_AMENDMENT_SERVICE_END_DATE_INPUT
      }
      control={control}
      error={errors?.pricing?.serviceEndAt}
      defaultValue={defaultValue ?? ''}
      labelText="New service end date *"
      helperText={null}
      minDate={minServiceEndDate()}
    />
  );
};

export default AmendmentEndDateField;
