import React from 'react';
import {
  flow,
  get,
  defaultTo,
  isEmpty,
  find,
  range,
  capitalize,
  sortBy,
} from 'lodash/fp';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Chip, Grid } from 'vendor/material';
import {
  FormSection,
  Select as PUISelect,
  TextField,
} from '@tackle-io/platform-ui';

import MarketplaceFeeField from './MarketplaceFeeField';

import { useSelectedProduct } from 'stores/products/hooks';
import {
  Edition,
  EditionCharge,
  RedhatProduct,
} from 'stores/products/typings/RedhatProduct';
import useStyles from './FieldsRedHatPricing.styles';

export type FieldsRedHatPricingProps = {
  cloud: RedhatProduct['cloud'];
  productId: RedhatProduct['productid'];
};

const getEditions: (p: RedhatProduct) => Edition[] = flow([
  get('pricing.editions'),
  defaultTo([]),
]);

const getEditionDetailsBy: (
  predicate: any,
  editions: Edition[],
) => Pick<Edition, 'billingFrequency' | 'editionCharges'> = flow([
  find,
  defaultTo({ billingFrequency: [], editionCharges: [] }),
]);

const FieldsRedHatPricing: React.FC<FieldsRedHatPricingProps> = ({
  cloud,
  productId,
}) => {
  const classes = useStyles();
  const { watch, control } = useFormContext();
  const { content: selectedProduct } = useSelectedProduct(productId, cloud);
  const product = selectedProduct as RedhatProduct;

  const editionLabel = 'Edition';
  const editionName = 'pricing.editionId';
  const formEdition: string | undefined = watch(editionName);

  const editions = getEditions(product);
  const { billingFrequency = [], editionCharges = [] } = getEditionDetailsBy(
    { id: formEdition },
    editions,
  );

  const billingFrequencyLabel = 'Billing term';
  const billingFrequencyName = 'pricing.billingFrequency';
  const formBillingFrequency = watch(billingFrequencyName);

  const subscriptionTermName = 'pricing.subscriptionTerm';
  const subscriptionTermLabel = 'Subscription term';

  if (isEmpty(editions)) return <div>No editions configured</div>;

  return (
    <>
      <MarketplaceFeeField cloud={cloud} />

      <Box mt={1.5}>
        <Controller
          name={editionName}
          control={control}
          defaultValue=""
          render={({ name, ...props }) => (
            <PUISelect
              id={name}
              name={name}
              label={editionLabel}
              SelectProps={{ 'aria-label': editionLabel }}
              {...props}
            >
              {editions.map((edition: Edition) => (
                <option value={edition.id} key={edition.id}>
                  {edition.name}
                </option>
              ))}
            </PUISelect>
          )}
        />
      </Box>

      {!isEmpty(formEdition) &&
        (isEmpty(billingFrequency) ? (
          <Box mt={1.5}>
            <span>Billing Frequency not configured for selected Edition</span>
          </Box>
        ) : (
          <>
            <Box mt={1.5}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Controller
                    name={billingFrequencyName}
                    control={control}
                    defaultValue={formBillingFrequency}
                    render={({ name, ...props }) => (
                      <PUISelect
                        id={name}
                        name={name}
                        label={billingFrequencyLabel}
                        SelectProps={{ 'aria-label': billingFrequencyLabel }}
                        {...props}
                      >
                        {billingFrequency.map((term) => (
                          <option value={term} key={term}>
                            {capitalize(term)}
                          </option>
                        ))}
                      </PUISelect>
                    )}
                  />
                </Grid>

                <Grid item md={6}>
                  <Controller
                    name={subscriptionTermName}
                    control={control}
                    defaultValue={'1'}
                    render={({ name, ...props }) => (
                      <PUISelect
                        id={name}
                        name={name}
                        label={subscriptionTermLabel}
                        SelectProps={{ 'aria-label': subscriptionTermLabel }}
                        {...props}
                      >
                        {range(1, 61).map((i) => {
                          const label = i > 1 ? `${i} months` : `${i} month`;
                          return (
                            <option value={i.toString()} key={label}>
                              {label}
                            </option>
                          );
                        })}
                      </PUISelect>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mt={1.5}>
              <div className={classes.editionCharges}>
                {sortBy(['chargeType', 'vendorChargeid'], editionCharges).map(
                  (charge: EditionCharge, i: number) => (
                    <Box
                      className={classes.editionCharge}
                      key={charge.name}
                      mt={1.5}
                      padding={1.5}
                    >
                      <FieldsEditionCharge
                        formPath={`pricing.editionCharges[${i}]`}
                        charge={charge}
                        billingFrequency="monthly"
                      />
                    </Box>
                  ),
                )}
              </div>
            </Box>
          </>
        ))}
    </>
  );
};

function FieldsEditionCharge({
  formPath,
  charge,
  billingFrequency,
}: {
  formPath: string;
  charge: EditionCharge;
  billingFrequency: string;
}) {
  const classes = useStyles();
  const { control, errors } = useFormContext();

  const formSectionTitle = (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <span>
        <span className={classes.editionChargeEmphasis}>{`Charge: `}</span>
        <span>{`${charge.name} ${charge.required ? '*' : ''}`}</span>
      </span>
      <Chip label={charge.chargeType} />
    </Box>
  );

  // { 'Tackle API Term': Red Hat Term' }
  const RedHatBillingTerms = {
    annual: 'year',
    quarterly: 'quarter',
    monthly: 'month',
  };

  const idName = `${formPath}.id`;

  const includeName = `${formPath}.include`;
  const includeLabel = 'Include';
  const includeLabelAria = `${includeName} - ${includeLabel}`;

  const quantityName = `${formPath}.quantity`;
  const quantityLabel = `Number of ${charge.unitOfMeasure}`;
  const quantityLabelAria = `${quantityName} - ${quantityLabel}`;
  const quantityError = get([formPath, 'quantity.message'], errors);
  const quantityDisabled = charge.chargeType === 'usage';

  const unitPriceName = `${formPath}.unitPrice`;
  const unitPriceLabel = `Unit price per ${RedHatBillingTerms[billingFrequency]}`;
  const unitPriceLabelAria = `${unitPriceName} - ${quantityLabel}`;
  const unitPriceError = get([formPath, 'unitPrice.message'], errors);

  return (
    <FormSection title={formSectionTitle} mb={2}>
      <Grid container spacing={2}>
        <Grid item md={1} xs={1}>
          <Controller
            name={idName}
            control={control}
            defaultValue={charge.vendorChargeid}
            render={(props): React.ReactElement => (
              <input {...props} disabled={true} hidden={true} />
            )}
          />

          <Controller
            name={includeName}
            control={control}
            defaultValue={charge.required}
            render={({
              name,
              onChange,
              value,
              ...props
            }): React.ReactElement => (
              <TextField
                id={name}
                name={name}
                label={includeLabel}
                type="checkbox"
                disabled={charge.required}
                onChange={({ target }) => onChange(target.checked)}
                inputProps={{
                  checked: value,
                  'aria-label': includeLabelAria,
                }}
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item md={5} xs={11}>
          <Controller
            name={quantityName}
            control={control}
            defaultValue={1}
            render={({ ref, ...props }): React.ReactElement => (
              <TextField
                {...props}
                name={props.name}
                id={props.name}
                label={quantityLabel}
                error={quantityError}
                type="number"
                inputRef={ref}
                inputProps={{ 'aria-label': quantityLabelAria }}
                disabled={quantityDisabled}
              />
            )}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            name={unitPriceName}
            control={control}
            defaultValue={charge.price}
            render={({ ref, onChange, ...props }): React.ReactElement => (
              <TextField
                {...props}
                name={props.name}
                id={props.name}
                label={unitPriceLabel}
                error={unitPriceError}
                type="number"
                mode="numberformat"
                fixedDecimalScale
                decimalScale={2}
                inputRef={ref}
                onChange={({ target }) => onChange(target.value)}
                inputProps={{ 'aria-label': unitPriceLabelAria }}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
}

export default FieldsRedHatPricing;
