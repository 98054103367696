import { useState } from 'react';

// HELPERS
import { idGenerator } from '../utils';

const verifyMatchKey = (file) => (file.uid !== undefined ? 'uid' : 'name');

const generatedId = idGenerator('file');

export default function useFileList() {
  const [files, setFiles] = useState([]);

  const addFile = (file) => {
    const newFileList = [
      ...files,
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        uid: generatedId(file.name),
      }),
    ];

    setFiles(newFileList);
  };

  const removeFile = (file) => {
    const matchKey = verifyMatchKey(file);
    const newFileList = files.filter(
      (item) => item[matchKey] !== file[matchKey],
    );
    setFiles(newFileList);
  };

  const downloadFile = (file) => {
    const matchKey = verifyMatchKey(file);
    const [fileToDownload] = files.filter(
      (item) => item[matchKey] === file[matchKey],
    );
    if (file.type === 'application/pdf') {
      const anchor = document.createElement('a');
      anchor.href = fileToDownload.preview;
      anchor.download = file.name;
      document.body.append(anchor);
      anchor.click();
      anchor.remove();
    } else {
      window.open(fileToDownload.preview);
    }
  };

  const resetFiles = () => {
    // Cleanup all files
    if (files.length) {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      setFiles([]);
    }
  };

  return {
    files,
    addFile,
    removeFile,
    downloadFile,
    resetFiles,
  };
}
