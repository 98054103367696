import React, { Suspense } from 'react';
import classNames from 'classnames';
import { Loader } from '@tackle-io/platform-ui';

// ASSETS
import useStyles from './Page.styles';

const Page = ({ className = undefined, size = undefined, ...props }) => {
  // HOOKS
  const classes = useStyles(size);

  // STYLES
  const cssPage = classNames(className, classes.root);

  return (
    <Suspense fallback={<Loader />}>
      <div {...props} className={cssPage} />
    </Suspense>
  );
};

export default Page;
