import React from 'react';
import { Typography, Box, useTheme } from 'vendor/material';
// ASSETS
import useStyles from './ZeroState.styles';

interface IconProps {
  className: string;
  htmlColor: string;
}

interface ZeroStateProps {
  Icon: React.FC<IconProps>;
  title: string;
  body?: string | JSX.Element;
}

const ZeroState: React.FC<ZeroStateProps> = ({
  Icon,
  title,
  body,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <Box mt={6}>
        <Icon className={classes.icon} htmlColor={theme.palette.NEUTRAL050} />
      </Box>
      <Box mb={2} mt={2}>
        <Typography component="h1" className={classes.title}>
          {title}
        </Typography>
      </Box>
      {body && (
        <Typography component="p" className={classes.message}>
          {body}
        </Typography>
      )}
      {children}
    </div>
  );
};

export default ZeroState;
