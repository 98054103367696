import React from 'react';
import classNames from 'classnames';
import useStyles from './Tag.styles';
import { TagColorEnum } from '../../types/enums';

interface TagProps {
  className?: string;
  children: React.ReactNode;
  fontSize?: string | number;
  color?: TagColorEnum;
}

export const Tag: React.FC<TagProps> = ({
  className,
  children,
  fontSize,
  color = 'standard',
}) => {
  const classes = useStyles();

  const cssContainer = classNames(
    className,
    classes.container,
    { [classes.standard]: color === TagColorEnum.STANDARD },
    { [classes.blue]: color === TagColorEnum.BLUE },
    { [classes.green]: color === TagColorEnum.GREEN },
    { [classes.red]: color === TagColorEnum.RED },
    { [classes.yellow]: color === TagColorEnum.YELLOW },
    { [classes.purple]: color === TagColorEnum.PURPLE },
  );

  return (
    <div className={cssContainer}>
      <span style={{ fontSize }}>{children}</span>
    </div>
  );
};

export default Tag;
