import { makeStyles } from 'vendor/material';
import { fontWeightMedium } from '../../../../../utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  widgetTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: fontWeightMedium,
    color: theme.palette.NEUTRAL500,
    marginTop: theme.spacing(4),
  },
  widgetTitleFade: {
    color: theme.palette.NEUTRAL100,
  },
  activitiesList: {
    marginTop: theme.spacing(4),
    maxHeight: 400,
    marginRight: '-20px',
    overflowY: 'auto',
    scrollbarColor: `${theme.palette.NEUTRAL100} ${theme.palette.NEUTRAL020}`,
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: 0,
      backgroundColor: theme.palette.NEUTRAL020,
    },
    '&::-webkit-scrollbar': {
      width: 3,
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: theme.palette.NEUTRAL100,
    },
  },
}));

export default useStyles;
