import { DateTime } from 'luxon';

export const buildDateString = (selectedDate): string => {
  let dateString = '';
  if (selectedDate.length > 1) {
    dateString = selectedDate
      .map((date) => date.toFormat('LLL dd'))
      .join(' - ');
  }

  return dateString;
};

export const formatDisplayDate = (selectedDate, pickerView): string => {
  if (selectedDate) {
    if (pickerView === 'month') {
      return selectedDate.toFormat('LLL dd, yyyy');
    }
    return selectedDate.toFormat('LLL, yyyy');
  }

  return '';
};

export type DateObjectRange = {
  from: DateTime;
  to: DateTime;
};

export type DateListOptions = {
  key: string;
  text: string;
  isSelected: boolean;
};

export interface DatePopoverProps {
  id: string;
  rangePosition: string;
  open: boolean;
  onChangeDate: Function;
  datesSelected: DateObjectRange;
  anchorEl: HTMLElement;
  onClose: Function;
  pickerView: string;
  maxDate?: Date;
}

export interface DateListPickerProps {
  pickerView?: string;
  maxDate?: Date;
  id?: string;
  selectedDate: Array<DateTime>;
  options: Array<DateListOptions>;
  anchorEl: Element | ((element: Element) => Element);
  onClose: any;
  onChangeList: Function;
  onChangeDate: Function;
  open: boolean;
  selectedOptionKey: string;
  hasCustomRange?: boolean;
}
