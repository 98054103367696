import { Box, Grid } from 'vendor/material';
import SummaryCard from './components/SummaryCard/SummaryCard';
import { useSingleContractData } from '../../providers/SingleContractDataProvider';
import { buildSummaryDetails } from '../../ContractDetailsMapper';
import { LoadingMetrics } from 'pages/Contracts/components/ContractsMetrics/components/LoadingMetrics';
import { Banner } from '@tackle-io/platform-ui';

const displayCancelledBanner = (contract): boolean => {
  return contract?.contractStatus === 'Cancelled';
};

const Summary: React.FC = () => {
  const { contract, loading } = useSingleContractData();

  if (loading) {
    return <LoadingMetrics />;
  }

  const {
    summaryItems,
    totalContractValueMetric,
    totalDisbursedMetric,
    totalUninvoicedPaymentsMetric,
  } = buildSummaryDetails(contract);

  return (
    <>
      {displayCancelledBanner(contract) && (
        <Box marginBottom={4}>
          <Banner
            borderPosition="top"
            defaultOpen={false}
            title={
              <span>
                The contract was cancelled by the buyer before the end date.
              </span>
            }
          />
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item md={12}>
          <SummaryCard items={summaryItems} />
        </Grid>
        <Grid item sm={9} md={4}>
          <SummaryCard items={totalContractValueMetric} />
        </Grid>
        <Grid item sm={9} md={4}>
          <SummaryCard items={totalDisbursedMetric} />
        </Grid>
        <Grid item sm={9} md={4}>
          <SummaryCard items={totalUninvoicedPaymentsMetric} />
        </Grid>
      </Grid>
    </>
  );
};

export default Summary;
