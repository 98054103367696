import { FormValues } from './CreatePassword.types';

type FormErrors = {
  form?: string[];
};

const PASSWORD_LENGTH = 12;
const UPPER_REGEXP = /[A-Z]/;
const LOWER_REGEXP = /[a-z]/;
const NUM_REGEXP = /[0-9]/;

const LENGTH_ERROR = `Password must be at least ${PASSWORD_LENGTH} characters long.`;
const UPPER_ERROR = 'Password must have at least 1 uppercase character';
const LOWER_ERROR = 'Password must have at least 1 lowercase character';
const NUM_ERROR = 'Password must have a number';
const PASSWORDS_MATCHING_ERROR = 'Passwords must match';

export const PASSWORD_TESTS = {
  length: {
    description: LENGTH_ERROR,
    test: (password: string, _passwordConfirm): boolean =>
      password.length >= PASSWORD_LENGTH,
  },
  upper: {
    description: UPPER_ERROR,
    test: (password: string, _passwordConfirm): boolean =>
      password.search(UPPER_REGEXP) >= 0,
  },
  lower: {
    description: LOWER_ERROR,
    test: (password: string, _passwordConfirm): boolean =>
      password.search(LOWER_REGEXP) >= 0,
  },
  number: {
    description: NUM_ERROR,
    test: (password: string, _passwordConfirm): boolean =>
      password.search(NUM_REGEXP) >= 0,
  },
  confirm: {
    description: PASSWORDS_MATCHING_ERROR,
    test: (password: string, passwordConfirm: string): boolean =>
      password === passwordConfirm,
  },
};

export const PASSWORD_TEST_NAMES = Object.keys(PASSWORD_TESTS);

export const validatePassword = (values: FormValues): FormErrors => {
  const { password, confirmPassword } = values;

  if (!password) {
    return { form: PASSWORD_TEST_NAMES };
  }

  const errors: string[] = PASSWORD_TEST_NAMES.reduce((accum, testName) => {
    const { test } = PASSWORD_TESTS[testName];

    if (test(password, confirmPassword)) {
      return accum;
    }

    return [...accum, testName];
  }, []);

  return { form: errors };
};
