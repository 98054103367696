import React from 'react';
import { reportError } from 'utils/monitor';
import { isEmpty } from 'lodash';

interface ErrorBoundaryProps {
  hub?: any;
  renderError: any;
  logTags?: LogTags;
}

interface ErrorBoundaryState {
  error: any;
}

interface LogTags {
  [key: string]: string | number;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error): any {
    return { error };
  }

  componentDidCatch(error, errorInfo): void {
    const { logTags } = this.props;
    const context = errorInfo.componentStack
      ? {
          component_stack_trace: errorInfo.componentStack,
          ...(!isEmpty(logTags) && { tags: logTags }),
        }
      : null;
    reportError(error, context);
  }

  render(): any {
    const { children, renderError: ErrorComponent } = this.props;

    const { error } = this.state;

    if (error && ErrorComponent) {
      return <ErrorComponent error={error} />;
    }

    return children;
  }
}

export default ErrorBoundary;
