import React, { useMemo, useState } from 'react';
import { Autocomplete, Box, Grid, IconButton } from 'vendor/material';
import { Delete } from 'mdi-material-ui';
import {
  DocsTooltip,
  TextField,
  useTextFieldStyles,
} from '@tackle-io/platform-ui';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import useStyles from './styles';
import { DimensionFieldProps } from './typings';
import { Dimension, PaymentModelValue } from 'stores/privateOffers/typings';
import classNames from 'classnames';
import {
  DimensionNameOption,
  getAvailableDimensionNameOptions,
  getFilteredOptions,
  getOnBlur,
  getOnChange,
  getOnTextFieldChanged,
  getOptionLabel,
  getRenderInput,
  getRenderOption,
} from './awsDimensionFieldRenderUtils';
import { currencyCodeFormPath } from '../utils';
import {
  CurrencyCode,
  currencySymbolByCurrencyCode,
} from '../../../utils/currency';

const dimensionQuantityMinValue = 0;

const emptyDimensionNameOption: DimensionNameOption = {
  name: '',
  lowerCasedName: '',
  apiName: '',
};

const toDimensionNameOption = (d: Dimension): DimensionNameOption => ({
  name: d.name,
  lowerCasedName: d.name.toLowerCase(),
  apiName: d.apiName,
});

const AwsDimensionField: React.FC<DimensionFieldProps> = ({
  field,
  index,
  onRemove,
  paymentModel,
  awsMarketplacePricing,
}) => {
  const { control, errors, setValue } = useFormContext();
  const { name, apiName, price, quantity } = field;
  const classes = useStyles();
  const textFieldStyles = useTextFieldStyles();
  const showPrice = paymentModel === PaymentModelValue.PerProduct;

  const formCurrencyCode = useWatch({
    name: currencyCodeFormPath,
    control,
  }) as CurrencyCode;

  const currencySymbol = currencySymbolByCurrencyCode[formCurrencyCode];

  const [selectedDimensionNameOption, setSelectedDimensionNameOption] =
    useState<DimensionNameOption>({
      name: name ?? '',
      lowerCasedName: name?.toLowerCase() ?? '',
      apiName: apiName ?? '',
    });
  const [dimensionNameInputValue, setDimensionNameInputValue] = useState(
    selectedDimensionNameOption.name,
  );

  const formDimensions = useWatch({
    control,
    name: 'pricing.dimensions',
  }) as Dimension[];

  const formDimensionAtIndex = formDimensions.at(index);

  const marketplaceDimensions = useMemo(
    () => (awsMarketplacePricing?.dimensions as Dimension[]) ?? [],
    [awsMarketplacePricing],
  );

  const marketplaceDimensionNameOptions = useMemo(
    () => marketplaceDimensions.map(toDimensionNameOption),
    [marketplaceDimensions],
  );

  const formDimensionIsMarketplaceDimension =
    formDimensionAtIndex &&
    !!marketplaceDimensionNameOptions.find(
      (v) =>
        v.name === formDimensionAtIndex?.name &&
        v.apiName === formDimensionAtIndex?.apiName,
    );

  const availableDimensionNameOptions = useMemo(
    () =>
      getAvailableDimensionNameOptions(
        marketplaceDimensionNameOptions,
        formDimensions,
        index,
      ),
    [marketplaceDimensionNameOptions, formDimensions, index],
  );

  const filterOptions = useMemo(
    () => getFilteredOptions(dimensionNameInputValue, formDimensions),
    [dimensionNameInputValue, formDimensions],
  );

  const renderOption = useMemo(
    () => getRenderOption(marketplaceDimensionNameOptions, classes),
    [marketplaceDimensionNameOptions, classes],
  );

  const onTextFieldChanged = useMemo(
    () => getOnTextFieldChanged(setDimensionNameInputValue),
    [],
  );

  const renderInput = useMemo(() => {
    const error = errors.pricing?.dimensions?.[index]?.name?.message;
    const isNewDimension =
      awsMarketplacePricing &&
      dimensionNameInputValue &&
      !formDimensionIsMarketplaceDimension;

    return getRenderInput(
      textFieldStyles,
      onTextFieldChanged,
      isNewDimension,
      error,
    );
  }, [
    errors,
    index,
    awsMarketplacePricing,
    dimensionNameInputValue,
    formDimensionIsMarketplaceDimension,
    textFieldStyles,
    onTextFieldChanged,
  ]);

  const onChange = useMemo(
    () =>
      getOnChange(
        setValue,
        setSelectedDimensionNameOption,
        setDimensionNameInputValue,
        index,
        formDimensionAtIndex,
      ),
    [setValue, index, formDimensionAtIndex],
  );

  const onBlur = useMemo(
    () =>
      getOnBlur(
        selectedDimensionNameOption,
        dimensionNameInputValue,
        setValue,
        setSelectedDimensionNameOption,
        setDimensionNameInputValue,
        index,
      ),
    [selectedDimensionNameOption, dimensionNameInputValue, setValue, index],
  );

  return (
    <Grid container spacing={2} className={classes.dimensionFields}>
      <Grid
        item
        md={showPrice ? 4 : 6}
        sm={11}
        xs={11}
        className={classes.fieldKey}
      >
        <Controller
          name={`pricing.dimensions[${index}].name`}
          control={control}
          defaultValue={dimensionNameInputValue}
          render={({ ref }): React.ReactElement => {
            return (
              <Autocomplete
                ref={ref}
                value={selectedDimensionNameOption ?? emptyDimensionNameOption}
                inputValue={dimensionNameInputValue}
                options={availableDimensionNameOptions}
                getOptionLabel={getOptionLabel}
                filterOptions={filterOptions}
                renderOption={renderOption}
                renderInput={renderInput}
                onChange={onChange}
                onBlur={onBlur}
                forcePopupIcon={availableDimensionNameOptions.length > 0}
                size="small"
                freeSolo={true}
                openOnFocus={true}
                selectOnFocus={true}
                handleHomeEndKeys={true}
                disablePortal={true}
                disableClearable={true}
              />
            );
          }}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12} className={classes.fieldValue}>
        <Controller
          name={`pricing.dimensions[${index}].apiName`}
          control={control}
          defaultValue={apiName ?? ''}
          render={({ ref, onChange, ...props }) => (
            <TextField
              {...props}
              id={`pricing.dimensions.${index}.apiName`}
              label={
                <>
                  <Box className={classes.formLabel}>
                    Api name
                    <span className={classes.tooltip}>
                      <DocsTooltip
                        topicId="private-offers-AWS-API-name-field-tooltip"
                        iconSize="small"
                      />
                    </span>
                  </Box>
                </>
              }
              error={errors.pricing?.dimensions?.[index]?.apiName?.message}
              inputRef={ref}
              inputProps={{ 'aria-label': `Dimension ${index} api name` }}
              onChange={({ target: { value } }) => {
                onChange(value);
              }}
              readOnly={formDimensionIsMarketplaceDimension}
              disableClearAll={formDimensionIsMarketplaceDimension}
            />
          )}
        />
      </Grid>
      {showPrice && (
        <Grid item md={2} sm={12} xs={12} className={classes.fieldValue}>
          <Controller
            name={`pricing.dimensions[${index}].price`}
            control={control}
            defaultValue={price ?? 0}
            render={({ ref, ...props }): React.ReactElement => (
              <TextField
                {...props}
                label="Price per"
                id={`pricing.dimensions.${index}.price`}
                error={errors.pricing?.dimensions?.[index]?.price?.message}
                type="number"
                mode="numberformat"
                numberPrefix={currencySymbol}
                fixedDecimalScale
                decimalScale={3}
                aria-label={`Dimension ${index} price per`}
                inputProps={{ ref }}
              />
            )}
          />
        </Grid>
      )}
      <Grid item md={2} sm={12} xs={12} className={classes.fieldValue}>
        <Controller
          name={`pricing.dimensions[${index}].quantity`}
          control={control}
          defaultValue={quantity ?? 0}
          render={({ ref, onChange, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Quantity"
              id={`pricing.dimensions.${index}.quantity`}
              error={errors.pricing?.dimensions?.[index]?.quantity?.message}
              type="number"
              inputRef={ref}
              inputProps={{
                'aria-label': `Dimension ${index} quantity`,
                min: dimensionQuantityMinValue,
              }}
              onChange={({ target: { value } }) => {
                const valueToUse = value ? value : dimensionQuantityMinValue;
                onChange(valueToUse);
              }}
            />
          )}
        />
      </Grid>

      <Grid
        item
        md={1}
        sm={1}
        xs={1}
        className={classNames(classes.fieldAction, classes.deleteButton)}
      >
        <IconButton
          onClick={(): void => onRemove(index)}
          aria-label={`Delete dimension ${index} button`}
        >
          <Delete className={classes.buttonIcon} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AwsDimensionField;
