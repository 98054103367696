import { isObject, mapValues } from 'lodash-es';

const emptyStringToNull = (value: any): any | null => {
  if (value === '') return null;
  return value;
};

const emptyStringToNullAndTrim = (value: any): any | null => {
  if (value === '') return null;
  if (typeof value === 'string') {
    return value.trim();
  }
  return value;
};

const recursiveEmptyStringToNull = (value: any): any | null => {
  if (value === '') return null;

  if (Array.isArray(value)) {
    return value.map(recursiveEmptyStringToNull);
  }

  if (isObject(value)) {
    return mapValues(value, recursiveEmptyStringToNull);
  }

  return value;
};

export { recursiveEmptyStringToNull, emptyStringToNullAndTrim };
export default emptyStringToNull;
