import React, { useReducer, useEffect } from 'react';
import api, { omitAuthHeader } from 'utils/api';

import {
  getLocalStorageState,
  setLocalStorageState,
} from 'stores/cache/localStorage';
import { ProviderProps } from 'stores/typings';
import { defaultState } from 'stores/utils';

import Context, { storageKey } from './context';
import reducer from './reducer';
import { serialize, deserialize, isAPISuccess, APIResponse } from './typings';

const Provider: React.FC<ProviderProps> = ({
  content = {},
  disableSync = false,
  disableCache = false,
  localStorageKey = storageKey,
  children,
}) => {
  let providerContent = content;

  // get cached content
  if (!disableCache) {
    const { data: cachedContent } = getLocalStorageState(localStorageKey);
    providerContent = deserialize(cachedContent);
  }

  // setup Context reducer
  const [state, dispatch] = useReducer(
    reducer,
    defaultState({ content: providerContent }),
  );

  // sync with server on first render
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    // abort any pending requests
    if (disableSync) return (): void => controller.abort();

    dispatch({ type: 'SYNC' });
    api
      .get('version', { signal, ...omitAuthHeader() })
      .json()
      .then((data: APIResponse) => {
        if (isAPISuccess(data)) {
          dispatch({
            type: 'SET_CONTENT',
            payload: {
              content: deserialize(data),
            },
          });
        } else {
          dispatch({ type: 'SET_ERROR', payload: { error: data.error } });
        }
      })
      .catch((error) => {
        dispatch({ type: 'SET_ERROR', payload: { error } });
      });

    return (): void => controller.abort(); // on unmount, abort any pending requests
  }, [disableSync]);

  // save content updates to cache
  useEffect(() => {
    if (state.content && !disableCache) {
      setLocalStorageState(storageKey, serialize(state.content));
    }
  }, [state.content, disableCache]);

  // return Context.Provider
  return (
    <Context.Provider value={{ ...state, dispatch }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
