import {
  CurrencyFormField,
  DateFormField,
  MultiSelectFormField,
  TextFormField,
} from 'packages/cosell/src/components';
import { AceOpportunityReviewStatusEnum } from 'packages/cosell/src/types/enums';
import { isFieldEditable } from './helpers';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';

// The following fields are shared with the Edit Modal
export const ExpectedMonthlyAwsRevenue = ({
  status,
}: {
  status: AceOpportunityReviewStatusEnum;
}) => (
  <CurrencyFormField
    name="expectedCustomerSpend"
    label="Estimated AWS monthly recurring revenue"
    required
    disabled={
      !isFieldEditable({
        name: 'expectedCustomerSpend',
        status,
      })
    }
  />
);

export const TargetCloseDate = ({
  status,
}: {
  status: AceOpportunityReviewStatusEnum;
}) => (
  <DateFormField
    name="targetCloseDate"
    label="Target close date"
    required
    disabled={
      !isFieldEditable({
        name: 'targetCloseDate',
        status,
      })
    }
  />
);

export const Solutions = ({
  status,
  setFieldValue,
}: {
  status: AceOpportunityReviewStatusEnum;
  setFieldValue: (field: string, value: any) => void;
}) => {
  const { solutionList } = useOpportunity();

  return (
    <MultiSelectFormField
      fieldId="solutions"
      label="Solutions offered"
      onChange={() => setFieldValue('otherSolutionDescription', null)}
      options={solutionList}
      disabled={
        !isFieldEditable({
          name: 'solutions',
          status,
        })
      }
      required
      helperText="Choose up to 10 partner solutions that you're offering to this customer. If other, describe your solution."
      filterSelectedOptions={true}
    />
  );
};

export const AdditionalComments = ({
  status,
}: {
  status: AceOpportunityReviewStatusEnum;
}) => (
  <TextFormField
    name="additionalComments"
    label="Additional comments"
    multiline
    rows={4}
    helperText="Enter project description, opportunity details, customer pain points, customer needs, etc."
    maxLength={255}
    disabled={
      !isFieldEditable({
        name: 'additionalComments',
        status,
      })
    }
  />
);
