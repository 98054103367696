import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '32px',
    width: '32px',
  },
  menuItemIcon: {
    paddingLeft: 0,
  },
  menu: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    borderTop: '1px solid #111432',
    borderBottom: '1px solid #111432',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#111432',
    },
  },
  profileCollapsed: {
    justifyContent: 'center',
  },
  profileName: {
    color: theme.palette.NEUTRAL000,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
  },
  profileEmail: {
    color: theme.palette.NEUTRAL050,
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default useStyles;
