import { decorate, observable } from 'mobx';
import { assign } from 'lodash-es';

class Instance {
  type = '';

  count = '';

  constructor(props) {
    const { type_ = '' } = props;
    const type = type_;
    delete props.type_;

    assign(this, props, { type });
  }
}

decorate(Instance, {
  type: observable,
  count: observable,
});

export default Instance;
