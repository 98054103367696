export enum OpportunityDetailStatusEnum {
  ACTIVE = 'ACTIVE', // mspc
  CLOSED = 'CLOSED', // mspc
  NEW = 'NEW', // mspc
  PROSPECT = 'Prospect', // ace
  QUALIFIED = 'Qualified', // ace
  TECHNICAL_VALIDATION = 'Technical Validation', // ace
  BUSINESS_VALIDATION = 'Business Validation', // ace
  COMMITTED = 'Committed', // ace
  LAUNCHED = 'Launched', // ace
  CLOSED_LOST = 'Closed Lost', // ace
}
