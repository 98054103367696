import { Activity } from 'stores/privateOffers/typings';
import { ActivitySlug } from './constants';

const acceptedAndCancelledSlugs = [
  ActivitySlug.BuyerAcceptedPrivateOffer,
  ActivitySlug.VendorCancelledMarketplaceOfferSuccess,
];

const isEditableOffer = (activities: Activity[] = []): boolean =>
  activities.every(
    ({ slug }) => !acceptedAndCancelledSlugs.includes(slug as ActivitySlug),
  );

export default isEditableOffer;
