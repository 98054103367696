import React from 'react';
import { IconButton } from 'vendor/material';
import { useHoverCopyStyles } from './HoverCopy.styles';
import { ClipboardOutline, ClipboardCheckOutline } from 'mdi-material-ui';
import { useToast } from 'hooks';
import { Slide } from 'react-toastify';

interface HoverCopyProps {
  pendoId?: string;
  text: string;
}

export const HoverCopy = ({ text, pendoId }: HoverCopyProps) => {
  const classes = useHoverCopyStyles();
  const [copied, setCopied] = React.useState(false);
  const { toaster } = useToast();

  const copyToClipboard = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text.toString());
    setCopied(true);
    toaster({
      message: `Copied ${text} to clipboard`,
      options: {
        closeButton: false,
        position: 'bottom-right',
        autoClose: 2000,
        closeOnClick: false,
        transition: Slide,
        draggable: false,
        toastId: `copied-${text}`,
      },
    });
  };

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <div className={classes.hoverCopyContainer} data-id={pendoId}>
      <p className={classes.copyText}>{text}</p>
      <IconButton
        className={classes.copyButton}
        color="primary"
        onClick={copyToClipboard}
      >
        {copied ? (
          <ClipboardCheckOutline fontSize="small" />
        ) : (
          <ClipboardOutline fontSize="small" />
        )}
      </IconButton>
    </div>
  );
};
