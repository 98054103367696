import React from 'react';
import {
  Button as MuiButton,
  CircularProgress,
  //   ButtonProps as MuiButtonProps,
} from 'vendor/material';
// eslint-disable-next-line no-restricted-imports
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import classNames from 'classnames';

// ASSETS
import useStyles from './Button.style';

export interface ButtonProps extends MuiButtonProps {
  /**
   *  The Button component has a appearance property with 6 available values:
   *  - note: 'disabled' is to give a Button the disabled look while still
   *  functioning as a button
   *  */
  appearance?:
    | 'primary'
    | 'secondary'
    | 'destructive'
    | 'success'
    | 'white'
    | 'disabled';
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  className,
  appearance = 'secondary',
  variant = 'contained',
  fullWidth = false,
  loading = false,
  children = null,
  startIcon = null,
  ...props
}: ButtonProps) => {
  const classes = useStyles();

  // STYLES
  const buttonClasses = {
    root: classNames(classes.root, className, {
      noChildren: !children,
    }),
    [variant]: classNames(classes[variant], {
      secondary: appearance === 'secondary',
      primary: appearance === 'primary',
      destructive: appearance === 'destructive',
      success: appearance === 'success',
      white: appearance === 'white',
      disabled: appearance === 'disabled',
    }),
    startIcon: classNames(classes.startIcon, {
      noChildren: !children,
    }),
    disabled: classes.disabled,
    sizeLarge: classes.sizeLarge,
    sizeSmall: classes.sizeSmall,
    outlinedSizeSmall: classes.outlinedSizeSmall,
    outlinedSizeLarge: classes.outlinedSizeLarge,
  };

  const spinnerClasses = {
    root: classNames(classes.circularProgress, variant, {
      secondary: appearance === 'secondary',
      primary: appearance === 'primary',
      destructive: appearance === 'destructive',
      success: appearance === 'success',
      white: appearance === 'white',
    }),
  };

  const cssLoadingSpinner = classNames(classes.loadingSpinner, {
    asIcon: startIcon,
  });

  const cssButtonText = classNames(classes.buttonText, {
    loading,
    withIcon: startIcon,
  });

  // RENDERS
  const loadingSpinner = (
    <span className={cssLoadingSpinner}>
      <CircularProgress size={20} classes={spinnerClasses} />
    </span>
  );

  const iconLeft = loading && startIcon ? loadingSpinner : startIcon;

  return (
    <MuiButton
      disableRipple
      variant={variant}
      classes={buttonClasses}
      fullWidth={fullWidth}
      startIcon={iconLeft}
      {...props}
    >
      <span className={cssButtonText}>{children}</span>
      {loading && !startIcon && loadingSpinner}
    </MuiButton>
  );
};

export default Button;
