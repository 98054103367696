import { createContext } from 'react';

import { defaultState } from 'stores/utils';

// Storage Key
export const storageKey = 'private-offers-v2';

// Context
const Context = createContext(defaultState());
Context.displayName = storageKey;

// Provider
export default Context;
