export enum MsftOpportunityClosedErrorReasonEnum {
  DUPLICATE_BULK = 'Duplicate - created through bulk upload',
  DUPLICATE_PARTNER_CENTER = 'Duplicate - created in Partner Center',
  DUPLICATE_MICROSOFT = 'Duplicate - sent by Microsoft',
  CONNECTOR_SYNC = 'Connector data sync issues',
  CUSTOMER_MATCHING = 'Customer matching issues',
  SOLUTION_ISSUE = 'Solution issues - explain in notes',
  TEST_REFERRAL = 'Test referral',
  DATA_ERROR = 'Data error',
  OTHER = 'Other - explain in notes',
}
