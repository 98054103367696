import {
  BillingTermValue,
  BillingTermValueType,
  PaymentModelValue,
  PaymentModelValueType,
} from 'stores/privateOffers/typings';
import { Cloud } from 'utils/cloudTypes';

interface PricingConfigBillingTermOption {
  text: string;
  value: BillingTermValueType;
}

interface PricingConfigPaymentModelOption {
  text: string;
  value: PaymentModelValueType;
}

const awsPaymentScheduleOption = {
  text: 'Payment schedule',
  value: PaymentModelValue.PaymentSchedule,
};

const getAwsPaymentModelOptions = (isAmendmentOffer: boolean) =>
  isAmendmentOffer
    ? [awsPaymentScheduleOption]
    : [
        awsPaymentScheduleOption,
        {
          text: 'Per product',
          value: PaymentModelValue.PerProduct,
        },
      ];

export const paymentModelOptionsByCloud = (
  isAmendmentOffer: boolean,
): Record<Cloud, PricingConfigPaymentModelOption[]> => ({
  [Cloud.Aws]: getAwsPaymentModelOptions(isAmendmentOffer),
  [Cloud.Gcp]: [
    {
      text: 'Payment schedule',
      value: PaymentModelValue.PaymentSchedule,
    },
  ],
  [Cloud.Azure]: [],
  [Cloud.Redhat]: [],
});

export const billingTermOptionsByCloud: Record<
  Cloud,
  PricingConfigBillingTermOption[]
> = {
  [Cloud.Aws]: [
    {
      text: '1 month',
      value: BillingTermValue.OneMonth,
    },
    {
      text: '1 year',
      value: BillingTermValue.OneYear,
    },
    {
      text: '2 years',
      value: BillingTermValue.TwoYears,
    },
    {
      text: '3 years',
      value: BillingTermValue.ThreeYears,
    },
    {
      text: 'Custom',
      value: BillingTermValue.Custom,
    },
    {
      text: 'Future dated',
      value: BillingTermValue.FutureDated,
    },
  ],
  [Cloud.Gcp]: [
    {
      text: 'Custom',
      value: BillingTermValue.Custom,
    },
    {
      text: 'Future dated',
      value: BillingTermValue.FutureDated,
    },
  ],
  [Cloud.Azure]: [],
  [Cloud.Redhat]: [],
};

const defaultExport = {
  paymentModelOptionsByCloud,
  billingTermOptionsByCloud,
};

export default defaultExport;
