import { decorate, observable } from 'mobx';

class Offer {
  offerid = '';

  offerType = '';

  pricing = [];

  constructor(props) {
    const { offerid, offer_type: offerType, pricing } = props;

    this.offerid = offerid;
    this.offerType = offerType;
    this.pricing = pricing;
  }
}

decorate(Offer, {
  offerid: observable,
  offerType: observable,
  pricing: observable,
});

export default Offer;
