import { makeStyles } from 'vendor/material';
import { fontWeightMedium } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  nameContainer: { alignItems: 'center' },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: fontWeightMedium,
  },
}));

export default useStyles;
