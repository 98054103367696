export enum MsftOpportunityClosedLostReasonEnum {
  CUSTOMER_BUDGET = 'Customer did not have budget',
  CUSTOMER_OPPORTUNITY_SIZE = 'Customer / opportunity too small',
  CUSTOMER_DID_NOT_RESPOND = 'Customer did not respond',
  CUSTOMER_SELECTED_OTHER_VENDOR = 'Customer selected another vendor',
  SOLUTION_NOT_A_FIT = 'My solution or service is not a fit',
  NOT_A_CUSTOMER = 'Not a customer',
  PROPOSED_TIMELINE = 'Proposed timeline too short',
  REPORT_ABUSE_SPAM_PHISHING = 'Report as abuse, spam, or phishing',
  OTHER = 'Other',
}
