import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    boxShadow: theme.depth.d100,
    minHeight: theme.spacing(7.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.NEUTRAL000,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.NEUTRAL020,
    },
    '@media (max-width: 1028px)': {
      overflow: 'scroll',
    },
    margin: `${theme.spacing(1)}px 0`,
    borderRadius: '4px',
    minWidth: '1024px',
  },
  smallLabel: {
    color: theme.palette.NEUTRAL300,
    fontSize: '12px',
    fontWeight: 500,
  },
  'mt-0.5': {
    marginTop: theme.spacing(0.5),
  },
  'mt-0.25': {
    marginTop: theme.spacing(0.25),
  },
  flex: {
    display: 'flex',
  },
}));
export default useStyles;
