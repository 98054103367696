import React from 'react';
import { TableHead, TableRow } from 'vendor/material';

// COMPONENTS
import TableHeadCell from '../TableHeadCell/TableHeadCell';

const EnhancedTableHead = ({
  classes,
  order,
  orderColumn,
  onRequestSort,
  headers,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableHeadCell
            {...header}
            key={header.id}
            order={order}
            orderColumn={orderColumn}
            onSort={createSortHandler}
            classes={classes}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
