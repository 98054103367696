import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: { width: '320px', marginBottom: theme.spacing(1) },
  loadingIcon: {
    animation: '$spin 1s linear infinite',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(360deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
}));

export default useStyles;
