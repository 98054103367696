import { useContext, useEffect } from 'react';
import { flow, filter, isEqual } from 'lodash/fp';

import { reportError } from 'utils/monitor';

// Partial of a Store, but only with properties needed for store hooks
interface StoreForCtx {
  hasData?: boolean;
  loading?: boolean;
  skip?: boolean;
  syncing?: boolean;
  // TODO find out if error should be string, Error, or something else
  error?: unknown;
  load?: () => void;
}

// Overload is only needed to support the Auth store, which has no properties in common with StoreForCtx
function useStore<T>(context: React.Context<T>): T;
function useStore<T extends StoreForCtx>(context: React.Context<T>): T {
  const store = useContext(context);

  useEffect(() => {
    if (
      Object.prototype.hasOwnProperty.call(store, 'load') &&
      !store.hasData &&
      !store.loading
    ) {
      store.load();
    }
  }, [store.hasData, store]);

  return store;
}

const hasLoaded = (...stores: StoreForCtx[]) => {
  stores.forEach((s) => {
    if (s.error) {
      console.error(s.error);
      console.info('Offending Store', s);
      reportError(s.error);
    }
  });

  const loaded = isEqual(
    flow(
      filter((o: StoreForCtx) => o.hasData || o.skip || !!o.error),
      filter((o) => !o.loading),
    )(stores).length,
    stores.length,
  );

  return loaded;
};

const hasSynced = (...stores) => {
  stores.forEach((s) => {
    if (s.error) {
      console.error(s.error);
      console.info('Offending Store', s);
      reportError(s.error);
    }
  });

  const synced = isEqual(
    flow(
      filter((o: StoreForCtx) => o.hasData || o.skip || !!o.error),
      filter((o) => !o.syncing),
    )(stores).length,
    stores.length,
  );

  return synced;
};

export default useStore;
export { hasLoaded, hasSynced };
