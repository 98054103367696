import { makeStyles } from 'vendor/material';

export const useHoverCopyStyles = makeStyles((theme) => ({
  copyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  copyButton: {
    visibility: 'hidden',
    height: 'fit-content',
    marginLeft: theme.spacing(0.25),
    padding: '0',
    borderRadius: '5px',
  },
  hoverCopyContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      '& $copyButton': {
        visibility: 'visible',
      },
    },
  },
}));
