import { useHistory } from 'react-router';
import { AceOpportunityResponse } from '../types/responses/AceOpportunityResponse';

export interface CoSellHistoryState {
  // metadata search term
  coSellSearchTerm?: string;
  // react router search params
  coSellSearchParams?: string;
  opportunity?: AceOpportunityResponse;
  opportunityId?: string;
}

export const useCoSellHistory = () => {
  const history = useHistory<CoSellHistoryState>();
  return history;
};
