import { decorate, action, computed } from 'mobx';

import Store from './Store';
import api from 'utils/api';
import { Vendor } from '../models';

class VendorStore extends Store {
  get vendor() {
    return super.data;
  }

  set vendor(vendor) {
    super.data = new Vendor(vendor);
  }

  load = (options = { headers: {} }) => {
    super.loading = true;

    api
      .get('vendor', options)
      .json()
      .then(({ vendors }) => {
        this.vendor = vendors[0];
        super.loading = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };

  // reloads vendor in the background without changing the loadings state
  reload = (options = { headers: {} }) => {
    return new Promise((resolve, reject) => {
      api
        .get('vendor', options)
        .json()
        .then(({ vendors }) => {
          this.vendor = vendors[0];
          resolve(vendors[0]);
        })
        .catch((error) => {
          super.error = error;
          reject(error);
        });
    });
  };

  // TODO convert to promise
  initSalesforce = (client_id, client_secret, callback) => {
    this.setLoading(true);

    api
      .post('vendor/salesforce', {
        json: { client_id, client_secret },
      })
      .json()
      .then(({ vendor }) => {
        this.vendor = vendor;
        super.loading = false;
        callback(this.vendor);
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
        // callback(error)
      });
  };

  // TODO convert to promise
  deleteSalesforce = (callback) => {
    super.loading = true;

    api
      .delete('vendor/salesforce')
      .json()
      .then(({ vendor }) => {
        this.vendor = vendor;
        super.loading = false;
        callback(this.vendor);
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
        // callback(error)
      });
  };

  // TODO convert to promise
  setupSalesforce = (json, callback) => {
    super.loading = true;

    api
      .post('vendor/salesforce/setup', {
        json,
      })
      .json()
      .then(({ vendor }) => {
        this.vendor = vendor;
        super.loading = false;
        callback(this.vendor);
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
        // callback(error)
      });
  };

  get hasData() {
    return (this.vendor && this.vendor.id) || (!this.vendor && super.error);
  }
}

decorate(VendorStore, {
  vendor: computed,
  load: action,
  reload: action,
  hasData: computed,
  initSalesforce: action,
  deleteSalesforce: action,
  setupSalesforce: action,
});

export default VendorStore;

const vendorStore = new VendorStore();
export { vendorStore };
