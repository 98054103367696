import { makeStyles } from 'vendor/material';

const useStyles = ({ type }) =>
  makeStyles((theme) => {
    let background = '';
    let color = '';
    switch (type) {
      case 'error':
        background = theme.palette.error.main;
        color = theme.palette.common.white;
        break;
      case 'info':
      default:
        background = theme.palette;
        color = theme.palette.common.black;
        break;
    }

    return {
      root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(1.25),
        textAlign: 'center',
        background,
        color,
      },
    };
  })();

export default useStyles;
