import { useMutation } from '@tanstack/react-query';

// temprorary for development
const sleep = (ms: number = 2000) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * WIP: this is simulated for development
 * TODO:
 *  - replace with actual aws sync request
 */
export function useAwsOpportunitySync({
  opportunityId,
  token,
}: {
  opportunityId: string;
  token: string;
}) {
  const {
    mutateAsync: syncAwsOpportunity,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: () => sleep(2000),
    onSuccess: () => {
      console.log('syncAwsOpportunity success');
    },
  });
  return {
    syncAwsOpportunity,
    isLoading,
    isSuccess,
  };
}
