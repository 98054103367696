import { BrowserClient, Hub, init as SentryInit } from '@sentry/browser';

import buildEnvironment from '../buildEnvironment';

import { User } from './types';

const initSentry = (): void => {
  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    SentryInit({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: buildEnvironment.environment,
      release: `downstream-app@${process.env.REACT_APP_RELEASE_SHA}`, // should match SENTRY_RELEASE from .circleci/config.yml
    });
  }
};

const dsn =
  process.env.REACT_APP_ENABLE_SENTRY === 'true'
    ? process.env.REACT_APP_SENTRY_DSN
    : '';

const client = new BrowserClient({
  dsn,
  environment: buildEnvironment.environment,
});
const globalHub = new Hub(client);

const addError = (exception: any, context?: object, fingerprint?: string[]) => {
  globalHub.withScope((scope) => {
    if (context) {
      for (const [key, val] of Object.entries(context)) {
        scope.setContext(key, val);
      }
    }
    if (fingerprint) {
      scope.setFingerprint(fingerprint);
    }
    globalHub.captureException(exception);
  });
};

function setUserDetails(user: User) {
  const id = user?.id;
  const email = user?.email;
  const vendorid = user?.metadata?.vendorid;
  const role = user?.metadata?.role;

  globalHub.configureScope((scope) => {
    scope.setUser({ id, email, username: user.name });
    scope.setExtra('vendorId', vendorid);
    scope.setExtra('role', role);
  });

  // Allow vendor ID to be searched in Sentry events
  globalHub.setTag('vendorid', vendorid);
}

export { setUserDetails, initSentry, addError };
