import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
  },
  calendarContent: {
    marginBottom: theme.spacing(1.5),
  },
  containerList: {
    width: 280,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  menuItem: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.NEUTRAL500,
    padding: theme.spacing(1.25, 2),
    '&:hover': {
      backgroundColor: theme.palette.BLUE050,
    },
    '&.selected': {
      color: theme.palette.BLUE400,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  containerRange: {
    width: 280,
    padding: 12,
    display: 'flex !important',
    flexDirection: 'row',
    borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
  },
  inputDateContainer: {
    display: 'flex',
    position: 'relative',
    '& input': {
      textAlign: 'left',
      paddingLeft: 10,
      paddingRight: 10,
      transitionProperty: 'box-shadow, background !important',
      transitionDuration: '150ms !important',
      transitionTimingFunction: 'ease-in-out !important',
      backgroundColor: '#FFFFFF',
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#DAE3F3',
      borderRadius: 0,
      color: '#272C34',
      width: '100%',
      cursor: 'text',
      fontSize: 14,
      letterSpacing: 0,
      fontWeight: 400,
      height: 30,
    },
  },
  dividerTo: {
    padding: 6,
  },
}));

export default useStyles;
