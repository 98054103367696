import { fontWeightRegular, fontWeightBold } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    overflowWrap: 'anywhere', // prevent long link text from overflowing
  },
  businessTitle: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.NEUTRAL700,
    fontWeight: fontWeightBold,
  },
  contactName: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.NEUTRAL700,
    fontWeight: fontWeightRegular,
  },
  contactEmail: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.NEUTRAL300,
    fontWeight: fontWeightRegular,
  },
}));

export default useStyles;
