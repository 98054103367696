import React from 'react';
import { CoSellActivityFeedItem } from './CoSellActivityFeedItem';
import { AceOpportunityEventResponse } from '../../types/responses/AceOpportunityEventResponse';
import { Typography } from '../../../../../vendor/material';
import { DateTime } from 'luxon';
import useStyles from './CoSellActivityFeed.styles';

interface CoSellActivityFeedProps {
  activities: AceOpportunityEventResponse[];
}

export const CoSellActivityFeed = (props: CoSellActivityFeedProps) => {
  const classes = useStyles();

  return (
    <div>
      <Typography component="h4" className={classes.widgetTitle}>
        Activity{' '}
        <span className={classes.widgetTitleFade}>
          ({DateTime.local().toFormat('ZZZZ')})
        </span>
      </Typography>
      <div className={classes.activitiesList}>
        {props.activities.map((activity) => {
          const activityDetails = activity.activityDetails;
          let displayName =
            activityDetails.given_name || activityDetails.family_name || 'User';
          if (activityDetails.given_name && activityDetails.family_name) {
            displayName =
              activityDetails.given_name + ' ' + activityDetails.family_name;
          }
          return (
            <CoSellActivityFeedItem
              source={activity.operation}
              displayName={displayName}
              message={activityDetails.displayText}
              timestamp={activity.timestamp}
              picture_url={activityDetails.picture_url}
            />
          );
        })}
      </div>
    </div>
  );
};
