import React, { useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AccountAvatar } from '@tackle-io/platform-ui';
import classnames from 'classnames';
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from 'vendor/material';
import { DotsVertical, Logout } from 'mdi-material-ui';
import { truncate } from 'lodash-es';
import { GlobalHelp } from 'components/GlobalHelp/GlobalHelp';

// ASSETS
import useStyles from './Profile.styles';

// COMPONENTS
import buildEnvironment from '../../utils/buildEnvironment';
import EnvironmentButton from './Environment/EnvironmentButton';
import TextButton from '../TextButton';

// HELPERS
import { useMinWidth } from '../../utils/hooks';
import { Auth } from '../../stores';

function Profile({ drawerOpen }) {
  const { expired, profile } = useContext(Auth);

  const classes = useStyles();
  const [isOpen, setMenuOpen] = useState(false);
  const anchorEl = useRef(null);

  // EVENTS
  function toggleMenu() {
    setMenuOpen(!isOpen);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  const widthSM = useMinWidth('sm');

  // Display Login
  if (expired) {
    return (
      <TextButton component={Link} to="/login" color="inherit">
        Login
      </TextButton>
    );
  }

  if (!widthSM) {
    return (
      <>
        <GlobalHelp iconColor="light" />
        <IconButton
          edge="end"
          buttonRef={anchorEl}
          onClick={() => toggleMenu()}
          aria-haspopup="true"
          color="inherit"
        >
          <DotsVertical />
        </IconButton>
        <Menu
          anchorEl={anchorEl.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          getContentAnchorEl={null}
          marginThreshold={12}
          open={isOpen}
          onClose={() => closeMenu()}
          classes={{ paper: classes.menu }}
          disableAutoFocus
        >
          {(buildEnvironment.environment === 'staging' ||
            buildEnvironment.environment === 'local/unknown') && (
            <EnvironmentButton />
          )}
          <MenuItem component={Link} to="/logout" color="inherit">
            <ListItemIcon color="inherit" className={classes.menuItemIcon}>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </>
    );
  }
  // STYLES
  const cssProfile = classnames(classes.profile, {
    [classes.profileCollapsed]: !drawerOpen,
  });

  const displayName =
    profile.name === profile.email ? profile.nickname : profile.name;

  return (
    <div>
      <div className={cssProfile} onClick={toggleMenu} role="presentation">
        <IconButton ref={anchorEl} aria-haspopup="true" color="inherit">
          <AccountAvatar
            src={profile.picture}
            alt={profile.nickname}
            className={classes.avatar}
          />
        </IconButton>
        {drawerOpen && (
          <div>
            <Typography className={classes.profileName}>
              {truncate(displayName, { length: 19 })}
            </Typography>
            <Typography className={classes.profileEmail}>
              {truncate(profile.email, { length: 22 })}
            </Typography>
          </div>
        )}
      </div>
      {drawerOpen && (
        <>
          {(buildEnvironment.environment === 'staging' ||
            buildEnvironment.environment === 'local/unknown') && (
            <EnvironmentButton />
          )}
        </>
      )}
      <Menu
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        marginThreshold={12}
        open={isOpen}
        onClose={() => closeMenu()}
        classes={{ paper: classes.menu }}
        disableAutoFocus
      >
        <MenuItem component={Link} to="/logout" color="inherit">
          <ListItemIcon color="inherit" className={classes.menuItemIcon}>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </div>
  );
}

export default observer(Profile);
