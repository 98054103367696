import React from 'react';
import { Typography } from 'vendor/material';
import { Link } from '@tackle-io/platform-ui';

import { buildMailTo } from 'utils';
import Email from 'utils/constants';
import Background from '../Background/Background';
import Modal from '../Modal/Modal';
import Message from '../Message/Message';

type EntryTemplateProps = {
  Alert?: React.ReactElement;
  notificationMessage?: string;
  notificationType?: string;
  showContactSupport?: boolean;
};

const Empty: React.FC = () => null;

const EntryTemplate: React.FC<EntryTemplateProps> = ({
  Alert = <Empty />,
  notificationMessage,
  notificationType,
  showContactSupport = false,
  children,
}) => {
  return (
    <>
      <Background />
      {Alert}
      <Modal>
        {children}
        {notificationMessage && (
          <Message type={notificationType}>
            <Typography color="inherit">{notificationMessage}</Typography>
            {showContactSupport && (
              <Typography color="inherit">
                For assistance, please contact{' '}
                <Link
                  style={{ color: 'white' }}
                  to={buildMailTo(
                    `${Email.Support}`,
                    'Cannot create user password',
                  )}
                  external
                >
                  {`${Email.Support}`}
                </Link>
              </Typography>
            )}
          </Message>
        )}
      </Modal>
    </>
  );
};

export default EntryTemplate;
