import {
  Button,
  Calendar,
  Dropdown,
  useTextFieldStyles,
} from '@tackle-io/platform-ui';
import { get } from 'lodash/fp';
import { DateTime } from 'luxon';
import { Alert, Calendar as CalendarIcon } from 'mdi-material-ui';
import { minimumExpirationDate } from 'pages/PrivateOffers/utils/formatFormData';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Cloud } from 'utils/cloudTypes';
import { Box, InputLabel, Popover, SvgIcon, Typography } from 'vendor/material';
import useStyles from './styles';
import { ScheduleDateFieldProps } from './typings';
import { scheduleFormPath } from '../utils';
import { pendoIdGenerator } from 'utils/idGenerator';
import { OffersDataId } from 'utils/intercomEnums';
import { ValidationError } from 'yup';
import { getMidnightUTCLocalDateOrNull } from '../dateUtils';

const AwsScheduleDateField: React.FC<ScheduleDateFieldProps> = ({
  field,
  index,
}) => {
  const { control, errors } = useFormContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const inputClasses = useTextFieldStyles();
  const open = !!anchorEl;
  const id = open ? 'date-popover' : undefined;

  const scheduleError: { [i: number]: ValidationError } | null =
    get(scheduleFormPath)(errors) ?? {};

  const errorForIndex = scheduleError[index];

  const handleClick = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Controller
      name={`${scheduleFormPath}[${index}].invoiceDate`}
      defaultValue={field.invoiceDate ?? ''}
      control={control}
      render={({ onChange, value }): React.ReactElement => (
        <div
          data-id={pendoIdGenerator(
            OffersDataId.PRIVATE_OFFERS_NEW_OFFER_PRICING_PAYMENT_SCHEDULE_DATE,
          )}
        >
          <InputLabel className={inputClasses.inputLabel} htmlFor="logo">
            {index === 0 ? 'Invoice date *' : 'Invoice date'}
          </InputLabel>
          <Dropdown
            value={getMidnightUTCLocalDateOrNull(value)?.toFormat('D')}
            onClick={handleClick}
            placeholder="Invoice date"
            startIcon={<CalendarIcon />}
            fullWidth
            hideEndIcon
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            classes={{
              paper: classes.paper,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className={classes.calendarContent}>
              <Calendar
                onChange={(date: Date): void => {
                  onChange(DateTime.fromJSDate(date));
                  setAnchorEl(null);
                }}
                value={getMidnightUTCLocalDateOrNull(value)?.toJSDate()}
                minDate={minimumExpirationDate(Cloud.Aws)}
              />
            </div>
            {value && (
              <div className={classes.calendarFooter}>
                <Button
                  variant="outlined"
                  appearance="secondary"
                  onClick={(e): void => onChange(null)}
                >
                  Clear Date
                </Button>
              </div>
            )}
          </Popover>
          {errorForIndex && (
            <Box className={classes.paymentScheduleErrorContainer}>
              <SvgIcon>
                <Alert />
              </SvgIcon>
              <Typography component="span">{errorForIndex.message}</Typography>
            </Box>
          )}
        </div>
      )}
    />
  );
};

export default AwsScheduleDateField;
