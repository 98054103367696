import { filter, flow, sortBy } from 'lodash/fp';

import { CalendarAlert } from 'mdi-material-ui';

const useExpiringContractsItems = (contracts, onClick) => {
  const expiringcontracts = flow(
    filter((c) => c.expiringSoon),
    filter((c) => !c.archivedAt),
    filter('expirationDate.isValid'),
    sortBy('expirationDate'),
  )(contracts);

  return expiringcontracts.map((contract) => ({
    Icon: CalendarAlert,
    date: contract.expirationDate,
    client: contract.displayName,
    status: 'error',
    onClick: () => onClick(`/contracts/${contract.id}`),
  }));
};

export default useExpiringContractsItems;
