import { DocsTooltip } from '@tackle-io/platform-ui';
import React from 'react';
import { Box, Drawer, Typography } from 'vendor/material';
import PaymentScheduler from './PaymentScheduler';
import useStyles from './PaymentScheduler.styles';
import { pendoIdGenerator } from '../../../../utils/idGenerator';
import { privateOffersUIElements } from '../../../../utils/pendoEnums';

export interface PaymentScheduleDrawerProps {
  isPaymentScheduleDrawerOpen: boolean;
  closePaymentScheduleDrawer: () => void;
  disablePaymentScheduleToolDurationField?: boolean;
}

const PaymentScheduleDrawer: React.FC<PaymentScheduleDrawerProps> = ({
  isPaymentScheduleDrawerOpen,
  closePaymentScheduleDrawer,
  disablePaymentScheduleToolDurationField = false,
}) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={isPaymentScheduleDrawerOpen}
      onClose={closePaymentScheduleDrawer}
      classes={{ paper: classes.drawer }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography classes={{ root: classes.title }}>
          Create payment schedule
        </Typography>
        <DocsTooltip
          topicId="offers-create-payment-schedule-tooltip"
          data-id={pendoIdGenerator(
            privateOffersUIElements.PRIVATE_OFFERS_PAYMENT_SCHEDULE_DOCS_TOOLTIP,
          )}
        />
      </Box>

      <PaymentScheduler
        closePaymentScheduleDrawer={closePaymentScheduleDrawer}
        disablePaymentScheduleToolDurationField={
          disablePaymentScheduleToolDurationField
        }
      />
    </Drawer>
  );
};

export default PaymentScheduleDrawer;
