import { makeStyles } from 'vendor/material';
import { VendorEnvironmentLabelEnum, getProductionVendorId } from '../utils';
import { List, ListSubheader, ListItem, ListItemText } from 'vendor/material';
import { Alert, Check, Loading } from 'mdi-material-ui';

import { fontWeightBold } from 'utils/fontWeightConstants';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSwapVendors } from '../AccountSwitcher/AccountList';
import { Vendor } from 'generated/graphql';
import classNames from 'classnames';
import { ampli } from 'utils/analytics/ampli';
import { EnvironmentColorMap } from '../hooks/useEnvironmentColor';
import theme from 'Theme';
import { EnvironmentTag } from './EnvironmentTag';

const useStyles = makeStyles((theme) => ({
  environmentList: {
    color: 'white',
    minWidth: 176,
  },
  listSubHeader: {
    lineHeight: 2,
    color: 'white',
    fontSize: theme.typography.pxToRem(16),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  listItem: {
    lineHeight: 2,
    paddingBottom: '8px',
    paddingTop: '8px',
    '& span': {
      fontSize: theme.typography.pxToRem(16),
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  checkIcon: {
    color: 'white',
  },
  statusCircle: {
    height: 12,
    width: 12,
    borderRadius: '100%',
    marginRight: '8px',
  },
  skeleton: {
    backgroundColor: theme.palette.NEUTRAL300,
    margin: 'auto',
  },
  activeEnvironment: {
    '& span': { fontWeight: fontWeightBold },
  },
  hoverEnvironment: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#111432',
    },
  },
  environmentTagContainer: {
    marginRight: '8px',
  },
}));

export const VendorEnvironmentList = ({
  activeVendor,
  vendorEnvironmentsList,
  closeMenu,
  setSwapSucceeded,
  swapSucceeded,
}: {
  activeVendor?: Pick<Vendor, 'id' | 'production_vendor_id' | 'vendor_type'>;
  vendorEnvironmentsList: Array<Vendor>;
  closeMenu: () => void;
  setSwapSucceeded: Dispatch<SetStateAction<boolean>>;
  swapSucceeded: boolean;
}) => {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState(null);

  const productionVendorId =
    activeVendor && getProductionVendorId(activeVendor);

  const handleSwapSuccess = () => {
    setSwapSucceeded(true);
    ampli.vendorEnvironmentSwitched({
      new_vendor_id: activeVendor?.id,
      new_vendor_type: activeVendor?.vendor_type,
    });
  };

  const {
    swapVendors,
    loading: swapLoading,
    error: swapError,
  } = useSwapVendors({ onCompleted: handleSwapSuccess });

  const handleClickItem = (item: { id: string }) => {
    if (!swapLoading) {
      setSelectedId(item.id);
      swapVendors(item.id);
      ampli.buttonClicked({
        button_location: 'vendor-environment-switcher',
        button_name: 'switch-environment',
        button_product_area: 'account-management',
      });
      closeMenu();
    }
  };

  return (
    <List
      subheader={
        <ListSubheader className={classes.listSubHeader}>
          Environments
        </ListSubheader>
      }
      disablePadding
      dense
      className={classes.environmentList}
    >
      {vendorEnvironmentsList?.map(({ name, id, vendor_type }) => {
        const statusColor = EnvironmentColorMap[vendor_type];
        const isActive = activeVendor?.id === id;
        return (
          <ListItem
            key={id}
            className={classNames(
              classes.listItem,
              isActive ? classes.activeEnvironment : classes.hoverEnvironment,
            )}
            onClick={() => {
              if (!isActive) handleClickItem({ id });
            }}
          >
            <div
              className={classes.statusCircle}
              style={{
                backgroundColor: statusColor && theme.palette[statusColor],
              }}
            />
            <EnvironmentTag
              environment={vendor_type}
              isShortLabel={true}
              className={classes.environmentTagContainer}
            />
            <ListItemText
              color="black"
              primary={
                id === productionVendorId
                  ? VendorEnvironmentLabelEnum.prod
                  : name
              }
            />
            {((swapSucceeded && selectedId === id) ||
              (!swapSucceeded && activeVendor?.id === id)) && (
              <Check
                role="status"
                fontSize="medium"
                aria-hidden={false}
                aria-label="active environment icon"
                className={classes.checkIcon}
              />
            )}
            {swapLoading && selectedId === id && (
              <Loading
                role="status"
                fontSize="medium"
                aria-hidden={false}
                aria-label="loading environment icon"
              />
            )}
            {swapError && selectedId === id && (
              <Alert
                role="status"
                fontSize="medium"
                aria-hidden={false}
                aria-label="error switching environment icon"
              />
            )}
          </ListItem>
        );
      })}
    </List>
  );
};
