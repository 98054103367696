import React from 'react';
import { Grid, Typography } from 'vendor/material';
import { ListStatus } from 'mdi-material-ui';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { NextStepsHistory } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';
interface NextStepsCardProps {
  nextSteps?: string; //TODO: check what we need to for this property
  nextStepsHistory: NextStepsHistory[];
}

const NextStepsCard: React.FC<NextStepsCardProps> = ({
  // nextSteps,
  nextStepsHistory,
}) => {
  const checkNextSteps = () => {
    if (!nextStepsHistory || nextStepsHistory.length === 0)
      return (
        <Typography variant="body2">
          When added, "Next steps" will appear here.
        </Typography>
      );
    return nextStepsHistory?.map((nextStep, index) => (
      <InfoItem
        key={index}
        itemTitle={nextStep.time} //TODO: update the time format
        value={nextStep.value}
        gridSize={12}
        flipContent
      />
    ));
  };

  return (
    <DetailsSectionCard
      title="Next steps"
      icon={<ListStatus />}
      showTurner
      defaultShowSteps={false} // TODO: add logic here to check if the opportunity is a draft, if so, defaultShowSteps should be false
    >
      <Grid container spacing={2}>
        {checkNextSteps()}
      </Grid>
    </DetailsSectionCard>
  );
};

export default NextStepsCard;
