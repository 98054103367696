export enum OpportunityMemberTitleEnum {
  CUSTOMER = 'Customer',
  CUSTOMER_TEAM = 'Customer Team',
  OPPORTUNITY_TEAM = 'Opportunity Team',
  PRIMARY_CONTACT = 'Primary Contact',
  OPPORTUNITY_OWNER = 'Opportunity Owner',
  SUCCESS_MANAGER = 'Success Manager',
  SALES_REP = 'Sales Rep',
  ACCOUNT_MANAGER = 'Account Manager',
  DEVELOPMENT_MANAGER = 'Development Manager',
  ISV_SUCCESS_MANAGER = 'ISV Success Manager',
  WWPS_DEVELOPMENT_MANAGER = 'WWPS Development Manager',
}
