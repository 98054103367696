import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  itemList: {},
  item: {},
  itemHeader: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: '#5E6C84',
  },
  itemContent: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: '#091E42',
  },
  itemFooter: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: '#97A0AF',
  },
}));

export default useStyles;
