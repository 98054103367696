import * as amplitude from '@amplitude/analytics-browser';
import {
  AnalyticsUser,
  IAnalyticsService,
  InitOptions,
  CurrentUser,
} from './types';
import {
  DestinationPlugin,
  BeforePlugin,
  EnrichmentPlugin,
} from '@amplitude/analytics-types';
import { ampli, Event } from './ampli';
import { reportError } from '../monitor';
import {
  OffersPageViewedEnrichmentPlugin,
  PageViewedEnrichmentPlugin,
} from './plugins';

type AmplitudePlugin = DestinationPlugin | BeforePlugin | EnrichmentPlugin;

const environment = process.env.NODE_ENV;
const isDevEnvironment = environment === 'development';
const proxyServerUrl = process.env.REACT_APP_AMPLITUDE_SERVER_URL;
const defaultServerUrl = 'https://api2.amplitude.com/2/httpapi';
const GROUP_TYPE_VENDOR_ID = 'vendor_id';

export const isEnabled = () => {
  return Boolean(
    process.env.REACT_APP_ENABLE_USAGE_ANALYTICS === 'true' &&
      process.env.REACT_APP_ENABLE_AMPLITUDE === 'true' &&
      process.env.REACT_APP_AMPLITUDE_API_KEY,
  );
};

const buildPlugins = () => {
  const plugins: Array<AmplitudePlugin> = [];

  plugins.push(PageViewedEnrichmentPlugin());
  plugins.push(OffersPageViewedEnrichmentPlugin());

  return plugins;
};

const init = (
  initOptions: InitOptions = {
    apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
  },
) => {
  const { apiKey } = initOptions;
  if (!apiKey && !process.env.REACT_APP_AMPLITUDE_API_KEY) {
    const err = 'Cannot init Amplitude as the apiKey has not been set';
    isDevEnvironment ? console.warn(err) : reportError(new Error(err));
    return;
  }
  const sdkOptions = {
    defaultTracking: true,
    serverUrl: proxyServerUrl ? `${proxyServerUrl}/events` : defaultServerUrl,
  };

  ampli.load({
    client: {
      apiKey: apiKey,
      configuration: sdkOptions,
    },
  });
  buildPlugins()?.forEach((plugin) => {
    ampli.client.add(plugin);
  });
};

const identify = (currentUser: CurrentUser) => {
  // Set User ID and User Properties
  const userIdentifyEvent = new amplitude.Identify();
  ampli.client.setUserId(currentUser.id);
  const vendorId = currentUser?.vendor?.id || '';
  userIdentifyEvent.set('email', currentUser?.email);
  userIdentifyEvent.set('name', currentUser?.name);
  const userRole = currentUser?.role || '';
  userIdentifyEvent.set('role', userRole);
  ampli.client.identify(userIdentifyEvent);

  // Set Group Properties
  const groupIdentifyEvent = new amplitude.Identify();
  const vendorType = currentUser?.vendor?.vendor_type;
  vendorType && groupIdentifyEvent.set('vendor_type', vendorType);
  const vendorName = currentUser?.vendor?.name;
  vendorName && groupIdentifyEvent.set('vendor_name', vendorName);
  ampli.client.groupIdentify(
    GROUP_TYPE_VENDOR_ID,
    vendorId,
    groupIdentifyEvent,
  );
};

const track = (
  eventName: Event['event_type'],
  eventProperties?: Record<string, any>,
  eventOptions?: amplitude.Types.EventOptions,
) => {
  const vendorId = eventProperties?.vendor_id || '';
  ampli.track(
    {
      event_type: eventName,
      event_properties: eventProperties,
      groups: { vendor_id: vendorId },
    },
    eventOptions,
  );
};

const optOut = (user: AnalyticsUser) => {
  ampli.client.setOptOut(true);
};

const optIn = (user: AnalyticsUser) => {
  ampli.client.setOptOut(false);
};

const flush = () => {
  ampli.flush();
};

const noOp = () => {
  const err = 'Cannot perform this action -- Amplitude is disabled';
  isDevEnvironment ? console.warn(err) : reportError(new Error(err));
  return;
};

export const analyticsServiceDisabled: IAnalyticsService = {
  init: noOp,
  identify: noOp,
  track: noOp,
  optIn: noOp,
  optOut: noOp,
  flush: noOp,
};

const AmplitudeAnalytics: IAnalyticsService = {
  identify,
  track,
  optOut,
  optIn,
  init,
  flush,
};

export default AmplitudeAnalytics;
