import { UpdateAceOpportunityFormValues } from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import {
  AceInvolvementTypeEnum,
  AceInvolvementVisibilityEnum,
} from '../../types/enums';
import {
  LifeCycle,
  UpdateAceOpportunityRequest,
} from '../../types/requests/AceOpportunityRequest';
import { SoftwareRevenue } from '../../types/responses/AceOpportunityResponse';
import {
  getCustomer,
  getMarketing,
  getPartnerOpportunityTeam,
  getProject,
} from './convertAceFormValuesToCreateRequest';

// TODO: Assign values as UpdateAceSRRPOpportunityFormValues & enforce types
export const getSoftwareRevenue = (values) => {
  const softwareRevenue: SoftwareRevenue = {
    deliveryModel: values?.deliveryModel,
    value: {
      amount: values?.customerSoftwareValue,
      currencyCode: values?.currencyCode,
    },
    effectiveDate: values?.effectiveDate,
    expirationDate: values?.expirationDate,
  };
  return softwareRevenue;
};

const getAwsMarketplaceOffers = (values: UpdateAceOpportunityFormValues) => {
  return values?.marketplaceOfferId ? [values.marketplaceOfferId] : null;
};

export const getUpdatedLifecycle = (values: UpdateAceOpportunityFormValues) => {
  const targetCloseDate = values.targetCloseDate
    ? new Date(values.targetCloseDate)?.toISOString()
    : null;
  const lifeCycle: LifeCycle = {
    nextSteps: values.nextStep,
    targetCloseDate,
    closedLostReason: values.closedLostReason,
  };
  return lifeCycle;
};

export const convertAceFormValuesToUpdateRequest = (
  values: UpdateAceOpportunityFormValues,
): UpdateAceOpportunityRequest => {
  return {
    customer: getCustomer(values),
    lifeCycle: getUpdatedLifecycle(values),
    marketing: getMarketing(values),
    opportunityType: values.opportunityType,
    opportunityTeam: getPartnerOpportunityTeam(values),
    primaryNeedsFromAws: values.primaryNeedsFromAws,
    project: getProject(values),
    solutions: values.solutions,
    awsProducts: values.awsProducts,
    awsMarketplaceOffers: getAwsMarketplaceOffers(values),
    softwareRevenue: getSoftwareRevenue(values),
    // TODO: update the below values from form
    awsSubmission: {
      involvementType: AceInvolvementTypeEnum.CO_SELL,
      visibility: AceInvolvementVisibilityEnum.FULL,
    },
  };
};
