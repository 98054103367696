export enum v13AceUseCaseEnum {
  AI_MACHINE_LEARNING = 'AI/Machine Learning',
  BIG_DATA = 'Big Data',
  BUSINESS_APPLICATIONS = 'Business Applications',
  CLOUD_MANAGEMENT_TOOLS_DEVOPS = 'Cloud Management Tools & DevOps',
  CONTAINERS_SERVERLESS = 'Containers & Serverless',
  END_USER_COMPUTING = 'End User Computing',
  ENERGY = 'Energy',
  FINANCIAL_SERVICES = 'Financial Services',
  HEALTHCARE_LIFE_SCIENCES = 'Healthcare & Life Sciences',
  HYBRID_APPLICATION_PLATFORM = 'Hybrid application platform',
  INDUSTRIAL_SOFTWARE = 'Industrial Software',
  IOT = 'IOT',
  MEDIA_HIGH_PERFORMANCE_COMPUTING = 'Media & High performance computing (HPC)',
  MIGRATION = 'Migration',
  NETWORKING = 'Networking',
  SECURITY = 'Security',
  STORAGE = 'Storage',
}
