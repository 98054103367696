import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  flagBanner: {
    backgroundColor: theme.palette.NEUTRAL000,
    display: 'flex',
    border: `1px solid ${theme.palette.NEUTRAL040}`,
    borderLeft: `3px solid ${theme.palette.GREEN400}`,
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(3),
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.NEUTRAL010,
      borderLeft: `3px solid ${theme.palette.GREEN300}`,
    },
    '&:hover $flagIcon': {
      color: theme.palette.GREEN300,
    },
  },
  flagIcon: {
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.GREEN400,
    marginRight: theme.spacing(2),
  },
  flagText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL500,
    '& strong': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  flagTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL700,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.75),
    fontWeight: 500,
  },
  time: {
    position: 'absolute',
    top: 14,
    right: 14,
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.NEUTRAL100,
    '& p': {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.NEUTRAL100,
      margin: 0,
      marginLeft: theme.spacing(0.5),
    },
  },
}));

export default useStyles;
