import { Box } from 'vendor/material';
import DetailsCard from './components/DetailsCard/DetailsCard';
import useStyles from './DetailsTab.style';
import DetailsIcon from './components/DetailsCard/assets/DetailsIcon';
import PricingIcon from './components/DetailsCard/assets/PricingIcon';
import { buildContractDetails } from 'pages/Contracts/pages/Details/ContractDetailsMapper';
import { useSingleContractData } from 'pages/Contracts/pages/Details/providers/SingleContractDataProvider';
import { LoadingMetrics } from 'pages/Payments/components/PaymentsMetrics/components/LoadingMetrics';

const DetailsTab: React.FC = () => {
  const classes = useStyles();
  const { contract, loading } = useSingleContractData();

  if (loading) {
    return <LoadingMetrics />;
  }

  const { basicInformation, resellerInformation, productAndPricing } =
    buildContractDetails(contract);

  return (
    <Box className={classes.cardContainer}>
      <DetailsCard
        icon={<DetailsIcon />}
        headerText="Basic information"
        cardBodySections={basicInformation}
      />
      <DetailsCard
        icon={<DetailsIcon />}
        headerText="Reseller information"
        cardBodySections={resellerInformation}
      />
      <DetailsCard
        icon={<PricingIcon />}
        headerText="Product and pricing"
        cardBodySections={productAndPricing}
      />
    </Box>
  );
};

export default DetailsTab;
