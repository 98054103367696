import React from 'react';
import { useSalesforceCoSellWidgetCallToActionStyles } from './SalesforceCoSellWidgetCallToAction.styles';
import { LightbulbOnOutline } from 'mdi-material-ui';

interface SalesforceCoSellWidgetCallToActionProps {}

export const SalesforceCoSellWidgetCallToAction = (
  props: SalesforceCoSellWidgetCallToActionProps,
) => {
  const classes = useSalesforceCoSellWidgetCallToActionStyles();
  return (
    <div className={classes.callToActionContainer}>
      <p className={classes.header}>
        <LightbulbOnOutline /> Tackle expert tip
      </p>
      <p className={classes.detail}>
        Co-Sell increases win rates by 27% and deal size by 80%.
      </p>
    </div>
  );
};
