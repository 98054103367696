import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  nameContainer: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.NEUTRAL100}`,
  },
  name: {
    color: theme.palette.NEUTRAL300,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
  },
  value: {
    color: theme.palette.NEUTRAL500,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  rows: {
    padding: theme.spacing(1),
    '&:nth-child(odd)': {
      background: theme.palette.NEUTRAL020,
    },
  },
  totalPaymentsContainer: {
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.NEUTRAL100}`,
  },
  totalPayments: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
