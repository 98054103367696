import React from 'react';
import { Grid, IconButton } from 'vendor/material';
import { TextField } from '@tackle-io/platform-ui';
import { Delete } from 'mdi-material-ui';
import { Controller, useFormContext } from 'react-hook-form';
import useStyles from './FieldsAwsAmiHourlyAnnualPricing.styles';

const UnitField = ({ field, index, onRemove }) => {
  const classes = useStyles();
  const { control, errors } = useFormContext();

  return (
    <Grid container spacing={2} className={classes.dimensionFields}>
      <Grid item md={5} sm={11} xs={10} className={classes.fieldKey}>
        <Controller
          name={`pricing.units[${index}].unit`}
          control={control}
          defaultValue={field.unit}
          render={({ ref, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Unit"
              id={`pricing.units.${index}.unit`}
              error={errors.pricing?.units?.[index]?.unit?.message}
              inputRef={ref}
              inputProps={{
                'aria-label': `Unit ${index} name`,
              }}
            />
          )}
        />
      </Grid>

      <Grid item md={3} sm={12} xs={12} className={classes.priceContainer}>
        <Controller
          name={`pricing.units[${index}].hourlyPrice`}
          control={control}
          defaultValue={field.hourlyPrice}
          render={({ ref, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Hourly price"
              id={`pricing.units.${index}.hourlyPrice`}
              error={errors.pricing?.units?.[index]?.hourlyPrice?.message}
              mode="numberformat"
              fixedDecimalScale
              decimalScale={3}
              type="number"
              aria-label={`Unit ${index} hourly price`}
              inputProps={{ ref }}
            />
          )}
        />
      </Grid>

      <Grid item md={3} sm={12} xs={12} className={classes.fieldValue}>
        <Controller
          name={`pricing.units[${index}].durationPrice`}
          control={control}
          defaultValue={field.durationPrice}
          render={({ ref, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Duration price"
              id={`pricing.units.${index}.durationPrice`}
              error={errors.pricing?.units?.[index]?.durationPrice?.message}
              mode="numberformat"
              fixedDecimalScale
              decimalScale={3}
              type="number"
              aria-label={`Unit ${index} duration price`}
              inputProps={{ ref }}
            />
          )}
        />
      </Grid>

      {index !== 0 && (
        <Grid item md={1} sm={1} xs={2} className={classes.fieldAction}>
          <IconButton
            onClick={(): void => onRemove(index)}
            aria-label={`Delete unit ${index} button`}
          >
            <Delete className={classes.buttonIcon} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default UnitField;
