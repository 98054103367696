import { Box, Tab, Tabs } from 'vendor/material';
import useStyles from './ContractTabs.style';
import { useState } from 'react';
import DetailsTab from './components/DetailsTab/DetailsTab';
import PaymentScheduleTable from './components/DetailsTab/components/PaymentSchedule/PaymentScheduleTable';

interface PanelSelectorProps {
  children?: React.ReactNode;
  index: number;
  selectedIndex: number;
}
const PanelSelector = (props: PanelSelectorProps) => {
  const { children, selectedIndex, index } = props;

  if (selectedIndex !== index) {
    return null;
  }
  return <>{children}</>;
};

const ContractTabs: React.FC = () => {
  const classes = useStyles();
  const [contractDetailTabIndex, setContractDetailTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setContractDetailTabValue(newValue);
  };

  return (
    <Box className={classes.tabsContainer}>
      <Box>
        <Tabs
          value={contractDetailTabIndex}
          TabIndicatorProps={{ className: classes.tabIndicator }}
          onChange={handleChange}
        >
          <Tab label="Details" className={classes.tabLabel} />
          <Tab label="Payment schedule" className={classes.tabLabel} />
          <Tab label="Payments" className={classes.tabLabel} />
          <Tab label="Usage" className={classes.tabLabel} />
        </Tabs>
      </Box>

      <PanelSelector selectedIndex={contractDetailTabIndex} index={0}>
        <DetailsTab />
      </PanelSelector>
      <PanelSelector selectedIndex={contractDetailTabIndex} index={1}>
        <PaymentScheduleTable />
      </PanelSelector>
      <PanelSelector selectedIndex={contractDetailTabIndex} index={2}>
        Payments tab is under construction
      </PanelSelector>
      <PanelSelector selectedIndex={contractDetailTabIndex} index={3}>
        Usage tab is under construction
      </PanelSelector>
    </Box>
  );
};

export default ContractTabs;
