import React, { useState } from 'react';
import { Input, makeStyles } from 'vendor/material';
import { useDebounce } from 'react-use';
import withTooltip from './withTooltip';

const useStyles = makeStyles((theme: any) => ({
  root: {
    background: '#ddd',
    width: theme.typography.pxToRem(180),
  },
  input: {
    color: '#000',
    padding: theme.spacing(1, 1, 1, 2),
  },
}));

const DefaultComponent: React.FC = (props: any) => {
  const classes = useStyles();
  return <Input disableUnderline classes={classes} {...props} />;
};

interface SearchInputProps {
  onClick?: any;
  onChange: any;
  debounceInterval?: number;
  inputProps: any;
  tooltipProps?: any;
  component?: any;
  disabled?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  debounceInterval = 200,
  inputProps,
  tooltipProps = { disabled: true },
  component: Component = DefaultComponent,
}) => {
  const [searchValue, setSearchValue] = useState('');

  function handleSearchValueChange(event: any): void {
    setSearchValue(event.target.value);
  }

  // Debounce onChange to reduce render lag between keystrokes,
  useDebounce(() => onChange(searchValue), debounceInterval, [searchValue]);

  return withTooltip(
    <Component
      id="search-field"
      label="Search"
      placeholder="Search"
      value={searchValue}
      onChange={handleSearchValueChange}
      {...inputProps}
    />,
    tooltipProps,
  );
};

export default SearchInput;
