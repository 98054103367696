import React from 'react';
import { useSalesforceCoSellWidgetDetailTable } from './SalesforceCoSellWidgetDetailTable.styles';
import { Grid } from 'vendor/material';
import { SalesforceCoSellWidgetDetailTableItem } from '../SalesforceCoSellWidgetDetailTableItem/SalesforceCoSellWidgetDetailTableItem';
import { AwsCoSellStageTag } from '../../AwsCoSellStageTag';
import { AwsCoSellStatusTag } from '../../AwsCoSellStatusTag';
import { CoSellSyncStatusTag } from '../../CoSellSyncStatusTag';
import { CoSellSyncStatus } from '../../CoSellSyncStatusTag/CoSellSyncStatusTag';
import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { toDateTime } from 'utils/dates';
import { CurrencyCode, formatValueWithCurrencyPrefix } from 'utils/currency';
import { AwsCoSellSyncStatus } from '../../AwsCoSellSyncStatus/AwsCoSellSyncStatus';
import { DisplayCloudType } from 'pages/CoSell/types/enums';
import { TextWithCloudIconPrefix } from '../../TextWithCloudIconPrefix/TextWithCloudIconPrefix';

interface SalesforceCoSellWidgetDetailTableProps {
  opportunity: AceOpportunityResponse;
}

export const SalesforceCoSellWidgetDetailTable = (
  props: SalesforceCoSellWidgetDetailTableProps,
) => {
  const classes = useSalesforceCoSellWidgetDetailTable();

  // TODO: derive this from the opportunity object
  const cloudType = DisplayCloudType.AWS;

  return (
    <>
      <AwsCoSellSyncStatus
        // WIP: placeholder date - need to replace with actual last sync date
        lastSyncDate={props.opportunity.lastModifiedDate}
        opportunityId={props.opportunity.crmId}
      />
      <Grid container direction="row" className={classes.tableRow} spacing={1}>
        <SalesforceCoSellWidgetDetailTableItem title="Co-Sell Name">
          <TextWithCloudIconPrefix icon={{ provider: cloudType }}>
            {props.opportunity.project.title}
          </TextWithCloudIconPrefix>
        </SalesforceCoSellWidgetDetailTableItem>
        <SalesforceCoSellWidgetDetailTableItem title="Source Partner">
          {props.opportunity?.origin}
        </SalesforceCoSellWidgetDetailTableItem>
      </Grid>
      <Grid container direction="row" className={classes.tableRow}>
        <SalesforceCoSellWidgetDetailTableItem title="Co-Sell Status">
          <AwsCoSellStatusTag
            status={props.opportunity.lifeCycle?.reviewStatus}
          />
        </SalesforceCoSellWidgetDetailTableItem>
        <SalesforceCoSellWidgetDetailTableItem title="Co-Sell Stage">
          <AwsCoSellStageTag stage={props.opportunity.lifeCycle?.stage} />
        </SalesforceCoSellWidgetDetailTableItem>
      </Grid>
      <Grid container direction="row" className={classes.tableRow}>
        <SalesforceCoSellWidgetDetailTableItem title="Sync Status">
          <CoSellSyncStatusTag status={CoSellSyncStatus.SYNCED} />
        </SalesforceCoSellWidgetDetailTableItem>

        {props.opportunity.project?.expectedCustomerSpend.map(
          ({ amount, currencyCode }, i) => (
            <SalesforceCoSellWidgetDetailTableItem
              key={`${amount}-${currencyCode}-${i}`}
              title={`Estimated AWS MRR in ${currencyCode}`}
            >
              {formatValueWithCurrencyPrefix(
                amount,
                currencyCode as CurrencyCode,
              )}
            </SalesforceCoSellWidgetDetailTableItem>
          ),
        )}
      </Grid>
      <hr />
      <Grid container direction="row" className={classes.tableRow}>
        <SalesforceCoSellWidgetDetailTableItem title="Date Created">
          {toDateTime(props.opportunity.createdDate).toFormat('MM/dd/yy')}
        </SalesforceCoSellWidgetDetailTableItem>
        <SalesforceCoSellWidgetDetailTableItem title="Target Close Date">
          {toDateTime(props.opportunity.lifeCycle?.targetCloseDate).toFormat(
            'MM/dd/yy',
          )}
        </SalesforceCoSellWidgetDetailTableItem>
      </Grid>
      <Grid container direction="row" className={classes.tableRow}>
        <SalesforceCoSellWidgetDetailTableItem title="Last Updated">
          {toDateTime(props.opportunity.lastModifiedDate).toFormat('MM/dd/yy')}
        </SalesforceCoSellWidgetDetailTableItem>
      </Grid>
      <hr />
      <Grid container direction="row" className={classes.tableRow}>
        <SalesforceCoSellWidgetDetailTableItem title="Next Steps" width={12}>
          {props.opportunity.lifeCycle?.nextSteps ?? '-'}
        </SalesforceCoSellWidgetDetailTableItem>
      </Grid>
    </>
  );
};
