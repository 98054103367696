import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames';
import { Vendor } from 'generated/graphql';
import { EnvironmentTag } from './EnvironmentTag';
import { VendorEnvironmentList } from './VendorEnvironmentList';
import {
  Avatar,
  IconButton,
  Typography,
  Skeleton,
  Menu,
  Tooltip,
} from 'vendor/material';
import NavTooltip from '../NavTooltip';

// ASSETS
import useStyles from './VendorEnvironment.styles';

// HELPERS
import { AppEnvironment, getEnvironmentAcronyms } from '../utils';
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation';

const CTA_TEXT = 'Switch environments';

const IconBlock = ({
  loading,
  activeEnvironment,
  drawerOpen,
  swapSucceeded,
}: {
  activeEnvironment?: AppEnvironment;
  drawerOpen: boolean;
  loading: boolean;
  swapSucceeded: boolean;
}) => {
  const classes = useStyles();
  const displayEnvironmentName = activeEnvironment?.name;
  const environmentAcronym = getEnvironmentAcronyms(displayEnvironmentName);

  // STYLES
  const cssIconBlock = classnames(classes.iconBlock, classes.lightBlock, {
    [classes.twoLetterAcronym]: environmentAcronym?.length === 2,
  });

  if (loading) {
    return (
      <Skeleton variant="rect" className={classes.skeleton}>
        <Avatar className={classes.avatar} />
      </Skeleton>
    );
  }

  if (swapSucceeded) {
    return (
      <IconButton aria-haspopup="true" color="inherit" disabled>
        <div className={classnames(classes.loadingAnimation)}>
          <LoadingAnimation />
        </div>
      </IconButton>
    );
  }

  return (
    <IconButton aria-haspopup="true" color="inherit" disabled>
      {drawerOpen ? (
        <div className={cssIconBlock}>{environmentAcronym}</div>
      ) : (
        <EnvironmentTag
          environment={activeEnvironment?.type}
          isShortLabel={true}
        />
      )}
    </IconButton>
  );
};

const VendorEnvironmentContainer = ({
  activeEnvironment,
  toggleMenu,
  drawerOpen,
  loading,
  swapSucceeded,
}: {
  activeEnvironment?: AppEnvironment;
  toggleMenu: (e: React.MouseEvent<HTMLElement>) => void;
  drawerOpen: boolean;
  loading: boolean;
  swapSucceeded: boolean;
}) => {
  const displayEnvironmentName = activeEnvironment?.name;
  const classes = useStyles();

  // STYLES
  const cssProfile = classnames(classes.vendorProfile, {
    [classes.vendorProfileCollapsed]: !drawerOpen,
  });

  return (
    <div className={cssProfile} onClick={toggleMenu} role="presentation">
      <IconBlock
        loading={loading}
        activeEnvironment={activeEnvironment}
        drawerOpen={drawerOpen}
        swapSucceeded={swapSucceeded}
      />
      {drawerOpen && (
        <div style={{ flexGrow: 1 }}>
          <Tooltip placement="top" title={displayEnvironmentName}>
            <Typography className={classes.vendorEnvironmentName}>
              {displayEnvironmentName}
            </Typography>
          </Tooltip>
          <div className={classes.tagContainer}>
            <EnvironmentTag
              environment={activeEnvironment?.type}
              isShortLabel={false}
            />
          </div>
          <Typography className={classnames(classes.CTA)}>
            {CTA_TEXT}
          </Typography>
        </div>
      )}
    </div>
  );
};

const VendorEnvironment = ({
  drawerOpen,
  activeVendor,
  activeEnvironment,
  vendorEnvironmentsList,
  loading = false,
  loadingVendors = false,
}: {
  drawerOpen: boolean;
  activeVendor?: Pick<
    Vendor,
    'id' | 'name' | 'vendor_type' | 'production_vendor_id'
  >;
  activeEnvironment?: AppEnvironment;
  vendorEnvironmentsList?: Array<Vendor>;
  loading: boolean;
  loadingVendors: boolean;
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [swapSucceeded, setSwapSucceeded] = useState(false);
  const classes = useStyles();
  const [isOpen, setMenuOpen] = useState(false);
  const canSwitch =
    !loading && !loadingVendors && vendorEnvironmentsList?.length > 1;

  // EVENTS
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  function toggleMenu(e): void {
    handleClick(e);
    setMenuOpen(!isOpen);
  }

  const closeMenu = (): void => {
    setMenuOpen(false);
    handleClose();
  };

  if (!canSwitch) return null;

  return (
    <div>
      <NavTooltip text={CTA_TEXT || ''} disabled={loading || drawerOpen}>
        <>
          <VendorEnvironmentContainer
            activeEnvironment={activeEnvironment}
            toggleMenu={toggleMenu}
            drawerOpen={drawerOpen}
            loading={loading}
            swapSucceeded={swapSucceeded}
          />
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            marginThreshold={12}
            open={isOpen}
            keepMounted
            onClose={() => closeMenu()}
            classes={{ paper: classes.menu }}
            disableAutoFocus
          >
            <VendorEnvironmentList
              activeVendor={activeVendor}
              vendorEnvironmentsList={vendorEnvironmentsList}
              closeMenu={() => setMenuOpen(false)}
              setSwapSucceeded={setSwapSucceeded}
              swapSucceeded={swapSucceeded}
            />
          </Menu>
        </>
      </NavTooltip>
    </div>
  );
};

export default observer(VendorEnvironment);
