import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
