import { makeStyles } from 'vendor/material';

export const useSalesforceCoSellWidgetButtonGroupStyles = makeStyles(
  (theme) => ({
    buttonGroupContainer: {
      paddingTop: '1rem',
    },
    bottomButtonContainer: {
      marginTop: '12px',
      '&>:nth-child(1)': {
        paddingRight: '6px',
      },
      '&>:nth-child(2)': {
        paddingLeft: '6px',
      },
    },
    dashboardButtonElement: {
      width: '100%',
      backgroundColor: theme.palette.BLUE400,
      '&:hover': {
        backgroundColor: theme.palette.BLUE500,
      },
    },
    buttonElement: {
      width: '100%',
    },
  }),
);
