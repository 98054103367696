export enum AceNeedFromCloudEnumValues {
  COSELL_ARCHITECTURAL_VALIDATION = 'Co-Sell - Architectural Validation',
  COSELL_BUSINESS_PRESENTATION = 'Co-Sell - Business Presentation',
  COSELL_COMPETITIVE_INFORMATION = 'Co-Sell - Competitive Information',
  COSELL_DEAL_SUPPORT = 'Co-Sell - Deal Support',
  COSELL_PRICING_ASSISTANCE = 'Co-Sell - Pricing Assistance',
  COSELL_SUPPORT_FOR_PUBLIC_TENDER_RFX = 'Co-Sell - Support for Public Tender / RFx',
  COSELL_TECHNICAL_CONSULTATION = 'Co-Sell - Technical Consultation',
  COSELL_TOTAL_COST_OF_OWNERSHIP_EVALUATION = 'Co-Sell - Total Cost of Ownership Evaluation',
}
export enum AceNeedFromCloudEnumLabels {
  COSELL_ARCHITECTURAL_VALIDATION = 'Architectural Validation',
  COSELL_BUSINESS_PRESENTATION = 'Business Presentation',
  COSELL_COMPETITIVE_INFORMATION = 'Competitive Information',
  COSELL_PRICING_ASSISTANCE = 'Pricing Assistance',
  COSELL_TECHNICAL_CONSULTATION = 'Technical Consultation',
  COSELL_TOTAL_COST_OF_OWNERSHIP_EVALUATION = 'Total Cost of Ownership Evaluation',
  COSELL_DEAL_SUPPORT = 'Deal Support',
  COSELL_SUPPORT_FOR_PUBLIC_TENDER_RFX = 'Support for Public Tender / RFx',
}
export enum AceNotNeededFromCloud {
  DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP = 'Do Not Need Support from AWS Sales Rep',
}

export const AceNeedFromCloudValuesToLabels = {
  [AceNeedFromCloudEnumValues.COSELL_ARCHITECTURAL_VALIDATION]:
    AceNeedFromCloudEnumLabels.COSELL_ARCHITECTURAL_VALIDATION,
  [AceNeedFromCloudEnumValues.COSELL_BUSINESS_PRESENTATION]:
    AceNeedFromCloudEnumLabels.COSELL_BUSINESS_PRESENTATION,
  [AceNeedFromCloudEnumValues.COSELL_COMPETITIVE_INFORMATION]:
    AceNeedFromCloudEnumLabels.COSELL_COMPETITIVE_INFORMATION,
  [AceNeedFromCloudEnumValues.COSELL_DEAL_SUPPORT]:
    AceNeedFromCloudEnumLabels.COSELL_DEAL_SUPPORT,
  [AceNeedFromCloudEnumValues.COSELL_PRICING_ASSISTANCE]:
    AceNeedFromCloudEnumLabels.COSELL_PRICING_ASSISTANCE,
  [AceNeedFromCloudEnumValues.COSELL_SUPPORT_FOR_PUBLIC_TENDER_RFX]:
    AceNeedFromCloudEnumLabels.COSELL_SUPPORT_FOR_PUBLIC_TENDER_RFX,
  [AceNeedFromCloudEnumValues.COSELL_TECHNICAL_CONSULTATION]:
    AceNeedFromCloudEnumLabels.COSELL_TECHNICAL_CONSULTATION,
  [AceNeedFromCloudEnumValues.COSELL_TOTAL_COST_OF_OWNERSHIP_EVALUATION]:
    AceNeedFromCloudEnumLabels.COSELL_TOTAL_COST_OF_OWNERSHIP_EVALUATION,
  [AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP]:
    AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP,
};
