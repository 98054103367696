import { Action } from 'stores/typings';

import { withConvenienceBooleans } from 'stores/utils';

import { APIReducerState } from './typings';

const apiReducer = (
  state: APIReducerState,
  action: Action,
): APIReducerState => {
  let updatedState = state;

  switch (action.type) {
    case 'SYNC':
      updatedState = {
        ...updatedState,
        status: 'syncing',
      };
      break;
    case 'SET_CONTENT':
      updatedState = {
        ...updatedState,
        content: action.payload.content,
        status: 'hasValue',
      };
      break;
    case 'SET_ERROR':
      updatedState = {
        ...updatedState,
        error: action.payload.error,
        status: 'hasError',
      };
      break;
    case 'UPDATE':
      updatedState = {
        ...updatedState,
        status: 'hasValue',
      };
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }

  return withConvenienceBooleans(updatedState) as APIReducerState;
};

export default apiReducer;
