const errorScrollingOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'center',
};

const textSelector = "[class*='errorContainer'], [class*='ErrorContainer']";
const selectSelector = "[class*='inputWrapperInvalid']";
const errorLabelSelector = "[class*='error']";

export const scrollToFirstError = () => {
  let hasScrolled = false;
  const textErrorElement = document.querySelector(textSelector);
  const selectErrorElement = document.querySelector(selectSelector);
  const errorLabelElement = document.querySelector(errorLabelSelector);

  if (textErrorElement) {
    textErrorElement.scrollIntoView(errorScrollingOptions);
    hasScrolled = true;
  } else if (selectErrorElement) {
    selectErrorElement.scrollIntoView(errorScrollingOptions);
    hasScrolled = true;
  } else if (errorLabelElement) {
    errorLabelElement.scrollIntoView(errorScrollingOptions);
    hasScrolled = true;
  }
  return hasScrolled;
};
