import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { VendorEnvironmentEnum } from 'utils/constants';

import { User } from 'generated/graphql';

export const Intercom: React.FC<{ currentUser?: User }> = ({ currentUser }) => {
  const { update, trackEvent } = useIntercom();
  const location = useLocation();
  const isTestEnvironment =
    currentUser?.vendor?.vendor_type === VendorEnvironmentEnum.SANDBOX;

  useEffect(() => {
    update();
  }, [location, update]);

  useEffect(() => {
    if (currentUser) {
      const details = {
        name: currentUser.name,
        email: currentUser.email,
        userId: currentUser.id,
        company: {
          companyId: currentUser.vendor.id,
          name: currentUser.vendor.name,
          customAttributes: {
            vendor_type: currentUser?.vendor?.vendor_type,
          },
        },
        customAttributes: {
          role: currentUser.role,
        },
        userHash: currentUser.intercomUserHash,
      };

      update(details);

      if (isTestEnvironment) {
        trackEvent('current-user-in-test-environment', {
          vendor_id: currentUser?.vendor?.id,
          vendor_type: currentUser?.vendor?.vendor_type,
        });
      }
    }
  }, [update, currentUser, isTestEnvironment, trackEvent]);

  return null;
};
