import { legacyColors } from 'Theme';
import { fontWeightMedium } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  activityContainer: {
    display: 'flex',
    marginBottom: 24,
  },
  logoContainer: {
    width: '40px',
    height: '40px',
    borderRadius: 9999,
    position: 'relative',
    overflow: 'hidden',
    '& > img, & > svg': {
      width: 40,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
    },
    marginRight: 16,
  },
  title: {
    fontSize: '.875rem',
    display: 'block',
    fontWeight: fontWeightMedium,
    color: legacyColors.NEUTRAL500,
  },
  subTitle: {
    display: 'block',
    color: legacyColors.NEUTRAL200,
    fontSize: '.875rem',
    marginBottom: '4px',
  },
  dateTimestamp: {
    color: legacyColors.NEUTRAL200,
    fontSize: '.75rem',
  },
}));

export default useStyles;
