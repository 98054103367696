import React from 'react';
import { Tooltip } from 'vendor/material';
import useStyles from './NavTooltip.styles';

interface NavTooltipProps {
  text: string;
  disabled: boolean;
  children: React.ReactElement;
}

const NavTooltip = ({ text, disabled, children }: NavTooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={text}
      placement="right"
      classes={{ tooltip: classes.tooltip }}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
    >
      <span
      // we need this span incase children cannot accept a ref
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default NavTooltip;
