// HMN 5/23/23: These fields are used by the CoSell table as identifiers for
// column sorting
export enum ColumnField {
  CLOUD_ID = 'external_opportunity_id',
  TARGET_CLOSE_DATE = 'target_close_date',
  COMPANY_NAME = 'company_name',
  CLOUD_CREATED_DATE = 'external_created_at',
  CRM_COSELL_ID = 'external_crm_cosell_id',
  LAST_MODIFIED = 'updated_at',
  ORIGINATION = 'origination',
  STATUS = 'status',
  VALUE = 'deal_value',
}
