import { makeStyles } from 'vendor/material';

export const useStyle = makeStyles((theme) => ({
  page: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '50%',
    margin: theme.spacing(0, 0, 4),
  },
  subheader: {
    padding: theme.spacing(8, 0, 0),
    textAlign: 'center',
  },
}));
