// mspc related enum
export enum MsftNeedFromCloudEnum {
  GENERAL_OR_OTHER = 'GeneralOrOther',
  WORKLOAD = 'WorkloadSpecificValueProposition',
  CUSTOMER_TECHNICAL_ARCHITECTURE = 'CustomerTechnicalArchitecture',
  PROOF_OF_CONCEPT = 'ProofOfConceptOrDemo',
  QUOTES = 'QuotesOrLicensing',
  POST_SALES_CUSTOMER_SUCCESS = 'PostSalesCustomerSuccess',
  NO_HELP_REQUIRED = 'Unknown',
}
