import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.BLUE300,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.BLUE500,
    },
    '&:hover': {
      color: theme.palette.BLUE400,
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
