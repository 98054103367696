import {
  ActivitySlug,
  isOfferExpired,
  lastActivityAction,
  PrivateOfferStep,
  PrivateOfferStepText,
  ACTIVITY_SLUG_TO_OFFER_STEP_INFO,
} from 'pages/PrivateOffers/utils';
import { Activity } from 'stores/privateOffers/typings';

interface PrivateOfferCollectionItemStatus {
  step: PrivateOfferStep;
  text: PrivateOfferStepText;
  stepBackgroundColor: string;
  offerContainerBorderTopColor: string;
  statusTextColor: string;
  statusColorName: string;
  statusBackgroundColor: string;
}

const getOfferCollectionItemStatus = (
  activities: Activity[] = [],
  offerExpirationAt?: string,
  hasOfferRef: boolean = false,
): PrivateOfferCollectionItemStatus => {
  const { step, slug } = lastActivityAction(activities, hasOfferRef);
  const notCancelled =
    slug !== ActivitySlug.VendorCancelledMarketplaceOfferSuccess;

  if (hasOfferRef && notCancelled && step < PrivateOfferStep.Accepted) {
    const {
      isExpiring,
      expirationText,
      expirationColorText,
      expirationColorName,
      expirationColorBorder,
      expirationColorBG,
    } = isOfferExpired(offerExpirationAt);

    if (isExpiring) {
      return {
        text: expirationText as string,
        statusTextColor: expirationColorText,
        statusColorName: expirationColorName,
        stepBackgroundColor: expirationColorBorder,
        statusBackgroundColor: expirationColorBG,
        offerContainerBorderTopColor: expirationColorBorder,
        step,
      } as PrivateOfferCollectionItemStatus;
    }
  }

  return {
    ...ACTIVITY_SLUG_TO_OFFER_STEP_INFO[slug],
    step,
  } as PrivateOfferCollectionItemStatus;
};

export default getOfferCollectionItemStatus;
