import { makeStyles } from 'vendor/material';

const useStyles = ({ width }: any): any =>
  makeStyles((theme) => ({
    button: {
      textTransform: 'none',
    },
    customWidth: { width },
  }))();

export default useStyles;
