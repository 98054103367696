import { useState } from 'react';
import type { PointerEvent } from 'react';
import { Tooltip } from '@tackle-io/platform-ui';

/**
 *   Wrapper for the Tooltip, to conditionally render the mui Tooltip component only when the children are hovered.
 *   UseCase: You have need to use a tooltip in a list item or row component.
 *
 *   Temporary solution!
 *   This is a workaround to avoid rendering the Tooltip component when it's not needed, especially in lists, as it's a heavy component.
 *   Newer version of Tooltip components (from various component libraries) have this issue handled.
 * */
export const OptimizedTooltip: typeof Tooltip = (props) => {
  const [isOver, setIsOver] = useState(false);
  const handlePointerEvent = (event: PointerEvent<HTMLSpanElement>) => {
    setIsOver(event.type === 'pointerenter');
  };
  return (
    <span
      onPointerEnter={handlePointerEvent}
      onPointerLeave={handlePointerEvent}
      style={{ display: 'inline-flex' }}
    >
      {!isOver ? props.children : <Tooltip {...props} />}
    </span>
  );
};
