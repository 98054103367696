export enum AceOpportunityCampaignNameEnum {
  APN_IMMERSION_DAYS = 'APN Immersion Days',
  APN_SOLUTIONS_SPACE = 'APN Solution Space',
  ATO_AUTHORITY_TO_OPERATE = 'ATO (Authority to Operate)',
  AWS_MARKETPLACE_CAMPIAGN = 'AWS Marketplace Campaign',
  ISV_IMMERSION_DAY = 'ISV Immersion Day SFID Program',
  ISV_WORKLOAD_MIGRATION = 'ISV Workload Migration',
  MIGRATION_ACCELERATION_PROGRAM = 'Migration Acceleration Program',
  PARTNER_LAUNCH_INITIATIVE = 'Partner Launch Initiative',
  PARTNER_OPP_ACCELERATION_FUNDED = 'Partner Opportunity Acceleration Funded',
  THE_NEXT_SMART = 'The Next Smart',
  VMWARE_CLOUD_ON_AWS = 'VMware Cloud on AWS',
  WELL_ARCHITECTED = 'Well-Architected',
  WINDOWS = 'Windows',
  WORKSPACES_APP_STREAM_ACCELERATOR_PROGRAM = 'Workspaces/AppStream Accelerator Program',
  WWPS_NDPP = 'WWPS NDPP',
  P3 = 'P3',
}
