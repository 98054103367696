import { makeStyles } from 'vendor/material';

const useStyles = makeStyles(
  (theme): Record<string, any> => ({
    validationRule: {
      marginLeft: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
    },
  }),
);

export default useStyles;
