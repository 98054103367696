import React, { useMemo } from 'react';
import { Box } from 'vendor/material';
import { ArrowUp, ArrowDown } from 'mdi-material-ui';
import useStyles from './SortHeader.styles';
import classNames from 'classnames';
import {
  OpportunityColumnKey,
  OpportunitySortState,
} from '../../../../helpers/urlParamsHelper';

interface SortHeaderProps {
  sortState: OpportunitySortState;
  columnKey: OpportunityColumnKey;
}

const SortHeader: React.FC<SortHeaderProps> = ({
  children,
  columnKey,
  sortState,
}) => {
  const { orderAscending } = sortState || {};

  const classes = useStyles();

  const sortArrow = useMemo(() => {
    const ArrowComponent = orderAscending ? ArrowUp : ArrowDown;

    return <ArrowComponent fontSize="inherit" className={classes.arrow} />;
  }, [orderAscending, classes.arrow]);

  return (
    <Box className={classNames(classes.sortHeaderContainer)}>
      {children}
      {columnKey === sortState?.sortKey && sortArrow}
    </Box>
  );
};

export default SortHeader;
