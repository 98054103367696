import { makeStyles } from 'vendor/material';

const useTokenStyles = makeStyles((theme) => ({
  inputBase: {
    width: '100%',
  },
  inputGroup: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F7F7F9',
    border: '1px solid #E5E6E7',
    fontSize: 14,
    padding: '0 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
    '& > :first-child': {
      width: '100%',
    },
  },
  inputGroupAppend: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 12,
  },
  iconButton: {
    padding: 0,
  },
}));

export default useTokenStyles;
