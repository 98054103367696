import {
  AzurePricingBillingTypeKeys,
  AzurePricingBillingTypeText,
  AzurePricingType,
} from 'stores/privateOffers/typings';

export enum ActivitySlug {
  VendorDraftedPrivateOffer = 'vendor-drafted-offer',
  VendorCreatedPrivateOfferInSalesforce = 'vendor-created-offer-in-salesforce',
  VendorCreatedPrivateOffer = 'vendor-created-offer',
  VendorClonedPrivateOffer = 'vendor-cloned-offer',
  VendorSentPurchaseInstructions = 'vendor-sent-purchase-instructions',
  VendorReSentPurchaseInstructions = 'vendor-resent-purchase-instructions',
  BuyerOpenedPurchaseInstructions = 'buyer-opened-purchase-instructions',
  BuyerViewedPrivateOffer = 'buyer-viewed-private-offer',
  BuyerAcceptedPrivateOffer = 'buyer-accepted-private-offer',
  VendorCreatedMarketplaceOfferPending = 'vendor-created-marketplace-offer-pending',
  VendorCreatedMarketplaceOfferSuccess = 'vendor-created-marketplace-offer-success',
  VendorCreatedMarketplaceOfferFailed = 'vendor-created-marketplace-offer-failed',
  VendorChangedMarketplaceOfferExpirationPending = 'marketplace-offer-change-expiration-pending',
  VendorChangedMarketplaceOfferExpirationSuccess = 'marketplace-offer-change-expiration-success',
  VendorChangedMarketplaceOfferExpirationFailed = 'marketplace-offer-change-expiration-failed',
  VendorCancelledMarketplaceOfferPending = 'marketplace-offer-cancel-offer-pending',
  VendorCancelledMarketplaceOfferSuccess = 'marketplace-offer-cancel-offer-success',
  VendorCancelledMarketplaceOfferFailed = 'marketplace-offer-cancel-offer-failed',
}

export const ACTIVITY_SLUG_TO_ACTION_DESCRIPTION = {
  [ActivitySlug.VendorCreatedPrivateOfferInSalesforce]:
    'Created offer in Salesforce',
  [ActivitySlug.VendorCreatedPrivateOffer]: 'Created offer',
  [ActivitySlug.VendorClonedPrivateOffer]: 'Cloned offer',
  [ActivitySlug.VendorSentPurchaseInstructions]: 'Sent purchase instructions',
  [ActivitySlug.VendorReSentPurchaseInstructions]:
    'Resent purchase instructions',
  [ActivitySlug.BuyerOpenedPurchaseInstructions]: 'Opened instructions',
  [ActivitySlug.BuyerViewedPrivateOffer]: 'Viewed offer',
  [ActivitySlug.BuyerAcceptedPrivateOffer]: 'Accepted offer',
  [ActivitySlug.VendorCreatedMarketplaceOfferFailed]:
    'Marketplace offer failed to create',
  [ActivitySlug.VendorCreatedMarketplaceOfferPending]:
    'Marketplace offer pending',
  [ActivitySlug.VendorCreatedMarketplaceOfferSuccess]:
    'Offer created in marketplace',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationPending]:
    'Offer expiration update pending',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationSuccess]:
    'Offer expiration updated',
  [ActivitySlug.VendorChangedMarketplaceOfferExpirationFailed]:
    'Offer expiration failed to update',
  [ActivitySlug.VendorCancelledMarketplaceOfferPending]:
    'Offer cancellation pending',
  [ActivitySlug.VendorCancelledMarketplaceOfferSuccess]: 'Offer cancelled',
  [ActivitySlug.VendorCancelledMarketplaceOfferFailed]:
    'Offer cancellation failed',
} as Record<ActivitySlug, string>;

export enum PrivateOfferStep {
  Drafted = 1,
  Created = 2,
  Sent = 3,
  Opened = 4,
  Viewed = 5,
  Accepted = 6,
}

export enum PrivateOfferStepText {
  Drafted = 'Draft',
  SalesforceDrafted = 'CRM draft',
  Created = 'Offer created',
  Sent = 'Offer sent',
  OpenedEmail = 'Email opened',
  Viewed = 'Offer viewed',
  Accepted = 'Offer accepted',
  Cancelled = 'Cancelled',
}

export interface OfferStepInfo {
  step: PrivateOfferStep;
  text: PrivateOfferStepText;
  stepBackgroundColor: string;
  offerContainerBorderTopColor: string;
  statusTextColor: string;
  statusColorName: string;
  statusBackgroundColor: string;
}

export const ACTIVITY_SLUG_TO_OFFER_STEP_INFO = {
  [ActivitySlug.VendorCreatedPrivateOfferInSalesforce]: {
    step: PrivateOfferStep.Drafted,
    text: PrivateOfferStepText.SalesforceDrafted,
    stepBackgroundColor: '#0052cc',
    offerContainerBorderTopColor: '#0052cc',
    statusTextColor: '#0052cc',
    statusColorName: 'blue',
    statusBackgroundColor: '#deebff',
  },
  [ActivitySlug.VendorDraftedPrivateOffer]: {
    step: PrivateOfferStep.Drafted,
    text: PrivateOfferStepText.Drafted,
    stepBackgroundColor: '#0052cc',
    offerContainerBorderTopColor: '#0052cc',
    statusTextColor: '#0052cc',
    statusColorName: 'blue',
    statusBackgroundColor: '#deebff',
  },
  [ActivitySlug.VendorCreatedPrivateOffer]: {
    step: PrivateOfferStep.Created,
    text: PrivateOfferStepText.Created,
    stepBackgroundColor: '#0052cc',
    offerContainerBorderTopColor: '#0052cc',
    statusTextColor: '#0052cc',
    statusColorName: 'blue',
    statusBackgroundColor: '#deebff',
  },
  [ActivitySlug.VendorSentPurchaseInstructions]: {
    step: PrivateOfferStep.Sent,
    text: PrivateOfferStepText.Sent,
    stepBackgroundColor: '#0052cc',
    offerContainerBorderTopColor: '#0052cc',
    statusTextColor: '#0052cc',
    statusColorName: 'blue',
    statusBackgroundColor: '#deebff',
  },
  [ActivitySlug.BuyerOpenedPurchaseInstructions]: {
    step: PrivateOfferStep.Opened,
    text: PrivateOfferStepText.OpenedEmail,
    stepBackgroundColor: '#5243aa',
    offerContainerBorderTopColor: '#5243aa',
    statusTextColor: '#5243aa',
    statusColorName: 'purple',
    statusBackgroundColor: '#eae6ff',
  },
  [ActivitySlug.BuyerViewedPrivateOffer]: {
    step: PrivateOfferStep.Viewed,
    text: PrivateOfferStepText.Viewed,
    stepBackgroundColor: '#5243aa',
    offerContainerBorderTopColor: '#5243aa',
    statusTextColor: '#5243aa',
    statusColorName: 'purple',
    statusBackgroundColor: '#eae6ff',
  },
  [ActivitySlug.BuyerAcceptedPrivateOffer]: {
    step: PrivateOfferStep.Accepted,
    text: PrivateOfferStepText.Accepted,
    stepBackgroundColor: '#00875a',
    offerContainerBorderTopColor: '#00875a',
    statusTextColor: '#00875a',
    statusColorName: 'green',
    statusBackgroundColor: '#e2ffee',
  },
  // Cancelled is a "floating" status
  // The step will be whatever the active step was before cancellation
  [ActivitySlug.VendorCancelledMarketplaceOfferSuccess]: {
    // step: ??
    text: PrivateOfferStepText.Cancelled,
    stepBackgroundColor: '#DE350B',
    offerContainerBorderTopColor: '#DE350B',
    statusTextColor: '#DE350B',
    statusColorName: 'red',
    statusBackgroundColor: '#FFEBE5',
  },
} as Record<ActivitySlug, OfferStepInfo>;

// Current version of typescript has reverse mapping so an enum has keys for both forward/backward lookups
// e.g. PrivateOfferStep.Accepted => 6 and PrivateOfferStep[6] => "Accepted"
// This causes Object.keys to return double the expected count
// The number filter will count just the expected states (the forward lookups)
export const STATUSES_COUNT =
  Object.keys(PrivateOfferStep).filter(Number).length;

export const PRIVATE_OFFER_AUTO_CREATE_ERROR_CODE =
  'invalid_private_offer_auto_create_payload';

export const PRIVATE_OFFER_DEFAULT_ERROR_MESSAGE =
  "Whoops, we're sorry about that. If you continue to run into this issue, please contact support.";

export const CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIX = {
  AWS: 'AWS',
  REDHAT: 'RHM',
  AZURE: 'Azure',
};

export const CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIXES = [
  CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIX.AWS,
  CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIX.REDHAT,
  CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIX.AZURE,
];

export const CLOUD_METADATA_FIELD_DISPLAY_KEYS = {
  AWS_ACCOUNT_NUMBER: `${CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIX.AWS} Account Number`,
  RHM_ACCOUNT_ID: `${CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIX.REDHAT} Account ID`,
  AZURE_BILLING_ACCOUNT_ID: `${CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIX.AZURE} Billing Account ID`,
  AZURE_TENANT_ID: `${CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIX.AZURE} Tenant ID`,
};

// Add metadata keys here that need to be used as a field value to prevent errors in upstream
export const CLOUD_METADATA_FIELD_DISPLAY_VALUES = {
  AZURE_TENANT_ID: `${CLOUD_METADATA_FIELD_DISPLAY_KEY_PREFIX.AZURE} Tenant ID`,
};

export const AZURE_PRICING_OPTION_KEY_MAP = {
  [AzurePricingType.Month]: AzurePricingBillingTypeKeys.OneMonth,
  1: AzurePricingBillingTypeKeys.OneYear,
  2: AzurePricingBillingTypeKeys.TwoYears,
  3: AzurePricingBillingTypeKeys.ThreeYears,
};

export const AZURE_PRICING_OPTION_TEXT_MAP = {
  [AzurePricingType.Month]: AzurePricingBillingTypeText.OneMonth,
  1: AzurePricingBillingTypeText.OneYear,
  2: AzurePricingBillingTypeText.TwoYears,
  3: AzurePricingBillingTypeText.ThreeYears,
};

export const AZURE_PRICING_OPTION_INDEX_MAP = {
  [AzurePricingBillingTypeKeys.OneMonth]: 0,
  [AzurePricingBillingTypeKeys.OneYear]: 1,
  [AzurePricingBillingTypeKeys.TwoYears]: 2,
  [AzurePricingBillingTypeKeys.ThreeYears]: 3,
};

export const AZURE_PRICING_OPTION_KEY_TEXT_MAP = {
  [AzurePricingBillingTypeKeys.OneMonth]: AzurePricingBillingTypeText.OneMonth,
  [AzurePricingBillingTypeKeys.OneYear]: AzurePricingBillingTypeText.OneYear,
  [AzurePricingBillingTypeKeys.TwoYears]: AzurePricingBillingTypeText.TwoYears,
  [AzurePricingBillingTypeKeys.ThreeYears]:
    AzurePricingBillingTypeText.ThreeYears,
};

export const AZURE_PRICING_BILLING_TERMS_MAP = {
  [AzurePricingBillingTypeKeys.OneMonth]: {
    value: 1,
    type: AzurePricingType.Month,
  },
  [AzurePricingBillingTypeKeys.OneYear]: {
    value: 1,
    type: AzurePricingType.Year,
  },
  [AzurePricingBillingTypeKeys.TwoYears]: {
    value: 2,
    type: AzurePricingType.Year,
  },
  [AzurePricingBillingTypeKeys.ThreeYears]: {
    value: 3,
    type: AzurePricingType.Year,
  },
};

export enum MarketplaceFilterKey {
  ANY = 'any',
  AWS = 'aws',
  GCP = 'gcp',
  AZURE = 'azure',
}

export enum OfferTypeFilterKey {
  ANY = 'any',
  DIRECT = 'direct',
  PARTNER_RESALE = 'partner_resale',
}

export enum StatusFilterKey {
  ANY = 'any',
  DRAFT = 'draft',
  CRM_DRAFT = 'crm-draft',
  IN_PROGRESS = 'progress',
  WAITING = 'waiting',
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  ARCHIVED = 'archived',
  ACTIVE = 'active',
}

export enum ProductListingFilterKey {
  ANY = 'any',
}

export enum ArchivedFilterOption {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  ACTIVE_OR_ARCHIVED = 'active-or-archived',
}
