import { themeObject } from 'Theme';
import CloseIcon from 'mdi-material-ui/Close';
import { ReactElement } from 'react';
import { Box, IconButton, Typography, useTheme } from 'vendor/material';

export const PAGE_BANNER_TEST_ID = 'page-banner-dismissable';

interface PageBannerDismissableProps {
  text: string;
  onClose: () => void;
  bannerColor: string;
  textColor: string;
  className?: string;
  icon?: ReactElement;
}

export default function PageBannerDismissable({
  text,
  icon,
  onClose,
  bannerColor,
  textColor,
  className,
}: PageBannerDismissableProps) {
  const theme = useTheme();

  return (
    <Box
      className={className}
      position="relative"
      width="100%"
      minHeight={theme.typography.pxToRem(54)}
      bgcolor={bannerColor}
      data-testid={PAGE_BANNER_TEST_ID}
    >
      <Box
        display="flex"
        position="relative"
        flexDirection="row"
        alignItems="center"
        width="100%"
        padding={theme.typography.pxToRem(16)}
        boxShadow={themeObject.depth.d200}
        zIndex={9}
      >
        {icon && <>{icon}</>}
        <Typography
          style={{
            marginLeft: theme.typography.pxToRem(8),
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 600,
            color: textColor,
          }}
          align="left"
          variant="body1"
        >
          {text}
        </Typography>
        <Box
          marginLeft="auto"
          justifySelf="self-end"
          marginRight={theme.typography.pxToRem(4)}
        >
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => onClose()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
