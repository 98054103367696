import React from 'react';
import { toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './useToast.animation.css';
import classNames from 'classnames';
import { Close } from 'mdi-material-ui';

// ASSETS
import useStyles from './useToast.styles';

const useToast = () => {
  const classes = useStyles();
  const { update, dismiss, isActive } = toast;

  const fade = cssTransition({
    enter: 'fadeIn',
    exit: 'fadeOut',
    duration: 0,
  });

  const toaster = ({ message = undefined, updateId = undefined, options }) => {
    const {
      type = 'default',
      closeButton = true,
      className: addClassName = '',
      ...moreOptions
    } = options;

    const cssToast = classNames(
      classes.toast,
      addClassName,
      { [classes.toastSuccess]: type === 'success' },
      { [classes.toastInfo]: type === 'info' },
      { [classes.toastDanger]: type === 'warning' },
      { [classes.toastWarning]: type === 'error' },
      { [classes.toastNeutral]: type === 'neutral' },
    );

    const styles = {
      hideProgressBar: true,
      borderRadius: 4,
      className: cssToast,
    };

    if (updateId && isActive(updateId)) {
      return update(updateId, {
        type,
        transition: fade,
        closeButton: closeButton && <Close />,
        ...styles,
        ...moreOptions,
      });
    }

    return toast(message, {
      transition: fade,
      closeButton: closeButton && <Close />,
      ...styles,
      ...moreOptions,
    });
  };

  return {
    toaster,
    dismiss,
    isActive,
  };
};

export default useToast;
