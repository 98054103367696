import React from 'react';
// COMPONENTS
import { Box, Skeleton } from 'vendor/material';

export const LoadingMetrics: React.FC = () => {
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      paddingTop={4}
    >
      <Skeleton height={300} width="45%"></Skeleton>
      <Skeleton height={300} width="45%"></Skeleton>
    </Box>
  );
};
