import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider';
import UnifiedOpportunityForm from './pages/UnifiedOpportunityForm/UnifiedOpportunityForm';
import { useMemo } from 'react';
import {
  getUnifiedOpportunityView,
  UnifiedOpportunityView,
} from './utilities/constants';
import CoSellLandingPage from './pages/CoSellLandingPage';

/**
 * WIP: Main entry point for Unified CoSell content
 *
 * TODO:
 * - consider using a scoped queryClient for ReactQueryClientProvider
 *
 */

const unifiedOpportunityViews = [
  UnifiedOpportunityView.CO_SELL_DETAILS,
  UnifiedOpportunityView.CREATE_NEW_CO_SELL_FORM,
  UnifiedOpportunityView.EDIT_CO_SELL_FORM,
];

export const UnifiedCoSellRoot = ({
  match,
}: {
  match: { params: { [key: string]: string }; url: string };
}) => {
  const opportunityId = useMemo(
    () => match?.params?.opportunityId?.split('?')[0],
    [match?.params?.opportunityId],
  );
  const view = getUnifiedOpportunityView({ opportunityId, url: match.url });
  const component = useMemo(() => {
    if (view === UnifiedOpportunityView.CO_SELL_LANDING_PAGE) {
      return <CoSellLandingPage />;
    } else if (unifiedOpportunityViews.includes(view)) {
      return <UnifiedOpportunityForm view={view} match={match} />;
    }
  }, [match, view]);
  return <UnifiedCoSellRootContainer>{component}</UnifiedCoSellRootContainer>;
};

export const UnifiedCoSellRootContainer = ({ children }) => {
  return <ReactQueryClientProvider>{children}</ReactQueryClientProvider>;
};
