import React, { useEffect } from 'react';
import { useAuth } from 'vendor/auth0';
import { Redirect } from 'react-router-dom';
import { Loader } from '@tackle-io/platform-ui';

const Login = () => {
  const [initialized, setInitialized] = React.useState(false);
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth();
  useEffect(() => {
    if (!isLoading && !isAuthenticated && !initialized) {
      loginWithRedirect();
      setInitialized(true);
    }
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    initialized,
    setInitialized,
  ]);

  if (isLoading) return null;

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <Loader />;
};

export default Login;
