import {
  camelCase,
  concat,
  drop,
  findIndex,
  flow,
  get,
  isEmpty,
  isEqual,
  isNumber,
  isUndefined,
  map,
  mapKeys,
  reject,
  replace,
  sum,
  toFinite,
  toPairs,
  toString,
  trim,
} from 'lodash/fp';

import {
  AzureAbsolutePrice,
  BillingTermValue,
  Dimension,
  MetadataPricing,
  PrivateOffer,
  RedHatPricing,
  RedHatPricingOfferTermCharge,
  Schedule,
  ScheduleInvoiceDateType,
  ScheduleInvoiceDateTypeToDisplayLabel,
  Unit,
  UsageDimension,
} from 'stores/privateOffers/typings';
import { DateTime } from 'luxon';
import { ListingTypeType } from 'utils/listingTypes';
import {
  CloudToPricingVersion,
  getPricingByCloudAndListingType,
} from 'utils/pricingTypes';
import { PricingDurationUnitOfMeasure } from 'utils/pricingDurationTypes';
import { Cloud } from 'stores/typings';
import { Cloud as CloudEnum } from 'utils/cloudTypes';

// utils
const convertBuyerKeysToFormKeys = flow(
  mapKeys((key: string): string => `buyer ${key}`),
  mapKeys(camelCase),
);

export interface FormOfferBuyerDetails {
  buyers: PrivateOffer['metadata']['buyers'];
}

export const defaultBuyerDetails = (
  po?: PrivateOffer,
): FormOfferBuyerDetails => {
  const buyers =
    po?.metadata?.buyers && po.metadata.buyers.length > 0
      ? po.metadata.buyers
      : [{ fullName: '', emailAddress: '', title: '' }];

  return { buyers };
};

// Form Helpers
export interface FormPreRegistrationDetails {
  [key: string]: any;
}

export const defaultPreRegistrationDetails = (
  po: PrivateOffer,
): FormPreRegistrationDetails => {
  const buyerDetails = po.metadata.preRegistrationDetails;

  return {
    ...convertBuyerKeysToFormKeys(buyerDetails),
    customerRef: po.customerRef,
  } as FormPreRegistrationDetails;
};

export interface FormOfferDetails {
  offerRef: PrivateOffer['offerRef'];
  offerExpiration?: Date;
  renewal?: boolean;
  enableZeroDollarPrices?: boolean;
}

interface FormOfferMetadataEntry {
  keyDisabled?: boolean;
  key?: string;
  displayKey: string;
  value: string;
}

export interface FormOfferMetadata {
  offerMetadata: FormOfferMetadataEntry[];
  customerRef?: string;
}

export interface FormattedFormOfferMetadata {
  offerMetadata: PrivateOffer['metadata']['offerMetadata'];
  customerRef?: PrivateOffer['customerRef'];
}

export interface UserBuiltFieldPreset {
  title: string;
}

export const defaultOfferMetadata = (
  po: PrivateOffer | null,
  rawStandardFields: UserBuiltFieldPreset[],
): FormOfferMetadata => {
  // TODO JD 2020/9/17 This code is not good and could be cleaned up
  const result: FormOfferMetadata = { offerMetadata: [] };

  let transformedStandardFields: FormOfferMetadataEntry[] = map(({ title }) => {
    return { displayKey: title, value: null, keyDisabled: true };
  })(rawStandardFields);

  const azureTenantIdIndex = findIndex(
    ({ displayKey }) => displayKey === 'Azure Tenant ID',
    transformedStandardFields,
  );

  // This will drop the first entry -- if we ever begin shipping additional Azure
  // Fields this will no longer work as expected. A more intelligent implementation
  // would slice 'around' the Azure Tenant ID standard field and work with that,
  // not assume that it's the first entry.
  if (azureTenantIdIndex !== -1) {
    transformedStandardFields = drop(1, transformedStandardFields);
    result.customerRef = isUndefined(po?.customerRef) ? '' : po.customerRef;
  }

  const offerMetadataCopy: PrivateOffer['metadata']['offerMetadata'] = {
    ...(po?.metadata?.offerMetadata ?? {}),
  };

  // use any values from offerMetadataCopy + mutate offerMetadataCopy to be left
  // only with keys that we didn't pull a value from
  const standardFieldsUpdatedWithMetadataValues: FormOfferMetadataEntry[] = map(
    ({ displayKey, value }) => {
      let newValue = value;
      if (Object.prototype.hasOwnProperty.call(offerMetadataCopy, displayKey)) {
        newValue = offerMetadataCopy[displayKey];
        delete offerMetadataCopy[displayKey];
      }
      return { displayKey, value: newValue, keyDisabled: true };
    },
  )(transformedStandardFields);

  const remainingMetadataOnlyEntries: FormOfferMetadataEntry[] = map(
    ([displayKey, value]) => ({ displayKey, value, keyDisabled: false }),
    toPairs(offerMetadataCopy),
  );

  const combined = concat(
    standardFieldsUpdatedWithMetadataValues,
    remainingMetadataOnlyEntries,
  );

  // Always return with atleast one entry (even if it's a placeholder empty)
  result.offerMetadata =
    isEmpty(combined) && isUndefined(result.customerRef)
      ? [{ displayKey: '', value: '' }]
      : combined;

  return result;
};

export const minimumExpirationDate = (
  cloud: Cloud,
  isUpdate: boolean = false,
): Date => {
  const { year, month, day } = DateTime.utc().startOf('day');
  switch (cloud) {
    // Azure offers cannot have today's date as the expiration date,
    // so making sure that the minimum date selectable on the Calendar is tomorrow.
    // See https://tackle.atlassian.net/browse/OFFERS-266 for more details.
    case CloudEnum.Azure:
      return DateTime.local(year, month, day).plus({ days: 1 }).toJSDate();
    // As of https://tackle.atlassian.net/browse/OFFERS-1227, AWS offers
    // can be created with today's date as the expiration date, but
    // must update the expiration date to be at least 1 day after today's date.
    case CloudEnum.Aws:
      if (isUpdate) {
        return DateTime.local(year, month, day).plus({ days: 1 }).toJSDate();
      }
      return DateTime.local(year, month, day).toJSDate();
    default:
      return DateTime.local(year, month, day).toJSDate();
  }
};

export const maximumExpirationDate = (cloud: Cloud): Date => {
  const { year, month, day } = DateTime.utc();

  switch (cloud) {
    case CloudEnum.Azure:
      return DateTime.local(year, month, day).plus({ years: 1 }).toJSDate();
    case CloudEnum.Gcp:
      // GCP offers must have an expiration between today's date and before 3 months from now
      // e.g. if today is 8/17/23, the expiration date must be in range 8/17/23 - 11/16/23
      return DateTime.local(year, month, day)
        .plus({ months: 3 })
        .minus({ days: 1 })
        .toJSDate();
    default:
      return null;
  }
};

export const defaultExpirationDate = (): Date => {
  const { year, month, day } = DateTime.utc().endOf('month');
  return DateTime.local(year, month, day).toJSDate();
};

export const minimumStartDate = (): Date => {
  const { year, month, day } = DateTime.utc().plus({ days: 1 }).startOf('day');
  return DateTime.local(year, month, day).toJSDate();
};

export const minServiceStartDate = (): Date => {
  const { year, month, day } = DateTime.utc().startOf('day');
  return DateTime.local(year, month, day).toJSDate();
};

export const maxServiceStartDate = (): Date => {
  const { year, month, day } = DateTime.utc().startOf('day');
  return DateTime.local(year, month, day).plus({ years: 5 }).toJSDate();
};

export const minServiceEndDate = (): Date => {
  const { year, month, day } = DateTime.utc().plus({ days: 1 }).startOf('day');
  return DateTime.local(year, month, day).toJSDate();
};

export const maxServiceEndDate = (): Date => {
  const { year, month, day } = DateTime.utc().startOf('day');
  return DateTime.local(year, month, day).plus({ years: 5 }).toJSDate();
};

export const minFirstPaymentDate = (): Date => {
  const { year, month, day } = DateTime.utc().startOf('day');
  return DateTime.local(year, month, day).toJSDate();
};

export const maxFirstPaymentDate = (): Date => {
  const { year, month, day } = DateTime.utc().startOf('day');
  return DateTime.local(year, month, day).plus({ years: 5 }).toJSDate();
};

/**
 * Some marketplaces, such as Azure Marketplace, require the Start Date to be
 * the 1st of the month and after the Expiration (Accept By) Date. This ensures that
 * we always get the nearest date that meets that criteria.
 *
 * @returns {Date} The 1st day of the next month
 *
 * @example
 * // if current date is 2022-02-28 (last day of month)
 * minimumStartMonth() // returns Date object for Mar 01, 2022
 *
 * // if current date is 2022-03-01 (first day of month)
 * minimumStartMonth() // returns Date object for Apr 01, 2022
 *
 * // if current date is 2022-03-09 (any day of month)
 * minimumStartMonth() // returns Date object for Apr 01, 2022
 */
export const minimumStartMonth = (): Date => {
  const { year, month } = DateTime.utc().plus({ months: 1 }).startOf('day');
  return DateTime.local(year, month, 1).toJSDate();
};

export const minimumEndDate = (): Date => {
  const { year, month, day } = DateTime.utc().endOf('month');
  return DateTime.local(year, month, day).toJSDate();
};

export const buildUtcMidnightIsoStringFromLocalJsDate = (
  localDate: Date,
): string => {
  const { year, month, day } = DateTime.fromJSDate(localDate);
  return DateTime.utc(year, month, day).toISO();
};

export const getLocalDateFromIsoUtcDate = (
  isoDateString?: string,
): DateTime => {
  if (isEmpty(isoDateString)) {
    return DateTime.fromJSDate(defaultExpirationDate());
  }

  const { year, month, day } = DateTime.fromISO(isoDateString, {
    zone: 'utc',
  });

  return DateTime.local(year, month, day);
};

export const getLocaJsDateFromIsoUtcDate = (dateString?: string): Date =>
  getLocalDateFromIsoUtcDate(dateString).toJSDate();

interface FormEula {
  eulaType: string;
  eulaFiles: string[];
  eulaFileSizesByUrl: { [u: string]: number };
}

interface FormOfferExpiration {
  offerExpiration?: Date;
}

interface FormRenewal {
  renewal?: boolean;
}

interface FormEnableZeroDollarPrices {
  enableZeroDollarPrices?: boolean;
}

export type FormData = FormPreRegistrationDetails &
  FormOfferBuyerDetails &
  FormOfferDetails &
  FormOfferMetadata & { pricing?: FormPricingDetails } & FormEula &
  FormOfferExpiration &
  FormRenewal &
  FormEnableZeroDollarPrices;

export type FormattedFormData = Pick<
  PrivateOffer,
  'offerRef' | 'customerRef' | 'metadata'
>;

export const buildOfferMetadata = ({
  offerMetadata = [],
  customerRef,
}: FormOfferMetadata): {
  offerMetadata: FormattedFormData['metadata']['offerMetadata'];
  customerRef?: PrivateOffer['customerRef'];
} => {
  const result: {
    offerMetadata: FormattedFormData['metadata']['offerMetadata'];
    customerRef?: PrivateOffer['customerRef'];
  } = {
    offerMetadata: Object.assign(
      {},
      ...offerMetadata
        .filter(({ value }) => value !== '') // clears Standard Fields that user hasn't filled
        .filter(({ displayKey }) => !isEmpty(displayKey)) // clears entries where client left key blank
        .map(({ displayKey, value }) => ({ [displayKey]: value })),
    ),
  };
  if (!isUndefined(customerRef)) {
    result.customerRef = customerRef;
  }
  return result;
};

export interface FormDimension {
  name: string | undefined;
  price: number | undefined;
  quantity?: string | number | undefined;
  absolutePrices?: AzureAbsolutePrice[] | undefined;
}

export interface FormUsageDimension {
  sku: string;
  price: number | undefined;
  description: string;
}

export interface FormUnit {
  unit: string | undefined;
  hourlyPrice: number | undefined;
  durationPrice: number | undefined;
}

export interface FormPricingDetails {
  dimensions: Array<FormDimension>;
  units: Array<FormUnit>;
  usageDimensions: FormUsageDimension[];
  duration?: string | number;
  showOnEmail: boolean;
  marketplaceFee: number;
  schedule: Schedule[];
  version: string;
  paymentModel: string;
  billingTerm: string;
  serviceStartAt: string;
  serviceEndAt: string;
  allowAutoRenew: boolean;
}

export interface FormRedhatPricingDetails {
  marketplaceFee: string | number;
  editionId: string;
  subscriptionTerm: string;
  billingTerm: string;
  editionCharges: RedHatPricingOfferTermCharge[];
}

const MonthsRegex = new RegExp(/\sMonth[s]?/);
const parseDuration = (
  duration:
    | FormPricingDetails['duration']
    | MetadataPricing['duration'] = undefined,
): number => {
  if (isNumber(duration)) return duration;
  return flow(replace(MonthsRegex, ''))(duration);
};

export const calcDimensionValue = ({
  price,
  quantity = null,
}: FormDimension | Dimension): number => calcTotalValue(price, quantity);

// Calculate price * quantity without needing a Dimension object
export const calcTotalValue = (
  price: string | number,
  quantity: string | number,
): number => toFinite(price ?? 0) * toFinite(quantity ?? 1);

const toDimension = ({
  name,
  price,
  quantity = null,
}: FormDimension): Dimension => ({
  name: trim(toString(name)),
  price: trim(toString(price)),
  quantity: quantity ? trim(toString(quantity)) : null,
  dimensionValue: trim(
    toString(+calcDimensionValue({ name, price, quantity }).toFixed(6)),
  ),
});

const toUnit = ({ unit, hourlyPrice, durationPrice }: FormUnit): Unit => ({
  unit: trim(toString(unit)),
  hourlyPrice: hourlyPrice ? toFinite(hourlyPrice) : undefined,
  durationPrice: durationPrice ? toFinite(durationPrice) : undefined,
});

const toUsageDimension = ({
  sku,
  description,
  price = null,
}: FormUsageDimension): UsageDimension => ({
  sku: trim(toString(sku)),
  price: trim(toString(price)),
  description: description,
});

export const calcTotalContractValue = flow(map(calcDimensionValue), sum);

export const calcMonthlyContractValue = flow(
  map(flow(({ price = 0 }) => price ?? 0, toFinite)),
  sum,
);

export const calcGcpMonthlyContractValue = (
  dimensions: FormDimension[] | Dimension[],
  duration: FormPricingDetails['duration'] | MetadataPricing['duration'] = '0',
): string => {
  const [{ price } = { price: 0 }] = dimensions ?? [
    { price: 0, name: '', quantity: 0 },
  ];
  const parsedDuration = parseDuration(duration);
  if (parsedDuration === 0) return Number.parseFloat('0').toFixed(6);

  const value = toFinite(toFinite(price) / parsedDuration);
  return Number.parseFloat(toString(value)).toFixed(6);
};

const buildPaymentSchedule = (paymentSchedule: Schedule[]): Schedule[] => {
  if (!paymentSchedule) return [];

  return paymentSchedule.map((schedule) => {
    const { invoiceAmount, invoiceDate } = schedule;
    const { year, month, day } = DateTime.fromISO(invoiceDate).toUTC();

    return {
      invoiceAmount: invoiceAmount
        ? Number.parseFloat(invoiceAmount).toFixed(2)
        : '0',
      invoiceDate: DateTime.utc(year, month, day).toISO(),
    };
  });
};

export const buildPricingDetails = (
  data: Partial<FormPricingDetails> | FormRedhatPricingDetails = null,
  cloud: Cloud,
  listingType?: ListingTypeType,
  isAzureLTSPricing?: boolean,
  pricingVersionOverride: CloudToPricingVersion = {} as CloudToPricingVersion,
): MetadataPricing | RedHatPricing => {
  if (!cloud) return null;

  const marketplaceFee = Number.parseFloat(
    toString(data?.marketplaceFee ?? '0'),
  ).toFixed(cloud === 'gcp' ? 6 : 2);

  if (cloud === 'redhat') {
    // ToPrivateOfferShape
    data = data as FormRedhatPricingDetails;
    return {
      marketplaceFee,
      editionId: data.editionId,
      billingFrequency: data.billingTerm,
      offerTerms: [
        {
          term: data.subscriptionTerm,
          termUnit: data.billingTerm,
          charges: data.editionCharges,
        },
      ],
    } as RedHatPricing;
  }

  data = data as Partial<FormPricingDetails>;
  const duration =
    (cloud === CloudEnum.Gcp || cloud === CloudEnum.Aws) && data?.duration
      ? `${toFinite(data?.duration)} ${PricingDurationUnitOfMeasure.Months}`
      : toString(data?.duration);

  const dimensions = flow(
    reject(isFormDimensionEmpty),
    map(toDimension),
  )(data?.dimensions);

  const units = flow(reject(isFormUnitEmpty), map(toUnit))(data?.units);

  const usageDimensions = map(toUsageDimension, data?.usageDimensions);

  const version =
    pricingVersionOverride[cloud] ||
    getPricingByCloudAndListingType(
      cloud as CloudEnum,
      listingType as ListingTypeType,
      isAzureLTSPricing,
    );

  return {
    dimensions,
    units,
    usageDimensions,
    duration,
    showOnEmail: data?.showOnEmail ?? false,
    marketplaceFee,
    version,
    schedule: buildPaymentSchedule(data?.schedule),
    billingTerm: BillingTermValue.Custom,
  } as MetadataPricing;
};

export const isFormDimensionEmpty = (
  dimension: FormDimension | Dimension,
): boolean =>
  isEmpty(dimension.name) &&
  toFinite(dimension.price) === 0 &&
  isEmpty(dimension?.quantity);

export const isFormUnitEmpty = (unit: FormUnit | Unit): boolean =>
  isEmpty(unit.unit) &&
  toFinite(unit.hourlyPrice) === 0 &&
  toFinite(unit.durationPrice) === 0;

export const allFormDimensionsEmpty = flow(
  reject(isFormDimensionEmpty),
  get('length'),
  isEqual(0),
);

export const allFormUnitsEmpty = flow(
  reject(isFormUnitEmpty),
  get('length'),
  isEqual(0),
);

export const contractDetailsIsEmpty = (
  pricing?: MetadataPricing | FormPricingDetails,
): boolean => isEmpty(pricing?.duration) && isEmpty(pricing?.marketplaceFee);

export const pricingIsEmpty = (
  pricing?: FormPricingDetails | MetadataPricing | null,
): boolean =>
  allFormDimensionsEmpty(pricing?.dimensions ?? []) &&
  isEmpty(pricing?.usageDimensions) &&
  contractDetailsIsEmpty(pricing);

export const unitPricingIsEmpty = (
  pricing?: FormPricingDetails | MetadataPricing | null,
): boolean =>
  allFormUnitsEmpty(pricing?.units ?? []) && contractDetailsIsEmpty(pricing);

export const buildBuyerDetails = ({
  buyers = [],
}: FormOfferBuyerDetails): FormattedFormData['metadata']['buyers'] =>
  reject(
    ({ fullName, emailAddress }) => isEmpty(fullName) && isEmpty(emailAddress),
    buyers,
  );

interface EnableZeroDollarPricesDetails {
  enableZeroDollarPrices: boolean;
}

export const buildEnableZeroDollarPrices = (
  data: FormEnableZeroDollarPrices,
): EnableZeroDollarPricesDetails => ({
  enableZeroDollarPrices: data.enableZeroDollarPrices,
});

interface RenewalDetails {
  renewal: boolean;
}

export const buildRenewalDetails = (data: FormRenewal): RenewalDetails => {
  return { renewal: data.renewal };
};

export function convertISODateStringToLocalJSDate(
  isoDateString?: string,
): Date | null {
  if (isEmpty(isoDateString)) return null;

  const { year, month, day } = DateTime.fromISO(isoDateString, {
    zone: 'utc',
  });

  return DateTime.local(year, month, day).toJSDate();
}

export function convertLocalJSDateToMidnightISOString(
  localDate: Date | string,
): string {
  if (!localDate) return null;
  const { year, month, day } = DateTime.fromJSDate(localDate as Date);
  return DateTime.utc(year, month, day).toISO();
}

export function getMonthsDifferenceFromJSDates(
  fromDate: Date | string,
  toDate: Date | string,
): number {
  if (
    !fromDate ||
    !toDate ||
    !(fromDate instanceof Date) ||
    !(toDate instanceof Date)
  )
    return 0;
  const fromDateTime = DateTime.fromJSDate(fromDate);
  const toDateTime = DateTime.fromJSDate(toDate);
  const difference = toDateTime.diff(fromDateTime, ['months']).toObject();

  if (difference?.months <= 0 || !isNumber(difference?.months)) return 0;

  if (difference?.months < 0.5) return 1;

  return Math.round(difference?.months);
}

const invoiceDateTypesWithCustomLabels = [
  ScheduleInvoiceDateType.OnAcceptance,
  ScheduleInvoiceDateType.OnStartDate,
];

export function getPaymentInvoiceDateDisplay(payment?: any): string {
  if (
    isEmpty(payment?.invoiceDate) &&
    (isEmpty(payment?.invoiceDateType) ||
      !invoiceDateTypesWithCustomLabels.includes(payment?.invoiceDateType))
  )
    return 'N/A';

  if (invoiceDateTypesWithCustomLabels.includes(payment?.invoiceDateType)) {
    return ScheduleInvoiceDateTypeToDisplayLabel[payment.invoiceDateType];
  }

  const { year, month, day } = DateTime.fromISO(payment.invoiceDate).toUTC();

  return DateTime.local(year, month, day).toFormat('L/d/yyyy');
}

export function getInvoiceDateTimeDisplayFromDate(
  invoiceDate?: DateTime,
): string {
  if (isEmpty(invoiceDate)) return 'N/A';

  const { year, month, day } = invoiceDate.toUTC();

  return DateTime.local(year, month, day).toFormat('LL/dd/yyyy');
}
