import { assign, curryRight, every, filter, some } from 'lodash/fp';

import { Products } from 'stores';
import useStore, { hasLoaded } from 'hooks/useStore';

import { Cloud } from 'stores/typings';

interface HasFeatureAccess {
  loading: boolean;
  hasAccess: boolean;
}

interface Options {
  cloud?: Cloud;
  productid?: string;
  strict?: boolean;
}

const DEFAULT_OPTIONS: Options = {
  cloud: null,
  productid: null,
  strict: false,
};

function useFeatureAccessValidation(
  features: string[],
  options?: Options,
): Readonly<HasFeatureAccess> {
  const { cloud, strict, productid } = assign(DEFAULT_OPTIONS, options);

  let hasAccess = false;
  let loading = true;

  const productsStore = useStore(Products);
  if (!hasLoaded(productsStore)) return { loading, hasAccess };
  loading = false;

  let { products = [] } = productsStore;
  if (cloud) products = filter({ provider: cloud }, products);
  if (productid) products = filter({ productid }, products);

  const hasFeatures = curryRight((product, _features): boolean => {
    return product.hasFeatures(_features);
  });

  const anyHaveAccess = some(hasFeatures(features));
  const allHaveAccess = every(hasFeatures(features));

  hasAccess = strict ? allHaveAccess(products) : anyHaveAccess(products);
  return { loading, hasAccess };
}

export default useFeatureAccessValidation;
