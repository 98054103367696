import React, { useEffect } from 'react';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import { SalesforceCoSellWidget } from 'packages/cosell/src/components/SalesforceCoSellWidget/';
import CanvasSdk from '../../../src/lib/salesforceCanvasSdk';
import { useTackleOpportunityForCrmId } from '../hooks/useTackleOpportunityForCrmId';
import { useTackleCoSellOpportunity } from '../hooks/useTackleCoSellOpportunity';
import { Loader } from '@tackle-io/platform-ui';

/** opportunity refers to Tackle Opportunity */
export default function CanvasCoSellDetails() {
  const { canvasClient, recordId: salesforceOpportunityId } =
    useCanvasSession();

  const { data: opportunitySummary, isLoading: isOpportunitySummaryLoading } =
    useTackleOpportunityForCrmId({ crmId: salesforceOpportunityId });
  const { opportunity, isLoading: isOpportunityLoading } =
    useTackleCoSellOpportunity({
      id: opportunitySummary?.id,
    });

  useEffect(() => {
    CanvasSdk.canvas.client.autogrow(canvasClient, true, 100);
  }, [canvasClient]);

  const isLoading =
    isOpportunitySummaryLoading ||
    (!!opportunitySummary && isOpportunityLoading);

  if (!salesforceOpportunityId) {
    return (
      <p>
        Unable to get Salesforce Opportunity ID. Please verify with your admin
        this component is on the Salesforce Opportunity page.
      </p>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  if (opportunity?.metadata?.hasErrors) {
    return (
      <div>
        <h2>Opportunity has errors</h2>
        <pre>{JSON.stringify(opportunity?.metadata, null, 2)}</pre>
      </div>
    );
  }

  return <SalesforceCoSellWidget coSellOpportunity={opportunity} />;
}
