const CaretLeft = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_715_77275)">
      <path
        d="M10.274 11.06L7.22065 8L10.274 4.94L9.33398 4L5.33398 8L9.33398 12L10.274 11.06Z"
        fill="#253858"
      />
    </g>
    <defs>
      <clipPath id="clip0_715_77275">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CaretLeft;
