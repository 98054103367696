import { makeStyles } from 'vendor/material';

type TextAlignCenter = 'center';

const typographyStyles = (theme) => ({
  textAlign: 'center' as TextAlignCenter,
  padding: theme.spacing(2),
  color: theme.palette.NEUTRAL400,
});

const useStyles = makeStyles((theme) => ({
  accountAvatar: {
    height: '40px',
    width: '40px',
  },
  accountList: {
    height: '250px',
    overflow: 'auto',
  },
  accountListZero: {
    ...typographyStyles(theme),
  },
  accountListFailed: {
    ...typographyStyles(theme),
    color: theme.palette.RED500,
  },
  errorText: {
    color: theme.palette.RED500,
    fontSize: theme.typography.pxToRem(12),
  },
  successIcon: {
    color: theme.palette.GREEN400,
  },
}));

export default useStyles;
