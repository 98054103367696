import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    padding: theme.spacing(4, 0),
    gap: theme.spacing(1),
  },
  leftSection: {
    display: 'flex',
    alignItems: 'left',
    gap: theme.spacing(1),
    flexDirection: 'column',
  },
  contractNameAndLogo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  cloudLogo: {
    marginRight: theme.spacing(1),
  },
  buyer: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
  },
  offerName: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  backText: {
    fontSize: theme.typography.pxToRem(12),
  },
  badges: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  rightSection: {
    display: 'flex',
    gap: theme.spacing(1),
    marginLeft: 'auto',
  },
  statusChip: {
    fontWeight: 600,
  },
  previewTag: {
    all: 'unset',
    display: 'inline',
  },
}));

export default useStyles;
