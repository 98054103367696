import {
  number as yupNumber,
  object as yupObject,
  string as yupString,
} from 'yup';

export const paymentSchedulerSchema = yupObject().shape({
  totalContractValue: yupNumber().required().min(0.01),
  durationInMonths: yupNumber().required().min(1).max(60),
  paymentFrequency: yupString().required(),
  firstPaymentDate: yupString().required().min(1),
});
