export enum PaymentFrequencyValue {
  Monthly = 'monthly',
  Annual = 'annual',
  Quarterly = 'quarterly',
  SemiAnnual = 'semi_annual',
}

export type PaymentFrequencyValueType =
  | PaymentFrequencyValue.Monthly
  | PaymentFrequencyValue.Annual
  | PaymentFrequencyValue.Quarterly
  | PaymentFrequencyValue.SemiAnnual;

export interface PaymentFrequencyOption {
  text: string;
  value: PaymentFrequencyValueType;
}

export const paymentFrequencyOptions: PaymentFrequencyOption[] = [
  {
    text: 'Monthly',
    value: PaymentFrequencyValue.Monthly,
  },
  {
    text: 'Annually',
    value: PaymentFrequencyValue.Annual,
  },
  {
    text: 'Quarterly',
    value: PaymentFrequencyValue.Quarterly,
  },
  {
    text: 'Semi-annually',
    value: PaymentFrequencyValue.SemiAnnual,
  },
];

export const PAYMENT_FREQUENCY_DIVISOR_MAP = {
  [PaymentFrequencyValue.Monthly]: 1,
  [PaymentFrequencyValue.Annual]: 12,
  [PaymentFrequencyValue.Quarterly]: 3,
  [PaymentFrequencyValue.SemiAnnual]: 6,
};

const defaultExport = {
  paymentFrequencyOptions,
  PAYMENT_FREQUENCY_DIVISOR_MAP,
};

export default defaultExport;
