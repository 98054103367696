import { Box } from 'vendor/material';

export const CoSellHeaderFilters = () => (
  <Box
    display={'flex'}
    justifyContent={'space-between'}
    alignItems={'flex-start'}
    alignContent={'center'}
  >
    {/* Filters */}
  </Box>
);
