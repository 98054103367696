export const TEST_ENV_SUFFIX = 'test_env';

export const idGenerator = (prefix: string) => {
  const index = 1;
  return (suffix: string) => {
    if (suffix) {
      return `${prefix}${index + 1}${suffix}`;
    }

    return `${prefix}${index + 1}`;
  };
};

/*
  Use this utility method to create unique IDs for elements that need to be recognized by Pendo
*/
export const pendoIdGenerator = (pendoId: string, suffix?: string) => {
  if (suffix) {
    return `${pendoId}-${suffix}`;
  }

  return pendoId;
};
