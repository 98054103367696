import { decorate, observable } from 'mobx';

import Order from './Order';

class AZUREOrder extends Order {
  provider = 'AZURE';

  planid = '';

  sub_name = '';

  registration_id = '';
}

decorate(AZUREOrder, {
  provider: observable,
  planid: observable,
  sub_name: observable,
  registration_id: observable,
});

export default AZUREOrder;
