import { DateTime } from 'luxon';
import {
  filter,
  flatMap,
  flow,
  isEmpty,
  map,
  reverse,
  sortBy,
  take,
  overEvery,
} from 'lodash/fp';
import { CartOutline, CashMultiple } from 'mdi-material-ui';

import { isUnacceptedPrivateOffer } from '../utils';

const prepareDescription = (description, cloud) => {
  const desc = description ?? '';

  if (desc.includes('with an estimated revenue')) {
    switch (cloud) {
      case 'aws':
        return 'AWS reported estimated revenue';
      default:
        return desc;
    }
  }
  if (desc.includes('Tackle was alerted to a new')) {
    switch (cloud) {
      case 'aws':
        return 'Tackle reported a new AWS order';
      case 'azure':
        return 'Tackle reported a new Azure order';
      case 'gcp':
        return 'Tackle reported a new GCP order';
      default:
        return 'Tackle reported a new order';
    }
  }
  if (desc.includes('reported contract revenue')) {
    switch (cloud) {
      case 'aws':
        return 'AWS reported contract revenue';
      default:
        return desc;
    }
  }
  if (desc.includes('reported a disbursement')) {
    switch (cloud) {
      case 'aws':
        return 'AWS reported a disbursement';
      default:
        return desc;
    }
  }
  if (desc.includes('reported a refund')) {
    switch (cloud) {
      case 'aws':
        return 'AWS reported a refund';
      default:
        return desc;
    }
  }
  if (desc.includes('reported metered revenue')) {
    switch (cloud) {
      case 'aws':
        return 'AWS reported metered revenue';
      default:
        return desc;
    }
  }
  if (desc.includes('reported a metered transaction')) {
    switch (cloud) {
      case 'aws':
        return 'AWS reported estimated metered revenue';
      default:
        return desc;
    }
  }
  if (desc.includes('reported an entitlements update')) {
    switch (cloud) {
      case 'aws':
        return 'AWS SNS reported an entitlements update';
      default:
        return desc;
    }
  }
  if (desc.includes('reported a subscription failure')) {
    switch (cloud) {
      case 'aws':
        return 'AWS SNS reported a subscription failure';
      default:
        return desc;
    }
  }
  if (desc.includes('reported a successful subscription')) {
    switch (cloud) {
      case 'aws':
        return 'AWS SNS reported a successful subscription';
      default:
        return desc;
    }
  }
  if (desc.includes('reported a pending subscription unsubscribe')) {
    switch (cloud) {
      case 'aws':
        return 'AWS SNS reported a pending subscription unsubscribe';
      default:
        return desc;
    }
  }
  if (desc.includes('reported a subscription unsubscribe')) {
    switch (cloud) {
      case 'aws':
        return 'AWS SNS reported a subscription unsubscribe';
      default:
        return desc;
    }
  }
  if (desc.includes('reported the entitlements have been updated')) {
    switch (cloud) {
      case 'aws':
        return 'AWS SNS reported an entitlements update';
      default:
        return desc;
    }
  }

  return desc;
};

const tackleActivity = (
  { datetime, description, eventId },
  { displayName, id, cloud },
  redirect,
) => ({
  Icon: CartOutline,
  date: datetime.isValid ? datetime : DateTime.local(1970, 1, 1, 1, 1),
  client: displayName,
  type: 'contract',
  secondary: prepareDescription(description, cloud),
  status: 'success',
  onClick: () => redirect(`/contracts/${id}?eventId=${eventId}`),
});

const usageActivity = (
  { datetime, description, eventId },
  { displayName, id, cloud, cloudMetadata },
  redirect,
) => ({
  Icon: CashMultiple,
  date: datetime,
  client: displayName,
  type: 'usage',
  cloud: cloud,
  productCode: cloudMetadata.product_code,
  secondary: prepareDescription(description, cloud),
  status: 'success',
  onClick: () => redirect(`/contracts/${id}?eventId=${eventId}`),
});

const contractToActivities = (contract, redirect) =>
  map((event) => {
    switch (event.eventType) {
      case 'tkl':
        return tackleActivity(event, contract, redirect);
      case 'aws:monthly_revenue':
      case 'aws:disbursement':
      case 'aws:refund':
      case 'aws:daily_business':
        return usageActivity(event, contract, redirect);
      case 'aws:sns_event':
        return usageActivity(event, contract, redirect);
      default:
        return {};
    }
  })(contract.events);

const latestActivityItems = (contracts, filterQuery, redirect, products = []) =>
  flow(
    filter(
      overEvery([
        (c) => !c.archivedAt,
        (c) => !isUnacceptedPrivateOffer(c, products),
      ]),
    ),
    flatMap((contract) => contractToActivities(contract, redirect)),
    filter((c) => {
      const filterParams = new URLSearchParams(filterQuery);
      let hasMatch = true;

      if (filterParams.get('productRef')) {
        if (filterParams.get('productRef') !== c.productCode) {
          hasMatch = false;
        }
      }

      if (filterParams.get('marketplace')) {
        if (filterParams.get('marketplace') !== c.cloud) {
          hasMatch = false;
        }
      }

      if (
        filterParams.has('event_date__gt') &&
        filterParams.has('event_date__lt')
      ) {
        if (!c.date) {
          hasMatch = false;
          return false;
        }

        if (
          c.date.toFormat('yyyyLLLdd') <
            DateTime.fromISO(filterParams.get('event_date__gt')).toFormat(
              'yyyyLLLdd',
            ) ||
          c.date.toFormat('yyyyLLLdd') >
            DateTime.fromISO(filterParams.get('event_date__lt')).toFormat(
              'yyyyLLLdd',
            )
        ) {
          hasMatch = false;
        }
      }

      return hasMatch;
    }),
    filter((c) => !isEmpty(c)),
    sortBy('date'),
    reverse,
    take(10),
  )(contracts);

export default latestActivityItems;
