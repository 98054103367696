import { floatToDinero } from './money';

export enum CurrencyCode {
  AustralianDollar = 'AUD',
  BritishPound = 'GBP',
  Euro = 'EUR',
  JapaneseYen = 'JPY',
  UnitedStatesDollar = 'USD',
}

export const currencyResourceByCurrencyCode: { [cc: string]: string } = {
  [CurrencyCode.AustralianDollar]: 'Australian Dollar',
  [CurrencyCode.BritishPound]: 'British Pound',
  [CurrencyCode.Euro]: 'Euro',
  [CurrencyCode.JapaneseYen]: 'Japanese Yen',
  [CurrencyCode.UnitedStatesDollar]: 'United States Dollar',
};

export const currencySymbolByCurrencyCode: { [cc: string]: string } = {
  [CurrencyCode.AustralianDollar]: 'A\u0024',
  [CurrencyCode.BritishPound]: '\u00A3',
  [CurrencyCode.Euro]: '\u20AC',
  [CurrencyCode.JapaneseYen]: '\u00A5',
  [CurrencyCode.UnitedStatesDollar]: '\u0024',
};

const defaultCurrencyFormat = '$0,0.00';

// This function simply formats the value prefixed with the currency symbol for
// the provided CurrencyCode - Example (USD): $10,000.00.
// It is not intended to produce a properly formatted locale string. To do that
// you can call `.toLocaleString(locale, options)` on the Dinero object.
// See Dinero docs for more info: https://dinerojs.com/module-dinero#~toFormat
export const formatValueWithCurrencyPrefix = (
  value: number | string,
  currencyCode: CurrencyCode = CurrencyCode.UnitedStatesDollar,
  format: string = defaultCurrencyFormat,
): string => {
  const cc = currencyCode || CurrencyCode.UnitedStatesDollar;
  const dineroWithCurrency = floatToDinero(value, 2, cc);
  return dineroWithCurrency.toFormat(format);
};

export const getCurrencyResource = (cc: CurrencyCode) => {
  const currencyResource = currencyResourceByCurrencyCode[cc];
  const currencySymbol = currencySymbolByCurrencyCode[cc];

  return `${currencyResource} | ${cc} (${currencySymbol})`;
};
