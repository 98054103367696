import React from 'react';
import { Typography } from 'vendor/material';
import { Link, Spacer } from '@tackle-io/platform-ui';

import Page from 'components/Page/Page';

import { buildMailTo } from 'utils';
import Email from 'utils/constants.ts';

const TemporarilyOffline = () => (
  <Page size="sm" style={{ textAlign: 'center' }}>
    <Spacer />
    <Typography component="h1" variant="h4">
      We are sorry!
    </Typography>
    <Typography component="h2" variant="body1">
      We are currently experiencing a temporary outage.
    </Typography>
    <Typography component="h3" variant="body2">
      Our engineers are aware of the issue and are working to resolve the
      problem. If you continue to run into this issue, please contact{' '}
      <Link to={buildMailTo(`${Email.Support}`)} external>
        {`${Email.Support}`}
      </Link>
      .
    </Typography>
  </Page>
);

export default TemporarilyOffline;
