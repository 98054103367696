import { ApolloProvider } from '@apollo/client';
import { useRef } from 'react';

import { setContext } from '@apollo/client/link/context';

import { useAuth } from 'vendor/auth0';
import getApolloClient from 'utils/getApolloClient';

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth();

  const authLink = setContext(async (operation, prevContext) => {
    try {
      const token = await getAccessTokenSilently();
      if (!token) return prevContext;
      return {
        ...prevContext,
        headers: { ...prevContext.headers, authorization: `Bearer ${token}` },
      };
    } catch (e) {
      return prevContext;
    }
  });

  const client = useRef(getApolloClient(authLink));
  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
