export enum AceOpportunitySalesActivityEnum {
  INITIALIZED_DISCUSSIONS_WITH_CUSTOMER = 'Initialized discussions with customer',
  CUSTOMER_HAS_SHOWN_INTEREST_IN_SOLUTION = 'Customer has shown interest in solution',
  CONDUCTED_POC_DEMO = 'Conducted POC / Demo',
  IN_EVALUATION_PLANNING_STAGE = 'In evaluation / planning stage',
  AGREED_ON_SOLUTION_TO_BUSINESS_PROBLEM = 'Agreed on solution to Business Problem',
  COMPLETED_ACTION_PLAN = 'Completed Action Plan',
  FINALIZED_DEPLOYMENT_NEED = 'Finalized Deployment Need',
  SOW_SIGNED = 'SOW Signed',
}
