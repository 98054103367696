import { decorate, observable } from 'mobx';
import { assign } from 'lodash-es';

class CustomerDetails {
  country = '';

  state = '';

  city = '';

  zip_code = '';

  email_domain = '';

  constructor(props) {
    const customer = Object.keys(props).reduce((o, propsKey) => {
      const [, k] = propsKey.split('customer_');
      o[k] = props[propsKey];
      return o;
    }, {});

    assign(this, customer);
  }
}

decorate(CustomerDetails, {
  country: observable,
  state: observable,
  city: observable,
  zip_code: observable,
  email_domain: observable,
});

export default CustomerDetails;
