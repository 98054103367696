import React from 'react';
import { Alert } from 'mdi-material-ui';
import { FormHelperText } from '../../../../vendor/material/index';
import useStyles from './FieldError.styles';

interface FieldErrorProps {
  error: string;
}

const FieldError: React.FC<FieldErrorProps> = ({ error }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.helperTextContainer}>
        <div className={classes.errorContainer}>
          <Alert fontSize="inherit" />
          <FormHelperText error>{error}</FormHelperText>
        </div>
      </div>
    </div>
  );
};

export default FieldError;
