import { Cloud, CloudType } from 'utils/cloudTypes';

export enum ContractsTableColumnNames {
  CHECKBOX = 'CHECKBOX',
  CONTRACT_DETAILS = 'CONTRACT_DETAILS',
  STATUS = 'STATUS',
  PRODUCT = 'PRODUCT',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  TOTAL_CONTRACT_VALUE = 'TOTAL_CONTRACT_VALUE',
  DISBURSED = 'DISBURSED',
  CONTRACT_ID = 'CONTRACT_ID',
}

export enum ContractStatusEnum {
  PAID = 'PAID',
  PENDING = 'PENDING',
  PAST_DUE = 'PAST',
}

export const CONTRACT_STATUS_TEXT_LOOKUP = {
  [ContractStatusEnum.PAID]: 'Paid',
  [ContractStatusEnum.PENDING]: 'Pending',
  [ContractStatusEnum.PAST_DUE]: 'Past due',
};

export const getContractStatusText = (
  status: ContractStatusEnum | string,
): string =>
  CONTRACT_STATUS_TEXT_LOOKUP[status] || status || NOT_PROVIDED_VALUE;

export const NOT_PROVIDED = 'Not provided by marketplace';
export const NOT_PROVIDED_VALUE = '--';

export const defaultContractColumnsObj = {
  CHECKBOX: null,
  CONTRACT_DETAILS: null,
  STATUS: null,
  PRODUCT: null,
  START_DATE: null,
  END_DATE: null,
  TOTAL_CONTRACT_VALUE: null,
  DISBURSED: null,
};

export const getProviderIcon = (provider: CloudType | string) =>
  provider === Cloud.Azure ? 'microsoft' : provider;

export const getContractKey = (
  contractId: number | string,
  contractStartDate: number | string,
) => `${contractId}-${contractStartDate}`;

export const CONTRACTS_FIELDS_NULL_VALUES = ['0', 'NULL', 'Null'];

export const getDisplayValue = (value: string) => {
  if (!value || CONTRACTS_FIELDS_NULL_VALUES.includes(value))
    return NOT_PROVIDED_VALUE;

  return value;
};
