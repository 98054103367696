import { useAuth } from 'vendor/auth0';
import { useState, useEffect } from 'react';

const useAuthToken = (): string => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth();
  const [token, setToken] = useState<string>(null);

  useEffect(() => {
    const initializeToken = async () => {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);
    };

    if (!token && isAuthenticated) {
      initializeToken();
    }
  }, [getAccessTokenSilently, isAuthenticated, token]);

  return token;
};

export default useAuthToken;
