import React from 'react';
import { Grid } from 'vendor/material';
import { TextField } from '@tackle-io/platform-ui';
import { Controller, useFormContext } from 'react-hook-form';

import useStyles from './styles';
import { UsageDimensionFieldProps } from './typings';

const AwsUsageDimensionField: React.FC<UsageDimensionFieldProps> = ({
  field,
  index,
}) => {
  const classes = useStyles();
  const { control, errors } = useFormContext();

  return (
    <Grid container spacing={2} className={classes.usageDimensionFields}>
      <Grid item md={3} sm={12} xs={12}>
        <Controller
          name={`pricing.usageDimensions[${index}].sku`}
          control={control}
          defaultValue={field.sku}
          render={({ ref, onChange: _, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="SKU"
              id={`pricing.usageDimensions.${index}.sku`}
              error={errors.pricing?.usageDimensions?.[index]?.sku?.message}
              inputRef={ref}
              aria-label={`Usage Dimension ${index} SKU`}
              readOnly
            />
          )}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <Controller
          name={`pricing.usageDimensions[${index}].price`}
          control={control}
          defaultValue={field.price}
          render={({ ref, onChange, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Fee amount"
              id={`pricing.usageDimensions.${index}.price`}
              error={errors.pricing?.usageDimensions?.[index]?.price?.message}
              type="number"
              mode="numberformat"
              fixedDecimalScale
              decimalScale={3}
              aria-label={`Usage Dimension ${index} Fee Amount`}
              inputRef={ref}
              onChange={({ target: { value } }) => onChange(value)}
            />
          )}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <Controller
          name={`pricing.usageDimensions[${index}].description`}
          control={control}
          defaultValue={field.description}
          render={({ ref, onChange: _, ...props }): React.ReactElement => (
            <TextField
              {...props}
              label="Description"
              id={`pricing.usageDimensions.${index}.description`}
              error={
                errors.pricing?.usageDimensions?.[index]?.description?.message
              }
              inputRef={ref}
              aria-label={`Dimension ${index} Quantity`}
              readOnly
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AwsUsageDimensionField;
