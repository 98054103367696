import React, { MouseEvent } from 'react';
import { Modal, Button } from '@tackle-io/platform-ui';

interface ConfirmModalProps {
  body: string;
  cancelButtonLabel: string;
  onClose?: (event: MouseEvent) => void;
  onSubmit: (event: MouseEvent<HTMLButtonElement>) => void;
  open?: boolean;
  pendoCancel: string;
  pendoConfirm: string;
  submitButtonLabel: string;
  title: string;
  submitButtonLoading?: boolean;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  body,
  cancelButtonLabel,
  onClose,
  onSubmit,
  open = false,
  pendoCancel,
  pendoConfirm,
  submitButtonLabel,
  title,
  submitButtonLoading,
}) => (
  <Modal
    data-testid={'cosell_confirm_modal'}
    title={title}
    width="medium"
    open={open}
    onClose={onClose}
    footerActions={
      <>
        <Button
          id={pendoCancel}
          appearance="secondary"
          variant="text"
          onClick={onClose}
          disabled={submitButtonLoading}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          id={pendoConfirm}
          appearance="destructive"
          variant="text"
          onClick={onSubmit}
          loading={submitButtonLoading}
          disabled={submitButtonLoading}
        >
          {submitButtonLabel}
        </Button>
      </>
    }
  >
    <>{body}</>
  </Modal>
);
