// HMN 4/24/23: This represents the value of the query parameters used to filter an opportunity by status
export const OpportunitySearchStatusFilterEnum = {
  All: '',
  Accepted: 'accepted',
  'Action required': 'action_required',
  'Closed error': 'closed_error',
  'Closed lost': 'closed_lost',
  'Closed won': 'closed_won',
  'Cloud review': 'cloud_review',
  Declined: 'declined',
  Draft: 'draft',
  Expired: 'expired',
  'Internal review': 'internal_review',
};
