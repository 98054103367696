export const PRODUCTION_ENV = 'production';
export const STAGING_ENV = 'staging';
export const LOCAL_UNKNOWN_ENV = 'local/unknown';

interface BuildEnvironment {
  isCloudflare: boolean;
  isCircleci: boolean;
  compilationEnvironment: 'circleci' | 'cloudflare' | 'local/unknown';
  environment:
    | typeof PRODUCTION_ENV
    | typeof STAGING_ENV
    | typeof LOCAL_UNKNOWN_ENV;
  sha1: string | 'unknown';
  branch: string | 'unknown';
  apiEndpoint: string;
  apiEnvironment: 'production' | 'staging' | 'local';
}

export const getCIEnvironment = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'staging':
    case 'staging2':
    case 'canary':
      return STAGING_ENV;
    case 'production':
      return PRODUCTION_ENV;
    case 'development':
    default:
      return LOCAL_UNKNOWN_ENV;
  }
};

const buildEnvironment = {
  isCircleci: !!process.env.REACT_APP_BUILD_CIRCLECI,
  isCloudflare: !!process.env.REACT_APP_BUILD_CLOUDFLARE,
  compilationEnvironment:
    (process.env.REACT_APP_BUILD_CIRCLECI && 'circleci') ||
    (process.env.REACT_APP_BUILD_CLOUDFLARE && 'cloudflare') ||
    'local/unknown',
  environment:
    (process.env.REACT_APP_BUILD_CIRCLECI && getCIEnvironment()) ||
    (process.env.REACT_APP_BUILD_CLOUDFLARE && STAGING_ENV) ||
    LOCAL_UNKNOWN_ENV,
  sha1:
    (process.env.REACT_APP_BUILD_CIRCLECI &&
      process.env.REACT_APP_BUILD_CIRCLECI_SHA1) ||
    (process.env.REACT_APP_BUILD_CLOUDFLARE &&
      process.env.REACT_APP_BUILD_CLOUDFLARE_SHA1) ||
    'unknown',
  branch:
    (process.env.REACT_APP_BUILD_CIRCLECI &&
      process.env.REACT_APP_BUILD_CIRCLECI_BRANCH) ||
    (process.env.REACT_APP_BUILD_CLOUDFLARE &&
      process.env.REACT_APP_BUILD_CLOUDFLARE_BRANCH) ||
    'unknown',
  apiEndpoint: process.env.REACT_APP_API_URL,
  apiEnvironment:
    (process.env.REACT_APP_API_URL.includes('stg') && 'staging') ||
    (process.env.REACT_APP_API_URL.includes('localhost') && 'local') ||
    'production',
} as BuildEnvironment;

export default buildEnvironment;
