import { makeStyles } from 'vendor/material';

export const useSalesforceCoSellWidgetHeaderStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.TEAL900,
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingInline: '1rem',
  },
  openInTackleIcon: {
    marginLeft: '6px',
  },
}));
