import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { coSellApi } from '../tackleApiClient';

export const getTackleCoSellOpportunity = async ({
  id,
}: {
  id: string;
}): Promise<AceOpportunityResponse> => {
  return coSellApi.get(`opportunities/${id}`).json();
};
