import { fontWeightMedium } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

export const useOpportunityFormStyles = makeStyles((theme) => ({
  title: {
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(27),
    color: theme.palette.NEUTRAL700,
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
  },
  formSection: {
    color: theme.palette.NEUTRAL600,
    margin: `${theme.spacing(5)}px 0`,
  },
  stepIcon: {
    color: theme.palette.NEUTRAL600,
  },
  cosellFormButtonsContainer: {
    margin: `${theme.spacing(5)}px 0`,
    display: 'flex',
    justifyContent: 'space-between',
  },
  cosellFormCancelButton: {
    display: 'inline',
    marginRight: theme.spacing(1),
  },
  cosellFormDeleteDraftButton: {
    display: 'inline',
    marginRight: theme.spacing(1),
  },
  cosellFormButtonContainer: {
    display: 'inline',
  },
  submitIncompleteFieldsTextContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  picklistField: {
    paddingBottom: theme.spacing(2.5),
  },
  callout: {
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
      marginLeft: theme.spacing(1.5),
    },
    fontSize: theme.typography.fontSize,
    margin: 'auto',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[800],
  },
  calloutContainer: {
    width: '100%',
  },
  consentList: {
    color: theme.palette.NEUTRAL800,
    margin: 0,
    paddingLeft: theme.spacing(7),
  },
  // TODO: Remove when v14 gets GA
  v14Tag: {
    marginLeft: theme.spacing(1),
    alignSelf: 'baseline',
  },
}));
