import React from 'react';
import { Paper, Zoom } from 'vendor/material';

import useStyles from './Message.styles';

const Message = ({ children, type }) => {
  const classes = useStyles({ type });

  return (
    <Zoom in>
      <Paper elevation={1} className={classes.root}>
        {children}
      </Paper>
    </Zoom>
  );
};

export default Message;
