import { Switch, withStyles } from 'vendor/material';

const CustomSwitch = withStyles((theme) => {
  let thumbAdditionals = {};

  return {
    root: {
      width: 44,
      height: 20,
      padding: 0,
      display: 'flex',
      marginRight: theme.spacing(2),
      overflow: 'visible',
      '&:hover $thumb': {
        transform: 'scale(1.05)',
      },
    },
    switchBase: {
      position: 'static',
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.BLUE400,
          borderColor: theme.palette.NEUTRAL060,
        },
        '& input': {
          width: 40,
          left: '-16px',
        },
      },
    },
    thumb: {
      boxShadow: '0 2px 2px -1px rgba(152, 162, 179, 0.3)',
      padding: 9,
      border: `1px solid #c9cbcd`,
      background: `#FFF no-repeat center`,
      borderRadius: 18,
      left: 4,
      position: 'absolute',
      display: 'inline-block',
      transition: `border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1), background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1), left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1), -webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1)`,
      ...thumbAdditionals,
    },
    track: {
      border: `1px solid ${theme.palette.NEUTRAL060}`,
      borderRadius: 20,
      opacity: 1,
      backgroundColor: theme.palette.NEUTRAL030,
    },
    checked: {},
  };
})(Switch);

export default CustomSwitch;
