import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-flex',
    alignItems: 'top',
    gap: theme.spacing(0.75),
  },
  iconWrap: {
    display: 'inline-block',
    paddingTop: theme.spacing(0.5),
  },
}));

export default useStyles;
