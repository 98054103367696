enum Email {
  Support = 'support@tackle.io',
  Onboarding = 'onboarding@tackle.io',
}

export const REGISTRATION_BASE_URL = 'register.tackle.io';

export default Email;

export const CONTACT_SUPPORT_URL = 'https://support.tackle.io/s/contactsupport';

// Possible values for vendor health status from reporting API
export enum ReportingStatus {
  N_A = 'N/A',
  GOOD = 'GOOD',
  NOT_CONFIGURED = 'NOT CONFIGURED',
  DISCONNECTED = 'DISCONNECTED',
}

// Possible values for vendor environments
export enum VendorEnvironmentEnum {
  PROD = 'prod',
  SANDBOX = 'sandbox',
  INTERNAL_TEST = 'internal test',
  CUSTOMER_TEST = 'customer test',
  DEMO = 'demo',
  DEV = 'dev',
}
