import { useEffect } from 'react';

const errorCodeDescriptionMap = {
  // Error code when `UnauthorizedError` is used in an auth0 rule callback.
  unauthorized: {
    user_not_invited:
      'You are not yet invited. Please contact your Tackle Administrator.',
    already_registered: "You're already registered. Please log in.",
  },
  // Error code when `Error` is used in a auth0 rule callback or
  // when `api.access.deny` is used in an auth0 action.
  access_denied: {
    user_not_invited:
      'You are not yet invited. Please contact your Tackle Administrator.',
    multiple_user_profiles_found: `We could not link this account because we found \
        multiple user profiles for this user.`,
    error_linking_azure_ad_user: `We could not link your Azure AD account.`,
    sso_connection_not_found: `SSO Connection not found. Please contact your IT administrator.`,
    internal_error: `Something went wrong. Please try again.`,
    sso_connection_required: {
      okta: 'Please log in through your Okta Dashboard.',
    },
  },
};

export const translateErrorToHumanReadable = (code, description) => {
  const unmappedErrorMessage = `${code}: ${description}`;
  const [desc, idp] = description.split(':');
  const errorMesage =
    errorCodeDescriptionMap[code]?.[desc] ?? unmappedErrorMessage;
  return errorMesage?.[idp] || errorMesage;
};

const useErrorNotification = ({ code, description }, notifierFn): void => {
  useEffect(() => {
    if (code && description) {
      notifierFn({
        msg: translateErrorToHumanReadable(code, description),
        type: 'error',
      });
    }
  }, [code, description, notifierFn]);
};

export default useErrorNotification;
