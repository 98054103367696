import { DateTime } from 'luxon';

import { Content } from 'stores/typings';
import { reportError } from 'utils/monitor';

// CachedState - LocalStorage instance of data
export interface CachedState {
  lastModified: DateTime | null;
  data: Content;
}

export const getLocalStorageState = (storageKey: string): CachedState => {
  const localData = localStorage.getItem(storageKey);
  const localLastModified = localStorage.getItem(`${storageKey}-last-modified`);

  let data: CachedState['data'] = [];
  if (localData) data = JSON.parse(localData);

  let lastModified: CachedState['lastModified'] = null;
  if (localLastModified) {
    lastModified = DateTime.fromISO(localLastModified, { zone: 'utc' });
  }

  return { data, lastModified } as CachedState;
};

export const setLocalStorageState = (
  storageKey: string,
  data: CachedState['data'],
): void => {
  const lastModified: CachedState['lastModified'] = DateTime.utc();
  try {
    localStorage.setItem(storageKey, JSON.stringify(data));
    localStorage.setItem(
      `${storageKey}-last-modified`,
      lastModified.toString(),
    );
  } catch (e) {
    if (e.name !== 'QuotaExceededError') reportError(e);
  }
};
