import React from 'react';
import { ClickAwayListener } from 'vendor/material';
import { truncate } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { Dropdown, ActionList, ActionListOption } from '@tackle-io/platform-ui';

const FilterMarketplace: React.FC = () => {
  // HOOKS
  const [anchorEl, setAnchorEl] = React.useState<Element>();
  const open = !!anchorEl;
  const history = useHistory();

  // MISC
  const searchParams = new URLSearchParams(history.location.search);

  const options = [
    {
      key: 'aws',
      text: 'AWS Marketplace',
      isSelected: searchParams.get('marketplace') === 'aws',
    },
    {
      key: 'gcp',
      text: 'Google Cloud Platform Marketplace',
      isSelected: searchParams.get('marketplace') === 'gcp',
    },
    {
      key: 'azure',
      text: 'Microsoft Azure Marketplace',
      isSelected: searchParams.get('marketplace') === 'azure',
    },
    {
      key: 'redhat',
      text: 'Red Hat Marketplace',
      isSelected: searchParams.get('marketplace') === 'redhat',
    },
  ];

  options.unshift({
    key: '',
    text: 'All Marketplaces',
    isSelected: false,
  });

  // EVENTS
  const handleChange = (item: ActionListOption): void => {
    if (searchParams.has('marketplace')) {
      searchParams.set('marketplace', item.key);
    } else {
      searchParams.append('marketplace', item.key);
    }

    history.push({
      search: searchParams.toString(),
    });
  };

  const handleClick = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    // setting to null will close list
    setAnchorEl(undefined);
  };

  // RENDERS
  const selectedLabel = options.filter((option) => option.isSelected)[0];

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Dropdown
            value={selectedLabel && truncate(selectedLabel.text)}
            onClick={handleClick}
            placeholder="Marketplace"
            fullWidth
          />
        </div>
      </ClickAwayListener>
      <ActionList
        id="dropdown-list"
        anchorEl={anchorEl}
        open={open}
        onSelect={handleChange}
        onClose={handleClose}
        items={options}
      />
    </>
  );
};

export default FilterMarketplace;
