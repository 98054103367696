import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  fieldKey: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  fieldAction: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttonIcon: {
    color: theme.palette.NEUTRAL500,
  },
  awsDimensionFields: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  dimensionFields: {
    marginBottom: 16,
  },
  invoiceAmountContainer: {
    '& input': {
      textAlign: 'right',
    },
  },
  paymentNumberContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentNumber: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    marginTop: theme.spacing(1),
  },
  calendarContent: {
    marginBottom: theme.spacing(1.5),
  },
  calendarFooter: {
    padding: theme.spacing(1.5, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
  },
  paymentScheduleErrorContainer: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    display: 'flex',
    color: theme.palette.RED400,
    '& svg': {
      fontSize: theme.typography.pxToRem(16),
    },
    '& span': {
      paddingLeft: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(12),
    },
  },
  deleteIcon: {
    marginTop: theme.spacing(1.5),
  },
  firstPaymentLabel: {
    '& svg': {
      color: theme.palette.NEUTRAL300,
      fontSize: theme.typography.pxToRem(20),
      marginTop: theme.spacing(0.25),
      marginLeft: theme.spacing(0.625),
    },
    '& input': {
      paddingLeft: theme.spacing(5),
    },
  },
}));

export default useStyles;
