export enum AceOpportunityClosedLostReasonEnum {
  CUSTOMER_DEFICIENCY = 'Customer Deficiency',
  DELAY_CANCELLATION_OF_PROJECT = 'Delay / Cancellation of Project',
  LEGAL_TAX_REGULATORY = 'Legal / Tax / Regulatory',
  LOST_TO_COMPETITOR_GOOGLE = 'Lost to Competitor – Google',
  LOST_TO_COMPETITOR_MICROSOFT = 'Lost to Competitor – Microsoft',
  LOST_TO_COMPETITOR_SOFTLAYER = 'Lost to Competitor – SoftLayer',
  LOST_TO_COMPETITOR_VMWARE = 'Lost to Competitor – VMWare',
  LOST_TO_COMPETITOR_OTHER = 'Lost to Competitor – Other',
  NO_OPPORTUNITY = 'No Opportunity',
  ON_PREMISES_DEPLOYMENT = 'On Premises Deployment',
  PARTNER_GAP = 'Partner Gap',
  PRICE = 'Price',
  SECURITY_COMPLIANCE = 'Security / Compliance',
  TECHNICAL_LIMITATIONS = 'Technical Limitations',
  CUSTOMER_EXPERIENCE = 'Customer Experience',
  OTHER = 'Other',
  PEOPLE_RELATIONSHIP_GOVERNANCE = 'People/Relationship/Governance',
  PRODUCT_TECHNOLOGY = 'Product/Technology',
  FINANCIAL_COMMERCIAL = 'Financial/Commercial',
}
