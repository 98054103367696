import React from 'react';
import { Tag } from '../Tag';
import {
  AceOpportunityReviewStatusEnum,
  TagColorEnum,
} from '../../types/enums';

const statusToColorMapping: {
  [key in AceOpportunityReviewStatusEnum]: TagColorEnum;
} = {
  [AceOpportunityReviewStatusEnum.SUBMITTED]: TagColorEnum.STANDARD,
  [AceOpportunityReviewStatusEnum.IN_REVIEW]: TagColorEnum.STANDARD,
  [AceOpportunityReviewStatusEnum.APPROVED]: TagColorEnum.STANDARD,
  [AceOpportunityReviewStatusEnum.PENDING_SUBMISSION]: TagColorEnum.STANDARD,
  [AceOpportunityReviewStatusEnum.REJECTED]: TagColorEnum.RED,
  [AceOpportunityReviewStatusEnum.ACTION_REQUIRED]: TagColorEnum.YELLOW,
};

interface AwsCoSellStatusTagProps {
  status: AceOpportunityReviewStatusEnum;
}

export const AwsCoSellStatusTag = (props: AwsCoSellStatusTagProps) => {
  return <Tag color={statusToColorMapping[props.status]}>{props.status}</Tag>;
};
