import { decorate, observable, action, computed } from 'mobx';
import { groupBy, sortBy } from 'lodash/fp';
import api from 'utils/api';
import Store from './Store';
import { CASReport, CASReportGroup } from '../models';

class CASStore extends Store {
  _reportsByName = {};

  get reportsByName() {
    return this._reportsByName;
  }

  set reportsByName(reportsByName = {}) {
    this._reportsByName = reportsByName;
  }

  get reports() {
    return super.data;
  }

  set reports(reports = []) {
    super.data = reports;
  }

  load = (args) => {
    super.loading = true;

    const params = new URLSearchParams(args).toString();
    let url = 'v3/analytics/data/aws';
    url = params.length > 0 ? `${url}?${params}` : url;

    api
      .get(url)
      .json()
      .then(({ data: { cas = [] } }) => {
        const reports = cas.reduce(
          (arr, report) => arr.concat([new CASReport(report)]),
          [],
        );

        this.reports = sortBy(['name', 'frequency', 'loadStart'], reports);

        const groups = groupBy('name', this.reports);
        this.reportsByName = Object.keys(groups).reduce((obj, key) => {
          obj[key] = new CASReportGroup(groups[key]);
          return obj;
        }, {});

        super.loading = false;
      })
      .catch((error) => {
        super.error = error;
        super.loading = false;
      });
  };
}

decorate(CASStore, {
  reports: computed,
  _reportsByName: observable,
  reportsByName: computed,
  load: action,
});

export default CASStore;

const casStore = new CASStore();
export { casStore };
