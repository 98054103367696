export enum InvitationsTableColumnNames {
  CHECKBOX = 'CHECKBOX',
  PROJECT_NAME = 'PROJECT_NAME',
  CUSTOMER = 'CUSTOMER',
  INVITATION_STATUS = 'INVITATION_STATUS',
  DATE_RECEIVED = 'DATE_RECEIVED',
  EXPIRATION_DATE = 'EXPIRATION_DATE',
  SYNC_STATUS = 'SYNC_STATUS',
}

export const defaultInvitationColumnsObj = {
  CHECKBOX: null,
  PROJECT_NAME: null,
  CUSTOMER: null,
  INVITATION_STATUS: null,
  DATE_RECEIVED: null,
  EXPIRATION_DATE: null,
  SYNC_STATUS: null,
};

const SourceTypes = {
  OUTBOUND: 'Outbound',
  INBOUND: 'Inbound',
};

export const getSourceTooltip = (source: string) => {
  if (source === SourceTypes.OUTBOUND) {
    return 'Partner originated';
  } else if (source === SourceTypes.INBOUND) {
    return 'AWS originated';
  }
  return '';
};
