import { FC, createContext, useContext, useEffect } from 'react';
import { IntercomProvider } from 'react-use-intercom';

import { Intercom } from 'components/Intercom';
import { observer } from 'mobx-react-lite';
import { gql } from '@apollo/client';
import analyticsService, {
  analyticsServiceDisabled,
} from 'utils/analytics/amplitude';
import { useGetThirdPartyServicesIdentificationInfoQuery } from 'generated/graphql';
import { useAuth } from 'vendor/auth0';
import { isEnabled as ampliEnabled } from 'utils/analytics/amplitude';
import { CurrentUser, IAnalyticsService } from 'utils/analytics/types';
import { ampli } from 'utils/analytics/ampli';
import { GroupsEnrichmentPlugin } from 'utils/analytics/plugins';
import { Loader } from '@tackle-io/platform-ui';
import { omit } from 'lodash-es';

export const GET_THIRD_PARTY_SERVICES_IDENTIFICATION_INFO = gql`
  query getThirdPartyServicesIdentificationInfo {
    currentUser {
      id
      name
      email
      role
      organizationId
      vendor {
        id
        name
        vendor_type
      }
      intercomUserHash
    }
  }
`;

export type ThirdPartyServicesEnabledContextState = {
  isAmplitudeEnabled: boolean;
  currentUser: CurrentUser;
  analyticsService: IAnalyticsService;
};

export const ThirdPartyServicesEnabledContext =
  createContext<ThirdPartyServicesEnabledContextState>(undefined);

interface ThirdPartyServicesProviderEnabledProps {
  children: React.ReactElement;
}

const ThirdPartyServicesEnabledProvider: FC = ({
  children,
}: ThirdPartyServicesProviderEnabledProps) => {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth();
  const { data } = useGetThirdPartyServicesIdentificationInfoQuery({
    skip: !isAuthenticated || isAuthLoading,
  });
  const currentUserWithIntercomHash = data?.currentUser || null;
  const currentUser = data?.currentUser
    ? omit(data?.currentUser, 'intercomUserHash')
    : null;
  const vendorId = currentUser?.vendor?.id || '';
  const organizationId = currentUser?.organizationId || '';
  const isAmplitudeEnabled = ampliEnabled();

  useEffect(() => {
    if (isAmplitudeEnabled) {
      analyticsService.init({
        apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
      });
      ampli.client.add(GroupsEnrichmentPlugin({ vendorId, organizationId }));
    }
  }, [isAmplitudeEnabled, vendorId, organizationId]);

  useEffect(() => {
    if (isAmplitudeEnabled && currentUser) {
      analyticsService.identify(currentUser);
    }
  }, [isAmplitudeEnabled, currentUser]);

  if (isAuthLoading) return <Loader />;

  const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
  const ENABLE_INTERCOM = !!INTERCOM_APP_ID;

  return (
    <ThirdPartyServicesEnabledContext.Provider
      value={{
        isAmplitudeEnabled,
        currentUser,
        analyticsService: isAmplitudeEnabled
          ? analyticsService
          : analyticsServiceDisabled,
      }}
    >
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        shouldInitialize={ENABLE_INTERCOM}
        autoBoot={ENABLE_INTERCOM}
      >
        <Intercom currentUser={currentUserWithIntercomHash} />
        {children}
      </IntercomProvider>
    </ThirdPartyServicesEnabledContext.Provider>
  );
};

interface ThirdPartyServicesProviderProps {
  enabled?: boolean;
  children: React.ReactElement;
}

const ThirdPartyServicesProvider: FC<ThirdPartyServicesProviderProps> =
  observer(({ children, enabled }) => {
    if (enabled) {
      return (
        <ThirdPartyServicesEnabledProvider>
          {children}
        </ThirdPartyServicesEnabledProvider>
      );
    } else {
      return <>{children}</>;
    }
  });

export function useAnalytics() {
  const context = useContext(ThirdPartyServicesEnabledContext);
  if (context === undefined) {
    throw new Error(
      'useAnalytics must be used within a ThirdPartyServicesEnabledProvider',
    );
  }
  return context;
}

export default ThirdPartyServicesProvider;
