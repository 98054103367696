import useStyles from './TableRowCell.styles';
import classnames from 'classnames';
import { useRef } from 'react';
import { OpportunitiesTableColumnNames } from '../../utils';
import type { ColumnWidths } from '../../types';

interface TableRowCellProps {
  children?: string | number | Date | React.ReactElement;
  className?: string;
  columnName: OpportunitiesTableColumnNames;
  columnWidths: ColumnWidths;
}

export const TableRowCell: React.FC<TableRowCellProps> = ({
  className,
  children,
  columnName,
  columnWidths,
}) => {
  const classes = useStyles();
  // This can be referenced in order to control whether the child components should be rendered before or after the text
  const tableCellRef = useRef<HTMLDivElement>(null);

  const getColumnWidth = (
    columnObj: typeof columnWidths,
    columnName: OpportunitiesTableColumnNames,
  ) => {
    const columnWidth = columnObj[columnName];
    return columnWidth;
  };

  const shouldAlignRight = (
    columnName: OpportunitiesTableColumnNames,
  ): boolean => {
    const rightAlignmentColumns = [OpportunitiesTableColumnNames.SYNC_STATUS];
    return rightAlignmentColumns.includes(columnName);
  };

  return (
    <div
      className={classnames(
        classes.truncateText,
        {
          [classes.tableRowItem]: !shouldAlignRight(columnName),
          [classes.alignRight]: shouldAlignRight(columnName),
        },
        className,
      )}
      style={{
        width: getColumnWidth(columnWidths, columnName) ?? undefined,
      }}
      ref={tableCellRef}
      role={'gridcell'}
      aria-labelledby={columnName}
    >
      <div>{children}</div>
    </div>
  );
};
