export const ContractsIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    height="1em"
    width="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M19 5.27808V19.2781H5V5.27808H19ZM21 3.27808H3V21.2781H21V3.27808ZM17 17.2781H7V16.2781H17V17.2781ZM17 15.2781H7V14.2781H17V15.2781ZM17 12.2781H7V7.27808H17V12.2781Z"
      fill="currentColor"
    />
  </svg>
);
