import { GenerateId, Rule, StyleSheet } from 'jss';

const productionClassPrefix = 'jss';

const lowerCasedClassNamesToExcludeFromMinification = ['errorcontainer'];

const ruleKeyIsExcludedFromMinification = (ruleKey: string): boolean => {
  const lowerCasedRuleKey = ruleKey.toLowerCase();

  return lowerCasedClassNamesToExcludeFromMinification.some(
    (className: string) => lowerCasedRuleKey.includes(className),
  );
};

export enum Environment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export const createClassNameGenerator = (env: Environment): GenerateId => {
  const isDevelopmentEnvironment = env === Environment.DEVELOPMENT;
  let count = 0;

  return (rule: Rule, sheet?: StyleSheet<string>): string => {
    const doNotMinifyClassName =
      isDevelopmentEnvironment || ruleKeyIsExcludedFromMinification(rule.key);

    return doNotMinifyClassName
      ? `${sheet?.options.classNamePrefix}-${rule.key}-${count++}`
      : `${productionClassPrefix}${count++}`;
  };
};
