import { makeStyles } from 'vendor/material';
import { fontWeightRegular } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
  },
  fdaFieldsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  calendarContent: {
    marginBottom: theme.spacing(1.5),
  },
  checkboxLabel: {
    color: theme.palette.NEUTRAL600,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: fontWeightRegular,
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
    userSelect: 'none',
  },
  dateSelectFormControl: {
    '& button': {
      paddingTop: '9px',
      paddingBottom: '9px',
      '&.selected': {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
  },
  calendarFooter: {
    padding: theme.spacing(1.5, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
  },
}));

export default useStyles;
