import { useRef, useEffect } from 'react';

function useDocumentTitle(title, prevailOnUnmount = false): void {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${title} - Tackle`;
  }, [title]);

  useEffect(
    () => (): void => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [prevailOnUnmount],
  );
}

export default useDocumentTitle;
