import React, { useEffect } from 'react';
import { useAuth } from 'vendor/auth0';
import { Redirect } from 'react-router-dom';

const Logout = () => {
  const { isLoading, isAuthenticated, logout } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      logout({
        returnTo: window.location.origin,
      });
    }
  }, [isAuthenticated, logout]);

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return null;
};

export default Logout;
