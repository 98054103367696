import { decorate, observable, computed, action } from 'mobx';
import { assign } from 'lodash-es';

import api from 'utils/api';

class User {
  id = null;

  name = '';

  email = '';

  metadata = {};

  loading = false;

  error = null;

  emailVerified = false;

  constructor(props) {
    const {
      app_metadata: metadata,
      email_verified: emailVerified,
      logins_count: loginsCount,
    } = props;

    let id = null;

    if (props.userid) {
      id = props.userid;
      delete props.userid;
    } else if (props.user_id) {
      id = props.user_id;
      delete props.user_id;
    }

    delete props.id;
    delete props.app_metadata;

    let name = '';
    if (props.profile) {
      name = props.profile.name;
    }

    let email = '';
    if (props.profile) {
      email = props.profile.email;
    }

    assign(this, {
      id,
      name,
      email,
      metadata,
      emailVerified,
      loginsCount,
      ...props,
    });
  }

  get role() {
    return this.metadata.role;
  }

  get pendingInvitation() {
    return !this.loginsCount || this.loginsCount < 1;
  }

  get displayName() {
    const { email, name, nickname } = this;
    const displayName = name === email ? nickname : name;
    return displayName;
  }

  setLoading = (loading) => {
    this.loading = loading;
  };

  setError = (error) => {
    this.error = error;
  };

  sync = ({ app_metadata }) => {
    assign(this, { metadata: app_metadata });
  };

  constructUrl() {
    let pathUserID = this.id;
    let url = `v1/vendor/users/`;

    // When a user ID contains a forward slash ('/') or a plus sign ('+'), it can cause issues.
    // This logic ensures that user IDs with slashes or plus signs are correctly handled by
    // appending them as query parameters instead of part of the path and encoding when needed..
    if (this.id.includes('/') || this.id.includes('+')) {
      pathUserID = 'user_id';
      url += pathUserID + '?user_id=' + encodeURIComponent(this.id);
    } else {
      // For user IDs without slashes, append the ID directly to the URL path.
      url += this.id;
    }

    return url;
  }

  update(json) {
    this.setLoading(true);

    const url = this.constructUrl();

    return api
      .put(url, {
        json,
      })
      .json()
      .then(({ user }) => {
        this.sync(user);
        this.setLoading(false);
      })
      .catch((error) => {
        this.setError(error);
        this.setLoading(false);
      });
  }

  delete() {
    super.loading = true;

    const url = this.constructUrl();

    return api.delete(url, {}).catch((error) => {
      super.error = error;
      super.loading = false;
    });
  }
}

decorate(User, {
  id: observable,
  name: observable,
  email: observable,
  metadata: observable,
  emailVerified: observable,
  displayName: computed,
  sync: action,
  update: action,
  delete: action,
  setLoading: action,
  setError: action,
});

export default User;
