import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingLeft: theme.spacing(2.5),
    marginTop: 0,
    marginBottom: theme.spacing(0.5),
  },
}));

export default useStyles;
