import {
  convertValuesTo,
  convertKeysTo,
  stringToDateTime,
  dateTimeToString,
  camelCase,
  snakeCase,
} from 'stores/utils';

import emptyStringToNull from '../../../utils/emptyStringToNull';

import { AwsProduct, AwsProductJSON } from './AwsProduct';
import { GcpProduct, GcpProductJSON } from './GcpProduct';
import { AzureProduct, AzureProductJSON } from './AzureProduct';
import { RedhatProduct, RedhatProductJSON } from './RedhatProduct';

import { ListingType } from 'utils/listingTypes';
import { Cloud } from 'utils/cloudTypes';

export type { AwsProduct, AwsProductJSON } from './AwsProduct';
export type { GcpProduct, GcpProductJSON, GcpDimension } from './GcpProduct';
export type { AzureProduct, AzureProductJSON } from './AzureProduct';
export type { RedhatProduct, RedhatProductJSON } from './RedhatProduct';

export type Product = AwsProduct | GcpProduct | AzureProduct | RedhatProduct;
export type ProductJSON =
  | AwsProductJSON
  | GcpProductJSON
  | AzureProductJSON
  | RedhatProductJSON;

const datetimeKeys = ['sns_configuration.updated_at'];
const uncontrolledObjectValuesByKeys = [];
const uncontrolledObjectKeys = ['ECCN', 'ECCN2', 'UNSPSC'];

export const deserialize = (jsonProduct: ProductJSON): Product => {
  const prod = convertKeysTo(
    camelCase,
    uncontrolledObjectValuesByKeys,
    uncontrolledObjectKeys,
  )(jsonProduct);
  const convertedDateTimes = stringToDateTime(datetimeKeys, prod);

  return {
    ...prod,
    ...convertedDateTimes,
  } as Product;
};

export const serialize = (product: Partial<Product>): Partial<ProductJSON> => {
  const prod = convertKeysTo(
    snakeCase,
    uncontrolledObjectValuesByKeys,
    uncontrolledObjectKeys,
  )(product);
  const convertedDateTimes = dateTimeToString(datetimeKeys, prod);

  const serializedProduct = convertValuesTo(emptyStringToNull)({
    ...prod,
    ...convertedDateTimes,
  });

  return serializedProduct as Partial<ProductJSON>;
};

export const isAwsProduct = (product: Product): boolean => {
  return product.cloud === Cloud.Aws;
};

export const isGcpProduct = (product: Product): boolean => {
  return product.cloud === Cloud.Gcp;
};

export const isAzureProduct = (product: Product): boolean => {
  return product.cloud === Cloud.Azure;
};

export const isRedhatProduct = (product: Product): boolean => {
  return product.cloud === Cloud.Redhat;
};

export const isAwsAmiHourlyAnnualProduct = (product: Product): boolean => {
  return (
    product.cloud === Cloud.Aws &&
    product.listingType === ListingType.AmiHourlyAnnual
  );
};
