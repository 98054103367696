import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '32px',
    width: '32px',
  },
  loadingAnimation: {
    height: '32px',
    width: '32px',
    borderRadius: '4px',
    backgroundColor: theme.palette.NEUTRAL030,
  },
  iconBlock: {
    height: '32px',
    width: '32px',
    borderRadius: '4px',
    paddingTop: '2px',
    paddingLeft: '1px',
  },
  twoLetterAcronym: {
    fontSize: '20px',
    paddingTop: '5px',
  },
  darkBlock: {
    backgroundColor: '#23263B',
    color: theme.palette.NEUTRAL000,
  },
  lightBlock: {
    backgroundColor: theme.palette.NEUTRAL050,
    color: theme.palette.NEUTRAL000,
  },
  vendorProfile: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    borderTop: `1px solid #111432`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#111432',
    },
  },
  menuItemIcon: {
    paddingLeft: 0,
  },
  menu: {
    borderRadius: '8px',
    backgroundColor: '#23263B',
    width: '320px',
    paddingTop: '8px',
    marginLeft: '4px',
  },
  vendorProfileCollapsed: {
    justifyContent: 'center',
  },
  vendorEnvironmentName: {
    color: theme.palette.NEUTRAL000,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    marginRight: '2px',
    textOverflow: 'ellipsis',
    width: '126px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tagContainer: {
    display: 'flex',
    margin: '4px 0',
  },
  CTA: {
    color: theme.palette.NEUTRAL050,
    fontSize: theme.typography.pxToRem(12),
  },
  popoverMenu: {
    position: 'relative',
    left: '20px',
  },
  skeleton: {
    backgroundColor: theme.palette.NEUTRAL300,
  },
}));

export default useStyles;
