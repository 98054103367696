import { Box, Typography } from 'vendor/material';
import useStyles from './TableError.styles';
import { CONTACT_SUPPORT_URL } from 'utils/constants';
import { Link } from 'components/Link';
import { GeneralErrorStateIcon } from 'packages/cosell/assets/GeneralErrorStateIcon';

export const TableError = () => {
  const classes = useStyles();
  return (
    <Box className={classes.errorTable}>
      <GeneralErrorStateIcon className="" />
      <Typography className={classes.errorHeader}>
        Something's wrong.
      </Typography>
      <Typography component={'p'} className={classes.errorDescription}>
        Could not load opportunities. Please try refreshing the page.{' '}
        <Link href={CONTACT_SUPPORT_URL} external>
          Contact Tackle support
        </Link>{' '}
        if the problem persists.
      </Typography>
    </Box>
  );
};
