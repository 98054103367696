import { fontWeightBold } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  currencyFormField: {
    fontWeight: fontWeightBold,
    paddingLeft: '35px !important',
  },
  formLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
