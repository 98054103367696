export enum ListingType {
  Saas = 'saas',
  Ccp = 'ccp',
  AmiHourlyAnnual = 'ami-hourly-annual',
  AmiContract = 'ami-contract',
  FlatRate = 'flat_rate',
  Standard = 'standard',
}

export type ListingTypeType =
  | ListingType.Saas
  | ListingType.Ccp
  | ListingType.AmiHourlyAnnual
  | ListingType.AmiContract
  | ListingType.FlatRate
  | ListingType.Standard;

export function isListingType(listingType: ListingTypeType): boolean {
  return Object.values(ListingType).indexOf(listingType) !== -1;
}

export function isSaas(listingType: string): boolean {
  return listingType === ListingType.Saas;
}

export function isCcp(listingType: string): boolean {
  return listingType === ListingType.Ccp;
}

export function isAmiHourlyAnnual(listingType: string): boolean {
  return listingType === ListingType.AmiHourlyAnnual;
}

export function isAmiContract(listingType: string): boolean {
  return listingType === ListingType.AmiContract;
}

export function isFlatRate(listingType: string): boolean {
  return listingType === ListingType.FlatRate;
}

export function isStandard(listingType: string): boolean {
  return listingType === ListingType.Standard;
}

export function isAmiListing(listingType: string): boolean {
  return (
    listingType === ListingType.AmiHourlyAnnual ||
    listingType === ListingType.AmiContract
  );
}
