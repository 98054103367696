import { CompanyAvatar, Tag } from '@tackle-io/platform-ui';

import {
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  Icon,
  Skeleton,
} from 'vendor/material';

import { CheckCircle } from 'mdi-material-ui';

// ASSETS
import useStyles from './AccountList.styles';

// HOOKS

import { pendoIdGenerator } from 'utils/idGenerator';
import { accountSwitcherElements } from 'utils/pendoEnums';

const LoadingAccountListItem = () => (
  <ListItem>
    <ListItemAvatar>
      <Skeleton variant="circle" height="40px" width="40px" />
    </ListItemAvatar>
    <ListItemText primary={<Skeleton width="75%" variant="text" />} />
  </ListItem>
);

type Account = { id: string; name?: string; logo_url?: string };

interface AccountListItemProps {
  account: Account;
  active: boolean;
  loading: boolean;
  onClick: (item: Account) => void;
  failed: boolean;
  succeeded: boolean;
}

type AccountListItemRightProps = Pick<
  AccountListItemProps,
  'active' | 'loading' | 'failed' | 'succeeded'
>;

const ListItemRight = ({
  loading,
  active,
  failed,
  succeeded,
}: AccountListItemRightProps) => {
  const classes = useStyles();

  if (active) return <Tag>Active</Tag>;
  if (loading) return <CircularProgress size={24} />;
  if (failed) {
    return (
      <Typography className={classes.errorText}>Failed. Retry?</Typography>
    );
  }

  if (succeeded) {
    return (
      <Icon
        className={classes.successIcon}
        data-testid="account-item-success-icon"
      >
        <CheckCircle />
      </Icon>
    );
  }

  return null;
};

const AccountListItem = ({
  account,
  active,
  loading,
  failed,
  onClick,
  succeeded,
}: AccountListItemProps) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!active && !loading) {
      onClick(account);
    }
  };

  return (
    <div
      id={pendoIdGenerator(
        accountSwitcherElements.ACCOUNT_SWITCH_SELECT_ACCOUNT,
      )}
      data-testid="account-list-item"
    >
      <ListItem
        // @ts-ignore
        button={!active}
        onClick={handleClick}
        selected={active}
        disableRipple
      >
        <ListItemAvatar>
          <CompanyAvatar
            logo={account.logo_url}
            alt={account.name}
            className={classes.accountAvatar}
          />
        </ListItemAvatar>
        <ListItemText primary={account.name} />
        <ListItemRight
          active={active}
          loading={loading}
          failed={failed}
          succeeded={succeeded}
        />
      </ListItem>
    </div>
  );
};

export { LoadingAccountListItem };
export default AccountListItem;
