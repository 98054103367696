const ChecklistIcon = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4315 7L10.9461 3.5L12.3402 2.1L14.4315 4.2L18.6141 0L20.0083 1.4L14.4315 7ZM8.95435 3H-0.00830078V5H8.95435V3ZM18.9129 9.4L17.5187 8L14.9295 10.6L12.3402 8L10.9461 9.4L13.5353 12L10.9461 14.6L12.3402 16L14.9295 13.4L17.5187 16L18.9129 14.6L16.3236 12L18.9129 9.4ZM8.95435 11H-0.00830078V13H8.95435V11Z"
      fill="#253858"
    />
  </svg>
);
export default ChecklistIcon;
