export type RoleAreaPermission =
  | 'cosell:*'
  | 'integrations:*'
  | 'listings:*'
  | 'metering:*'
  | 'offers:*'
  | 'payments:*'
  | 'prospect:*'
  | 'settings:*';
export type RoleAllPermissions = '*';
export const AREA_PERMISSION_COSELL: RoleAreaPermission = 'cosell:*';
export const AREA_PERMISSION_INTEGRATIONS: RoleAreaPermission =
  'integrations:*';
export const AREA_PERMISSION_LISTINGS: RoleAreaPermission = 'listings:*';
export const AREA_PERMISSION_METERING: RoleAreaPermission = 'metering:*';
export const AREA_PERMISSION_OFFERS: RoleAreaPermission = 'offers:*';
export const AREA_PERMISSION_PAYMENTS: RoleAreaPermission = 'payments:*';
export const AREA_PERMISSION_PROSPECT: RoleAreaPermission = 'prospect:*';
export const AREA_PERMISSION_SETTINGS: RoleAreaPermission = 'settings:*';
export const AREA_PERMISSION_ALL: RoleAllPermissions = '*';

export type UserRolePermissions = Array<string>;

export const hasPermission = (
  requiredPermission: RoleAreaPermission | RoleAllPermissions,
  userPermissions: UserRolePermissions,
): boolean => {
  if (!userPermissions) {
    return false;
  }
  if (
    userPermissions.includes(AREA_PERMISSION_ALL) ||
    userPermissions.includes(requiredPermission)
  ) {
    return true;
  }
  return false;
};
