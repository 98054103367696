import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme, ClickAwayListener, useMediaQuery } from 'vendor/material';
import { find, map, truncate } from 'lodash/fp';
import { Dropdown, ActionList, ActionListOption } from '@tackle-io/platform-ui';

// HELPERS
import { Product } from 'stores/products/typings';
import useProducts from 'stores/products/hooks';

const FilterProductListing: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const screen1280 = useMediaQuery(theme.breakpoints.between(901, 1280));
  const truncateLabel = truncate({ length: screen1280 ? 15 : 30 });

  const { content: products } = useProducts();
  const searchParams = new URLSearchParams(history.location.search);
  const productRef = searchParams.get('productRef');

  let filteredProducts = products;

  if (searchParams.has('marketplace')) {
    if (searchParams.get('marketplace')) {
      filteredProducts = filteredProducts.filter(
        (product) => product.cloud === searchParams.get('marketplace'),
      );
    }
  }

  let options: ActionListOption[] = map(
    ({ productid, name, cloud }: Product) => ({
      key: productid,
      text: name,
      isSelected: productRef === productid,
    }),
    filteredProducts,
  );

  options.unshift({
    key: '',
    text: 'All',
    isSelected: false,
  });

  const selectedOption: ActionListOption | undefined = find(
    'isSelected',
    options,
  );

  // EVENTS
  const [anchorEl, setAnchorEl] = React.useState<Element>(null);
  const open = !!anchorEl;
  const handleClose = (): void => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent): void =>
    setAnchorEl(event.currentTarget);

  const handleChange = (item: ActionListOption): void => {
    if (productRef !== item.key) {
      searchParams.set('productRef', item.key);

      if (item.key) {
        const product = products.filter(
          (product) => item.key === product.productid,
        )[0];
        searchParams.set('marketplace', product.cloud);
      }

      history.push({ search: searchParams.toString() });
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Dropdown
            value={selectedOption && truncateLabel(selectedOption.text)}
            onClick={handleClick}
            placeholder="Product listing"
            fullWidth
          />
        </div>
      </ClickAwayListener>
      <ActionList
        id="dropdown-list"
        anchorEl={anchorEl}
        open={open}
        onSelect={handleChange}
        onClose={handleClose}
        items={options}
      />
    </>
  );
};

export default FilterProductListing;
