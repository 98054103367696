import { DocsGlobalHelp, DocsGlobalHelpProps } from '@tackle-io/platform-ui';
import { pendoIdGenerator } from 'utils/idGenerator';
import { appUIElements } from 'utils/pendoEnums';

function GlobalHelp({ iconColor }: DocsGlobalHelpProps) {
  return (
    <DocsGlobalHelp
      iconColor={iconColor}
      id={pendoIdGenerator(appUIElements.HELP_CENTER)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: -30,
        horizontal: 'right',
      }}
    />
  );
}

export { GlobalHelp };
