import React, { MouseEvent } from 'react';
import { Modal, Button } from '@tackle-io/platform-ui';
import { useStyles } from './EditModal.styles';
import { useFormikContext } from 'formik';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';
import { coSellDetailsPageDataId } from '../../utilities/intercomEnums';
interface EditModalProps {
  children: JSX.Element;
  onClose?: (event: MouseEvent) => void;
  onSubmit: (values: UnifiedOpportunityFormValues) => void;
  open?: boolean;
  title: string;
  submitLabel?: string;
  submitButtonLoading?: boolean;
}

export const EditModal: React.FC<EditModalProps> = ({
  children,
  onClose,
  onSubmit,
  submitLabel = 'Submit',
  open = false,
  title,
  submitButtonLoading,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext<UnifiedOpportunityFormValues>();

  return (
    <Modal
      data-testid={'cosell_confirm_modal'}
      title={title}
      width="medium"
      paperClasses={classes.modal}
      open={open}
      onClose={onClose}
      footerActions={
        <>
          <Button
            id={coSellDetailsPageDataId.CANCEL_EDIT_MODAL}
            appearance="secondary"
            variant="outlined"
            onClick={onClose}
            disabled={submitButtonLoading}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            id={coSellDetailsPageDataId.SUBMIT_EDIT_MODAL}
            appearance="primary"
            className={classes.submitButton}
            onClick={() => onSubmit(values)}
            loading={submitButtonLoading}
            disabled={submitButtonLoading}
          >
            {submitLabel}
          </Button>
        </>
      }
    >
      {children}
    </Modal>
  );
};
