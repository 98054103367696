import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { authStore } from 'stores/Auth';
import {
  PAGE_SIZE_KEY,
  SEARCH_KEY,
  FROM_KEY,
} from '../pages/CoSellLandingPage/helpers/urlParamsHelper';

const useOpportunitiesListQuery = () => {
  const [token, setToken] = useState<string>(null);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const q = searchParams.get(SEARCH_KEY);
  const pageSize = searchParams.get(PAGE_SIZE_KEY);
  const from = searchParams.get(FROM_KEY);

  useEffect(() => {
    async function getDownstreamToken() {
      const downstreamToken = await authStore.getToken();
      setToken(downstreamToken);
    }

    if (!token) {
      getDownstreamToken();
    }
  }, [token]);

  const authorizedCosellClient = coSellClient(token);

  const {
    data: opportunitiesList,
    error: opportunitiesListError,
    isLoading: isOpportunitiesListLoading,
  } = useQuery({
    queryKey: ['cosell-opportunities-list', 'ace', { q, pageSize, from }],
    queryFn: () => {
      return authorizedCosellClient.getOpportunitiesList({
        q,
        pageSize,
        from,
      });
    },
    enabled: !!authorizedCosellClient,
    // adjust this to a value that makes sense for this data
    staleTime: 30000,
  });

  return {
    opportunitiesList,
    opportunitiesListError,
    isOpportunitiesListLoading,
    total: opportunitiesList?.total,
    isNextPage: opportunitiesList?.isNextPage,
    currentPageCount: opportunitiesList?.currentPageCount,
    nextIndex: opportunitiesList?.nextIndex,
  };
};

export default useOpportunitiesListQuery;
