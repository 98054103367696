import { makeStyles } from 'vendor/material';

const useStyles = (size = 'lg') =>
  makeStyles((theme) => {
    const widthMax =
      size === 'fullwidth' ? null : theme.breakpoints.values[size];

    return {
      root: {
        width: '100%',
        maxWidth: widthMax,
        margin: '0 auto',
        padding: theme.spacing(0, 2, 2),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(0, 4, 4),
        },
      },
    };
  })();

export default useStyles;
