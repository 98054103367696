import { makeStyles } from 'vendor/material';
import { fontWeightRegular } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  tableRowItem: {
    fontWeight: fontWeightRegular,
    size: theme.typography.pxToRem(16),
    color: theme.palette.NEUTRAL900,
    '&:not(:last-child)': {
      paddingRight: theme.spacing(1.5),
    },
    alignSelf: 'start',
  },
  truncateText: {
    '& div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
}));

export default useStyles;
