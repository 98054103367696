import { Box, Grid, IconButton, Tooltip, useTheme } from 'vendor/material';
import { Card } from '@tackle-io/platform-ui';
import { ChevronDown, ChevronUp, HelpBox } from 'mdi-material-ui';
import React from 'react';
import { pendoIdGenerator } from 'utils/idGenerator';
import { cosellFormElements } from 'utils/pendoEnums';
import { useOpportunityFormStyles } from './UnifiedOpportunityForm.styles';

interface UnifiedOpportunityFormSectionProps {
  title: string;
  titleIcon?: React.ReactElement;
  tooltip?: string;
  expandable?: boolean;
  children: React.ReactNode;
}

export const UnifiedOpportunityFormSection = ({
  title,
  children,
  tooltip,
  expandable = false,
  titleIcon,
}: UnifiedOpportunityFormSectionProps): JSX.Element => {
  const classes = useOpportunityFormStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(true);

  const getHeaderAction = () => {
    if (!tooltip && !expandable) return null;

    return tooltip ? (
      <Tooltip title={tooltip} placement="top-start">
        <HelpBox color="action" />
      </Tooltip>
    ) : (
      <IconButton
        id={pendoIdGenerator(cosellFormElements.EXPAND_NEXT_STEPS_BUTTON)}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? <ChevronDown /> : <ChevronUp />}
      </IconButton>
    );
  };

  return (
    <div className={classes.formSection}>
      <Card
        title={
          <div
            style={{
              marginLeft: '16px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>{titleIcon}</div>
            <div style={{ marginLeft: '8px', display: 'flex' }}>{title}</div>
          </div>
        }
        bodyStyle={{ backgroundColor: theme.palette.NEUTRAL000 }}
        headerAction={getHeaderAction()}
      >
        {expanded || !expandable ? (
          <Box px={2} py={0}>
            <Grid container spacing={2}>
              {children}
            </Grid>
          </Box>
        ) : null}
      </Card>
    </div>
  );
};
