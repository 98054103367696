import React, { isValidElement } from 'react';
import { Box, Typography } from 'vendor/material';
import useStyles from './RightRailSubSection.style';
import { coSellDetailsPageDataId } from '../../utilities/intercomEnums';

interface RightRailSubSectionProps {
  title?: string;
  subTitle?: string;
  guidanceText: string | React.ReactNode;
  icon?: React.ReactNode;
  subIcon?: React.ReactNode;
}

const RightRailSubSection: React.FC<RightRailSubSectionProps> = ({
  title,
  subTitle,
  guidanceText,
  icon,
  subIcon,
}) => {
  const classes = useStyles();

  const iconName = isValidElement(icon)
    ? (icon.type as React.ComponentType).name
    : '';

  return (
    <Box className={classes.sectionBox}>
      {title && (
        <Box className={classes.titleBox}>
          <Typography variant="subtitle1" className={classes.titleText}>
            {title}
          </Typography>
          {icon && (
            <Box
              mr={1}
              data-id={`${coSellDetailsPageDataId.SYNC_STATUS_ICON}-${iconName}`}
            >
              {icon}
            </Box>
          )}
        </Box>
      )}
      {subTitle && (
        <Box className={classes.subTitleBox}>
          {subIcon && <Box mr={1}>{subIcon}</Box>}
          <Typography variant="subtitle2" className={classes.subTitleText}>
            {subTitle}
          </Typography>
        </Box>
      )}
      <Typography variant="body2" className={classes.bodyText}>
        {guidanceText}
      </Typography>
    </Box>
  );
};

export default RightRailSubSection;
