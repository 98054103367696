import { CSSProperties } from 'vendor/material';
import { ThemeTackle } from 'Theme';

const getToolbarHeight = (theme: ThemeTackle) =>
  `${
    (theme.mixins.toolbar[theme.breakpoints.up('sm')] as CSSProperties)
      .minHeight
  }px`;

export { getToolbarHeight };
