import React, { ChangeEvent, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { OffersDataId } from '../../utils/intercomEnums';
import { Select } from '@tackle-io/platform-ui';
import { CurrencyCode, getCurrencyResource } from '../../utils/currency';
import { currencyCodeFormPath } from './utils';
import { ampli } from '../../utils/analytics/ampli/index';
import {
  offersAmpliButtonLocationForEditMode,
  OffersAmpliFormControlNames,
  offersAmpliProductArea,
} from '../../pages/PrivateOffers/utils/ampliTrackingUtils';
import { EditMode } from '../../pages/PrivateOffers/utils/editMode';

interface FieldCurrencyCodeProps {
  marketplaceCurrencies: CurrencyCode[];
  hasUsageDimensions: boolean;
}

const fieldLabel = 'Currency';

const toCurrencySelectOption =
  (marketplaceCurrencies: CurrencyCode[]) => (cc: CurrencyCode) => {
    const optionText = getCurrencyResource(cc);
    const disabled =
      cc !== CurrencyCode.UnitedStatesDollar &&
      marketplaceCurrencies.length > 0 &&
      marketplaceCurrencies.indexOf(cc) === -1;

    return (
      <option key={cc} value={cc} disabled={disabled}>
        {optionText}
      </option>
    );
  };

const currencyCodes = Object.values(CurrencyCode);

const FieldCurrencyCode: React.FC<FieldCurrencyCodeProps & EditMode> = ({
  marketplaceCurrencies,
  hasUsageDimensions,
  editMode,
}) => {
  const { control, errors } = useFormContext();
  const currencyOptions = currencyCodes.map(
    toCurrencySelectOption(marketplaceCurrencies),
  );

  const fieldRenderer = useCallback(
    ({ ref, onChange, ...props }): React.ReactElement => (
      <Select
        {...props}
        id={currencyCodeFormPath}
        data-id={OffersDataId.PRIVATE_OFFERS_CURRENCY_FIELD}
        label={fieldLabel}
        defaultValue={CurrencyCode.UnitedStatesDollar}
        error={errors.pricing?.currencyCode?.message}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          const currencyCode =
            e.target?.value || CurrencyCode.UnitedStatesDollar;

          ampli.buttonClicked(
            {
              button_location: offersAmpliButtonLocationForEditMode[editMode],
              button_product_area: offersAmpliProductArea,
              button_name: OffersAmpliFormControlNames.SelectCurrency,
            },
            { extra: { currencyCode } },
          );

          onChange(currencyCode);
        }}
        disabled={hasUsageDimensions}
      >
        {currencyOptions}
      </Select>
    ),
    [
      errors.pricing?.currencyCode?.message,
      hasUsageDimensions,
      currencyOptions,
      editMode,
    ],
  );

  return (
    <Controller
      name={currencyCodeFormPath}
      control={control}
      render={fieldRenderer}
    />
  );
};

export default FieldCurrencyCode;
