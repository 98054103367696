import api, { omitAuthHeader } from 'utils/api';

import { useSession } from '../../../hooks';
import { SessionHook } from '../../../hooks/useSession';

type AppMetadata = {
  password_set: boolean;
};

type User = {
  email: string;
  app_metadata: AppMetadata;
};

const getCreatePasswordSession = async ({
  sessionId,
  onSuccess,
  onFailure,
  abortSignal,
}): Promise<void> => {
  try {
    const user: User = await api
      .get(`user/invite/${sessionId}`, {
        signal: abortSignal,
        ...omitAuthHeader(),
      })
      .json();

    const {
      app_metadata: { password_set: passwordAlreadyCreated },
      email,
    } = user;

    onSuccess({ passwordAlreadyCreated, email });
  } catch (exception) {
    onFailure(exception);
  }
};

type PasswordSession = {
  passwordAlreadyCreated: boolean;
  email: string;
};

type UsePasswordSessionProps = {
  sessionId: string;
};

type PasswordSessionHook = SessionHook<PasswordSession>;

const usePasswordSession = ({
  sessionId,
}: UsePasswordSessionProps): PasswordSessionHook => {
  return useSession<PasswordSession>({
    sessionId,
    getSessionById: getCreatePasswordSession,
  });
};

export default usePasswordSession;
