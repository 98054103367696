import { DateTime } from 'luxon';
import { floatToDinero } from 'utils/money';
import { PaymentScheduleFormData } from './PaymentScheduler';
import {
  PAYMENT_FREQUENCY_DIVISOR_MAP,
  PaymentFrequencyValue,
  PaymentFrequencyValueType,
} from './constants';

export interface PaymentScheduleRow {
  invoiceDate: DateTime;
  invoiceAmount: number;
}

/**
 * @param {number} totalContractValue value to split up into payments
 * @param {number} durationInMonths number of months to split payments over
 * @param {PaymentFrequencyValueType} paymentFrequency how often payments are made
 * @param {Date} firstPaymentDate date of first payment; other payment dates based off this date
 * @returns {PaymentScheduleRow[]} the data to populate the payment schedule section in the form
 * Generates payments that are mathematically as equal as possible, placing the remaining cents if
 * they exist on the last payment. The sum of all the payments will equal the total contract value.
 */
export function generatePaymentSchedule({
  totalContractValue,
  durationInMonths,
  paymentFrequency,
  firstPaymentDate,
}: PaymentScheduleFormData): PaymentScheduleRow[] {
  const numberOfPayments = calculateNumberOfPayments(
    durationInMonths,
    paymentFrequency,
  );

  // Using the 'rounding' value of 'DOWN' from dinero.js here in order to
  // truncate to two decimals--always rounding down--in order to generate
  // as equal as possible payments. Remaining cents will be added to the last payment.
  const payment = floatToDinero(totalContractValue, 2).divide(
    numberOfPayments,
    'DOWN',
  );
  const difference = payment.multiply(numberOfPayments - 1);
  const lastPayment = floatToDinero(totalContractValue, 2).subtract(difference);

  const firstPaymentDateTime = DateTime.fromISO(
    new Date(firstPaymentDate).toISOString(),
  ).toUTC();

  return [...Array(numberOfPayments)]
    .fill(
      {
        invoiceAmount: payment.toUnit(),
        invoiceDate: firstPaymentDateTime,
      },
      0,
      1,
    )
    .map((p, i) => {
      if (i === 0) return p;
      if (i === numberOfPayments - 1) {
        return {
          invoiceAmount: lastPayment.toUnit(),
          invoiceDate: getPaymentInvoiceDate(
            firstPaymentDateTime,
            paymentFrequency,
            i,
          ),
        };
      }
      return {
        invoiceAmount: payment.toUnit(),
        invoiceDate: getPaymentInvoiceDate(
          firstPaymentDateTime,
          paymentFrequency,
          i,
        ),
      };
    });
}

export function calculateNumberOfPayments(
  durationInMonths: number,
  paymentFrequency: PaymentFrequencyValueType,
): number {
  if (!durationInMonths || !paymentFrequency) return undefined;

  if (paymentFrequency === PaymentFrequencyValue.Monthly)
    return durationInMonths;

  const numberOfPayments =
    durationInMonths / PAYMENT_FREQUENCY_DIVISOR_MAP[paymentFrequency];
  return numberOfPayments < 1
    ? Math.ceil(numberOfPayments)
    : Math.round(numberOfPayments);
}

export function getPaymentInvoiceDate(
  currInvoiceDate: DateTime,
  paymentFrequency: PaymentFrequencyValueType,
  currPaymentIndex: number,
): DateTime {
  if (!currInvoiceDate || !paymentFrequency) return undefined;

  if (currPaymentIndex === 0) return currInvoiceDate;

  return currInvoiceDate.plus({
    months: currPaymentIndex * PAYMENT_FREQUENCY_DIVISOR_MAP[paymentFrequency],
  });
}

export function getPaymentFrequencyTextForSubtitle(
  paymentFrequency: PaymentFrequencyValueType,
): string {
  switch (paymentFrequency) {
    case PaymentFrequencyValue.Monthly:
      return 'monthly';
    case PaymentFrequencyValue.Annual:
      return 'annual';
    case PaymentFrequencyValue.Quarterly:
      return 'quarterly';
    case PaymentFrequencyValue.SemiAnnual:
      return 'semi-annual';
    default:
      return '';
  }
}

export function getTotalContractValueFromSchedule(
  schedule: PaymentScheduleRow[],
): number {
  return schedule.reduce(
    (acc, curr) =>
      floatToDinero(acc, 2).add(floatToDinero(curr.invoiceAmount, 2)).toUnit(),
    0,
  );
}
