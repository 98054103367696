import React from 'react';
import {
  AzurePricingType,
  AzurePricingTypeAndValue,
  BillingTermValue,
  DurationTypeValue,
  PaymentModelValue,
} from '../../../../../stores/privateOffers/typings';
import {
  AzurePricingPlanProductBillingTerm,
  AzureProductPaymentOption,
} from '../../../../../stores/products/typings/AzureProduct';
import { mapBillingTermShapeToBillingTermValue } from './azureBillingTermUtils';

export const paymentModelLabels: { [pm: string]: string } = {
  [PaymentModelValue.PaymentSchedule]: 'Payment schedule',
  [PaymentModelValue.OneTime]: 'One-time',
  [PaymentModelValue.PerMonth]: 'Per month',
  [PaymentModelValue.PerYear]: 'Per year',
};

export const getDefaultPaymentModel = (
  billingTerm: BillingTermValue,
): PaymentModelValue => {
  return billingTerm === BillingTermValue.OneMonth
    ? PaymentModelValue.OneTime
    : PaymentModelValue.PaymentSchedule;
};

const scheduleEnabledBillingTerms: Set<BillingTermValue> =
  new Set<BillingTermValue>([
    BillingTermValue.OneYear,
    BillingTermValue.TwoYears,
    BillingTermValue.ThreeYears,
  ]);

const paymentModelByPaymentOptionType: { [dt: string]: PaymentModelValue } = {
  [DurationTypeValue.Month]: PaymentModelValue.PerMonth,
  [DurationTypeValue.Year]: PaymentModelValue.PerYear,
};

const isOneTimePaymentOption = (
  billingTerm: AzurePricingPlanProductBillingTerm,
  paymentOption: AzureProductPaymentOption,
) => {
  return (
    billingTerm.value === paymentOption?.value &&
    billingTerm.type === paymentOption?.type
  );
};

const getConfiguredPaymentModel = (
  billingTerm: AzurePricingPlanProductBillingTerm,
): PaymentModelValue => {
  const { paymentOption } = billingTerm;

  return isOneTimePaymentOption(billingTerm, paymentOption)
    ? PaymentModelValue.OneTime
    : paymentModelByPaymentOptionType[paymentOption?.type];
};

const toPaymentModelOption = (pm: PaymentModelValue) => {
  return (
    <option key={pm} value={pm}>
      {paymentModelLabels[pm] ?? ''}
    </option>
  );
};

export const getPaymentModelOptions = (
  billingTerm: AzurePricingPlanProductBillingTerm | null,
): React.ReactElement[] => {
  if (!billingTerm) {
    return [];
  }

  const billingTermValue = mapBillingTermShapeToBillingTermValue(billingTerm);
  const canUseSchedules = scheduleEnabledBillingTerms.has(billingTermValue);
  const configuredPaymentModel = getConfiguredPaymentModel(billingTerm);
  const availablePaymentModels = canUseSchedules
    ? [PaymentModelValue.PaymentSchedule, configuredPaymentModel]
    : [configuredPaymentModel];

  return availablePaymentModels.map(toPaymentModelOption);
};

export const paymentModelToAzurePricingType: {
  [pm: string]: AzurePricingType;
} = {
  [PaymentModelValue.PaymentSchedule]: AzurePricingType.Flexible,
  [PaymentModelValue.PerMonth]: AzurePricingType.Month,
  [PaymentModelValue.PerYear]: AzurePricingType.Year,
};

export const getAbsolutePricePaymentOption = (
  billingTerm: AzurePricingTypeAndValue,
  paymentModel: PaymentModelValue,
): AzurePricingTypeAndValue => {
  const type =
    paymentModel === PaymentModelValue.OneTime
      ? billingTerm?.type
      : paymentModelToAzurePricingType[paymentModel];
  const value =
    paymentModel === PaymentModelValue.OneTime ? billingTerm.value : 1;

  return { type, value };
};
