import React from 'react';
import { TableCell, TableBody, TableRow } from 'vendor/material';
import { values, orderBy } from 'lodash-es';

// ASSETS
import useStyles from './EnhancedTableBody.styles';

const EnhancedTableBody = ({ data, order, orderColumn, page, rowsPerPage }) => {
  const classes = useStyles();

  // Isolate "children" for a proper sort
  const sortChildren = (item) => item[orderColumn].children;
  const sortedData = orderBy(data, sortChildren, [order]);

  const rows = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <TableBody>
      {rows.map((row, index) => {
        const rowArr = values(row);
        const rowKey = `row-${index}`;

        return (
          <TableRow hover tabIndex={-1} key={rowKey}>
            {rowArr.map((cell, idx) => {
              const key = `cell-${idx}`;

              return (
                <TableCell
                  key={`${rowKey}-${key}`}
                  align={cell.align ? cell.align : 'left'}
                  classes={{ root: classes.tableCellRoot }}
                  className={cell.className}
                >
                  {cell.children}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default EnhancedTableBody;
