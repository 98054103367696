import React, { useCallback } from 'react';
import { CircularProgress, Box, Typography } from 'vendor/material';
import { Alert } from 'mdi-material-ui';
import useStyles from './AwsMarketplacePricingLoading.styles';

interface AwsMarketplacePricingLoadingProps {
  isLoadingPricing: boolean;
  onLoadPricing: () => void;
}

const AwsMarketplacePricingLoading: React.FC<
  AwsMarketplacePricingLoadingProps
> = ({ isLoadingPricing, onLoadPricing }) => {
  const classes = useStyles();

  const loadAwsMarketplacePricingClicked = useCallback(() => {
    if (onLoadPricing) {
      onLoadPricing();
    }
  }, [onLoadPricing]);

  return (
    <Box mb={2}>
      <Typography variant="subtitle1">
        {isLoadingPricing && (
          <>
            <CircularProgress size={12} /> Loading AWS dimensions
          </>
        )}
        {!isLoadingPricing && (
          <>
            <Alert color="error" fontSize="inherit" /> Failed to load AWS
            dimensions
          </>
        )}
      </Typography>
      <Typography variant="caption">
        {isLoadingPricing && <Box mb={2} />}
        {!isLoadingPricing && (
          <>
            Please{' '}
            <span
              className={classes.link}
              onClick={loadAwsMarketplacePricingClicked}
            >
              click here
            </span>{' '}
            to try reloading. If that fails and the issue persists, please
            contact Tackle support or manually enter your dimensions.
          </>
        )}
      </Typography>
    </Box>
  );
};

export default AwsMarketplacePricingLoading;
