import { makeStyles } from 'vendor/material';

export const useStyles = makeStyles((theme) => ({
  offerTag: {
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(12),
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.3),
    '& span': {
      textAlign: 'center',
    },
    padding: `7px 8px`,
    borderRadius: '4px',
    background: '#EBECF0',
    width: 'fit-content',
  },
}));
