const buildMailTo = (email, subject) => {
  let mailTo = `mailto:${email}`;

  if (subject) {
    mailTo += `?subject=${encodeURIComponent(subject)}`;
  }

  return mailTo;
};

export default buildMailTo;
