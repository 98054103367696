import React from 'react';
import { Box } from 'vendor/material';
import { Select } from '@tackle-io/platform-ui';
import Pagination from '@tackle-io/platform-ui/components/Pagination/Pagination';

import useStyles from './PaginationControls.styles';

interface PaginationControlsProps {
  total: number;
  size: number;
  pageNumber: number;
  onPageSizeChange: (size: number) => void;
  onPageNumberChange: (page: number) => void;
  pageSizes?: number[];
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  total,
  size,
  pageNumber,
  onPageSizeChange,
  onPageNumberChange,
  pageSizes = [10, 30, 50, 100],
}) => {
  const classes = useStyles();

  const numberOfPages = Math.ceil(total / size);
  const lowerBoundary = total === 0 ? 0 : (pageNumber - 1) * size + 1;
  const upperBoundary =
    pageNumber === numberOfPages ? total : size * pageNumber;

  return (
    <div className={classes.pagingContainer}>
      <div className={classes.pagingDetails}>
        <Box width={75}>
          <Select
            labelHidden
            name="pageSize"
            onChange={({ target: { value } }): void => {
              onPageSizeChange(Number(value));
            }}
            defaultValue={`${size}`}
          >
            {pageSizes.map((s) => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <span className={classes.pageInfo}>
            {`Showing ${lowerBoundary} - ${upperBoundary} of `}
            <b>{total}</b>
          </span>
        </Box>
      </div>
      <Pagination
        numPages={numberOfPages}
        pageNumber={pageNumber}
        showPageNumbers={numberOfPages > 1}
        onChangePage={(_, page): void => {
          onPageNumberChange(page);
        }}
      />
    </div>
  );
};

export default PaginationControls;
