import { decorate, observable, computed } from 'mobx';
import { assign } from 'lodash-es';
import { toDateTime } from '../utils/dates';

const FREQUENCIES = {
  // available daily at 5:00pm for the previous day
  customer_subscriber_hourly_monthly_subscriptions: 'daily',
  customer_subscriber_annual_subscriptions: 'daily',
  daily_business_usage_by_instance_type: 'daily',
  daily_business_fees: 'daily',
  daily_business_free_trial_conversions: 'daily',
  daily_business_new_instances: 'daily',
  daily_business_new_product_subscribers: 'daily',
  daily_business_canceled_product_subscribers: 'daily',

  // avilable every 30 days
  disbursed_amount_by_product: 'monthly',
  disbursed_amount_by_instance_hours: 'monthly',
  disbursed_amount_by_customer_geo: 'monthly',
  disbursed_amount_by_age_of_uncollected_funds: 'monthly',
  disbursed_amount_by_age_of_disbursed_funds: 'monthly',

  // available on the 15th 5:00pm
  monthly_revenue_billing_and_revenue_data: 'fifteenth',
  monthly_revenue_annual_subscriptions: 'fifteenth',
  monthly_revenue_field_demonstration_usage: 'fifteenth',
  monthly_revenue_flexible_payment_schedule: 'fifteenth',
  sales_compensation_billed_revenue: 'fifteenth',
  us_sales_and_use_tax_records: 'fifteenth',
};

class CASReport {
  name = '';

  pubDate = null;

  loaded = false;

  loadStart = null;

  loadEnd = null;

  loadTotal = 0;

  failure = false;

  constructor(props) {
    let {
      data_set_name: name = '',
      pub_date: pubDate = null,
      loaded = false,
      load_start: loadStart = null,
      load_end: loadEnd = null,
      rows_loaded: loadTotal = 0,
      failure = false,
    } = props;

    pubDate = toDateTime(pubDate, 'Missing Pub Date');
    loadStart = toDateTime(loadStart, 'Missing Load Start Date');
    loadEnd = toDateTime(loadEnd, 'Missing Load End Date');

    assign(this, {
      name,
      pubDate,
      loaded,
      loadStart,
      loadEnd,
      loadTotal,
      failure,
    });
  }

  get frequency() {
    return FREQUENCIES[this.name];
  }
}

decorate(CASReport, {
  name: observable,
  frequency: computed,
  pubDate: observable,
  loaded: observable,
  loadStart: observable,
  loadEnd: observable,
  loadTotal: observable,
  failure: observable,
});

export default CASReport;
