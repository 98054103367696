import React from 'react';
import { Tag } from '../Tag';
import { TagColorEnum } from '../../types/enums';

export enum CoSellSyncStatus {
  SYNCED = 'Synced',
  SYNCING = 'Syncing',
  SYNC_ERROR = 'Sync Error',
}

export const getSyncStatus = ({
  pendingRequestIds,
  hasError,
}: {
  pendingRequestIds: string[] | null;
  hasError?: boolean;
}) => {
  if (pendingRequestIds?.length && hasError) {
    return CoSellSyncStatus.SYNC_ERROR;
  } else if (pendingRequestIds?.length && !hasError) {
    return CoSellSyncStatus.SYNCING;
  } else if (pendingRequestIds == null) {
    return CoSellSyncStatus.SYNCED;
  }
  return null;
};

const statusToColorMapping: { [key in CoSellSyncStatus]: TagColorEnum } = {
  [CoSellSyncStatus.SYNCED]: TagColorEnum.STANDARD,
  [CoSellSyncStatus.SYNCING]: TagColorEnum.STANDARD,
  [CoSellSyncStatus.SYNC_ERROR]: TagColorEnum.RED,
};

interface CoSellSyncStatusTagProps {
  status: CoSellSyncStatus;
}

export const CoSellSyncStatusTag = (props: CoSellSyncStatusTagProps) => {
  return <Tag color={statusToColorMapping[props.status]}>{props.status}</Tag>;
};
