import { OfferEditMode } from './editMode';

export const offersAmpliProductArea = 'offers';

export enum OffersAmpliButtonLocation {
  CreateOfferForm = 'create-offer-form',
  EditOfferForm = 'edit-offer-form',
  ViewOffer = 'view-offer',
  OfferDetailPage = 'offer-detail-page',
}

export const offersAmpliButtonLocationForEditMode = {
  [OfferEditMode.CREATING]: OffersAmpliButtonLocation.CreateOfferForm,
  [OfferEditMode.EDITING]: OffersAmpliButtonLocation.EditOfferForm,
};

export enum OffersAmpliFormControlNames {
  ReviewOffer = 'review-offer',
  AssociateOffer = 'associate-offer',
  AzurePlanSelection = 'azure-plan-selection',
  AzureBillingTermSelection = 'azure-billing-term-selection',
  AzurePaymentModelSelection = 'azure-payment-model-selection',
  UnassociateFromExistingMarketplaceOffer = 'unassociate-from-an-existing-marketplace-offer',
  AssociateToAnExistingMarketplaceOffer = 'associate-to-an-existing-marketplace-offer',
  ResendPurchaseInstructions = 'resend-purchase-instructions',
  SendPurchaseInstructions = 'send-purchase-instructions',
  SelectProduct = 'select-product',
  SelectCurrency = 'select-currency',
}
