import { Button } from '@tackle-io/platform-ui';
import { useFormikContext, setNestedObjectValues } from 'formik';
import { isEmpty } from 'lodash';
import { pendoIdGenerator } from 'utils/idGenerator';
import { cosellDraftFormButtonElements } from 'utils/pendoEnums';
import { useOpportunityFormStyles } from './UnifiedOpportunityForm.styles';
import { ActiveOpportunityFormActions } from './UnifiedOpportunityFormShell';
import { UnifiedOpportunityFormValues } from './UnifiedOpportunityFormValues';
import { useCoSellHistory } from '../../hooks';
import { AceOpportunityReviewStatusEnum } from '../../types/enums';
import { COSELL_PATH } from '../../utilities/constants';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';

interface UnifiedOpportunityFormButtonSectionProps {
  onSubmit: (values: UnifiedOpportunityFormValues) => Promise<void>;
  onSaveAsDraft?: (values: UnifiedOpportunityFormValues) => Promise<void>;
  onCancel: () => void;
  onDelete: () => void;
  opportunity: AceOpportunityResponse;
  activeOpportunityFormAction: ActiveOpportunityFormActions;
}

export const UnifiedOpportunityFormButtonSection = ({
  onSubmit,
  onSaveAsDraft,
  onCancel,
  onDelete,
  opportunity,
  activeOpportunityFormAction,
}: UnifiedOpportunityFormButtonSectionProps) => {
  const classes = useOpportunityFormStyles();
  const history = useCoSellHistory();
  const { values, dirty, validateForm, setTouched, submitForm } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const isDraft =
    opportunity?.lifeCycle?.reviewStatus ===
    AceOpportunityReviewStatusEnum.PENDING_SUBMISSION;
  const isSaveForDraftEnabled = isDraft || !opportunity?.identifier;

  const redirectToCoSell = () => {
    const pathHome = COSELL_PATH;
    history.push({
      pathname: pathHome,
      search: history.location.state?.coSellSearchParams,
    });
  };

  const handleCancel = () => {
    dirty ? onCancel() : redirectToCoSell();
  };

  const handleSubmit = async () => {
    const errors = await validateForm();
    // submitForm() is only meant to update submitCount so that we know to scroll to errors
    submitForm();
    if (isEmpty(errors)) {
      onSubmit(values);
    } else {
      setTouched(setNestedObjectValues(errors, true));
    }
  };

  const handleSaveDraft = () => {
    onSaveAsDraft(values);
  };

  return (
    <>
      <div className={classes.cosellFormButtonsContainer}>
        <div className={classes.cosellFormCancelButton}>
          {/* TODO: Use updated button colors */}
          <Button
            id={pendoIdGenerator(cosellDraftFormButtonElements.CANCEL)}
            type="button"
            size="medium"
            name="cancel"
            variant="outlined"
            appearance="secondary"
            onClick={handleCancel}
            disabled={!!activeOpportunityFormAction}
          >
            Cancel
          </Button>
        </div>
        <div className={classes.cosellFormButtonContainer}>
          {isSaveForDraftEnabled ? (
            <Button
              appearance="secondary"
              id={pendoIdGenerator(
                cosellDraftFormButtonElements.SAVE_DRAFT_AND_EXIT,
              )}
              name="draft"
              onClick={handleSaveDraft}
              size="medium"
              type="button"
              variant="outlined"
              loading={
                activeOpportunityFormAction ===
                ActiveOpportunityFormActions.SAVING_DRAFT
              }
              disabled={!!activeOpportunityFormAction}
            >
              Save draft
            </Button>
          ) : null}
        </div>
        <div className={classes.cosellFormButtonContainer}>
          <Button
            id={pendoIdGenerator(cosellDraftFormButtonElements.SUBMIT_TO_CLOUD)}
            type="button"
            size="medium"
            name="submit"
            variant="contained"
            appearance="primary"
            onClick={handleSubmit}
            loading={
              activeOpportunityFormAction ===
              ActiveOpportunityFormActions.SUBMITTING
            }
            disabled={!!activeOpportunityFormAction}
          >
            {opportunity?.partnerOpportunityIdentifier && !isDraft
              ? 'Update'
              : 'Submit to cloud'}
          </Button>
        </div>
      </div>
    </>
  );
};
