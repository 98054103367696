import { lastActivityAction, ActivitySlug } from '../pages/PrivateOffers/utils';

function isUnacceptedPrivateOffer(
  { privateOffer = false, cloudMetadata = {}, activity = [] },
  products = [],
) {
  // ignore normal contracts
  if (!privateOffer) return false;

  const { offer_id: offerId = null, product_code: productCode } = cloudMetadata;
  const product = products.find((p) => p.productid === productCode);
  // ignore contracts which do not have an associated product
  if (!product) return false;

  const isTackleAssociated = product.offers.find(
    (offer) => offer.offerType === 'private' && offer.offerid === offerId,
  );

  // ignore non-tackle-associated private offers
  if (!isTackleAssociated) return false;

  const { customer_reference_id: customerId = null } = cloudMetadata;
  // private offer placeholder
  if (offerId === customerId) return true;

  // verify privateOffer is not accepted
  const activitiesWithSlug = activity.map((a) => ({
    ...a,
    slug: a?.action,
  }));
  const { slug } = lastActivityAction(activitiesWithSlug);
  return slug !== ActivitySlug.BuyerAcceptedPrivateOffer;
}

export default isUnacceptedPrivateOffer;
