import { fontWeightRegular } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectionTitleWrapper: {
    padding: theme.spacing(2, 3),
  },
  mainTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  mainTitleText: {
    color: theme.palette.BLACK,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
  },
  subTitle: {
    color: theme.palette.NEUTRAL300,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default useStyles;
