import { useState } from 'react';
import { ampli } from 'utils/analytics/ampli';
import { gql } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { Box } from 'vendor/material';
import { string as yupString, object as yupObject } from 'yup';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  Modal,
  TextField,
  Select,
  UserPicker,
  Spinner,
} from '@tackle-io/platform-ui';

// ASSETS
import useStyles from './UserModal.styles';

// HELPERS
import { UserManagement } from '../../stores';
import { useStore } from '../../hooks';
import { useGetRolesForUserModalQuery } from 'generated/graphql';

export const modalTypes = { invite: 'INVITE', edit: 'EDIT', delete: 'DELETE' };

const schema = yupObject().shape({
  email: yupString().email().required(),
  role: yupString().required(),
});

export const GET_ROLES_FOR_USER_MODAL_QUERY = gql`
  query GetRolesForUserModal {
    roles {
      id
      name
      organization_id
    }
  }
`;

const UserModal = ({ active, type, userData, onClose, usersList }) => {
  const classes = useStyles();
  const userManagementStore = useStore(UserManagement);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isEdit = type === modalTypes.edit;
  const { data, loading: loadingRoles } = useGetRolesForUserModalQuery();
  const defaultEmail = active && userData && isEdit ? userData.email : '';
  const defaultRole = active && userData && isEdit ? userData.role : '';

  const { register, formState, errors, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: { email: defaultEmail, role: defaultRole },
    resolver: yupResolver(schema),
  });

  function handleClose() {
    setLoading(false);
    reset({ email: '', role: '' });
    onClose();
  }

  function handleError(err) {
    setLoading(false);
    setError(err);
  }

  const onSubmit = ({ email, role }) => {
    if (type === modalTypes.invite) {
      setLoading(true);
      setError(null);
      userManagementStore
        .inviteUsers([{ email, role }])
        .then((err) => {
          if (err) {
            handleError(err);
          } else {
            const [assignedRole] = data.roles.filter((r) => r.id === role);
            ampli.customRoleAssigned({
              organization_id: assignedRole?.organization_id,
              custom_role_id: assignedRole?.id,
              custom_role_name: assignedRole?.name,
            });
            handleClose();
          }
          setLoading(false);
        })
        .catch(handleError);
    }

    if (type === modalTypes.edit) {
      setLoading(true);
      userData
        .update({ app_metadata: { role } })
        .then(() => {
          const [assignedRole] = data.roles.filter((r) => r.id === role);
          ampli.customRoleAssigned({
            organization_id: assignedRole?.organization_id,
            custom_role_id: assignedRole?.id,
            custom_role_name: assignedRole?.name,
          });
          handleClose();
        })
        .catch(handleError);
    }
  };

  const modalTitle = isEdit ? 'Edit user' : 'New user';
  const modalSubmitText = isEdit ? 'Update' : 'Send invite';
  const fieldEmailLabel = isEdit ? 'User email' : 'Please enter the user email';
  const displayUserName = isEdit && userData;
  const submitDisabled =
    formState.isSubmitting || !formState.isValid || !formState.isDirty;

  // STYLES
  const cssPaperClasses = classnames({
    [classes.modalContainer]: usersList,
  });

  const cssForm = classnames({
    [classes.formContainer]: usersList,
  });

  return (
    <Modal
      title={<div className={classes.title}>{modalTitle}</div>}
      width="medium"
      open={active}
      onClose={handleClose}
      paperClasses={cssPaperClasses}
      footerActions={[
        <Box key="close" mr={1}>
          <Button appearance="primary" variant="text" onClick={handleClose}>
            Cancel
          </Button>
        </Box>,
        <Button
          appearance="primary"
          variant="text"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
          disabled={submitDisabled}
          data-testid="userSubmit"
          key="submit"
        >
          {modalSubmitText}
        </Button>,
      ]}
    >
      <div className={cssForm}>
        {error && (
          <Box my={2}>
            <Alert
              appearance="danger"
              title={`Failed to create a new user. ${error}`}
            />
          </Box>
        )}
        <form noValidate>
          <TextField
            id="userEmail"
            type="email"
            label={fieldEmailLabel}
            name="email"
            readOnly={isEdit}
            error={errors.email?.message}
            data-testid="userEmail"
            inputRef={register}
          />
          {displayUserName && (
            <Box mt={2}>
              <TextField
                label="Name"
                defaultValue={userData.displayName}
                disabled
              />
            </Box>
          )}
          <Box mt={2}>
            {loadingRoles ? (
              <Spinner type="ellipsis" />
            ) : (
              <Select
                id="userRole"
                data-id="tooltip-userModalRoleSelection"
                label="Select the user role"
                name="role"
                SelectProps={{ 'aria-label': 'Field_Type_Select' }}
                data-testid="userRole"
                inputRef={register}
              >
                {data.roles.map((role) => (
                  <option key={role.name} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Select>
            )}
          </Box>
        </form>
      </div>
      {usersList && (
        <div className={classes.userListContainer}>
          <UserPicker
            disabled={false}
            onClick={() => {}}
            users={usersList}
            selectedUserIds={[]}
            mode="status"
            listItemClasses={classes.userListItem}
          />
        </div>
      )}
    </Modal>
  );
};

export default observer(UserModal);
