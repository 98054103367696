import { useQuery } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { useEffect, useState } from 'react';
import { authStore } from 'stores/Auth';

const useInvitationsListQuery = () => {
  const [token, setToken] = useState<string>(null);

  useEffect(() => {
    async function getDownstreamToken() {
      const downstreamToken = await authStore.getToken();
      setToken(downstreamToken);
    }

    if (!token) {
      getDownstreamToken();
    }
  }, [token]);

  const authorizedCosellClient = coSellClient(token);

  const {
    data: invitationsList,
    error: invitationsListError,
    isLoading: isInvitationsListLoading,
  } = useQuery({
    queryKey: ['cosell-invitations-list', 'ace', {}],
    queryFn: () => {
      return authorizedCosellClient.getInvitationsList();
    },
    enabled: !!authorizedCosellClient,
    // adjust this to a value that makes sense for this data
    staleTime: 30000,
  });

  return {
    invitationsList,
    invitationsListError,
    isInvitationsListLoading,
    total: invitationsList?.total,
    isNextPage: invitationsList?.isNextPage,
    currentPageCount: invitationsList?.currentPageCount,
    nextIndex: invitationsList?.nextIndex,
  };
};

export default useInvitationsListQuery;
