import React from 'react';
import { ProviderIcon } from '@tackle-io/platform-ui';
import useStyles from './TextWithCloudIconPrefix.styles';

/**
 * Adds a cloud provider icon in a flexbox before the text
 */
export function TextWithCloudIconPrefix({
  children,
  icon: { fontSize = 'inherit', provider },
}: {
  children: React.ReactText;
  icon: {
    provider: typeof ProviderIcon['defaultProps']['provider'];
    fontSize?: typeof ProviderIcon['defaultProps']['fontSize'];
  };
}) {
  const { iconWrap, wrapper } = useStyles();
  return (
    <div className={wrapper}>
      <span className={iconWrap}>
        <ProviderIcon fontSize={fontSize} provider={provider} />
      </span>
      {children}
    </div>
  );
}
