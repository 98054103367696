import { makeStyles } from 'vendor/material';

export const useSalesforceCoSellWidgetCallToActionStyles = makeStyles(
  (theme) => ({
    callToActionContainer: {
      marginTop: '12px',
    },
    header: {
      fontSize: '16px',
      fontWeight: 600,
      textAlign: 'center',
      '& svg': {
        fill: theme.palette.YELLOW500,
        fontSize: '1.7em',
        verticalAlign: 'text-bottom',
      },
    },
    detail: {
      fontSize: '14px',
      textAlign: 'center',
    },
  }),
);
