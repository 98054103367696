import { makeStyles } from '../../../../vendor/material/index';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  helperTextContainer: {
    flex: '1 1 auto',
    '& p': {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.NEUTRAL300,
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      '&.Mui-disabled': {
        color: theme.palette.NEUTRAL300,
      },
    },
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: theme.palette.RED400,
      fontSize: theme.typography.pxToRem(16),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
      marginRight: 0,
    },
  },
}));

export default useStyles;
