import { ApolloError, gql } from '@apollo/client';
import {
  CosellDashboardQuery,
  useCosellDashboardQuery,
} from 'generated/graphql';
import { OpportunityResponse } from 'pages/CoSell/types/responses/OpportunityResponse';
import { createContext, Dispatch, SetStateAction, useState } from 'react';

interface CoSellContextProps {
  submittingOpportunityList: OpportunityResponse[];
  updateSubmittingList: Dispatch<SetStateAction<OpportunityResponse[]>>;
  gqlData?: CosellDashboardQuery;
  gqlQueryLoading?: boolean;
  gqlQueryError?: ApolloError;
}

export const CoSellContext = createContext<CoSellContextProps>({
  submittingOpportunityList: [],
  updateSubmittingList: () => {},
  gqlData: {},
  gqlQueryLoading: null,
  gqlQueryError: null,
});

export const AWS_CO_SELL_DASHBOARD_QUERY = gql`
  query CosellDashboard {
    currentUser {
      id
      vendor {
        id
        vendor_type
        coSell {
          id
          ace_connected
          ace_entitled_status
          mspc_entitled_status
          mspc_connected
          microsoftSolutions {
            archivedAt
            externalId
            id
            solutionName
          }
        }
      }
    }
  }
`;

export const CoSellContextProvider = ({ children }) => {
  const [submittingOpportunityList, setSubmittingOpportunityList] = useState<
    OpportunityResponse[]
  >([]);
  const {
    data,
    loading: gqlQueryLoading,
    error: gqlQueryError,
  }: {
    data: CosellDashboardQuery;
    loading: boolean;
    error?: ApolloError;
  } = useCosellDashboardQuery({
    context: { batchKey: 'cosell-onboarding' },
  });

  return (
    <CoSellContext.Provider
      value={{
        submittingOpportunityList: submittingOpportunityList,
        updateSubmittingList: setSubmittingOpportunityList,
        gqlData: data,
        gqlQueryLoading: gqlQueryLoading,
        gqlQueryError: gqlQueryError,
      }}
    >
      {children}
    </CoSellContext.Provider>
  );
};
