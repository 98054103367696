import { makeStyles } from 'vendor/material';
import { fontWeightMedium } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  nameContainer: {
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  providerIcon: {
    height: 56,
    width: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    background: 'white',
  },
  menuTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: fontWeightMedium,
  },
}));

export default useStyles;
