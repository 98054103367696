const InsightsIcon = ({ className = '' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.39831 6.73333L6.33188 10.3333L5.26545 6.73333L1.66626 5.66667L5.26545 4.6L6.33188 1L7.39831 4.6L10.9975 5.66667L7.39831 6.73333ZM11.664 9.8L14.3301 8.33333L12.8638 11L14.3301 13.6667L11.664 12.2L8.99795 13.6667L10.4643 11L8.99795 8.33333L11.664 9.8ZM6.9984 11L5.86532 13L6.9984 15L4.99885 13.8667L2.99929 15L4.13237 13L2.99929 11L4.99885 12.1333L6.9984 11Z"
      fill="#5E6C84"
    />
  </svg>
);

export default InsightsIcon;
