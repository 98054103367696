export const getTackleSessionFromOtk = async (otk: string) => {
  const otkRequest = await fetch(
    'https://auth-service.dev.tkl.sh/api/v1/auth/one-time-keys/exchange',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        key: otk,
      }),
    },
  );

  const otkRequestJson =
    (await otkRequest.json()) as IOneTimeKeyExchangeResponse;

  return otkRequestJson;
};

export interface IOneTimeKeyExchangeResponse {
  /** auth token used as Bearer token for Tackle API requests */
  access_token: string;
  expires_in_seconds: number;
  context: SalesforceCanvasContext;
}

/** available canvas components/actions. */
export type ComponentId = 'CoSellDetails' | 'CreateCoSell' | 'BulkCreateCoSell';

/** data for salesforce record id, etc */
type RecordType = {
  Id: string;
};

interface SalesforceCanvasContext {
  client: Client;
  dimensions: Dimensions;
  /**
   * All data contained in Parameters is controlled by our tackle salesforce team.
   * if values are duplicated in the parent session object, use the values here.
   * Parameters vary depending on the Parameter.context value
   */
  parameters: Parameters;
}

interface Client {
  instanceId: string;
  oauthToken: string;
  instanceUrl: string;
  /** currently not implemented, so always returns null */
  refreshToken: null;
  targetOrigin: string;
}

/** dimensions of Canvas App in pixels */
interface Dimensions {
  width: string;
  height: string;
  maxWidth: string;
  maxHeight: string;
  clientWidth: string;
  clientHeight: string;
}

/**
 *  TODO: update this to conditionally include fields based on the context
 */
interface Parameters {
  componentId: ComponentId;
  permissions: Record<string, boolean>;
  redirectUri: string;
  /** returned for single record contexts */
  record?: RecordType;
  /** returned for bulk record contextx */
  records?: RecordType[];
  /** @deprecated - will be removed from response */
  clientId?: string;
  /** @deprecated - will be removed from response */
  clientSecret?: string;
}
