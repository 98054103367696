import { action, computed, decorate } from 'mobx';
import { flow, groupBy, map, orderBy } from 'lodash/fp';

import api from 'utils/api';
import Store from './Store';
import { sumDinero } from '../utils/money';
import { TransactionDisbursement } from '../models';
import { reportError } from 'utils/monitor';

function responseToDisbursements(data) {
  return flow(
    map((d) => new TransactionDisbursement(d)),
    groupBy('report_date'),
    map((disbursementsForThisMonth, _report_date) => ({
      disbursements: disbursementsForThisMonth,
      report_date: disbursementsForThisMonth[0].report_date,
      total_disbursed: flow(
        map('netAmount'),
        sumDinero,
      )(disbursementsForThisMonth),
    })),
    orderBy('report_date', 'desc'),
  )(data.disbursements);
}

class DisbursementsStore extends Store {
  load = () => {
    super.loading = true;

    const localDisbursements =
      JSON.parse(localStorage.getItem('disbursements')) || null;

    if (localDisbursements) {
      this.disbursements = responseToDisbursements(localDisbursements);
      super.loading = false;
      super.syncing = true;
    }

    api
      .get('disbursements')
      .json()
      .then((data) => {
        try {
          localStorage.setItem('disbursements', JSON.stringify(data));
        } catch (e) {
          if (e.name !== 'QuotaExceededError') reportError(e);
        }
        this.disbursements = responseToDisbursements(data);
        super.loading = false;
        super.syncing = false;
      });
  };

  get disbursements() {
    return super.data;
  }

  set disbursements(disbursements) {
    super.data = disbursements;
  }
}

decorate(DisbursementsStore, {
  disbursements: computed,
  load: action,
});

export default DisbursementsStore;

const disbursementsStore = new DisbursementsStore();
export { disbursementsStore };
