import { decorate, observable, action, computed } from 'mobx';
import Dinero from 'dinero.js';
import { DateTime } from 'luxon';
import { assign, filter, find, get, has, isEmpty, map } from 'lodash-es';

import api from 'utils/api';
import { toDateTime } from '../utils/dates';
import { sanitizeScalars } from '../utils/dynamo';

import AwsEntitlement from './AwsEntitlement';
import { PrivateOfferPricing } from 'stores/privateOffers/typings';

const PRIVATE_OFFER_ACTIONS = {
  vendorCreatedPrivateOffer: 'vendor-created-private-offer',
  vendorSentPurchaseInstructions: 'vendor-sent-purchase-instructions',
  buyerOpenedPurchaseInstructions: 'buyer-opened-purchase-instructions',
  buyerViewedPrivateOffer: 'buyer-viewed-private-offer',
  buyerAcceptedPrivateOffer: 'buyer-accepted-private-offer',
};

class Order {
  activity = [];
  archived_at = null;
  contract: any = {};
  customer_aws_account_number = '';
  customer_entitlements = [];
  customerid = '';
  error = null;
  loading = false;
  offerid = '';
  order_dt = null;
  order_props = {};
  payer_reference_id = 'NA';
  productid = '';
  report_date = '';
  update_dts = [];
  users_to_notify = [];
  contract_metadata = {};
  pricing: PrivateOfferPricing;

  constructor(props) {
    let {
      archived_at = null,
      order_dt = null,
      update_dt = null,
      update_dts = [],
      customer_entitlements = [],
    } = props;

    order_dt = toDateTime(order_dt, 'Pre-Tackle');
    update_dt = toDateTime(update_dt, 'Missing update_dt');
    update_dts = update_dts.map((dt) => toDateTime(dt, 'Missing update_dt'));
    customer_entitlements = customer_entitlements.map(
      (e) => new AwsEntitlement(e),
    );

    if (update_dt.isValid) {
      update_dts = update_dts.concat([update_dt]);
      update_dts.sort((a, b) => a.diff(b));
    }

    assign(this, props, {
      archived_at,
      order_dt,
      update_dts,
      customer_entitlements,
    });
  }

  setLoading = (loading) => {
    this.loading = loading;
  };

  setError = (error) => {
    this.error = error;
  };

  get hasRegistration() {
    return !isEmpty(this.order_props);
  }

  get hasCas() {
    return !isEmpty(this.contract);
  }

  get hasDisbursement() {
    return this.hasCas && !!this.contract.disbursements;
  }

  get hasProductTitle() {
    return this.hasCas && !!this.contract.productTitle;
  }

  get active() {
    return (
      this.expiration.isValid &&
      this.expiration.diffNow().toObject().milliseconds > 0
    );
  }

  get title() {
    const props = ['company', 'Company', 'Email Address', 'Full Name'];

    /**
     * Use a value from Tackle registration if possible
     */
    const title_prop = find(props, (p) => has(this.order_props, p));

    if (title_prop) {
      return get(this.order_props, title_prop);
    }

    /**
     * If the customer is unregistered we might be able to use email domain
     */
    if (
      this.hasCas &&
      has(this.contract.customer_details, 'email_domain') &&
      !isEmpty(this.contract.customer_details.email_domain)
    ) {
      return this.contract.customer_details.email_domain;
    }

    return 'Unregistered';
  }

  get emailDomain() {
    if (this.hasCas && has(this.contract.customer_details, 'email_domain')) {
      return this.contract.customer_details.email_domain;
    }

    return null;
  }

  get idStr() {
    let id = this.customerid;

    if (has(this.contract, 'customer_aws_account_number')) {
      id = `${id} | ${this.contract.customer_aws_account_number}`;
    }

    return id;
  }

  get contractStart() {
    if (this.hasCas && this.contract.orderDT) {
      return this.contract.orderDT;
    }

    if (this.order_dt) return this.order_dt;

    return DateTime.invalid('No order date');
  }

  get productTitle() {
    /**
     * This can be used to gather the product name based on the contract
     * if the product does not exist in tackles system.
     */
    if (this.hasCas) {
      return this.contract.productTitle;
    }

    return `Unknown Product Name (${this.productid})`;
  }

  get type() {
    if (this.hasCas) return `${this.contract.offerVisibility} Offer`;
    return 'Unknown Offer Type';
  }

  get expiration() {
    /**
     * Find the latest expiration available.
     * Each entitlement has a different expiration date, get the last one
     */
    let maxExpiration = null;
    const expirations = map(this.customer_entitlements, 'expiration');

    if (expirations.length > 0) {
      maxExpiration = DateTime.max(...expirations);
    }

    /**
     * If this order has CAS transactions find the latest
     * expiration date out of entitlements and transactions.
     */
    if (this.hasCas && this.contract.finalTransactionEndDate && maxExpiration) {
      return DateTime.max(this.contract.finalTransactionEndDate, maxExpiration);
    }

    if (maxExpiration) {
      return maxExpiration;
    } else if (this.hasCas && this.contract.isAmi) {
      return this.contract.amiExpiration;
    }

    /**
     * Return an invalid date if we can't find an expiration
     */
    return DateTime.invalid('No expiration date');
  }

  get disbursedAmount() {
    if (this.hasCas) return this.contract.disbursedToDate;
    return Dinero();
  }

  get transactionDates() {
    if (this.hasCas) return this.contract.transactionDates;

    let dates = [];
    if (this.order_dt) dates.push(this.order_dt);
    if (this.update_dts) dates = dates.concat(this.update_dts);
    return dates;
  }

  get transactions() {
    if (this.hasCas) {
      return this.contract.transactions;
    }

    return [];
  }

  get grossRevenue() {
    if (this.hasCas) {
      return this.contract.grossRevenue;
    }

    return Dinero();
  }

  get grossAnnualRevenue() {
    if (this.hasCas) {
      return this.contract.grossAnnualRevenue;
    }

    return Dinero();
  }

  get grossAnnualContractValue() {
    if (this.hasCas) {
      return this.contract.grossAnnualContractValue;
    }

    return Dinero();
  }

  get grossMtdRevenue() {
    if (this.hasCas) {
      return this.contract.grossMtdRevenue;
    }

    return Dinero();
  }

  rollingTransactionCount = (days = 30) => {
    const from = DateTime.utc().minus({ days });

    return filter(this.transactionDates, (td) => td.isValid && td >= from)
      .length;
  };

  get isUnacceptedPrivateOffer() {
    // if offerid === customerid, order is an unaccepted private offer
    return this.offerid === this.customerid;
  }

  get vendorSentPurchaseInstructions() {
    const action = PRIVATE_OFFER_ACTIONS.vendorSentPurchaseInstructions;
    return !!find(this.activity, { action });
  }

  get buyerOpenedPurchaseInstructions() {
    const action = PRIVATE_OFFER_ACTIONS.buyerOpenedPurchaseInstructions;
    return !!find(this.activity, { action });
  }

  get buyerViewedPrivateOffer() {
    const action = PRIVATE_OFFER_ACTIONS.buyerViewedPrivateOffer;
    return !!find(this.activity, { action });
  }

  get buyerAcceptedPrivateOffer() {
    const action = PRIVATE_OFFER_ACTIONS.buyerAcceptedPrivateOffer;
    return !!find(this.activity, { action });
  }

  get privateOfferActivityState() {
    if (this.buyerAcceptedPrivateOffer) {
      return {
        step: 6,
        action: PRIVATE_OFFER_ACTIONS.buyerAcceptedPrivateOffer,
      };
    }

    if (this.buyerViewedPrivateOffer) {
      return {
        step: 5,
        action: PRIVATE_OFFER_ACTIONS.buyerViewedPrivateOffer,
      };
    }

    if (this.buyerOpenedPurchaseInstructions) {
      return {
        step: 4,
        action: PRIVATE_OFFER_ACTIONS.buyerOpenedPurchaseInstructions,
      };
    }

    if (this.vendorSentPurchaseInstructions) {
      return {
        step: 3,
        action: PRIVATE_OFFER_ACTIONS.vendorSentPurchaseInstructions,
      };
    }

    return {
      step: 2,
      action: PRIVATE_OFFER_ACTIONS.vendorCreatedPrivateOffer,
    };

    // step: 1
    //   - does not exist in activity array
    //   - only exists on 'Select a Listing' in /private-offers/new
  }

  sync = (props) => {
    let {
      order_dt = null,
      update_dt = null,
      update_dts = [],
      customer_entitlements = [],
    } = props;

    order_dt = toDateTime(order_dt, 'Pre-Tackle');
    update_dt = toDateTime(update_dt, 'Missing update_dt');
    update_dts = update_dts.map((dt) => toDateTime(dt, 'Missing update_dt'));
    customer_entitlements = customer_entitlements.map(
      (e) => new AwsEntitlement(e),
    );

    if (update_dt.isValid) {
      update_dts = update_dts.concat([update_dt]);
      update_dts.sort((a, b) => a.diff(b));
    }

    assign(this, props, { order_dt, update_dts, customer_entitlements });
  };

  update = (json) => {
    this.setLoading(true);

    return api
      .put(`product/${this.productid}/order/${this.customerid}`, {
        json: sanitizeScalars(json),
      })
      .json()
      .then(({ order }) => {
        this.sync(order);
        this.setLoading(false);
      });
  };
}

decorate(Order, {
  archived_at: observable,
  customerid: observable,
  productid: observable,
  payer_reference_id: observable,
  customer_aws_account_number: observable,
  order_dt: observable,
  update_dts: observable,
  order_props: observable,
  customer_entitlements: observable,
  contract_metadata: observable,
  contract: observable,
  offerid: observable,
  activity: observable,
  users_to_notify: observable,
  loading: observable,
  error: observable,
  setLoading: action,
  setError: action,
  setContract: action,
  hasRegistration: computed,
  hasCas: computed,
  hasDisbursement: computed,
  hasProductTitle: computed,
  active: computed,
  title: computed,
  emailDomain: computed,
  idStr: computed,
  contractStart: computed,
  productTitle: computed,
  type: computed,
  expiration: computed,
  disbursedAmount: computed,
  transactionDates: computed,
  transactions: computed,
  grossRevenue: computed,
  grossAnnualRevenue: computed,
  grossAnnualContractValue: computed,
  grossMtdRevenue: computed,
  rollingTransactionCount: action,
  isUnacceptedPrivateOffer: computed,
  vendorSentPurchaseInstructions: computed,
  buyerOpenedPurchaseInstructions: computed,
  buyerViewedPrivateOffer: computed,
  buyerAcceptedPrivateOffer: computed,
  privateOfferActivityState: computed,
  sync: action,
  update: action,
} as any);

export default Order;
export { PRIVATE_OFFER_ACTIONS };
