import { makeStyles } from 'vendor/material';

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 0,
  },
  paper: {
    width: '100%',
    boxShadow: theme.depth.d200,
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCellRoot: {
    '&:first-child': {
      paddingLeft: 20,
    },
  },
  cellActions: {
    minWidth: 110,
  },
  iconActionButton: {
    padding: 0,
    marginLeft: 8,
  },
  sortedLabel: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default useTableStyles;
