import { makeStyles } from 'vendor/material';
import { Link as TackleLink } from '@tackle-io/platform-ui';

const useStyles = (props) =>
  makeStyles((theme) => ({
    link: {
      textDecoration: 'none',
      transition: 'color 0.3s ease-in-out',
      color: props.light ? theme.palette.NEUTRAL000 : theme.palette.BLUE300,
      ...(props.light && {
        textDecoration: 'underline',
      }),
      '&:visited': {
        color: props.light ? theme.palette.NEUTRAL000 : theme.palette.BLUE500,
      },
      '&:hover': {
        color: props.light ? theme.palette.NEUTRAL000 : theme.palette.BLUE400,
        textDecoration: 'underline',
      },
    },
  }))();

export const Link = (props) => {
  const classes = useStyles(props);
  return <TackleLink {...props} className={classes.link} />;
};
