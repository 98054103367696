const SyncedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M11 21.9C9.73333 21.7667 8.55417 21.4208 7.4625 20.8625C6.37083 20.3042 5.42083 19.5792 4.6125 18.6875C3.80417 17.7958 3.16667 16.775 2.7 15.625C2.23333 14.475 2 13.25 2 11.95C2 10.4333 2.30417 9.03333 2.9125 7.75C3.52083 6.46667 4.35 5.36667 5.4 4.45H3V2.45H9V8.45H7V5.725C6.08333 6.45833 5.35417 7.3625 4.8125 8.4375C4.27083 9.5125 4 10.6833 4 11.95C4 14 4.67083 15.7708 6.0125 17.2625C7.35417 18.7542 9.01667 19.625 11 19.875V21.9ZM10.575 16.55L6.35 12.3L7.75 10.9L10.575 13.725L16.25 8.05L17.65 9.475L10.575 16.55ZM15 21.45V15.45H17V18.175C17.9167 17.425 18.6458 16.5167 19.1875 15.45C19.7292 14.3833 20 13.2167 20 11.95C20 9.9 19.3292 8.12917 17.9875 6.6375C16.6458 5.14583 14.9833 4.275 13 4.025V2C15.5333 2.25 17.6667 3.31667 19.4 5.2C21.1333 7.08333 22 9.33333 22 11.95C22 13.4667 21.6958 14.8667 21.0875 16.15C20.4792 17.4333 19.65 18.5333 18.6 19.45H21V21.45H15Z"
      fill="#253858"
    />
  </svg>
);
export default SyncedIcon;
