import { yupResolver } from '@hookform/resolvers/yup';
import { Banner, Button } from '@tackle-io/platform-ui';
import React, { useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { Box } from 'vendor/material';
import FieldsPaymentScheduler from './FieldsPaymentScheduler';
import PaymentSchedulePreview from './PaymentSchedulePreview';
import useStyles from './PaymentScheduler.styles';
import {
  PaymentFrequencyValue,
  PaymentFrequencyValueType,
  paymentFrequencyOptions,
} from './constants';
import { paymentSchedulerSchema } from './schema';
import { PaymentScheduleRow, generatePaymentSchedule } from './utils';
import {
  getDurationValueFromFormData,
  scheduleFormPath,
} from 'components/FieldsPricing/utils';
import { pendoIdGenerator } from '../../../../utils/idGenerator';
import { privateOffersUIElements } from '../../../../utils/pendoEnums';
import { CurrencyCode } from '../../../../utils/currency';

export interface PaymentScheduleFormData {
  totalContractValue: number;
  durationInMonths: number;
  paymentFrequency: PaymentFrequencyValueType;
  firstPaymentDate: string;
}

interface PaymentSchedulerProps {
  closePaymentScheduleDrawer?: () => void;
  disablePaymentScheduleToolDurationField?: boolean;
}

const PaymentScheduler: React.FC<PaymentSchedulerProps> = ({
  closePaymentScheduleDrawer,
  disablePaymentScheduleToolDurationField = false,
}) => {
  const [isPreviewShown, setIsPreviewShown] = useState(false);
  const [schedulePreview, setSchedulePreview] = useState<PaymentScheduleRow[]>(
    [],
  );
  const [paymentScheduleData, setPaymentScheduleData] =
    useState<PaymentScheduleFormData>({
      totalContractValue: 0,
      durationInMonths: 0,
      paymentFrequency: PaymentFrequencyValue.Monthly,
      firstPaymentDate: '',
    });
  const { watch, setValue } = useFormContext();
  const pricing = watch('pricing');
  const currencyCode = pricing?.currencyCode ?? CurrencyCode.UnitedStatesDollar;
  const classes = useStyles();
  const paymentFrequencyDefault = paymentFrequencyOptions[0]?.value;
  const showBanner = !!pricing.schedule?.length;

  const durationInMonths = getDurationValueFromFormData(
    pricing.billingTerm,
    pricing.durationValue,
    pricing.serviceStartAt,
    pricing.serviceEndAt,
    pricing.duration,
  );

  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      totalContractValue: 0,
      durationInMonths: durationInMonths || 0,
      paymentFrequency: paymentFrequencyDefault,
      firstPaymentDate: '',
    },
    resolver: yupResolver(paymentSchedulerSchema),
  });

  const onSubmit = (formData: PaymentScheduleFormData) => {
    const scheduleOutput = generatePaymentSchedule(formData);
    setValue('pricing.durationValue', formData.durationInMonths);
    setValue(scheduleFormPath, scheduleOutput);
    closePaymentScheduleDrawer();
  };

  const handleDrawerViewChange = (formData: PaymentScheduleFormData) => {
    setIsPreviewShown(!isPreviewShown);
    setPaymentScheduleData(formData);
    const scheduleOutput = generatePaymentSchedule(formData);
    setSchedulePreview(scheduleOutput);
  };

  return (
    <Box className={classes.schedulerContainer}>
      <Box>
        {showBanner && !isPreviewShown && (
          <Box mb={4}>
            <Banner
              title="Any existing payments will be replaced with the new payment schedule."
              type="warning"
              defaultOpen={false}
            />
          </Box>
        )}
        {isPreviewShown && (
          <PaymentSchedulePreview
            schedule={schedulePreview}
            paymentScheduleData={paymentScheduleData}
            currencyCode={currencyCode}
          />
        )}
        <Box className={isPreviewShown ? classes.hidden : ''}>
          <FieldsPaymentScheduler
            control={control}
            errors={errors}
            currencyCode={currencyCode}
            disableDurationField={disablePaymentScheduleToolDurationField}
          />
        </Box>
      </Box>

      <Box>
        <Box display="flex" justifyContent="flex-end" marginTop={3.5}>
          <Box mr={2}>
            <Button
              variant="outlined"
              appearance="primary"
              onClick={closePaymentScheduleDrawer}
              data-id={pendoIdGenerator(
                privateOffersUIElements.PRIVATE_OFFERS_HIDE_PAYMENT_SCHEDULE_TOOL_BUTTON,
              )}
            >
              Cancel
            </Button>
          </Box>
          <Box mr={2}>
            <Button
              variant="outlined"
              appearance="primary"
              onClick={handleSubmit(handleDrawerViewChange)}
              data-id={pendoIdGenerator(
                isPreviewShown
                  ? privateOffersUIElements.PRIVATE_OFFERS_HIDE_PREVIEW_PAYMENT_SCHEDULE_BUTTON
                  : privateOffersUIElements.PRIVATE_OFFERS_SHOW_PREVIEW_PAYMENT_SCHEDULE_BUTTON,
              )}
            >
              {isPreviewShown ? 'Back' : 'Preview'}
            </Button>
          </Box>
          <Box>
            <Button
              appearance="primary"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              data-id={pendoIdGenerator(
                privateOffersUIElements.PRIVATE_OFFERS_CREATE_PAYMENT_SCHEDULE_BUTTON,
              )}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentScheduler;
