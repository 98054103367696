import { isEmpty } from 'lodash/fp';
import {
  BillingTermValue,
  BillingTermValueType,
  OfferType,
  PrivateOffer,
} from 'stores/privateOffers/typings';
import { Cloud } from '../../../../utils/cloudTypes';

export const DURATION_LEGACY_TO_BILLING_TERM = {
  1: BillingTermValue.OneMonth,
  12: BillingTermValue.OneYear,
  24: BillingTermValue.TwoYears,
  36: BillingTermValue.ThreeYears,
};

export const DURATION_TO_BILLING_TERM_LIST = [1, 12, 24, 36];

export function getBillingTermDefault(
  offer: PrivateOffer,
  billingTermDefault: BillingTermValueType,
) {
  const offerBillingTerm = offer?.metadata?.pricing?.billingTerm;
  if (offerBillingTerm) return offerBillingTerm;

  if (offer?.salesforceRef && !isEmpty(offer?.salesforceRef))
    return getBillingTermFromLegacyDuration(offer?.metadata?.pricing?.duration);

  return billingTermDefault;
}

export function getBillingTermFromLegacyDuration(duration: string | number) {
  if (!duration) return BillingTermValue.Custom;

  if (
    typeof duration === 'number' &&
    DURATION_TO_BILLING_TERM_LIST.includes(duration)
  )
    return DURATION_LEGACY_TO_BILLING_TERM[duration];

  if (typeof duration === 'string') {
    const durationPart = duration?.trim()?.split(' ')[0];
    const durationPartNum = Number(durationPart);
    if (DURATION_TO_BILLING_TERM_LIST.includes(durationPartNum))
      return DURATION_LEGACY_TO_BILLING_TERM[durationPartNum];
  }

  return BillingTermValue.Custom;
}

export function getDurationValueDefault(offer: PrivateOffer) {
  const offerDurationValue = offer?.metadata?.pricing?.durationValue;
  if (offerDurationValue) return offerDurationValue;

  if (offer?.salesforceRef && !isEmpty(offer?.salesforceRef)) {
    return getDurationValueFromLegacyDuration(
      offer?.metadata?.pricing?.duration,
    );
  }

  return 0;
}

export function getDurationValueFromLegacyDuration(duration: string | number) {
  if (!duration) return undefined;

  if (typeof duration === 'number') return duration;

  if (typeof duration === 'string') {
    const durationPart = duration?.trim()?.split(' ')[0];
    const durationPartNum = Number(durationPart);
    return durationPartNum;
  }

  return 0;
}

const fdaEnabledClouds = new Set<Cloud>([Cloud.Aws, Cloud.Gcp]);

export const shouldShowFDAPricing = (cloud: Cloud, offerType: OfferType) => {
  const isFdaEnabledCloud = fdaEnabledClouds.has(cloud);
  const isFdaSupportedOfferType =
    cloud === Cloud.Gcp || offerType === OfferType.Direct;

  return isFdaEnabledCloud && isFdaSupportedOfferType;
};
