import React from 'react';
import { Grid } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { convertArrayToString } from '../helper';
import { Project } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { ExpectedCustomerSpendDetails } from './ExpectedCustomerSpendDetails';

interface AceProjectDetailsProps {
  project: Project;
  targetCloseDate: string;
  awsProducts: string[];
  solutions: string[];
}

const AceProjectDetails: React.FC<AceProjectDetailsProps> = ({
  project,
  targetCloseDate,
  awsProducts,
  solutions,
}) => {
  return (
    <DetailsSectionCard title="Project details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Partner project title"
          value={project?.title}
          gridSize={12}
        />

        <InfoItem
          gridSize={12}
          itemTitle="Customer business problem"
          value={project?.customerBusinessProblem}
        />

        <InfoItem
          itemTitle="Solution offered"
          value={convertArrayToString(solutions)}
        />
        <InfoItem
          itemTitle="AWS products"
          value={convertArrayToString(awsProducts)}
        />

        <InfoItem itemTitle="Use case" value={project?.customerUseCase} />
        <InfoItem
          itemTitle="Delivery model"
          value={convertArrayToString(project?.deliveryModels)}
        />
        <InfoItem
          itemTitle="APN programs"
          value={convertArrayToString(project?.apnPrograms)}
          gridSize={12}
        />
        <InfoItem
          gridSize={12}
          itemTitle="Target close date"
          value={targetCloseDate}
        />
        {!!project.expectedCustomerSpend && (
          <ExpectedCustomerSpendDetails
            expectedCustomerSpend={project.expectedCustomerSpend}
          />
        )}
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceProjectDetails;
