export const REQUIRED = 'Required';
export const CONSENT_REQUIRED = 'Required - Must be checked to submit form.';
export const PHONE_NUMBER_MSG = 'Please enter a valid phone number';
export const WEBSITE_ERROR_MSG = 'Please enter a valid url';
export const UNITED_STATES_POSTAL_CODE_MSG =
  'United States postal code must be in 99999 OR 99999-9999 format.';
export const EMAIL_ERROR_MSG = 'Please enter a valid email address';
export const DUPLICATE_EMAIL_ERROR_MSG =
  'Your contact email must be different than the buyer email.';
export const PHONE_NUMBER_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const WEBSITE_REGEX =
  /((https?):\/\/)?(www\.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%-]+&?)?$/;

// we extended this regex to include specific invalid email used for testing
export const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$|co-sell-demo@tackle.io.demo/;
export const UNITED_STATES_POSTAL_CODE_REGEX = /^\d{5}(-\d{4})?$/;
