import React from 'react';
import { Box, Skeleton } from 'vendor/material';
import useStyles from './TableLoadingRows.styles';

export const TableLoadingRows = ({
  pageLimit,
}: {
  pageLimit: number;
}): JSX.Element => {
  const classes = useStyles();

  const rows = [...Array(pageLimit)].map((_, idx) => (
    <Box my={2} key={idx}>
      <Skeleton height={50} width="100%" className={classes.loadingRow} />
    </Box>
  ));
  return <>{rows}</>;
};
