import { makeStyles } from 'vendor/material';
import { ThemeTackle } from 'Theme';

import { fontWeightBold } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme: ThemeTackle) => ({
  editionCharges: {
    backgroundColor: theme.palette.NEUTRAL020,
  },
  editionCharge: {
    borderTop: `4px solid ${theme.palette.background.paper}`,
  },
  editionChargeEmphasis: {
    fontWeight: fontWeightBold,
  },
}));

export default useStyles;
