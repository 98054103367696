import { decorate, observable, action, computed } from 'mobx';

class Store {
  _error = null;

  set error(error) {
    this._error = error;
  }

  get error() {
    return this._error;
  }

  _loading = false;

  get loading() {
    return this._loading;
  }

  set loading(loading) {
    this._loading = loading;
  }

  _syncing = false;

  get syncing() {
    return this._syncing;
  }

  set syncing(syncing) {
    this._syncing = syncing;
  }

  _data = null;

  get data() {
    return this._data;
  }

  set data(data) {
    this._data = data;
  }

  get hasData() {
    return !!this.data;
  }

  load = () => {};

  get skip() {
    return this._skip;
  }

  set skip(skip) {
    this._skip = skip;
  }
}

decorate(Store, {
  _loading: observable,
  _syncing: observable,
  _error: observable,
  _data: observable,
  _skip: observable,
  loading: computed,
  syncing: computed,
  data: computed,
  hasData: computed,
  skip: computed,
  load: action,
});

export default Store;
