import { Box, Drawer, Typography } from 'vendor/material';
import { Button, ComboBox, Switch } from '@tackle-io/platform-ui';
import useStyles from './FilterPanel.styles';
import { Close } from 'mdi-material-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

export enum FILTER_TYPES {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  // feel free to add types.
}

type FilterOption = {
  title: string;
  value: string;
};

export type Filter = {
  key: string;
  label: string;
  type: FILTER_TYPES;
  placeholder?: string;
  options?: Array<FilterOption>;
  customClass?: string;
};

interface FilterPanelProps {
  isOpen: boolean; // state of the side-panel
  dataId?: string;
  className?: string; // extra classes to apply to the shell
  filterSet: Array<Filter>; // set of filters for the panel to show
  onClose: Function; // Panel is closing, but filters may not have applied
  onApplyFilters: Function; // function containing resulting filters to apply.
}

export type SelectedFilter = {
  [Key: string]: string | Array<string> | boolean;
};

export const FilterPanel: React.FC<FilterPanelProps> = ({
  isOpen,
  dataId = '',
  className = '',
  filterSet,
  onClose,
  onApplyFilters,
}) => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const existingFilters = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return filterSet.reduce((obj, item) => {
      item.type === FILTER_TYPES.MULTI_SELECT
        ? (obj[item.key] = searchParams.getAll(item.key))
        : (obj[item.key] = searchParams.get(item.key));
      return obj;
    }, {} as SelectedFilter);
  }, [filterSet, location.search]);

  const [filters, setFilters] = useState<SelectedFilter>(existingFilters);

  useEffect(() => {
    setFilters(existingFilters);
  }, [existingFilters]);

  const handleApplyFilters = () => {
    const newParams = new URLSearchParams(location.search);
    // build params here based on active filters.
    for (const [key, value] of Object.entries(filters)) {
      if (value === false) {
        newParams.delete(key);
      }
      if (!value) continue;
      newParams.delete(key); // remove old filters, but leave in place filters that are _not_ from our list
      Array.isArray(value)
        ? value.forEach((newValue) => newParams.append(key, newValue))
        : newParams.append(key, value.toString());
    }
    const correctedPath = `${location.pathname}?${newParams.toString()}`;
    history.push(correctedPath);
    onApplyFilters();
  };

  const handleResetFilters = () => {
    const clearedFilters = filterSet.reduce((obj, item) => {
      if (item.type === FILTER_TYPES.MULTI_SELECT) {
        obj[item.key] = [];
      } else if (item.type === FILTER_TYPES.BOOLEAN) {
        obj[item.key] = false;
      } else {
        obj[item.key] = undefined;
      }

      return obj;
    }, {} as SelectedFilter);
    setFilters(clearedFilters);
  };

  const handleClose = () => {
    setFilters(existingFilters);
    onClose();
  };

  const filterElements = useMemo(() => {
    const handleSingleSelectFilterChange = (
      filterKey: string,
      option: FilterOption,
    ) => {
      setFilters({
        ...filters,
        [filterKey]: option?.value || undefined,
      });
    };

    const handleMultiSelectFilterChange = (
      filterKey: string,
      options: Array<FilterOption>,
    ) => {
      const dataValues = options.map((selectedOption) => selectedOption.value);
      setFilters({
        ...filters,
        [filterKey]: dataValues,
      });
    };

    const handleBooleanFilterChange = (filterKey: string, data: boolean) => {
      setFilters({
        ...filters,
        [filterKey]: data || false,
      });
    };

    return filterSet.map((filter) => {
      let componentToRender = undefined;

      switch (filter.type) {
        case FILTER_TYPES.BOOLEAN:
          const booleanValue: boolean = filters
            ? !!filters[filter.key] || false
            : false;
          componentToRender = (
            <Switch
              onChange={(evt, data) =>
                handleBooleanFilterChange(filter.key, data)
              }
              checked={booleanValue}
              label={filter.label}
            />
          );
          break;
        case FILTER_TYPES.SINGLE_SELECT:
          const filterValue = filters ? filters[filter.key] || '' : '';
          const value =
            filter.options.find((option) => option.value === filterValue) || '';
          componentToRender = (
            <ComboBox
              id={filter.key}
              label={filter.label}
              options={filter.options || []}
              placeholder={filter.placeholder}
              onChange={(evt, data: FilterOption) =>
                handleSingleSelectFilterChange(filter.key, data)
              }
              key={filter.key}
              filterSelectedOptions
              {...(!!value ? { value: value } : {})}
            />
          );
          break;
        case FILTER_TYPES.MULTI_SELECT:
          const values = filters
            ? filters[filter.key] || [undefined]
            : [undefined];
          const valuesAsArray = Array.isArray(values) ? values : [values];
          const activeValues = filter.options.filter((option) =>
            valuesAsArray.includes(option.value),
          );

          componentToRender = (
            <ComboBox
              id={filter.key}
              label={filter.label}
              options={filter.options || []}
              placeholder={filter.placeholder}
              onChange={(evt, data: FilterOption[]) =>
                handleMultiSelectFilterChange(filter.key, data)
              }
              value={activeValues}
              filterSelectedOptions
              multiple
              key={filter.key}
            />
          );
          break;
        case FILTER_TYPES.TEXT:
          break;
      }

      return (
        <div
          className={`${classes.filter} ${filter.customClass}`}
          key={filter.key}
        >
          {componentToRender}
        </div>
      );
    });
  }, [classes.filter, filterSet, filters]);

  return (
    <Drawer
      className={`${classes.drawerShell} ${className}`}
      anchor="right"
      open={isOpen}
      onClose={handleClose}
    >
      <Box className={classes.drawerContainer} data-id={dataId}>
        <Box className={classes.drawerHeader}>
          <Typography className={classes.drawerHeaderText} variant="h1">
            Filter
          </Typography>
          <Box className={classes.drawerTopActions}>
            <Button
              variant="text"
              size="small"
              onClick={handleClose}
              className={classes.drawerTopAction}
              startIcon={<Close />}
            />
          </Box>
        </Box>
        <Box className={classes.drawerBody}>
          <div className={classes.bodyFadeReverseElement}></div>
          {isOpen && filterElements}
          <div className={classes.bodyFadeElement}></div>
        </Box>
        <Box className={classes.drawerFooter}>
          <Button
            variant="outlined"
            appearance="primary"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            appearance="primary"
            size="small"
            onClick={handleResetFilters}
          >
            Clear
          </Button>
          <Button
            appearance="primary"
            size="small"
            onClick={handleApplyFilters}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};
