import { decorate, observable } from 'mobx';
import { assign } from 'lodash-es';

class ProductStyleProps {
  bgcolor = '';

  fgcolor = '';

  constructor(props) {
    assign(this, props);
  }
}

decorate(ProductStyleProps, {
  bgcolor: observable,
  fgcolor: observable,
});

export default ProductStyleProps;
