/* eslint react/jsx-no-duplicate-props: 0 */
import React from 'react';
import { TextField } from 'vendor/material';

import useStyles from './TextField.styles';

const TextFieldWrapper = (props) => {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      inputProps={{ className: classes.input }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
        },
      }}
      margin="none"
      variant="outlined"
      required
      fullWidth
    />
  );
};

export default TextFieldWrapper;
