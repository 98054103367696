import { useCurrentUserVendorQuery } from 'generated/graphql';
import { VendorEnvironmentEnum } from 'utils/constants';
import { useAuth } from 'vendor/auth0';

function useIsSandboxEnvironment(): boolean {
  const { isAuthenticated } = useAuth();
  const { data } = useCurrentUserVendorQuery({
    skip: !isAuthenticated,
  });
  return (
    data?.currentUser?.vendor?.vendor_type === VendorEnvironmentEnum.SANDBOX
  );
}

export default useIsSandboxEnvironment;
