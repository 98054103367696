import { ProviderIcon } from '@tackle-io/platform-ui';
import { Box, Typography } from 'vendor/material';
import useStyles from './CoSellCloudMenu.styles';
import { useGetCloudProvider } from 'packages/cosell/src/hooks/useGetCloudProvider';
// import { useCoSellLandingPage } from '../../providers/CoSellLandingPageProvider';
// import { parseCloudProvider } from '../../helpers/urlParamsHelper';
export const CoSellCloudMenu = () => {
  const classes = useStyles();
  const cloud = useGetCloudProvider();

  return (
    <Box className={classes.nameContainer} sx={{ display: 'flex' }}>
      <Box className={classes.providerIcon} sx={{ display: 'flex' }} mr={1}>
        <ProviderIcon fontSize={'large'} provider={cloud} />
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography className={classes.menuTitle} variant="h5">
          AWS Co-Sell opportunities
        </Typography>
      </Box>
    </Box>
  );
};
