import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '33vw',
    minWidth: theme.typography.pxToRem(480),
    padding: '3rem',
  },
  title: {
    color: theme.palette.NEUTRAL600,
    marginBottom: '2rem',
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  schedulerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  paper: {
    marginTop: theme.spacing(1),
  },
  fdaFieldsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  calendarContent: {
    marginBottom: theme.spacing(1.5),
  },
  hidden: {
    display: 'none',
  },
}));

export default useStyles;
