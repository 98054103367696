import React from 'react';
import { Grid, GridSize } from 'vendor/material';

interface SalesforceCoSellWidgetDetailTableItemProps {
  title: string;
  width?: GridSize;
  children?: any;
}

export const SalesforceCoSellWidgetDetailTableItem = (
  props: SalesforceCoSellWidgetDetailTableItemProps,
) => {
  const { title, width = 6, children } = props;

  return (
    <Grid item xs={width}>
      <strong>{title}</strong> <br />
      {children}
    </Grid>
  );
};
