import React from 'react';
import { SalesforceCoSellWidgetHeader } from './SalesforceCoSellWidgetHeader/SalesforceCoSellWidgetHeader';
import { SalesforceCoSellWidgetDetailTable } from './SalesforceCoSellWidgetDetailTable/SalesforceCoSellWidgetDetailTable';
import { SalesforceCoSellWidgetButtonGroup } from './SalesforceCoSellWidgetButtonGroup/SalesforceCoSellWidgetButtonGroup';
import { SalesforceCoSellWidgetCallToAction } from './SalesforceCoSellWidgetCallToAction/SalesforceCoSellWidgetCallToAction';
import { FixedHeaderFooter } from 'packages/salesforce-canvas/cosell/src/components/FixeHeaderFooter';
import type { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';

interface SalesforceCoSellWidgetProps {
  // @to-do type this to AceOpportunityResponse once that contract is finalized
  coSellOpportunity?: AceOpportunityResponse & { id: string };
}

/**
 * WIP
 * TODO:
 *  - handle layout style when new Aura components is ready to be used (Elliot is working on it)
 *  - handle the rendering of all child content views here.
 */
export const SalesforceCoSellWidget = ({
  coSellOpportunity,
}: SalesforceCoSellWidgetProps) => {
  const buttonGroup = (
    <SalesforceCoSellWidgetButtonGroup tackleCoSellId={coSellOpportunity?.id} />
  );

  return (
    <FixedHeaderFooter
      footerContent={buttonGroup}
      headerContent={<SalesforceCoSellWidgetHeader />}
    >
      {coSellOpportunity ? (
        <SalesforceCoSellWidgetDetailTable opportunity={coSellOpportunity} />
      ) : (
        <SalesforceCoSellWidgetCallToAction />
      )}
    </FixedHeaderFooter>
  );
};
