export enum OpportunityDetailSubstatusEnum {
  ACCEPTED = 'Accepted', // mspc
  DECLINED = 'Declined', // mspc
  ERROR = 'Error', // mspc
  EXPIRED = 'Expired', // mspc
  LOST = 'Lost', // mspc or GCP
  OPEN = 'Open', // GCP
  PENDING = 'Pending', // mspc
  RECEIVED = 'Received', // mspc
  WON = 'Won', // mspc or GCP
  DRAFT = 'Draft', // ace
  SUBMITTED = 'Submitted', // ace
  IN_REVIEW = 'In review', // ace
  APPROVED = 'Approved', // ace
  REJECTED = 'Rejected', // ace
  ACTION_REQUIRED = 'Action Required', // ace
}
