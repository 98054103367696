import { DateTime } from 'luxon';
import { isEmpty } from 'lodash-es';

import { Event } from 'stores/Events';
import { Products, Contracts } from '../stores';
import useStore, { hasLoaded } from './useStore';
import usePrivateOffers from '../stores/privateOffers/hooks';

interface HydratedEvent extends Event {
  hydratedMetadata: { [key: string]: any }[];
  companyName: string;
  productName: string;
  retried: boolean;
  formattedCreatedAt: string;
}

function useHydratedEvent(baseEvent): {
  event: HydratedEvent;
  loaded: boolean;
} {
  const productsStore = useStore(Products);
  const contractsStore = useStore(Contracts);
  const privateOffers = usePrivateOffers();

  const loaded =
    hasLoaded(productsStore, contractsStore) && !privateOffers.isLoading;
  if (!loaded) return { event: baseEvent, loaded };

  const { productid, customerid, created_at, source } = baseEvent;

  const { outgoing_payload, outgoing_response, ...eventMetadata } = baseEvent;

  const product = productsStore.find(productid);

  const hydratedMetadata = [];
  hydratedMetadata.push({ name: 'METADATA', code: eventMetadata });
  if (!isEmpty(outgoing_payload)) {
    hydratedMetadata.push({ name: 'PAYLOAD', code: outgoing_payload });
  }

  if (!isEmpty(outgoing_response)) {
    hydratedMetadata.push({ name: 'RESPONSE', code: outgoing_response });
  }

  let companyName: string | undefined;
  if (!customerid && eventMetadata.po_id) {
    const privateOffer = privateOffers.content.find(
      (po) => po.poId === eventMetadata.po_id,
    );
    companyName = privateOffer?.metadata?.preRegistrationDetails?.Company;
  } else {
    // TODO: This WILL NOT WORK when Contracts are paginated in ContractsV5
    const contract = contractsStore.findByProductIdCustomerId({
      customerid,
      productid,
    });
    companyName =
      contract &&
      contract.displayName &&
      contract.displayName !== 'Unregistered'
        ? contract.displayName
        : `Customer ID: ${customerid}`;
  }

  const productName = product ? product.name : `Product ID: ${productid}`;
  const retried = source === 'retry';
  const formattedCreatedAt =
    DateTime.fromISO(created_at).toFormat('LLL dd, yyyy, t');

  return {
    event: {
      ...baseEvent,
      hydratedMetadata,
      companyName,
      productName,
      retried,
      formattedCreatedAt,
    },
    loaded,
  };
}

export default useHydratedEvent;
