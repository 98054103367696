import { useEffect, useState } from 'react';
import ky from 'ky';

interface SessionError {
  exception: ky.HTTPError;
  message: string;
}

export interface SessionHook<TSession> {
  loading: boolean;
  error: SessionError;
  data: TSession;
}

const HTTP_ERROR_TO_UI_MESSAGE_MAP = {
  400: 'Invalid session or expired',
  401: 'Invalid session or expired',
  404: 'Session not found',
  fallback: 'Bad session',
};

export const get_session_error_message_from_http_error = (
  error: ky.HTTPError,
): string => {
  const code = error.response?.status;
  return (
    HTTP_ERROR_TO_UI_MESSAGE_MAP[code] ?? HTTP_ERROR_TO_UI_MESSAGE_MAP.fallback
  );
};

function useSession<TSession>({
  sessionId,
  getSessionById,
}): SessionHook<TSession> {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<TSession>(null);
  const [error, setError] = useState<SessionError>(null);

  useEffect(() => {
    const abortController = new AbortController();

    const onSuccess = (sessionData: TSession): void => {
      setData(sessionData);
      setLoading(false);
    };

    const onFailure = (exception): void => {
      setError({
        exception,
        message: get_session_error_message_from_http_error(exception),
      });
      setLoading(false);
    };

    if (sessionId) {
      getSessionById({
        sessionId,
        onSuccess,
        onFailure,
        abortSignal: abortController.signal,
      });
    } else {
      setLoading(false);
    }

    return (): void => {
      abortController.abort();
    };
  }, [getSessionById, sessionId]);

  return {
    loading,
    error,
    data,
  };
}

export default useSession;
