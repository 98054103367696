export enum Cloud {
  Aws = 'aws',
  Azure = 'azure',
  Redhat = 'redhat',
  Gcp = 'gcp',
}

export type CloudType = Cloud.Aws | Cloud.Azure | Cloud.Redhat | Cloud.Gcp;

export function isCloudType(cloud: CloudType): boolean {
  return Object.values(Cloud).indexOf(cloud) !== -1;
}
