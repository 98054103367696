import React, { memo } from 'react';
import { Magnify } from 'mdi-material-ui';
import { debounce } from 'lodash-es';
import { TextField } from '@tackle-io/platform-ui';

interface FilterSearchProps {
  setSearchQuery: (string) => void;
  id: string;
}

const FilterSearch: React.FC<FilterSearchProps> = ({ setSearchQuery, id }) => {
  const debouncedSearch = debounce((value) => {
    setSearchQuery(value);
  }, 100);

  const handleSearchChange = (e): void => {
    debouncedSearch(e.target.value);
  };

  return (
    <TextField
      labelHidden
      iconLeft={<Magnify />}
      placeholder="Search"
      onChange={handleSearchChange}
      name="searchMeta"
      id={id}
    />
  );
};

export default memo(FilterSearch);
