const GeneralEmptyStateIcon = ({ className = '' }: { className?: string }) => (
  <svg
    className={className}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'luminosity' }}>
      <path d="M23 48H8V56H23V48Z" fill="#FF8C25" />
      <path d="M23 30H8V48H23V30Z" fill="#FFE195" />
      <path
        d="M2 35.7764H17.6025L22.4782 27H6.87577L2 35.7764Z"
        fill="#19C8B3"
      />
      <path
        d="M22.4783 27H6.94706L2.07129 35.7763H17.6025L22.4783 27Z"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0001 27L27.8759 35.7763H46"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46 36V56H8V36"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 48H46"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 55V27"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.0789 30.1578C44.7499 30.1578 50.1578 24.7499 50.1578 18.0789C50.1578 11.4079 44.7499 6 38.0789 6C31.4079 6 26 11.4079 26 18.0789C26 24.7499 31.4079 30.1578 38.0789 30.1578Z"
        fill="#CFEAF3"
        stroke="#063649"
        strokeWidth="2"
      />
      <path
        d="M48.1976 25.6841L59.2378 36.7252C59.6536 37.0619 59.9217 37.5475 59.9851 38.0788C60.0485 38.6101 59.9023 39.1452 59.5774 39.5704C59.1528 39.8988 58.6165 40.0481 58.0832 39.9862C57.55 39.9243 57.0621 39.6561 56.724 39.2392L45.6838 28.198"
        fill="#209DB2"
      />
      <path
        d="M48.1976 25.6841L59.2378 36.7252C59.6536 37.0619 59.9217 37.5475 59.9851 38.0788C60.0485 38.6101 59.9023 39.1452 59.5774 39.5704C59.1528 39.8988 58.6165 40.0481 58.0832 39.9862C57.55 39.9243 57.0621 39.6561 56.724 39.2392L45.6838 28.198"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.079 26.5791C42.7734 26.5791 46.579 22.7735 46.579 18.0791C46.579 13.3847 42.7734 9.5791 38.079 9.5791C33.3846 9.5791 29.579 13.3847 29.579 18.0791C29.579 22.7735 33.3846 26.5791 38.079 26.5791Z"
        fill="white"
        stroke="#063649"
        strokeWidth="2"
      />
      <path
        d="M34.9474 14.9475L41.2105 21.2107"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M41.2105 14.9475L34.9474 21.2106"
        stroke="#063649"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export { GeneralEmptyStateIcon };
