import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fill: theme.palette.NEUTRAL050,
    width: 144,
    height: 144,
  },
  title: {
    color: theme.palette.NEUTRAL900,
    fontSize: theme.typography.pxToRem(40),
  },
  message: {
    textAlign: 'center',
    color: theme.palette.NEUTRAL500,
    fontSize: theme.typography.pxToRem(18),
    maxWidth: 600,
    margin: '0 auto',
  },
}));

export default useStyles;
