import { Vendor } from 'generated/graphql';
import { sortBy } from 'lodash';
import { VendorEnvironmentEnum } from 'utils/constants';

export interface AppEnvironment {
  name: string;
  type: VendorEnvironmentEnum;
}

export enum VendorEnvironmentLabelEnum {
  prod = 'Production',
  sandbox = 'Test',
  'internal test' = 'Internal test',
  'customer test' = 'Customer test',
  demo = 'Demo',
  dev = 'Development',
}

export enum VendorEnvironmentShortLabelEnum {
  prod = 'Prod',
  sandbox = 'Test',
  'internal test' = 'Internal',
  'customer test' = 'Customer',
  demo = 'Demo',
  dev = 'Dev',
}

export enum StatusCircleColorEnum {
  prod = 'Production',
  sandbox = 'Test',
  'internal test' = 'Internal test',
  'customer test' = 'Customer test',
  demo = 'Demo',
  dev = 'Development',
}

const allowedProductionVendorIdTypes: VendorEnvironmentEnum[] = [
  VendorEnvironmentEnum.PROD,
  VendorEnvironmentEnum.DEMO,
  VendorEnvironmentEnum.DEV,
  VendorEnvironmentEnum.INTERNAL_TEST,
  VendorEnvironmentEnum.CUSTOMER_TEST,
];

export const getVendorEnvironmentLabel = ({
  environment,
  isShortLabel,
}: {
  environment: string;
  isShortLabel: boolean;
}): string => {
  if (typeof environment !== 'string') return environment;
  if (isShortLabel)
    return VendorEnvironmentShortLabelEnum[environment] || environment;
  return VendorEnvironmentLabelEnum[environment] || environment;
};

// This is the production_vendor_id associated to the sandbox vendor_type
export const getProductionVendorId = (vendor: Vendor) =>
  allowedProductionVendorIdTypes.includes(
    vendor.vendor_type as VendorEnvironmentEnum,
  )
    ? vendor.id
    : vendor.production_vendor_id;

export const getEnvironmentAcronyms = (environmentName?: string) =>
  environmentName?.charAt(0).toUpperCase();

export const getVendorEnvironmentsList = ({
  productionVendor,
  sandboxes,
}: {
  productionVendor: Vendor;
  sandboxes: Array<Vendor>;
}) => {
  const hasMultipleSandboxes = sandboxes.length > 1;
  const testSandboxName = '[Test]';
  const test1SandboxName = '[Test 1]';
  // Add altName for sorting [Test] before additional [Test n]
  const sandboxesWithAltName = sandboxes.map((sandbox) => ({
    ...sandbox,
    altName: hasMultipleSandboxes
      ? sandbox.name.replace(testSandboxName, test1SandboxName)
      : sandbox.name,
  }));

  const sortedSandboxes = sortBy(sandboxesWithAltName, 'altName').map(
    (sandbox) => ({
      ...sandbox,
      vendor_type: VendorEnvironmentEnum.SANDBOX,
    }),
  );
  return [productionVendor, ...sortedSandboxes];
};
