import React, { Dispatch, useEffect, useReducer, useState } from 'react';
import { defaultState } from 'stores/utils';
import {
  getLocalStorageState,
  setLocalStorageState,
} from 'stores/cache/localStorage';
import { ProviderProps } from 'stores/typings';
import Context, { storageKey } from './context';
import reducer, { PrivateOfferReducerAction } from './reducer';
import {
  deserialize,
  PrivateOffer,
  PrivateOffersReducerState,
  serialize,
} from './typings';
import { useLocation } from 'react-router-dom';
import URLSearchParams from '@ungap/url-search-params';
import { getPrivateOffers } from './offersApi';

interface PrivateOfferProviderProps extends ProviderProps {
  selectedOffer?: PrivateOffer;
}

const PRIVATE_OFFERS_PATHNAME = '/private-offers';

const getCachedDataFromLocalStorage = (localStorageKey: string) => {
  const { data: cachedContent } = getLocalStorageState(localStorageKey);
  return cachedContent?.map(deserialize);
};

const getSearchParamsValidForSync = (search: string): URLSearchParams => {
  const urlSearchParams = new URLSearchParams(search);
  urlSearchParams.delete('view');
  urlSearchParams.delete('search');
  urlSearchParams.delete('companyName');
  urlSearchParams.delete('enableTestEnvironment');

  return urlSearchParams;
};

const normalizeSearchParams = (
  searchParams: URLSearchParams,
): URLSearchParams => {
  if (!searchParams.has('sort')) {
    searchParams.set('sort', 'last_modified_at_desc');
  }
  return searchParams;
};

const syncPrivateOffers = (
  dispatch: Dispatch<any>,
  searchParams: URLSearchParams,
) => {
  dispatch({ type: PrivateOfferReducerAction.Sync });

  getPrivateOffers({ searchParams })
    .then((privateOffers: PrivateOffer[]) => {
      dispatch({
        type: PrivateOfferReducerAction.SetContent,
        payload: { content: privateOffers },
      });
    })
    .catch((error) => {
      dispatch({
        type: PrivateOfferReducerAction.SetError,
        payload: { error },
      });
    });
};

const Provider: React.FC<PrivateOfferProviderProps> = ({
  content = [],
  selectedOffer = undefined,
  disableSync = false,
  disableCache = false,
  localStorageKey = storageKey,
  children,
}) => {
  const { pathname, search } = useLocation();
  const [priorSearchParams, setPriorSearchParams] =
    useState<URLSearchParams>(null);

  const providerContent = disableCache
    ? content
    : getCachedDataFromLocalStorage(localStorageKey);

  // setup Context reducer
  const [state, dispatch] = useReducer(
    reducer,
    defaultState({
      content: providerContent,
      selectedOffer,
      listSearchParams: priorSearchParams,
    } as PrivateOffersReducerState),
  );

  /*
  server-side filtering offer sync flow

  Since we're no longer loading all offers and filtering client-side, this is
  the only place where we can catch all cases where a sync should
  happen. This is why we no longer sync directly after a filter change and
  instead rely on the searchParams changing to trigger a sync here, passing
  those along to offers-api.

  Also since, 'view' and 'search' can appear in the url by interacting with
  offers list controls, we strip those keys and values from the searchParams,
  for comparison against the prior searchParams to determine whether to sync.
  This way, search and the offers view can change without triggering a sync.
  Likewise, it allows the filters present in the url to be reflected in the UI
  when navigating directly to the offers page.

  Once server-side full-text search is implemented, we should no longer strip
  'search' from the searchParams when determining whether to sync, since a
  change to 'search' should trigger one. This call will likely need to be
  debounced to ensure we're not spamming offers-api as the user types in the
  search field.

  We only strip 'search' and 'view' from the searchParams for determining when
  to sync, but the full searchParams are passed to offers-api when syncing.
  This is so that 'search' will be taken into account once full-text search is
  implemented.
  */
  useEffect(() => {
    if (disableSync || pathname !== PRIVATE_OFFERS_PATHNAME) {
      return;
    }

    const searchParamsValidForSync = getSearchParamsValidForSync(search);
    const shouldSyncOffers =
      searchParamsValidForSync.toString() !== priorSearchParams?.toString();

    if (!shouldSyncOffers) {
      return;
    }

    const apiSearchParams = normalizeSearchParams(new URLSearchParams(search));

    syncPrivateOffers(dispatch, apiSearchParams);
    setPriorSearchParams(searchParamsValidForSync);

    dispatch({
      type: PrivateOfferReducerAction.UpdateListSearchParams,
      payload: { content: apiSearchParams },
    });
  }, [disableSync, pathname, search, priorSearchParams]);

  // save content updates to cache
  useEffect(() => {
    if (state.content && !disableCache) {
      setLocalStorageState(storageKey, state.content.map(serialize));
    }
  }, [state.content, disableCache]);

  return (
    <Context.Provider value={{ ...state, dispatch }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;
