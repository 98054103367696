import { OpportunityResponse } from 'pages/CoSell/types/responses/OpportunityResponse';
import { useHistory } from 'react-router';

export interface CoSellHistoryState {
  // metadata search term
  coSellSearchTerm?: string;
  // react router search params
  coSellSearchParams?: string;
  opportunity?: OpportunityResponse;
  opportunityId?: string;
}

export const useCoSellHistory = () => {
  const history = useHistory<CoSellHistoryState>();
  return history;
};
