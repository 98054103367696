import React from 'react';
import { Grid } from 'vendor/material';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';
import { PersonIcon } from 'packages/cosell/assets';
import { Contact } from 'packages/cosell/src/types/responses/AceOpportunityResponse';

const CHARACTER_COUNT = 25;
interface AcePartnerDetailsProps {
  partnerContact: Contact;
}

const AcePartnerDetails: React.FC<AcePartnerDetailsProps> = ({
  partnerContact,
}) => {
  return (
    <DetailsSectionCard
      title="Partner details"
      icon={<PersonIcon />}
      subTitle="Provide the details of the sales contact at your company who will work this opportunity."
    >
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Primary contact first name"
          value={partnerContact?.firstName}
        />

        <InfoItem
          itemTitle="Primary contact last name"
          value={partnerContact?.lastName}
        />

        <InfoItem
          itemTitle="Primary contact title"
          value={partnerContact?.businessTitle}
          gridSize={partnerContact?.email?.length > CHARACTER_COUNT ? 12 : 6}
        />

        <InfoItem
          itemTitle="Primary contact email"
          value={partnerContact?.email}
          gridSize={partnerContact?.email?.length > CHARACTER_COUNT ? 12 : 6}
        />
        <InfoItem
          itemTitle="Primary contact phone"
          value={partnerContact?.phone}
          gridSize={12}
        />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AcePartnerDetails;
