import React from 'react';
import { Box, Typography } from 'vendor/material';
import useStyles from './RightRailContactInfoSection.style';

interface RightRailContactInfoProps {
  businessTitle?: string;
  name?: string;
  email?: string;
}

const RightRailContactInfo: React.FC<RightRailContactInfoProps> = ({
  businessTitle,
  name,
  email,
}) => {
  const classes = useStyles();
  return (
    <>
      {!businessTitle && !name && !email ? null : (
        <Box className={classes.root}>
          {businessTitle && (
            <>
              <Typography
                component="span"
                variant="body2"
                className={classes.businessTitle}
              >
                {businessTitle}:
              </Typography>{' '}
            </>
          )}
          {name && (
            <Typography
              component="span"
              variant="body2"
              className={classes.contactName}
            >
              {name}
            </Typography>
          )}
          {email && (
            <Typography variant="body2" className={classes.contactEmail}>
              <a href={`mailto:${email}`}>{email}</a>
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default RightRailContactInfo;
