import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Popover } from 'vendor/material';
import { Calendar as CalendarIcon } from 'mdi-material-ui';
import { Dropdown, Calendar, Button } from '@tackle-io/platform-ui';
import { useHistory } from 'react-router-dom';

// ASSETS
import useStyles from './FilterDate.styles';

interface FilterDateProps {
  label?: string;
  slug?: string;
}

const FilterDate: React.FC<FilterDateProps> = ({
  label = 'Creation date',
  slug = 'create_date',
}) => {
  // HOOKS
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  // MISC
  const searchParams = new URLSearchParams(history.location.search);
  const open = !!anchorEl;
  const id = open ? 'date-popover' : undefined;

  // EVENTS
  const handleClick = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleChangeDate = (value, event): void => {
    // converting dates to DateTime object for having a better format method
    const dates = value.map((item) => {
      const newDate = {
        year: item.getFullYear(),
        month: item.getMonth() + 1,
        day: item.getDate(),
      };
      return DateTime.fromObject(newDate);
    });

    // setting to null will close the calendar
    setAnchorEl(null);

    if (searchParams.has(`${slug}__gt`)) {
      searchParams.set(`${slug}__gt`, dates[0].toFormat('yyyyLLdd'));
    } else {
      searchParams.append(`${slug}__gt`, dates[0].toFormat('yyyyLLdd'));
    }

    if (searchParams.has(`${slug}__lt`)) {
      searchParams.set(`${slug}__lt`, dates[1].toFormat('yyyyLLdd'));
    } else {
      searchParams.append(`${slug}__lt`, dates[1].toFormat('yyyyLLdd'));
    }

    history.push({ search: searchParams.toString() });
  };

  const handleClearDate = (): void => {
    if (searchParams.has(`${slug}__gt`)) {
      searchParams.delete(`${slug}__gt`);
    }

    if (searchParams.has(`${slug}__lt`)) {
      searchParams.delete(`${slug}__lt`);
    }

    history.push({ search: searchParams.toString() });
  };

  // RENDERS
  const selectedDate = [];

  if (searchParams.has(`${slug}__gt`)) {
    selectedDate.push(DateTime.fromISO(searchParams.get(`${slug}__gt`)));
  }

  if (searchParams.has(`${slug}__lt`)) {
    selectedDate.push(DateTime.fromISO(searchParams.get(`${slug}__lt`)));
  }

  let selectedDateString = '';
  if (selectedDate.length > 1) {
    selectedDateString = selectedDate
      .map((date) => date.toFormat('LLL dd, yyyy'))
      .join(' - ');
  }

  return (
    <div id="filterDate">
      <Dropdown
        value={selectedDateString}
        onClick={handleClick}
        placeholder={label}
        startIcon={<CalendarIcon />}
        fullWidth
        hideEndIcon
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.calendarContent}>
          <Calendar
            selectRange
            onChange={handleChangeDate}
            value={
              selectedDate.length > 1
                ? selectedDate.map((date) => date.toJSDate())
                : null
            }
          />
        </div>
        {selectedDateString && (
          <div className={classes.calendarFooter}>
            <Button
              variant="outlined"
              appearance="secondary"
              onClick={handleClearDate}
            >
              Clear Dates
            </Button>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default FilterDate;
