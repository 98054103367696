import { Alert } from '@tackle-io/platform-ui';
import {
  Alert as AlertIcon,
  CheckboxMarked,
  Download,
  Information,
  Close,
} from 'mdi-material-ui';
import { Box, CircularProgress } from 'vendor/material';

// ASSETS
import useStyles from './useToast.styles';

export const ToastLoading = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.toastContainer}>
      <CircularProgress size={18} className={classes.circularLoading} />
      <Box pl={2}>
        <span>{message}</span>
      </Box>
    </div>
  );
};

export const ToastInfo = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.toastContainer}>
      <Information />
      <Box pl={2}>
        <span>{message}</span>
      </Box>
    </div>
  );
};

export const ToastSuccess = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.toastContainer}>
      <CheckboxMarked />
      <Box pl={2}>
        <span>{message}</span>
      </Box>
    </div>
  );
};

export const ToastExport = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.toastContainer}>
      <Download />
      <Box pl={2}>
        <span>{message}</span>
      </Box>
    </div>
  );
};

export const ToastDanger = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.toastContainer}>
      <AlertIcon />
      <Box pl={2}>
        <span>{message}</span>
      </Box>
    </div>
  );
};

export const ToastDangerButton = ({ message, redirectButton }) => {
  const classes = useStyles();

  return (
    <div className={classes.toastContainer}>
      <AlertIcon />
      <Box pl={2}>
        <span>{message}</span>
        <span className={classes.toastButtonSpan}>{redirectButton}</span>
      </Box>
    </div>
  );
};

export const ToastAlertWithCloseButton = ({ message, onClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.toastContainer}>
      <Alert appearance="warning" title={message} noShadow size="medium" />
      <Close onClick={() => onClose()} />
    </div>
  );
};
