import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { useAuth } from 'vendor/auth0';

const LegacyLogout = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const returnTo = searchParams.get('returnTo');

  const { logout } = useAuth();

  useEffect(() => {
    logout({ returnTo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LegacyLogout;
