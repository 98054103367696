import { useContext, useCallback, useMemo } from 'react';
import { find } from 'lodash/fp';

import api from 'utils/api';

import { withConvenienceBooleans } from 'stores/utils';
import Context from './context';
import { serialize, deserialize, Product, ProductJSON } from './typings';

import {
  ProductsReducerState,
  APIResponse,
  APISuccessResponse,
  isAPISuccess,
} from './reducer';

type Hook = Omit<ProductsReducerState, 'dispatch'> & {
  update: (
    productid: Product['productid'],
    cloud: Product['cloud'],
    updates: Partial<Product>,
  ) => Promise<Product>;
  sync: (
    productidInternal: Product['productidInternal'],
    cloud: Product['cloud'],
  ) => Promise<void>;
};

const useProducts = (): Hook => {
  const { content, status, error, dispatch } = useContext(
    Context,
  ) as ProductsReducerState;

  const update = (
    productid: Product['productid'],
    cloud: Product['cloud'],
    updates: Partial<Product>,
  ): Promise<Product> =>
    new Promise((resolve, reject) => {
      dispatch({ type: 'UPDATE' });

      api
        .put(`product/${productid}`, {
          json: serialize(updates),
          throwHttpErrors: false,
        })
        .json()
        .then(
          (resp: APISuccessResponse) =>
            new Promise<APIResponse>((res, rej) =>
              isAPISuccess(resp) ? res(resp) : rej(resp),
            ),
        )
        .then(({ product }: APISuccessResponse) => {
          const updatedContent = deserialize({ cloud, ...product });
          dispatch({
            type: 'UPDATE_CONTENT_BY_ID',
            payload: {
              id: productid,
              cloud,
              content: updatedContent,
            },
          });
          resolve(updatedContent);
        })
        .catch((requestError) => {
          dispatch({ type: 'SET_ERROR', payload: { error: requestError } });
          reject(requestError);
        });
    });

  const sync = async (
    productidInternal: Product['productidInternal'],
    cloud: Product['cloud'],
  ): Promise<void> => {
    try {
      dispatch({ type: 'SYNC' });

      const data = (await api
        .get(`product/${productidInternal}`, {})
        .json()) as ProductJSON;

      const deserializedData = deserialize({ cloud, ...data });
      const product = content.find(
        (p) => p.productidInternal === productidInternal,
      );
      const updatedContent = {
        ...deserializedData,
        // GET /product/productidInternal doesn't return the contract standard field with cloud_field: true.
        // The code below ensures it isn't removed from the product in context.

        contractStandardFields: [
          ...product.contractStandardFields.filter((f) => f.cloudField),
          ...deserializedData.contractStandardFields,
        ],
      };

      dispatch({
        type: 'UPDATE_CONTENT_BY_ID',
        payload: {
          id: data.productid,
          cloud,
          content: updatedContent,
        },
      });
    } catch (error) {
      dispatch({ type: 'SET_ERROR', payload: { error } });
      throw error;
    }
  };

  return {
    ...withConvenienceBooleans({
      content,
      status,
      error,
    }),
    update,
    sync,
  } as Hook;
};

export default useProducts;

type SelectedHook = Omit<Hook, 'content' | 'update'> & {
  content: Product;
  update: (updates: Partial<Product>) => Promise<Product>;
};

export const useSelectedProduct = (
  productid: Product['productid'],
  cloud: Product['cloud'],
): SelectedHook => {
  const { content, update, ...store }: Hook = useProducts();

  return {
    ...withConvenienceBooleans({
      ...store,
      content: useMemo(
        () => find({ productid, cloud }, content),
        [content, productid, cloud],
      ),
    }),
    update: useCallback(
      (updates: Partial<Product>) => update(productid, cloud, updates),
      [productid, cloud, update],
    ),
  } as SelectedHook;
};
