import { Provider } from '@tackle-io/platform-ui';
import { DisplayCloudType } from '../types/enums';
import { useLocation } from 'react-router-dom';

export const CLOUD_KEY = 'cloud';

export const useGetCloudProvider = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const cloudParam = searchParams.get(CLOUD_KEY);
  if (cloudParam === DisplayCloudType.AWS) {
    return DisplayCloudType.AWS as Provider;
  }
  return DisplayCloudType.AWS as Provider;
};
